import { Box, Stack, Loader, Text } from 'braid-design-system';

import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialogNew';
import { WidgetError } from '../WidgetError/WidgetError';

export interface AdStatErrorOrLoadingProps {
  headingLabel: string;
  headingTooltip: ReactNodeNoStrings;
  isLoading: boolean;
}

export const AdStatErrorOrLoading = ({
  headingLabel,
  headingTooltip,
  isLoading,
}: AdStatErrorOrLoadingProps) => {
  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          height="full"
          justifyContent="center"
          padding="medium"
        >
          <Loader />
        </Box>
      );
    }

    return (
      <Stack space="small">
        <WidgetError />
      </Stack>
    );
  };

  return (
    <Box
      background="surface"
      borderRadius="large"
      height="full"
      boxShadow="borderNeutralLight"
      paddingY="gutter"
      paddingX="medium"
    >
      <Stack space="medium">
        <Box display="inlineBlock">
          <Text weight="strong">
            <DisclosureDialog
              id="heading-tooltip"
              tooltip={<Text size="small">{headingTooltip}</Text>}
            >
              {({ triggerProps }) => (
                <span role="button" {...triggerProps}>
                  {headingLabel}
                </span>
              )}
            </DisclosureDialog>
          </Text>
        </Box>
        {renderContent()}
      </Stack>
    </Box>
  );
};
