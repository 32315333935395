
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiLguK3guLHguJXguKPguLLguIHguLLguKPguJTguLnguJXguYjguK3guIHguLLguKPguITguKXguLTguIEgPSDguIHguLLguKPguITguKXguLTguIEv4LiB4Liy4Lij4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiLguJXguLHguKfguK3guKLguYjguLLguIfguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYHguKrguJTguIfguYPguJnguIHguLLguKPguITguYnguJnguKvguLLguIfguLLguJnguILguK3guIcgU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiLguK3guLHguJXguKPguLLguIHguLLguKPguJTguLnguJXguYjguK3guIHguLLguKPguITguKXguLTguIEgPSDguIHguLLguKPguITguKXguLTguIEv4LiB4Liy4Lij4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiLguJXguLHguKfguK3guKLguYjguLLguIfguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYHguKrguJTguIfguYPguJnguIHguLLguKPguITguYnguJnguKvguLLguIfguLLguJnguILguK3guIcgU0VFSyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlJhc2lvIGxpaGF0LWtlLWtsaWsgPSBLbGlrIGlrbGFuIC8gdGFtcGlsYW4gZGFsYW0gcGVuY2FyaWFuIHBla2VyamFhbi4iLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IkN1cGxpa2FuIGlrbGFuIEFuZGEgbXVuY3VsIGRpIHBlbmNhcmlhbiBsb3dvbmdhbiBrZXJqYSBTRUVLLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlJhc2lvIGxpaGF0LWtlLWtsaWsgPSBLbGlrIGlrbGFuIC8gdGFtcGlsYW4gZGFsYW0gcGVuY2FyaWFuIHBla2VyamFhbi4iLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IkN1cGxpa2FuIGlrbGFuIEFuZGEgbXVuY3VsIGRpIHBlbmNhcmlhbiBsb3dvbmdhbiBrZXJqYSBTRUVLLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiLguK3guLHguJXguKPguLLguIHguLLguKPguJTguLnguJXguYjguK3guIHguLLguKPguITguKXguLTguIEgPSDguIHguLLguKPguITguKXguLTguIEv4LiB4Liy4Lij4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiLguJXguLHguKfguK3guKLguYjguLLguIfguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYHguKrguJTguIfguYPguJnguIHguLLguKPguITguYnguJnguKvguLLguIfguLLguJnguILguK3guIcgU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoi4LiU4Li54LmD4LiZ4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4LmA4Lib4Lil4Li14LmI4Lii4LiZ4Lih4Liy4LiE4Lil4Li04LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiVmlldy10by1jbGljayByYXRlIGZvcm11bGEiOiLguK3guLHguJXguKPguLLguIHguLLguKPguJTguLnguJXguYjguK3guIHguLLguKPguITguKXguLTguIEgPSDguIHguLLguKPguITguKXguLTguIEv4LiB4Liy4Lij4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiLguJXguLHguKfguK3guKLguYjguLLguIfguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYHguKrguJTguIfguYPguJnguIHguLLguKPguITguYnguJnguKvguLLguIfguLLguJnguILguK3guIcgU0VFSyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlJhc2lvIGxpaGF0LWtlLWtsaWsgPSBLbGlrIGlrbGFuIC8gdGFtcGlsYW4gZGFsYW0gcGVuY2FyaWFuIHBla2VyamFhbi4iLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IkN1cGxpa2FuIGlrbGFuIEFuZGEgbXVuY3VsIGRpIHBlbmNhcmlhbiBsb3dvbmdhbiBrZXJqYSBTRUVLLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiQmVyYXBhIGthbGkgaWtsYW4gQW5kYSBkaWxpaGF0Iiwie3BlcmNlbnRhZ2V9IHZpZXctdG8tY2xpY2sgcmF0ZSI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIHZpZXctdG8tY2xpY2sgcmF0ZSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4gZGlrb252ZXJzaSBtZW5qYWRpIGp1bWxhaCBrbGlrIHBhZGEgaWtsYW4iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlJhc2lvIGxpaGF0LWtlLWtsaWsgPSBLbGlrIGlrbGFuIC8gdGFtcGlsYW4gZGFsYW0gcGVuY2FyaWFuIHBla2VyamFhbi4iLCJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaCI6IkN1cGxpa2FuIGlrbGFuIEFuZGEgbXVuY3VsIGRpIHBlbmNhcmlhbiBsb3dvbmdhbiBrZXJqYSBTRUVLLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiVmlld3MgaW4gam9iIHNlYXJjaCIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiI8TWVkaXVtPntwZXJjZW50YWdlfTwvTWVkaXVtPiB2aWV3LXRvLWNsaWNrIHJhdGUiLCJ7cGVyY2VudGFnZX0gY29udmVydGVkIHRvIGFkIGNsaWNrcyI6IjxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIGNvbnZlcnRlZCB0byBhZCBjbGlja3MiLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlZpZXctdG8tY2xpY2sgcmF0ZSA9IEFkIGNsaWNrcyAvIHZpZXdzIGluIGpvYiBzZWFyY2guIiwiWW91ciBhZCBzbmlwcGV0IGFwcGVhcmVkIGluIHRoZSBTRUVLIGpvYiBzZWFyY2giOiJZb3VyIGFkIHNuaXBwZXQgYXBwZWFyZWQgaW4gdGhlIFNFRUsgam9iIHNlYXJjaC4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiW%2BG5vMOsw6zDrOG6veG6veG6vcW1xaEgw6zDrMOs4LiB4Li14LmJIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiJbPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4bm9w6zDrMOs4bq94bq94bq9xbUt4bmvw7bDtsO2LcOnxprDrMOsw6zDp8S3IMWZxIPEg8SD4bmv4bq94bq94bq9XSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiWzxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIMOnw7bDtsO24LiB4Li14LmJ4bm94bq94bq94bq9xZnhua%2Fhur3hur3hur3GjCDhua%2FDtsO2w7YgxIPEg8SDxowgw6fGmsOsw6zDrMOnxLfFoV0iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlvhubzDrMOsw6zhur3hur3hur3FtS3hua%2FDtsO2w7Ytw6fGmsOsw6zDrMOnxLcgxZnEg8SDxIPhua%2Fhur3hur3hur0gPSDhuqzGjCDDp8aaw6zDrMOsw6fEt8WhIC8g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDDrMOsw6zguIHguLXguYkgxLXDtsO2w7bDnyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiW8Odw7bDtsO2x5rHmseaxZkgxIPEg8SDxowgxaHguIHguLXguYnDrMOsw6zGpcal4bq94bq94bq94bmvIMSDxIPEg8alxqXhur3hur3hur3Eg8SDxIPFmeG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5iSDhua%2FhuKnhur3hur3hur0gxaDDi8OL4biwIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3cyBpbiBqb2Igc2VhcmNoIjoiW%2BG5vMOsw6zDrOG6veG6veG6vcW1xaEgw6zDrMOs4LiB4Li14LmJIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSIsIntwZXJjZW50YWdlfSB2aWV3LXRvLWNsaWNrIHJhdGUiOiJbPE1lZGl1bT57cGVyY2VudGFnZX08L01lZGl1bT4g4bm9w6zDrMOs4bq94bq94bq9xbUt4bmvw7bDtsO2LcOnxprDrMOsw6zDp8S3IMWZxIPEg8SD4bmv4bq94bq94bq9XSIsIntwZXJjZW50YWdlfSBjb252ZXJ0ZWQgdG8gYWQgY2xpY2tzIjoiWzxNZWRpdW0%2Be3BlcmNlbnRhZ2V9PC9NZWRpdW0%2BIMOnw7bDtsO24LiB4Li14LmJ4bm94bq94bq94bq9xZnhua%2Fhur3hur3hur3GjCDhua%2FDtsO2w7YgxIPEg8SDxowgw6fGmsOsw6zDrMOnxLfFoV0iLCJWaWV3LXRvLWNsaWNrIHJhdGUgZm9ybXVsYSI6IlvhubzDrMOsw6zhur3hur3hur3FtS3hua%2FDtsO2w7Ytw6fGmsOsw6zDrMOnxLcgxZnEg8SDxIPhua%2Fhur3hur3hur0gPSDhuqzGjCDDp8aaw6zDrMOsw6fEt8WhIC8g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDDrMOsw6zguIHguLXguYkgxLXDtsO2w7bDnyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIllvdXIgYWQgc25pcHBldCBhcHBlYXJlZCBpbiB0aGUgU0VFSyBqb2Igc2VhcmNoIjoiW8Odw7bDtsO2x5rHmseaxZkgxIPEg8SDxowgxaHguIHguLXguYnDrMOsw6zGpcal4bq94bq94bq94bmvIMSDxIPEg8alxqXhur3hur3hur3Eg8SDxIPFmeG6veG6veG6vcaMIMOsw6zDrOC4geC4teC5iSDhua%2FhuKnhur3hur3hur0gxaDDi8OL4biwIMS1w7bDtsO2w58gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    