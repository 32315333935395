import { Box } from 'braid-design-system';
import type { ReactNode, RefObject } from 'react';

import { JobAdListTableHeader } from 'src/components/JobAdListTableHeader/JobAdListTableHeader';

import * as styles from './JobAdList.css';

export const JobAdListContainer = ({
  jobAdListRef,
  testId,
  lastUpdatedAgo,
  children,
}: {
  jobAdListRef: RefObject<HTMLElement>;
  testId?: string;
  lastUpdatedAgo: ReactNode | string | null;
  children: ReactNode;
}) => (
  <Box
    ref={jobAdListRef}
    borderRadius="large"
    paddingY="small"
    data-testid={testId}
    boxShadow="borderNeutralLight"
    className={styles.JobAdListContainerStyle}
  >
    <JobAdListTableHeader lastUpdatedAgo={lastUpdatedAgo} />
    {children}
  </Box>
);

export const JobAdListErrorContainer = ({
  testId,
  lastUpdatedAgo,
  children,
}: {
  testId?: string;
  lastUpdatedAgo: ReactNode | string | null;
  children: ReactNode;
}) => (
  <Box
    borderRadius="large"
    paddingY="small"
    data-testid={testId}
    boxShadow="borderNeutralLight"
  >
    <JobAdListTableHeader lastUpdatedAgo={lastUpdatedAgo} />
    {children}
  </Box>
);
