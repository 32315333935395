
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiLguITguLjguJPguIHguLPguKXguLHguIfguJTguLnguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJogPFRleHQ%2BPC9UZXh0PiDguJrguLHguI3guIrguLXguJrguKPguLTguKnguLHguJfguKXguLnguIHguYDguJfguYjguLLguJnguLHguYnguJkiLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiLguYHguKrguJTguIfguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJoge2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiLguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguYnguLLguJbguLbguIfguJrguLHguI3guIrguLXguK3guLfguYjguJkg4LmC4Lib4Lij4LiU4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiLguITguLjguJPguIHguLPguKXguLHguIfguJTguLnguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJogPFRleHQ%2BPC9UZXh0PiDguJrguLHguI3guIrguLXguJrguKPguLTguKnguLHguJfguKXguLnguIHguYDguJfguYjguLLguJnguLHguYnguJkiLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiLguYHguKrguJTguIfguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJoge2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiLguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguYnguLLguJbguLbguIfguJrguLHguI3guIrguLXguK3guLfguYjguJkg4LmC4Lib4Lij4LiU4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJBbmRhIHNlZGFuZyBtZWxpaGF0IGRhdGEgdW50dWsgPFRleHQ%2BPC9UZXh0PiBoYW55YSBha3VuIGFuYWsgc2FqYS4iLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiJUYW1waWxrYW4gZGF0YSB1bnR1ayB7YWNjb3VudE5hbWV9ICh7YWNjb3VudE51bWJlcn0pIiwiSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiI6Ikppa2EgQW5kYSBtZW1idXR1aGthbiBha3NlcyB1bnR1ayBha3VuIGxhaW5ueWEsIG1lbmd1YmFoIGFrdW4gYXRhdSBrb250YWsgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJBbmRhIHNlZGFuZyBtZWxpaGF0IGRhdGEgdW50dWsgPFRleHQ%2BPC9UZXh0PiBoYW55YSBha3VuIGFuYWsgc2FqYS4iLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiJUYW1waWxrYW4gZGF0YSB1bnR1ayB7YWNjb3VudE5hbWV9ICh7YWNjb3VudE51bWJlcn0pIiwiSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiI6Ikppa2EgQW5kYSBtZW1idXR1aGthbiBha3NlcyB1bnR1ayBha3VuIGxhaW5ueWEsIG1lbmd1YmFoIGFrdW4gYXRhdSBrb250YWsgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiLguITguLjguJPguIHguLPguKXguLHguIfguJTguLnguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJogPFRleHQ%2BPC9UZXh0PiDguJrguLHguI3guIrguLXguJrguKPguLTguKnguLHguJfguKXguLnguIHguYDguJfguYjguLLguJnguLHguYnguJkiLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiLguYHguKrguJTguIfguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJoge2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiLguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguYnguLLguJbguLbguIfguJrguLHguI3guIrguLXguK3guLfguYjguJkg4LmC4Lib4Lij4LiU4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiLguITguLjguJPguIHguLPguKXguLHguIfguJTguLnguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJogPFRleHQ%2BPC9UZXh0PiDguJrguLHguI3guIrguLXguJrguKPguLTguKnguLHguJfguKXguLnguIHguYDguJfguYjguLLguJnguLHguYnguJkiLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiLguYHguKrguJTguIfguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJoge2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiLguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYDguILguYnguLLguJbguLbguIfguJrguLHguI3guIrguLXguK3guLfguYjguJkg4LmC4Lib4Lij4LiU4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJBbmRhIHNlZGFuZyBtZWxpaGF0IGRhdGEgdW50dWsgPFRleHQ%2BPC9UZXh0PiBoYW55YSBha3VuIGFuYWsgc2FqYS4iLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiJUYW1waWxrYW4gZGF0YSB1bnR1ayB7YWNjb3VudE5hbWV9ICh7YWNjb3VudE51bWJlcn0pIiwiSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiI6Ikppa2EgQW5kYSBtZW1idXR1aGthbiBha3NlcyB1bnR1ayBha3VuIGxhaW5ueWEsIG1lbmd1YmFoIGFrdW4gYXRhdSBrb250YWsgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJBbmRhIHNlZGFuZyBtZWxpaGF0IGRhdGEgdW50dWsgPFRleHQ%2BPC9UZXh0PiBoYW55YSBha3VuIGFuYWsgc2FqYS4iLCJTaG93aW5nIGRhdGEgZm9yIHthY2NvdW50TmFtZX0gKHthY2NvdW50TnVtYmVyfSkiOiJUYW1waWxrYW4gZGF0YSB1bnR1ayB7YWNjb3VudE5hbWV9ICh7YWNjb3VudE51bWJlcn0pIiwiSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiI6Ikppa2EgQW5kYSBtZW1idXR1aGthbiBha3NlcyB1bnR1ayBha3VuIGxhaW5ueWEsIG1lbmd1YmFoIGFrdW4gYXRhdSBrb250YWsgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIDxUZXh0PjwvVGV4dD4gY2hpbGQgYWNjb3VudCBvbmx5LiIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJJZiB5b3UgbmVlZCBhY2Nlc3MgdG8gb3RoZXIgYWNjb3VudHMsIGVpdGhlciBzd2l0Y2ggYWNjb3VudHMgb3IgY29udGFjdCB5b3VyIGFkbWluaXN0cmF0b3IuIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJbw53DtsO2w7bHmseax5onxZnhur3hur3hur0gxaHhur3hur3hur3hur3hur3hur3DrMOsw6zguIHguLXguYnEoyDGjMSDxIPEg%2BG5r8SDxIPEgyDGksO2w7bDtsWZIDxUZXh0PjwvVGV4dD4gw6fhuKnDrMOsw6zGmsaMIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gw7bDtsO24LiB4Li14LmJxprDvcO9w70uXSIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgxozEg8SDxIPhua%2FEg8SDxIMgxpLDtsO2w7bFmSB7YWNjb3VudE5hbWV9ICh7YWNjb3VudE51bWJlcn0pXSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJbw4%2FGkiDDvcO9w73DtsO2w7bHmseax5og4LiB4Li14LmJ4bq94bq94bq94bq94bq94bq9xowgxIPEg8SDw6fDp%2BG6veG6veG6vcWhxaEg4bmvw7bDtsO2IMO2w7bDtuG5r%2BG4qeG6veG6veG6vcWZIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoSwg4bq94bq94bq9w6zDrMOs4bmv4bip4bq94bq94bq9xZkgxaHFtcOsw6zDrOG5r8On4bipIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoSDDtsO2w7bFmSDDp8O2w7bDtuC4geC4teC5ieG5r8SDxIPEg8On4bmvIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMbcyCw6zDrMOs4LiB4Li14LmJw6zDrMOsxaHhua%2FFmcSDxIPEg%2BG5r8O2w7bDtsWZLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgc2VlaW5nIGRhdGEgZm9yIEFDQ09VTlRfTkFNRSBjaGlsZCBhY2NvdW50IG9ubHkiOiJbw53DtsO2w7bHmseax5onxZnhur3hur3hur0gxaHhur3hur3hur3hur3hur3hur3DrMOsw6zguIHguLXguYnEoyDGjMSDxIPEg%2BG5r8SDxIPEgyDGksO2w7bDtsWZIDxUZXh0PjwvVGV4dD4gw6fhuKnDrMOsw6zGmsaMIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8gw7bDtsO24LiB4Li14LmJxprDvcO9w70uXSIsIlNob3dpbmcgZGF0YSBmb3Ige2FjY291bnROYW1lfSAoe2FjY291bnROdW1iZXJ9KSI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgxozEg8SDxIPhua%2FEg8SDxIMgxpLDtsO2w7bFmSB7YWNjb3VudE5hbWV9ICh7YWNjb3VudE51bWJlcn0pXSIsIklmIHlvdSBuZWVkIGFjY2VzcyB0byBvdGhlciBhY2NvdW50cywgZWl0aGVyIHN3aXRjaCBhY2NvdW50cyBvciBjb250YWN0IHlvdXIgYWRtaW5pc3RyYXRvci4iOiJbw4%2FGkiDDvcO9w73DtsO2w7bHmseax5og4LiB4Li14LmJ4bq94bq94bq94bq94bq94bq9xowgxIPEg8SDw6fDp%2BG6veG6veG6vcWhxaEg4bmvw7bDtsO2IMO2w7bDtuG5r%2BG4qeG6veG6veG6vcWZIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoSwg4bq94bq94bq9w6zDrMOs4bmv4bip4bq94bq94bq9xZkgxaHFtcOsw6zDrOG5r8On4bipIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoSDDtsO2w7bFmSDDp8O2w7bDtuC4geC4teC5ieG5r8SDxIPEg8On4bmvIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMbcyCw6zDrMOs4LiB4Li14LmJw6zDrMOsxaHhua%2FFmcSDxIPEg%2BG5r8O2w7bDtsWZLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    