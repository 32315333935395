import { useAccountContext } from '@seek/hirer-graphql-react';
import { type Country, getSiteFromZoneAndProduct } from '@seek/melways-sites';
import { Box, Inline, Loader, Stack } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { useConfig } from 'src/App/ConfigContext';
import { AdPerformancePageFrame } from 'src/components/AdPerformancePageFrame/AdPerformancePageFrame';
import { AdRatingNotice } from 'src/components/AdRatingNotice/AdRatingNotice';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { NoAccess } from 'src/components/NoAccess/NoAccess';
import { RoundedCard } from 'src/components/RoundedCard/RoundedCard';
import { getLocale, isLocal } from 'src/config';
import { HasAdRatingProvider } from 'src/context/hasAdRating';
import { HasMarketInsightsProvider } from 'src/context/hasMarketInsights';
import { useAdPerformanceReport } from 'src/hooks/useAdPerformanceReport';
import { useRefFromUrl } from 'src/hooks/useRefFromUrl';
import { triggerHotjar } from 'src/utils/hotjar';
import {
  getBrandFromSiteName,
  trackAPRPage,
} from 'src/utils/tealiumEventTracker';
import { MarketInsights } from 'src/widgets/MarketInsights/MarketInsights';
import { MarketInsightsFallback } from 'src/widgets/MarketInsights/MarketInsightsFallback';
import { MyJobInformation } from 'src/widgets/MyJobInformation/MyJobInformation';
import { MyJobInformationFallback } from 'src/widgets/MyJobInformation/MyJobInformationFallback';
import { PageTitleStickySection } from 'src/widgets/PageTitleStickySection/PageTitleStickySection';
import { Recommendations } from 'src/widgets/Recommendations/Recommendations';
import { RecommendationsFallback } from 'src/widgets/Recommendations/RecommendationsFallback';
import { SimilarAds } from 'src/widgets/SimilarAds/SimilarAds';
import { SimilarAdsFallback } from 'src/widgets/SimilarAds/SimilarAdsFallback';

import { AdRatingSection } from '../../blocks/AdRatingSection/AdRatingSection';
import { AdRatingSectionFallback } from '../../blocks/AdRatingSection/AdRatingSectionFallback';
import { MoreStats } from '../../blocks/MoreStats/MoreStats';
import { MoreStatsFallback } from '../../blocks/MoreStats/MoreStatsFallback';

import { AdPerformanceReportFallback } from './AdPerformanceReportFallback';

export const AdPerformanceReport = () => {
  /* istanbul ignore next */
  if (!isLocal()) {
    if (window.location.hostname.includes('.staging')) {
      triggerHotjar('poll_iap');
    } else {
      triggerHotjar('poll_iap_prod');
    }
  }

  const { language, zone } = useConfig();
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();
  const {
    data: adPerformanceReportData,
    error,
    isLoading,
  } = useAdPerformanceReport({ jobId: jobIdParam }); // APR page auth check
  const { accountContext } = useAccountContext();
  const strHirerId = accountContext?.currentAdvertiser?.seekId;
  const hirerId = strHirerId !== undefined ? Number(strHirerId) : undefined;
  const userId = accountContext?.headerFooterContext?.user?.id;
  const refFromUrl = useRefFromUrl();

  if (isLoading) {
    return (
      <AdPerformancePageFrame>
        <Stack space="large">
          <RoundedCard>
            <Inline align="center" space="none">
              <Box padding="large">
                <Loader />
              </Box>
            </Inline>
          </RoundedCard>
        </Stack>
      </AdPerformancePageFrame>
    );
  }

  if (error || !adPerformanceReportData) {
    // TODO: to implement new datadog logger to log error
    const isUnauthorisedError =
      error?.graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED' ||
      error?.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN';

    if (isUnauthorisedError) {
      return (
        <AdPerformanceReportFallback>
          <NoAccess />
        </AdPerformanceReportFallback>
      );
    }

    return <AdPerformanceReportFallback />;
  }

  const siteCountry = getLocale().split('-')[1].toLowerCase() as Country;
  const siteName = getSiteFromZoneAndProduct(zone, 'employer');
  const brand = getBrandFromSiteName(siteName);

  trackAPRPage({
    siteCountry,
    currentPage: 'ad performance report',
    hirerUserEmail: accountContext?.currentUser?.email || null,
    hirerUserFullName: accountContext?.headerFooterContext?.user?.name || null,
    seekAdvertiserId: hirerId,
    jobId: parseInt(jobIdParam as string, 10),
    userId: (userId && parseInt(userId, 10)) || null,
    zone,
    siteLanguage: language,
    adPerformance: null,
    adPostingType: null,
    adDaysLive: null,
    adTipsCount: null,
    adStatus: null,
    siteSection: 'jobs',
    siteSubsection: 'analytics',
    adTips: null,
    brand,
    actionOrigin: refFromUrl,
  });

  return (
    <AdPerformancePageFrame
      hirerId={hirerId}
      stickyBar={
        <ErrorBoundary fallback={null}>
          <PageTitleStickySection />
        </ErrorBoundary>
      }
    >
      <Box>
        <Stack space="xlarge">
          <ErrorBoundary fallback={<AdRatingSectionFallback />}>
            <AdRatingSection />
          </ErrorBoundary>
          <Stack space="medium">
            <ErrorBoundary fallback={<MoreStatsFallback />}>
              <HasAdRatingProvider>
                <MoreStats />
              </HasAdRatingProvider>
            </ErrorBoundary>
            <AdRatingNotice />
          </Stack>
          <ErrorBoundary fallback={<RecommendationsFallback />}>
            <Recommendations />
          </ErrorBoundary>
          <ErrorBoundary fallback={<SimilarAdsFallback />}>
            <SimilarAds />
          </ErrorBoundary>
          <ErrorBoundary fallback={<MarketInsightsFallback />}>
            <HasMarketInsightsProvider>
              <MarketInsights />
            </HasMarketInsightsProvider>
          </ErrorBoundary>
          <ErrorBoundary fallback={<MyJobInformationFallback />}>
            <MyJobInformation />
          </ErrorBoundary>
        </Stack>
        <Box id="modal-base">&nbsp;</Box>
      </Box>
    </AdPerformancePageFrame>
  );
};
