
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiLguYDguKPguYfguKfguYDguIHguLTguJnguYTguJvguJfguLXguYjguIjguLDguJTguLnguKfguYjguLLguJzguKXguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYDguJvguYfguJnguK3guKLguYjguLLguIfguYTguKMg4Liq4Liy4Lih4Liy4Lij4LiW4LiU4Li54Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmE4LiU4LmJ4Lig4Liy4Lii4LmD4LiZ4Lit4Li14LiB4Liq4Lit4LiH4LiW4Li24LiH4Liq4Liy4Lih4Lin4Lix4LiZIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IuC5hOC4oeC5iOC4oeC4teC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IuC4oeC4teC4guC5ieC4reC4oeC4ueC4peC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4iOC4suC4geC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4peC4sOC4quC4luC4suC4meC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5g%2BC4iuC5ieC5gOC4nuC4t%2BC5iOC4reC4geC4suC4o%2BC5gOC4m%2BC4o%2BC4teC4ouC4muC5gOC4l%2BC4teC4ouC4muC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5hOC4lOC5ieC4reC4ouC5iOC4suC4h%2BC4luC4ueC4geC4leC5ieC4reC4hyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiLguYDguKPguYfguKfguYDguIHguLTguJnguYTguJvguJfguLXguYjguIjguLDguJTguLnguKfguYjguLLguJzguKXguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYDguJvguYfguJnguK3guKLguYjguLLguIfguYTguKMg4Liq4Liy4Lih4Liy4Lij4LiW4LiU4Li54Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmE4LiU4LmJ4Lig4Liy4Lii4LmD4LiZ4Lit4Li14LiB4Liq4Lit4LiH4LiW4Li24LiH4Liq4Liy4Lih4Lin4Lix4LiZIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IuC5hOC4oeC5iOC4oeC4teC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IuC4oeC4teC4guC5ieC4reC4oeC4ueC4peC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4iOC4suC4geC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4peC4sOC4quC4luC4suC4meC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5g%2BC4iuC5ieC5gOC4nuC4t%2BC5iOC4reC4geC4suC4o%2BC5gOC4m%2BC4o%2BC4teC4ouC4muC5gOC4l%2BC4teC4ouC4muC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5hOC4lOC5ieC4reC4ouC5iOC4suC4h%2BC4luC4ueC4geC4leC5ieC4reC4hyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJNYXNpaCB0ZXJsYWx1IGF3YWwgdW50dWsgbWVsaWhhdCBwZXJmb3JtYSBpa2xhbiBBbmRhLiBQZXJmb3JtYSBpa2xhbiBha2FuIHRlcnNlZGlhIGRhbGFtIGJlYmVyYXBhIGhhcmkuIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IlBlbmlsYWlhbiBwZXJmb3JtYSBwYXNhciB0aWRhayB0ZXJzZWRpYS4iLCJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlIG1lc3NhZ2UiOiJUaWRhayBhZGEgaW5mb3JtYXNpIHlhbmcgY3VrdXAgYmVyZGFzYXJrYW4gamFiYXRhbiBwZWtlcmphYW4gZGFuIGxva2FzaSBpa2xhbiB1bnR1ayBtZW1iZXJpa2FuIHBlcmJhbmRpbmdhbiBwZXJmb3JtYSB5YW5nIGFrdXJhdC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJNYXNpaCB0ZXJsYWx1IGF3YWwgdW50dWsgbWVsaWhhdCBwZXJmb3JtYSBpa2xhbiBBbmRhLiBQZXJmb3JtYSBpa2xhbiBha2FuIHRlcnNlZGlhIGRhbGFtIGJlYmVyYXBhIGhhcmkuIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IlBlbmlsYWlhbiBwZXJmb3JtYSBwYXNhciB0aWRhayB0ZXJzZWRpYS4iLCJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlIG1lc3NhZ2UiOiJUaWRhayBhZGEgaW5mb3JtYXNpIHlhbmcgY3VrdXAgYmVyZGFzYXJrYW4gamFiYXRhbiBwZWtlcmphYW4gZGFuIGxva2FzaSBpa2xhbiB1bnR1ayBtZW1iZXJpa2FuIHBlcmJhbmRpbmdhbiBwZXJmb3JtYSB5YW5nIGFrdXJhdC4ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiLguYDguKPguYfguKfguYDguIHguLTguJnguYTguJvguJfguLXguYjguIjguLDguJTguLnguKfguYjguLLguJzguKXguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYDguJvguYfguJnguK3guKLguYjguLLguIfguYTguKMg4Liq4Liy4Lih4Liy4Lij4LiW4LiU4Li54Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmE4LiU4LmJ4Lig4Liy4Lii4LmD4LiZ4Lit4Li14LiB4Liq4Lit4LiH4LiW4Li24LiH4Liq4Liy4Lih4Lin4Lix4LiZIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IuC5hOC4oeC5iOC4oeC4teC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IuC4oeC4teC4guC5ieC4reC4oeC4ueC4peC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4iOC4suC4geC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4peC4sOC4quC4luC4suC4meC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5g%2BC4iuC5ieC5gOC4nuC4t%2BC5iOC4reC4geC4suC4o%2BC5gOC4m%2BC4o%2BC4teC4ouC4muC5gOC4l%2BC4teC4ouC4muC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5hOC4lOC5ieC4reC4ouC5iOC4suC4h%2BC4luC4ueC4geC4leC5ieC4reC4hyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiLguYDguKPguYfguKfguYDguIHguLTguJnguYTguJvguJfguLXguYjguIjguLDguJTguLnguKfguYjguLLguJzguKXguILguK3guIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYDguJvguYfguJnguK3guKLguYjguLLguIfguYTguKMg4Liq4Liy4Lih4Liy4Lij4LiW4LiU4Li54Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmE4LiU4LmJ4Lig4Liy4Lii4LmD4LiZ4Lit4Li14LiB4Liq4Lit4LiH4LiW4Li24LiH4Liq4Liy4Lih4Lin4Lix4LiZIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IuC5hOC4oeC5iOC4oeC4teC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IuC4oeC4teC4guC5ieC4reC4oeC4ueC4peC5hOC4oeC5iOC5gOC4nuC4teC4ouC4h%2BC4nuC4reC4iOC4suC4geC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC5geC4peC4sOC4quC4luC4suC4meC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5g%2BC4iuC5ieC5gOC4nuC4t%2BC5iOC4reC4geC4suC4o%2BC5gOC4m%2BC4o%2BC4teC4ouC4muC5gOC4l%2BC4teC4ouC4muC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5hOC4lOC5ieC4reC4ouC5iOC4suC4h%2BC4luC4ueC4geC4leC5ieC4reC4hyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJNYXNpaCB0ZXJsYWx1IGF3YWwgdW50dWsgbWVsaWhhdCBwZXJmb3JtYSBpa2xhbiBBbmRhLiBQZXJmb3JtYSBpa2xhbiBha2FuIHRlcnNlZGlhIGRhbGFtIGJlYmVyYXBhIGhhcmkuIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IlBlbmlsYWlhbiBwZXJmb3JtYSBwYXNhciB0aWRhayB0ZXJzZWRpYS4iLCJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlIG1lc3NhZ2UiOiJUaWRhayBhZGEgaW5mb3JtYXNpIHlhbmcgY3VrdXAgYmVyZGFzYXJrYW4gamFiYXRhbiBwZWtlcmphYW4gZGFuIGxva2FzaSBpa2xhbiB1bnR1ayBtZW1iZXJpa2FuIHBlcmJhbmRpbmdhbiBwZXJmb3JtYSB5YW5nIGFrdXJhdC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJNYXNpaCB0ZXJsYWx1IGF3YWwgdW50dWsgbWVsaWhhdCBwZXJmb3JtYSBpa2xhbiBBbmRhLiBQZXJmb3JtYSBpa2xhbiBha2FuIHRlcnNlZGlhIGRhbGFtIGJlYmVyYXBhIGhhcmkuIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSI6IlBlbmlsYWlhbiBwZXJmb3JtYSBwYXNhciB0aWRhayB0ZXJzZWRpYS4iLCJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlIG1lc3NhZ2UiOiJUaWRhayBhZGEgaW5mb3JtYXNpIHlhbmcgY3VrdXAgYmVyZGFzYXJrYW4gamFiYXRhbiBwZWtlcmphYW4gZGFuIGxva2FzaSBpa2xhbiB1bnR1ayBtZW1iZXJpa2FuIHBlcmJhbmRpbmdhbiBwZXJmb3JtYSB5YW5nIGFrdXJhdC4ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJJdCBpcyB0b28gZWFybHkgdG8gc2VlIGhvdyB5b3VyIGFkIGlzIGRvaW5nLiBNYXJrZXQgcGVyZm9ybWFuY2UgY29tcGFyaXNvbiB3aWxsIGJlIGF2YWlsYWJsZSBzb29uLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUiOiJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlLiIsIk1hcmtldCBwZXJmb3JtYW5jZSByYXRpbmcgdW5hdmFpbGFibGUgbWVzc2FnZSI6IlRoZXJlIGlzbid0IGVub3VnaCBpbmZvcm1hdGlvbiBiYXNlZCBvbiB5b3VyIGFkJ3Mgcm9sZSB0aXRsZSBhbmQgbG9jYXRpb24gdG8gcHJvdmlkZSBhbiBhY2N1cmF0ZSBwZXJmb3JtYW5jZSBjb21wYXJpc29uLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJbw4%2Fhua8gw6zDrMOsxaEg4bmvw7bDtsO2w7bDtsO2IOG6veG6veG6vcSDxIPEg8WZxprDvcO9w70g4bmvw7bDtsO2IMWh4bq94bq94bq94bq94bq94bq9IOG4qcO2w7bDtsW1IMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMIMOsw6zDrMWhIMaMw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMuIOG5gsSDxIPEg8WZxLfhur3hur3hur3hua8gxqXhur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vSDDp8O2w7bDtm3MgsalxIPEg8SDxZnDrMOsw6zFocO2w7bDtuC4geC4teC5iSDFtcOsw6zDrMaaxpogw5%2Fhur3hur3hur0gxIPEg8SD4bm9xIPEg8SDw6zDrMOsxprEg8SDxIPDn8aa4bq94bq94bq9IMWhw7bDtsO2w7bDtsO24LiB4Li14LmJLl0iLCJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlIjoiW%2BG5gsSDxIPEg8WZxLfhur3hur3hur3hua8gxqXhur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vSDFmcSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjIMeax5rHmuC4geC4teC5icSDxIPEg%2BG5vcSDxIPEg8Osw6zDrMaaxIPEg8SDw5%2FGmuG6veG6veG6vS5dIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSBtZXNzYWdlIjoiW%2BG5ruG4qeG6veG6veG6vcWZ4bq94bq94bq9IMOsw6zDrMWh4LiB4Li14LmJJ%2BG5ryDhur3hur3hur3guIHguLXguYnDtsO2w7bHmseax5rEo%2BG4qSDDrMOsw6zguIHguLXguYnGksO2w7bDtsWZbcyCxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMOfxIPEg8SDxaHhur3hur3hur3GjCDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxownxaEgxZnDtsO2w7bGmuG6veG6veG6vSDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDGmsO2w7bDtsOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIOG5r8O2w7bDtiDGpcWZw7bDtsO24bm9w6zDrMOsxozhur3hur3hur0gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8Onw6fHmseax5rFmcSDxIPEg%2BG5r%2BG6veG6veG6vSDGpeG6veG6veG6vcWZxpLDtsO2w7bFmW3MgsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9IMOnw7bDtsO2bcyCxqXEg8SDxIPFmcOsw6zDrMWhw7bDtsO24LiB4Li14LmJLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIGF2YWlsYWJsZSBzb29uIG1lc3NhZ2UiOiJbw4%2Fhua8gw6zDrMOsxaEg4bmvw7bDtsO2w7bDtsO2IOG6veG6veG6vcSDxIPEg8WZxprDvcO9w70g4bmvw7bDtsO2IMWh4bq94bq94bq94bq94bq94bq9IOG4qcO2w7bDtsW1IMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMIMOsw6zDrMWhIMaMw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMuIOG5gsSDxIPEg8WZxLfhur3hur3hur3hua8gxqXhur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vSDDp8O2w7bDtm3MgsalxIPEg8SDxZnDrMOsw6zFocO2w7bDtuC4geC4teC5iSDFtcOsw6zDrMaaxpogw5%2Fhur3hur3hur0gxIPEg8SD4bm9xIPEg8SDw6zDrMOsxprEg8SDxIPDn8aa4bq94bq94bq9IMWhw7bDtsO2w7bDtsO24LiB4Li14LmJLl0iLCJNYXJrZXQgcGVyZm9ybWFuY2UgcmF0aW5nIHVuYXZhaWxhYmxlIjoiW%2BG5gsSDxIPEg8WZxLfhur3hur3hur3hua8gxqXhur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vSDFmcSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjIMeax5rHmuC4geC4teC5icSDxIPEg%2BG5vcSDxIPEg8Osw6zDrMaaxIPEg8SDw5%2FGmuG6veG6veG6vS5dIiwiTWFya2V0IHBlcmZvcm1hbmNlIHJhdGluZyB1bmF2YWlsYWJsZSBtZXNzYWdlIjoiW%2BG5ruG4qeG6veG6veG6vcWZ4bq94bq94bq9IMOsw6zDrMWh4LiB4Li14LmJJ%2BG5ryDhur3hur3hur3guIHguLXguYnDtsO2w7bHmseax5rEo%2BG4qSDDrMOsw6zguIHguLXguYnGksO2w7bDtsWZbcyCxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMOfxIPEg8SDxaHhur3hur3hur3GjCDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxownxaEgxZnDtsO2w7bGmuG6veG6veG6vSDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDGmsO2w7bDtsOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIOG5r8O2w7bDtiDGpcWZw7bDtsO24bm9w6zDrMOsxozhur3hur3hur0gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8Onw6fHmseax5rFmcSDxIPEg%2BG5r%2BG6veG6veG6vSDGpeG6veG6veG6vcWZxpLDtsO2w7bFmW3MgsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9IMOnw7bDtsO2bcyCxqXEg8SDxIPFmcOsw6zDrMWhw7bDtsO24LiB4Li14LmJLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    