import { datadogRum } from '@datadog/browser-rum';

import type { Environment } from 'src/config';

import type { Logger, LoggerContext, LoggerAppContext } from '../logger/logger';

import { setupDatadogRum } from './datadogRumSetup';

interface DatadogRumLoggerConfig {
  environment: Environment;
  version: string;
  sessionReplayEnabled?: boolean;
}

const createDatadogRumLogger = ({
  environment,
  version,
  sessionReplayEnabled = false,
}: DatadogRumLoggerConfig): Logger => {
  setupDatadogRum({ environment, version, sessionReplayEnabled });

  return {
    info: (message: string, context?: LoggerContext) =>
      datadogRum.addAction(message, { ...context }),
    error: (errorOrMessage: Error | string, context?: LoggerContext) => {
      const error =
        errorOrMessage instanceof Error
          ? errorOrMessage
          : new Error(errorOrMessage);
      datadogRum.addError(error, {
        ...context,
        error,
      });
    },
    configure: (context: Partial<LoggerAppContext>) => {
      const tags = {
        ...datadogRum.getGlobalContext(),
        ...context,
      };
      datadogRum.setGlobalContext(tags);
    },
  };
};

export { createDatadogRumLogger };
