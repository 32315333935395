import type {
  ErrorLogger,
  RequestMetadata,
} from '@seek/hirer-graphql-client/lib-types/types';

import LoggerProvider from 'src/utils/logger/logger';

const logError: ErrorLogger = (
  error: unknown,
  operationName?: string,
  metadata?: RequestMetadata,
) => {
  LoggerProvider.logger.error(`ApolloClient | Error | ${operationName}`, {
    originalError: error,
    operationName,
    metadata,
  });
};

export { logError };
