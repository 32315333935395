import { useTranslations } from '@vocab/react';
import { Box, Loader, Stack, Text, Strong } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { GenericTitleContainer } from 'src/components/GenericTitleContainer/GenericTitleContainer';
import { PageSectionTracker } from 'src/components/PageSectionTracker/PageSectionTracker';
import { PrimarySection } from 'src/components/PrimarySection/PrimarySection';
import { SimilarAds as SimilarAdsComponent } from 'src/components/SimilarAds/SimilarAds';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { useSimilarAds } from 'src/hooks/useSimilarAds';
import { mapScenarioToGroupLabelParts } from 'src/utils/roleTitleHelper';

import localTranslations from './.vocab';

export const SimilarAds = () => {
  const { t } = useTranslations(localTranslations);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();

  const { data, isLoading, error } = useSimilarAds({
    jobId: jobIdParam!,
  });

  const genericTitle = t('Similar ads with the most candidates');

  const renderContent = () => {
    if (isLoading && !data) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <Stack align="center" space="large">
            <Loader />
          </Stack>
        </GenericTitleContainer>
      );
    }

    if (error) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <WidgetError />
        </GenericTitleContainer>
      );
    }

    const noRelevantSimilarAds =
      !data ||
      !data.ads.length ||
      !data.benchmarkScenario ||
      data.benchmarkScenario === 'COUNTRY';

    if (noRelevantSimilarAds) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <Box marginLeft={['medium', 'none', 'none']}>
            <Text tone="secondary">{t('Similar ads no data')}</Text>
          </Box>
        </GenericTitleContainer>
      );
    }

    const {
      ads,
      benchmarkLocationLabel,
      benchmarkScenario,
      classificationName,
      normalisedRoleTitle,
      subClassificationName,
      benchmarkLocationCountry,
    } = data;

    const sortedAds = [...(ads ?? [])].sort(
      (a, b) =>
        new Date(b.jobCreatedTimestampUTC).getTime() -
        new Date(a.jobCreatedTimestampUTC).getTime(),
    );

    const benchmarkGroupLabelParts = mapScenarioToGroupLabelParts({
      normalisedRoleTitle,
      benchmarkScenario,
      classificationName,
      subClassificationName,
      benchmarkLocationCountry,
    });
    const hasBenchmarkGroupLabel = benchmarkGroupLabelParts !== null;

    const title =
      hasBenchmarkGroupLabel && benchmarkLocationLabel
        ? t(
            `${benchmarkGroupLabelParts.type} benchmark ads with the most candidates in location`,
            {
              Strong: (text) => <Strong>{text}</Strong>,
              benchmarkGroupLabel: benchmarkGroupLabelParts.groupLabel,
              benchmarkLocationLabel: benchmarkGroupLabelParts.isRegional
                ? t('Regional {benchmarkLocationLabel}', {
                    benchmarkLocationLabel,
                  })
                : benchmarkLocationLabel,
            },
          )
        : genericTitle;

    return <SimilarAdsComponent title={title} sortedAds={sortedAds} />;
  };

  return (
    <PrimarySection data-id="ui-similar-ads-section" id="similar-ads">
      <PageSectionTracker
        eventName="ad_performance_page_section_displayed"
        props={{
          currentPageSection: 'similar ads',
        }}
      />
      {renderContent()}
    </PrimarySection>
  );
};
