import { createContext, useContext, useState } from 'react';

export interface IHasAdRatingContext {
  hasAdRating: boolean;
  updateHasAdRating?: (hasAdRating: boolean) => void;
}

interface IHasAdRatingContextProvider {
  children: ReactNodeNoStrings;
}

const defaultContext = {
  hasAdRating: false,
};

export const HasAdRatingContext =
  createContext<IHasAdRatingContext>(defaultContext);

export const HasAdRatingProvider = ({
  children,
}: IHasAdRatingContextProvider) => {
  const [hasAdRating, setHasRating] = useState(false);
  const updateHasAdRating = (newHasAdRating: boolean) => {
    if (!hasAdRating && newHasAdRating) {
      setHasRating(newHasAdRating);
    }
  };

  return (
    <HasAdRatingContext.Provider
      value={{
        hasAdRating,
        updateHasAdRating,
      }}
    >
      {children}
    </HasAdRatingContext.Provider>
  );
};

export const useHasAdRating = () => {
  const context = useContext(HasAdRatingContext);

  return context;
};
