import type { Environment as GraphQLClientEnvironment } from '@seek/hirer-graphql-client/lib-types/types';
import {
  siteNames,
  type SiteNamesMatchingClassification,
  type Locale,
  getLanguageFromLocale,
  type Language,
} from '@seek/melways-sites';

enum ConfigItemKey {
  DEV_TOKEN = 'devToken',
  ENV = 'environment',
  IS_LOCAL = 'isLocal',
  LOCALE = 'locale',
  VERSION = 'version',
  LANGUAGE = 'language',
  SITE = 'site',
  IS_TEST = 'isTest',
  HOTJAR_ID = 'hotjarId',
  BRANCH = 'branch',
  IS_MOCK_ENV = 'isMockEnv',
}

type EmployerSiteName = SiteNamesMatchingClassification<{
  product: 'employer';
}>;

type Environment = GraphQLClientEnvironment | 'mock';

interface Config {
  [ConfigItemKey.DEV_TOKEN]?: string | undefined;
  [ConfigItemKey.ENV]: Environment;
  [ConfigItemKey.IS_LOCAL]: boolean;
  [ConfigItemKey.LOCALE]: Locale;
  [ConfigItemKey.VERSION]: string;
  [ConfigItemKey.LANGUAGE]: Language;
  [ConfigItemKey.SITE]: EmployerSiteName;
  [ConfigItemKey.IS_TEST]: boolean;
  [ConfigItemKey.HOTJAR_ID]: number | undefined;
  [ConfigItemKey.BRANCH]: string;
  [ConfigItemKey.IS_MOCK_ENV]: boolean;
}

interface ConfigOptions {
  appVersion: string;
  environment: string;
  locale: Locale;
  site: EmployerSiteName;
  branch: string;
}

const createConfig = ({
  locale,
  environment,
  appVersion,
  site,
  branch,
}: ConfigOptions) => ({
  [ConfigItemKey.DEV_TOKEN]: getDevToken(),
  [ConfigItemKey.ENV]: environment as Environment,
  [ConfigItemKey.IS_LOCAL]: isLocal(),
  [ConfigItemKey.LOCALE]: locale,
  [ConfigItemKey.VERSION]: appVersion,
  [ConfigItemKey.SITE]: site,
  [ConfigItemKey.LANGUAGE]: getLanguageFromLocale(locale),
  [ConfigItemKey.IS_TEST]: isTest(),
  [ConfigItemKey.HOTJAR_ID]: getHotjarId(site),
  [ConfigItemKey.BRANCH]: branch,
  [ConfigItemKey.IS_MOCK_ENV]: isMockEnv(),
});

type ConfigItemFallback = any;

const getConfigItem = <T>(
  configItem: ConfigItemKey,
  fallback: T | ConfigItemFallback,
) => {
  if (typeof window !== 'undefined' && window.__config) {
    return window.__config[configItem] || fallback;
  }
  return fallback;
};

const isClient = (): boolean =>
  typeof window !== 'undefined' && typeof window === 'object';

const isLocal = (): boolean => {
  if (isClient()) {
    return getConfigItem(ConfigItemKey.IS_LOCAL, false);
  }
  if (typeof process !== 'undefined') {
    return Boolean(process.env.IS_LOCAL === 'true');
  }
  return false;
};

const isMockEnv = (): boolean => {
  if (isClient()) {
    return getConfigItem(ConfigItemKey.IS_MOCK_ENV, false);
  }
  if (typeof process !== 'undefined') {
    return Boolean(process.env.IS_MOCK === 'true');
  }
  return false;
};

const isTest = (): boolean => {
  if (typeof process !== 'undefined') {
    return Boolean(process.env.IS_TEST);
  }
  if (isClient()) {
    return getConfigItem(ConfigItemKey.IS_TEST, false);
  }
  return false;
};

// TODO: Remove this because we swapped to HirerAuthProvider now
const getDevToken = (): string | undefined => {
  if (isClient()) {
    return getConfigItem(ConfigItemKey.DEV_TOKEN, undefined);
  }
  if (typeof process !== 'undefined') {
    return process.env.DEV_TOKEN;
  }
  return undefined;
};

const getDevGraphUri = (): string => {
  const defaultDevGraphUrl = 'http://localhost:4000/graphql';
  const token = getDevToken();
  if (!token) {
    return defaultDevGraphUrl;
  }

  try {
    // Parse the JWT to extract the issuer
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = isClient()
      ? decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''),
        )
      : Buffer.from(token.split('.')[1], 'base64').toString();
    const { iss } = JSON.parse(jsonPayload);

    // Switch graph url based on issuer
    switch (iss) {
      case 'https://authenticate.staging.seek.com/':
        return 'https://talent.staging.seek.com.au/graphql';
      case 'https://authenticate.seek.com/':
        return 'https://talent.seek.com.au/graphql';
      default:
        return defaultDevGraphUrl;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Invalid token, choosing default graph', { err });
    return defaultDevGraphUrl;
  }
};

const getExportUrl = (): string => {
  const exportPath = '/attachment/analytics/ad-usage/export';
  const token = getDevToken();

  if (isLocal() && token) {
    // GQL server is running on localhost:4000
    return `http://localhost:4000${exportPath}`;
  }

  return exportPath;
};

const isStaging = (): boolean => getEnvironment() === 'staging';
const isProduction = (): boolean => getEnvironment() === 'production';

const getEnvironment = (): Environment =>
  getConfigItem<Environment>(ConfigItemKey.ENV, 'development') as Environment;
const getLocale = (): Locale =>
  getConfigItem<Locale>(ConfigItemKey.LOCALE, 'en-AU') as Locale;
const getSite = (): EmployerSiteName =>
  getConfigItem<string>(ConfigItemKey.SITE, siteNames.employer.seek.au);
const getVersion = (): EmployerSiteName =>
  getConfigItem<string>(ConfigItemKey.VERSION, null);
const getLanguage = (): Language =>
  getConfigItem<string>(ConfigItemKey.LANGUAGE, 'en');

const hotjarIds: Record<EmployerSiteName, number> = {
  'employer-seek-au': 162402,
  'employer-seek-nz': 335909,
  'employer-seek-my': 390553,
  'employer-seek-sg': 640495,
  'employer-seek-ph': 640501,
  'employer-seek-id': 640499,
  'employer-seek-th': 337118,
  'employer-seek-hk': 337118,
};

const getHotjarId = (site: EmployerSiteName): number => hotjarIds[site];

// Helper utils specifically about knowing the launch dates and if we are within them
const LAUNCH_DATES: Record<string, string> = {
  [siteNames.employer.seek.hk]: '2024-01-20T13:53:00Z',
  [siteNames.employer.seek.id]: '2023-11-18T17:35:00Z',
  [siteNames.employer.seek.my]: '2023-11-18T17:35:00Z',
  [siteNames.employer.seek.ph]: '2023-10-14T12:15:00Z',
  [siteNames.employer.seek.sg]: '2023-11-18T17:35:00Z',
  [siteNames.employer.seek.th]: '2024-01-20T13:53:00Z',
};

const isHistoricalDataAvailable: (
  site: string,
  lastUpdated: string,
) => boolean = (site, lastUpdated) =>
  new Date().getTime() >= new Date(LAUNCH_DATES[site]).getTime() &&
  new Date(lastUpdated).getTime() > new Date(LAUNCH_DATES[site]).getTime();

export {
  type Config,
  type ConfigOptions,
  type EmployerSiteName,
  type Environment,
  LAUNCH_DATES,
  createConfig,
  getDevToken,
  getDevGraphUri,
  getEnvironment,
  getExportUrl,
  getLocale,
  isClient,
  isLocal,
  isTest,
  isStaging,
  isProduction,
  isHistoricalDataAvailable,
  getSite,
  getVersion,
  getLanguage,
  getHotjarId,
};
