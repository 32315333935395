import { Inline, Text } from 'braid-design-system';
import classNames from 'classnames';
import type React from 'react';
import { Link } from 'react-router-dom';

import { useCountUp } from 'src/App/hooks/useCountUp';
import { formatNumber } from 'src/utils/number';

import * as styles from './metric.css';

export interface MetricProps extends React.HTMLProps<HTMLDivElement> {
  align?: 'center' | 'left' | 'right';
  metric: number;
  metricNotation?: 'standard' | 'compact';
  metricLabelDataId?: string;
  metricPrefix?: string;
  metricSize: 'medium' | 'large' | 'xlarge';
  metricSuffix?: string;
  weight?: 'medium' | 'strong';
  isCandidate?: boolean;
  url?: string;
}

export const Metric = ({
  align = 'left',
  className,
  metric = 0,
  metricNotation = 'standard',
  metricLabelDataId,
  metricPrefix,
  metricSize,
  metricSuffix,
  weight,
  isCandidate,
  url,
}: MetricProps) => {
  const currentMetricValue = useCountUp(metric);
  const prettyMetricValue = formatNumber(currentMetricValue, {
    notation: metricNotation,
  });

  function sizeClassFromMetricSize() {
    switch (metricSize) {
      case 'medium':
        return styles.metricMedium;
      case 'large':
        return styles.metricLarge;
      case 'xlarge':
        return styles.metricXLarge;
    }
  }

  const metricSizeClass = sizeClassFromMetricSize();

  return (
    <div className={classNames(styles.metric, className)}>
      <Inline align={align} space="none">
        {metricPrefix ? (
          <Text weight="medium">
            <div className={classNames(metricSizeClass)}>{metricPrefix}</div>
          </Text>
        ) : null}
        <Text weight={weight === 'strong' ? 'strong' : 'medium'}>
          <div
            className={classNames(metricSizeClass)}
            data-id={metricLabelDataId}
          >
            {isCandidate && url ? (
              <Link className={styles.candidateApplicationsLink} to={url}>
                {prettyMetricValue}
              </Link>
            ) : (
              prettyMetricValue
            )}{' '}
          </div>
        </Text>
        {metricSuffix ? (
          <Text weight="medium">
            <div className={classNames(metricSizeClass)}>{metricSuffix}</div>
          </Text>
        ) : null}
      </Inline>
    </div>
  );
};
