import { throttle } from 'lodash';
import { useEffect, useRef } from 'react';

import { trackEvent } from 'src/utils/tealiumEventTracker';

export interface PageSectionTrackerProps {
  eventName: string;
  props: Record<string, unknown>;
}

export const PageSectionTracker = ({
  eventName,
  props,
}: PageSectionTrackerProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const throttledOnWindowScroll = throttle(onWindowScroll, 1000);

  function onWindowScroll() {
    const sectionTop =
      (elementRef.current && elementRef.current.getBoundingClientRect().top) ||
      0;

    if (sectionTop < window.innerHeight) {
      trackEvent(eventName, props);
      window.removeEventListener('scroll', throttledOnWindowScroll, false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', throttledOnWindowScroll, false);
    return () => {
      window.removeEventListener('scroll', throttledOnWindowScroll, false);
    };
  }, [throttledOnWindowScroll]);

  return <div ref={elementRef} />;
};
