import { Box, Disclosure, Heading, Stack, Text } from 'braid-design-system';

import { ClickableTooltip } from 'src/components/ClickableTooltip/ClickableTooltip';

import { AnimatedNumber } from '../AnimatedText';

import * as styles from './ConversionCard.css';

export interface BadgeProps {
  tone: 'critical' | 'neutral' | 'positive';
  text: string;
}

export interface DisclosureProps {
  expandLabel: string;
  collapseLabel: string;
  content: ReactNodeNoStrings;
  id: string;
  onToggle?: ((expanded: boolean) => void) | undefined;
}

export interface TotalProps {
  heading: string | number;
  description?: string;
}

export interface ConversionCardProps {
  id: string;
  view: 'default' | 'compact';
  header: string;
  headerTooltip: ReactNodeNoStrings;
  comparisonRateText: ReactNodeNoStrings;
  totalProps: TotalProps;
  badgeProps?: BadgeProps;
  disclosureProps?: DisclosureProps;
  mobileText?: ReactNodeNoStrings;
  mainComparisionText?: ReactNodeNoStrings;
  conversionRateTitle?: ReactNodeNoStrings;
  conversionRate?: ReactNodeNoStrings;
}

export const ConversionCard = ({
  id,
  header,
  headerTooltip,
  comparisonRateText,
  disclosureProps,
  totalProps,
  mobileText,
  mainComparisionText,
  conversionRateTitle,
  conversionRate,
}: ConversionCardProps) => {
  const getDisclosure = (props: DisclosureProps) => (
    <Disclosure
      expandLabel={props.expandLabel}
      collapseLabel={props.collapseLabel}
      id={props.id}
      data={{
        testid: 'conversion-card-disclosure-handle',
      }}
      onToggle={props.onToggle}
      size="xsmall"
      weight="weak"
    >
      <Box style={{ minHeight: 50 }} data-testid="disclosure-content">
        <Stack space="medium">
          <Text size="small" weight="regular">
            {props.content}
          </Text>
          {mobileText && (
            <Text size="small" weight="regular">
              {mobileText}
            </Text>
          )}
        </Stack>
      </Box>
    </Disclosure>
  );

  const totalHeading =
    typeof totalProps.heading === 'number' ? (
      <AnimatedNumber value={Number(totalProps.heading)} />
    ) : (
      totalProps.heading
    );

  const total = totalProps.description ? (
    <Stack space="gutter">
      <Heading
        level="4"
        data={{
          testid: 'total-heading',
        }}
      >
        {totalHeading}
      </Heading>
      <Text size="small" data={{ testid: 'total-description' }}>
        {totalProps.description}
      </Text>
    </Stack>
  ) : (
    <>
      <Heading level="4" data={{ testid: 'total-heading' }}>
        {totalHeading}
      </Heading>
      {!disclosureProps && mobileText && <Text size="small">{mobileText}</Text>}
    </>
  );

  return (
    <Box
      className={styles.conversionCard}
      background="surface"
      boxShadow="borderNeutralLight"
      padding="medium"
      borderRadius="large"
      data-testid={header.toLocaleLowerCase().split(' ').join('-')}
      flexShrink={0}
    >
      <Stack space="xlarge">
        <Stack space="medium">
          <Box
            display="flex"
            gap="xsmall"
            alignItems="center"
            flexWrap="nowrap"
          >
            <Text size="small" weight="strong" maxLines={1}>
              {header}
            </Text>
            <Box flexShrink={0}>
              <ClickableTooltip
                id={`${id}-tooltip`}
                title={header}
                contents={headerTooltip}
                size="small"
              />
            </Box>
          </Box>
          {total}
          {mainComparisionText && (
            <Text size="small" tone="neutral">
              {mainComparisionText}
            </Text>
          )}
          {disclosureProps && getDisclosure(disclosureProps)}
        </Stack>
        <Stack space="medium">
          {Boolean(conversionRateTitle) && conversionRateTitle}
          {Boolean(conversionRate) && conversionRate}
          {comparisonRateText}
        </Stack>
      </Stack>
    </Box>
  );
};
