import { useCallback, useState } from 'react';

import type { MarketInsightTrendChartData } from 'src/types/MarketInsight';

import { useMarketInsightTrendChartRenderer } from './useMarketInsightTrendChartRenderer';

export interface MarketInsightTrendChartProps {
  data: MarketInsightTrendChartData;
  height: number;
  width: number;
  widthBehaviour?: 'fixed' | 'dynamic';
}

export const MarketInsightTrendChart = ({
  data,
  height,
  width: initialWidth,
  widthBehaviour,
}: MarketInsightTrendChartProps) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const containerRefHandler = useCallback((node: HTMLDivElement | null) => {
    setContainerRef(node);
  }, []);
  useMarketInsightTrendChartRenderer({
    containerRef,
    data,
    height,
    width: initialWidth,
    widthBehaviour,
  });

  return (
    <div
      ref={containerRefHandler}
      style={{ height, width: '100%', textAlign: 'left' }}
    />
  );
};
