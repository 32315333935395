import type { MultiSelectDropdownOption } from '@seek/multi-select-dropdown';

import type { LocationOption } from 'src/types/AdUsageFiltersResponse';

/**
 *
 * @param option
 * @returns boolean
 *
 * This function is to check if there is any job posted in the location itself instead of
 * its lower level locations. For example: if Victoria has count at 20, while the sum of the
 * count of all its child locations is less then 20, return true. if Victoria has count at 20,
 * and the sum of the count of all its child locations is 20 as well, return false.
 */
const numOfAdsPostedInItself = (option: MultiSelectDropdownOption) => {
  const { count = 0 } = option;
  const totalOfChildrenCount = (option?.children || []).reduce(
    (total, current) => total + (current.count || 0),
    0,
  );

  const selfCount = count - totalOfChildrenCount;

  // FIXME: This is a temporary fix for the issue that the count of jobs in the location itself is not correct, so,
  // FIXME: it doesn't include the count of jobs in its lower level locations. When the data is fixed, this logic
  // FIXME: should be removed. Also, the function now returns the correct count of jobs posted in the location itself
  // FIXME: when it was negative before the fix. This will result in correct value for "others" child location.
  if (selfCount < 0) {
    option.count = (option.count ?? 0) + totalOfChildrenCount;
    return count;
  }

  return selfCount;
};

/**
 *
 * @param LocationOption[]
 * @returns { MultiSelectOption[] }
 *
 * This function is to build a tree of location data from the flat array of location Data.
 *
 * The concepts of root location, branch location, and leaf location will be used in the
 * comments below.
 *
 * root location: the top level locations like Australia, New Zealand, they don't have
 *                valid parentId.
 * branch location: the locations in middle levels, they have parent and they also have
 *                children.
 * leaf location: the locations that only have parent but don't have children.
 *
 */
const buildLocationTreeData = ({
  data,
  leafNodeKeysMap,
  othersText = 'Others',
  restOfTheWorldText = 'Rest of the World',
}: {
  data: LocationOption[];
  leafNodeKeysMap: Record<string, string[]>;
  othersText?: string;
  restOfTheWorldText?: string;
}) => {
  /**
   * Step 1: sort the locations. the order is leaf location -> branch location -> root location
   *         use the location level field to perform the sort
   *         If two locations have same level, then order them alphabetically.
   */
  const sortedData = [...(data ?? [])].sort(
    (a: LocationOption, b: LocationOption) => {
      if (a.level === b.level) {
        return a.value <= b.value ? -1 : 1;
      }
      return b.level - a.level;
    },
  );

  // This is the temp data structure we used when building the result data.
  const dataMap: Record<string, MultiSelectDropdownOption> = {};

  // This is the desired returned data from this function
  const result: MultiSelectDropdownOption[] = [];
  let restOfTheWorldLocation: MultiSelectDropdownOption | undefined;

  // The purpose here is to use one for loop to build the location tree data.
  sortedData.forEach((option) => {
    const current = {
      ...option,
      key: option.key.toString(),
    } as MultiSelectDropdownOption;

    if (!current.parentId && current.key === '-1') {
      // This is the operation for root location for Rest of the World
      // Also updates leafNodeKeysMap
      restOfTheWorldLocation = { ...current, value: restOfTheWorldText };
      leafNodeKeysMap[current.key] = [current.key];
    } else if (!current.parentId && current.key && current.key !== '-1') {
      // This is the operation for root location, when the current one is root location,
      // we find its children and its leafNodeKeys from the dataMap assign to it. Then
      // add it to result array.
      // Also, update leafNodeKeysMap as well
      if (dataMap[current.key]?.children) {
        current.children = dataMap[current.key].children;
        current.leafNodeKeys = dataMap[current.key].leafNodeKeys;
        leafNodeKeysMap[current.key] = dataMap[current.key]?.leafNodeKeys || [];
      } else {
        leafNodeKeysMap[current.key] = [current.key];
      }

      result.push(current);
    } else if (current.key && !dataMap[current.key] && current.parentId) {
      // This is the operation for leaf location, when we loop the leaf node locations,
      // the dataMap won't have leaf location key as key, as leaf location will only be treated
      // as children in the whole process.
      // If the parentId of this leaf not location is not in the keys of dataMap, add the parentId
      // to the keys and push the current leaf location to its children list, and push the current
      // leaf node key to its parent's leafNodeKeys list as well.
      if (!dataMap[current.parentId]) {
        dataMap[current.parentId] = {
          children: [],
          leafNodeKeys: [],
        } as unknown as MultiSelectDropdownOption;
      }
      dataMap[current.parentId].children?.push(current);
      dataMap[current.parentId].leafNodeKeys?.push(current.key);
    } else if (current.key && dataMap[current.key]) {
      // This is the operation for branch location, when we start looping branch locations, their keys
      // should be already in the keys of dataMap after looping the leaf locations. And we also need to
      // do something similar to what we did with the leaf location. Find its parentId, and add it to the
      // dataMap keys. Then update the branch location to include children and leafNodeKeys, update the
      // leafNodeKeysMap, then push this branch location to its parent's children list, and concat this
      // branch location's leafNodeKeys to its parent's leafNodeKeys list.
      // The the last step is to delete the record with this branch location's key as key from the dataMap.
      // The reason is we only want to keep the highest level of locations in the  dataMap in the looping.
      if (current.parentId) {
        if (!dataMap[current.parentId]) {
          dataMap[current.parentId] = {
            children: [],
            leafNodeKeys: [],
          } as unknown as MultiSelectDropdownOption;
        }
        current.children = dataMap[current.key].children;
        current.leafNodeKeys = dataMap[current.key].leafNodeKeys;

        if (numOfAdsPostedInItself(current)) {
          current?.children?.push({
            key: `${current.key}-others`,
            value: `${current.value} - ${othersText}`,
            children: undefined,
            count: numOfAdsPostedInItself(current),
          });

          current?.leafNodeKeys?.push(`${current.key}-others`);
        }

        leafNodeKeysMap[current.key] = dataMap[current.key].leafNodeKeys || [];

        dataMap[current.parentId].children?.push(current);
        dataMap[current.parentId].leafNodeKeys = dataMap[
          current.parentId
        ]?.leafNodeKeys?.concat(current.leafNodeKeys || []);

        delete dataMap[current.key];
      }
    }
  });

  if (result.length === 1 && !restOfTheWorldLocation && result[0].children) {
    return result[0].children;
  }

  return restOfTheWorldLocation ? [...result, restOfTheWorldLocation] : result;
};

export { numOfAdsPostedInItself, buildLocationTreeData };
