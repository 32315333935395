import {
  scaleLinear,
  scaleTime,
  type ScaleLinear,
  type ScaleTime,
} from 'd3-scale';
import { pointer, select, type Selection } from 'd3-selection';
import { line, curveCatmullRom, area } from 'd3-shape';

import { getLanguage } from 'src/config';
import { chartUtils } from 'src/seek-charts';
import type { PerformancePredictionChartData } from 'src/types/PerformancePrediction';

import type {
  ApplicationPredictionChartLinePoint,
  ApplicationPredictionChartMarker,
  ChartLabels,
  PerformancePredictionChartLabel,
  PerformancePredictionChartRenderer,
} from './ApplicationPredictionChart.types';
import {
  getApplicantsPerformance,
  renderArrorDownPath,
  renderBox,
  renderTriangleBottomPath,
  textWrappper,
  textCapitalize,
} from './predictionChartFormatter';

function updateXAxis({
  data,
  plotHeight,
  xAxis,
  xScale,
}: {
  data: PerformancePredictionChartData;
  plotHeight: number;
  xAxis: Selection<SVGGElement, unknown, null, undefined>;
  xScale: ScaleTime<number, number>;
}) {
  xAxis
    .selectAll('g.xAxis .tick')
    .data(data.xAxisLabels)
    .join(
      (enter) =>
        enter
          .append('g')
          .attr('class', 'tick')
          .attr(
            'transform',
            (d) => `translate(${xScale(new Date(d.date))},${plotHeight})`,
          )
          .append('text')
          .text((d) => textCapitalize(d.label))
          .attr('alignment-baseline', 'hanging')
          .attr('text-anchor', (_, i) => {
            if (i === 0) {
              return 'start';
            } else if (i === data.xAxisLabels.length - 1) {
              return 'end';
            }
            return 'middle';
          })
          .attr('y', chartUtils.grid.gUnit(2)),
      (update) =>
        update.attr(
          'transform',
          (d) => `translate(${xScale(new Date(d.date))},${plotHeight})`,
        ),
      (exit) => exit.remove(),
    );
}

function updateYAxisLines({
  gridArea,
  plotHeight,
  plotWidth,
}: {
  gridArea: Selection<SVGGElement, unknown, null, undefined>;
  plotHeight: number;
  plotWidth: number;
}) {
  gridArea
    .selectAll('line.yLine')
    .data(Array(4).fill(0))
    .join(
      (enter) =>
        enter
          .append('line')
          .attr('class', 'yLine')
          .attr('x1', 0)
          .attr('x2', plotWidth)
          .attr('y1', (_, i) => (i * plotHeight) / 3)
          .attr('y2', (_, i) => (i * plotHeight) / 3)
          .attr('stroke', '#ECECEC'),
      (update) =>
        update
          .attr('x2', plotWidth)
          .attr('y1', (_, i) => (i * plotHeight) / 3)
          .attr('y2', (_, i) => (i * plotHeight) / 3),
      (exit) => exit.remove(),
    );
}

function updatePlotLineAreas({
  lineData,
  parent,
  xScale,
  yScale,
}: {
  lineData: ApplicationPredictionChartLinePoint[][];
  parent: Selection<SVGGElement, unknown, null, undefined>;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  parent
    .selectAll<SVGGElement, Array<{ line: string; x: string; y: number }>>(
      '.plotLineArea',
    )
    .data(lineData)
    .join(
      (enter) =>
        enter
          .append('path')
          .attr('class', 'plotLineArea')
          .attr('pointer-events', 'none')
          .attr('fill', (d) => `url(#${d[0].line})`)
          .attr('mask', (d) =>
            d[0].line === 'comparisonFill' ? 'url(#mask-stripe)' : 'none',
          )
          .attr('stroke', 'none')
          .attr('opacity', (d) => (d[0].line === 'valueFill' ? 0.06 : 1))
          .attr(
            'd',
            area<ApplicationPredictionChartLinePoint>()
              .curve(curveCatmullRom)
              .x((d) => xScale(new Date(d.x)) || 0)
              .y0(yScale(0) || 0)
              .y1((d) => yScale(d.y) || 0),
          ),
      (update) =>
        update.attr(
          'd',
          area<ApplicationPredictionChartLinePoint>()
            .curve(curveCatmullRom)
            .x((d) => xScale(new Date(d.x)) || 0)
            .y0(yScale(0) || 0)
            .y1((d) => yScale(d.y) || 0),
        ),
      (exit) => exit.remove(),
    );
}

function updatePlotLines({
  lineData,
  parent,
  xScale,
  yScale,
}: {
  lineData: ApplicationPredictionChartLinePoint[][];
  parent: Selection<SVGGElement, unknown, null, undefined>;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  parent
    .selectAll<SVGGElement, Array<{ line: string; x: string; y: number }>>(
      '.plotLine',
    )
    .data(lineData)
    .join(
      (enter) =>
        enter
          .append('path')
          .attr('class', 'plotLine')
          .attr('pointer-events', 'none')
          .attr('fill', 'none')
          .attr('stroke', (d) => `url(#${d[0].line})`)
          .attr('stroke-width', (d) => (d[0].line === 'valueStroke' ? 2 : 1))
          .attr('stroke-dasharray', (d) =>
            d[0].line.includes('predicted') ? '6 3' : 'none',
          )
          .attr(
            'd',
            line<ApplicationPredictionChartLinePoint>()
              .curve(curveCatmullRom)
              .x((d) => xScale(new Date(d.x)) || 0)
              .y((d) => yScale(d.y) || 0),
          ),
      (update) =>
        update.attr(
          'd',
          line<ApplicationPredictionChartLinePoint>()
            .curve(curveCatmullRom)
            .x((d) => xScale(new Date(d.x)) || 0)
            .y((d) => yScale(d.y) || 0),
        ),
      (exit) => exit.remove(),
    );
}

function updateMarkers({
  markerData,
  parent,
  theme,
  xScale,
  yScale,
}: {
  markerData: ApplicationPredictionChartMarker[];
  parent: Selection<SVGGElement, unknown, null, undefined>;
  theme: ChartTheme;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  parent
    .selectAll('circle')
    .data(markerData)
    .join(
      (enter) =>
        enter
          .append('circle')
          .attr('cx', (d) => xScale(new Date(d.date)) || 0)
          .attr('cy', (d) => yScale(d.value) || 0)
          .attr('fill', (d) => {
            if (d.line === 'value') {
              return theme.tokens.colour.indigo[500];
            }
            return theme.tokens.colour.grey[500];
          })
          .attr('r', 3),
      (update) =>
        update
          .attr('cx', (d) => xScale(new Date(d.date)) || 0)
          .attr('cy', (d) => yScale(d.value) || 0),
      (exit) => exit.remove(),
    );
}

function updateLabels({
  labelData,
  parent,
  theme,
  xScale,
  yScale,
  chartLabels,
}: {
  labelData: PerformancePredictionChartLabel;
  parent: Selection<SVGGElement, unknown, null, undefined>;
  theme: ChartTheme;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  chartLabels: ChartLabels;
}) {
  const language = getLanguage();

  let labelWidth = 180;
  if (language === 'id') {
    labelWidth = 236;
  } else if (language === 'th') {
    labelWidth = 186;
  }

  const textLineHeight =
    theme.tokens.typography.caption1.capHeight + chartUtils.grid.gUnit(1.5);
  const textMargin = chartUtils.grid.gUnit(2);
  const labelHeight = textMargin * 2 + textLineHeight * 3;
  const chevronLinePoints: Array<[number, number]> = [
    [-8, 0],
    [0, 8],
    [8, 0],
  ];

  const applicantsPerformance = (label: string) =>
    getApplicantsPerformance(label, chartLabels);
  const isEdited = ({
    day,
    editMade,
    dayLabel,
    editLabel,
  }: {
    day: number;
    editMade: boolean;
    dayLabel: (day: number) => string;
    editLabel: (day: number) => string;
  }) => (editMade ? editLabel(day) : dayLabel(day));

  const similarAdsAverage = (
    average: (avarage: number) => string,
    comparisonValue: number,
  ) => average(comparisonValue);

  const applicationPerformance = (label: string, value: number) =>
    applicantsPerformance(label)(value);

  const renderedLabel = parent
    .selectAll('.label')
    .data([labelData])
    .join(
      (enter) => {
        const label = enter
          .append('g')
          .attr('class', 'label')
          .attr('opacity', (d) => (d.showLabel ? 1 : 0))
          .attr(
            'transform',
            (d) =>
              `translate(${xScale(
                d.date === undefined ? 0 : new Date(d.date),
              )},0)`,
          )
          .attr('style', `transition: opacity ${theme.tokens.motion.normal};`);
        label
          .append('path')
          .attr('d', (d) =>
            line()([
              [0, (yScale(d.value) || 0) - 40],
              [0, yScale(0) || 0],
            ]),
          )
          .attr('fill', 'none')
          .attr('stroke-width', 0.6)
          .attr('stroke-dasharray', '3 3')
          .attr('stroke', theme.tokens.colour.indigo[600]);

        label
          .append('circle')
          .attr('class', 'circle1')
          .attr('fill', theme.tokens.colour.indigo[600])
          .attr('opacity', 0.17)
          .attr('cx', 0)
          .attr('cy', (d) => yScale(d.value) || 0)
          .attr('r', 6);
        label
          .append('circle')
          .attr('class', 'circle2')
          .attr('fill', theme.tokens.colour.indigo[600])
          .attr('cx', 0)
          .attr('cy', (d) => yScale(d.value) || 0)
          .attr('r', 3);

        const box = label
          .append('g')
          .attr('class', 'box')
          .attr('transform', (d) =>
            renderBox(yScale, d.value, labelWidth, labelHeight),
          );
        box
          .append('rect')
          .attr('stroke', theme.tokens.colour.grey[500])
          .attr('stroke-width', 0.6)
          .attr('fill', theme.tokens.systemColour.white)
          .attr('rx', 3)
          .attr('width', labelWidth)
          .attr('height', labelHeight);

        box
          .append('path')
          .attr('class', 'arrow-down')
          .attr('d', line()(chevronLinePoints) || '')
          .attr('fill', theme.tokens.systemColour.white)
          .attr('transform', renderArrorDownPath(labelWidth, labelHeight));
        box
          .append('path')
          .attr('class', 'triangle-bottom')
          .attr('d', line()(chevronLinePoints) || '')
          .attr('stroke', theme.tokens.colour.grey[500])
          .attr('stroke-width', 0.6)
          .attr('fill', theme.tokens.systemColour.white)
          .attr('transform', renderTriangleBottomPath(labelWidth, labelHeight));

        const text = box.append('text');
        text
          .append('tspan')
          .attr('class', 'line1')
          .attr('alignment-baseline', 'hanging')
          .attr('x', chartUtils.grid.gUnit(2))
          .attr('y', textMargin)
          .text((d) =>
            isEdited({
              day: d.day,
              editMade: d.editMade,
              dayLabel: chartLabels.day,
              editLabel: chartLabels.edited,
            }),
          );
        text
          .append('tspan')
          .attr('class', 'line2')
          .attr('alignment-baseline', 'hanging')
          .attr('x', chartUtils.grid.gUnit(2))
          .attr('y', textMargin + textLineHeight + chartUtils.grid.gUnit(1))
          .text((d) => applicationPerformance(d.valueLabel, d.value));
        text
          .append('tspan')
          .attr('class', 'line3')
          .attr('alignment-baseline', 'hanging')
          .attr('x', chartUtils.grid.gUnit(2))
          .attr('y', textMargin + textLineHeight * 2 + chartUtils.grid.gUnit(1))
          .text((d) =>
            similarAdsAverage(chartLabels.similarAdsAverage, d.comparisonValue),
          );
        return label;
      },
      (update) => {
        update
          .attr('opacity', (d) => (d.showLabel ? 1 : 0))
          .attr(
            'transform',
            (d) =>
              `translate(${xScale(
                d.date === undefined ? 0 : new Date(d.date),
              )},0)`,
          );
        update.select('path').attr('d', (d) =>
          line()([
            [0, (yScale(d.value) || 0) - 40],
            [0, yScale(0) || 0],
          ]),
        );
        update.select('.circle1').attr('cy', (d) => yScale(d.value || 0) || 0);
        update.select('.circle2').attr('cy', (d) => yScale(d.value || 0) || 0);
        update
          .select('.box')
          .attr('transform', (d) =>
            renderBox(yScale, d.value, labelWidth, labelHeight),
          );
        update.select('.line1').text((d) =>
          isEdited({
            day: d.day,
            editMade: d.editMade,
            dayLabel: chartLabels.day,
            editLabel: chartLabels.edited,
          }),
        );
        update
          .select('.line2')
          .text((d) => applicationPerformance(d.valueLabel, d.value));
        update
          .select('.line3')
          .text((d) =>
            similarAdsAverage(chartLabels.similarAdsAverage, d.comparisonValue),
          );
        return update;
      },
      (exit) => exit.remove(),
    );
  renderedLabel.call(
    textWrappper,
    labelWidth,
    { textMargin, textLineHeight },
    yScale,
  );
}

function updateFilters({
  gradientOffset,
  valuePoints,
  xScale,
  yScale,
}: {
  gradientOffset: number;
  valuePoints: Array<{ x: string; y: number }>;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  const filterData = [
    'valueFill',
    'valueStroke',
    'predictedValueStroke',
    'comparisonFill',
    'comparisonStroke',
    'predictedComparisonStroke',
  ];
  filterData.map((id) => {
    select(`#${id}`).select('stop').attr('offset', gradientOffset);
  });

  const areaPoints: Array<[number, number]> = valuePoints.map((p) => [
    xScale(new Date(p.x)) || 0,
    yScale(p.y) || 0,
  ]);
  select('#mask-stripe')
    .select('path')
    .attr('d', area()(areaPoints) || '');
}

function createFilters({
  parent,
  theme,
}: {
  parent: Selection<SVGDefsElement, unknown, null, undefined>;
  theme: ChartTheme;
}) {
  const filterData = [
    {
      id: 'valueFill',
      colour: theme.tokens.colour.indigo[500],
    },
    {
      id: 'valueStroke',
      colour: theme.tokens.colour.indigo[500],
    },
    {
      id: 'predictedValueStroke',
      colour: theme.tokens.colour.indigo[500],
    },
    {
      id: 'comparisonFill',
      colour: theme.tokens.colour.indigo[50],
    },
    {
      id: 'comparisonStroke',
      colour: theme.tokens.colour.grey[500],
    },
    {
      id: 'predictedComparisonStroke',
      colour: theme.tokens.colour.grey[500],
    },
  ];
  filterData.map((d) => {
    const linearGradient = parent
      .append('linearGradient')
      .attr('id', d.id)
      .attr('x1', '0')
      .attr('x2', '1')
      .attr('y1', '0')
      .attr('y2', '0');
    linearGradient
      .append('stop')
      .attr('offset', 0)
      .attr(
        'stop-color',
        d.id.includes('predicted') ? theme.tokens.systemColour.white : d.colour,
      )
      .attr('stop-opacity', d.id.includes('predicted') ? 0 : 1);
    linearGradient
      .append('stop')
      .attr('offset', 0)
      .attr(
        'stop-color',
        d.id.includes('predicted') ? d.colour : theme.tokens.systemColour.white,
      )
      .attr('stop-opacity', d.id.includes('predicted') ? 1 : 0);
  });

  const patternStripe = parent
    .append('pattern')
    .attr('id', 'pattern-stripe')
    .attr('width', '4')
    .attr('height', '8')
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('patternTransform', 'rotate(-45)');
  patternStripe
    .append('rect')
    .attr('width', '2')
    .attr('height', '8')
    .attr('transform', 'translate(0,0)')
    .attr('fill', 'black');
  const maskStripe = parent.append('mask').attr('id', 'mask-stripe');
  maskStripe.append('path').attr('fill', 'white');
  maskStripe
    .append('rect')
    .attr('x', '0')
    .attr('y', '0')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('fill', 'url(#pattern-stripe)');
}

export function createApplicationPredictionChartRenderer({
  container,
  containerHeight,
  containerWidth,
  textStyles,
  theme,
}: {
  container: HTMLDivElement | null;
  containerHeight: number;
  containerWidth: number;
  textStyles: TypographyStyles;
  theme: ChartTheme;
}): PerformancePredictionChartRenderer | null {
  if (!container) {
    return null;
  }
  const plotMargin = {
    top: 0,
    right: 0,
    bottom:
      theme.tokens.typography.caption1.capHeight + chartUtils.grid.gUnit(2),
    left: 0,
  };
  let plotWidth = containerWidth - plotMargin.left - plotMargin.right;
  const xAxisHeight =
    theme.tokens.typography.caption1.capHeight + chartUtils.grid.gUnit(2);
  const plotHeight =
    containerHeight - plotMargin.top - plotMargin.bottom - xAxisHeight;
  const pointerPosition = {
    x: 0,
    y: 0,
  };

  const rootSelection = select(container);
  const svgSelection = rootSelection
    .append('svg')
    .attr('width', containerWidth)
    .attr('height', containerHeight)
    .attr('style', 'overflow: visible;width:100%');
  const defs = svgSelection.append('defs');
  createFilters({ parent: defs, theme });
  const rootNode = svgSelection
    .append('g')
    .attr('transform', `translate(${plotMargin.left}, ${plotMargin.top})`);
  const gridArea = chartUtils.layerFactory.createLayer({
    className: 'gridArea',
    parent: rootNode,
  });
  const xAxis = chartUtils.layerFactory.createLayer({
    className: ['xAxis', textStyles.caption1],
    parent: rootNode,
  });
  const foregroundLayer = chartUtils.layerFactory.createLayer({
    className: 'foregroundLayer',
    parent: rootNode,
  });
  const labelLayer = chartUtils.layerFactory.createLayer({
    className: ['label', textStyles.caption1],
    parent: rootNode,
  });

  let data: PerformancePredictionChartData;
  const update = ({
    data: newData,
    width,
    chartLabels,
  }: {
    data: PerformancePredictionChartData;
    width: number;
    chartLabels: ChartLabels;
  }) => {
    data = newData;

    if (data.events === null) {
      return;
    }

    const { xRange, events, yRange, predictionDate } = data;

    const valueLabel = textCapitalize(data.valueLabel) as
      | 'Candidates'
      | 'Application starts';

    // Don't overwrite svg
    // svgSelection.attr('width', width);
    plotWidth = width - plotMargin.left - plotMargin.right;

    const yScale = scaleLinear().domain(yRange).range([plotHeight, 0]);
    const xScale = scaleTime()
      .domain(xRange.map((d) => new Date(d)))
      .range([0, plotWidth]);
    const comparisonPoints = events.map((e) => ({
      x: e.date,
      y: e.comparisonValue,
    }));
    const valuePoints = events.map((e) => ({ x: e.date, y: e.value }));
    const lineAreaData = [
      comparisonPoints.map((p) => ({ ...p, line: 'comparisonFill' })),
      valuePoints.map((p) => ({ ...p, line: 'valueFill' })),
    ];
    const lineData = [
      valuePoints.map((p) => ({ ...p, line: 'valueStroke' })),
      comparisonPoints.map((p) => ({ ...p, line: 'comparisonStroke' })),
      comparisonPoints.map((p) => ({
        ...p,
        line: 'predictedComparisonStroke',
      })),
      valuePoints.map((p) => ({ ...p, line: 'predictedValueStroke' })),
    ];
    let labelData: PerformancePredictionChartLabel = {
      comparisonValue: 0,
      date: undefined,
      day: 1,
      showLabel: false,
      value: 0,
      editMade: false,
      valueLabel,
    };

    const lastEventWithDataIndex = predictionDate
      ? events.findIndex((e) => e.date === predictionDate)
      : events.length - 1;
    const lastEventWithData =
      lastEventWithDataIndex !== -1
        ? events[lastEventWithDataIndex]
        : undefined;
    const markerData: ApplicationPredictionChartMarker[] = events.reduce(
      (acc, e) => {
        if (e.editMade) {
          return [
            ...acc,
            {
              line: 'value',
              date: e.date,
              value: e.value,
            },
          ];
        }
        return acc;
      },
      lastEventWithData !== undefined
        ? [
            {
              line: 'value',
              date: lastEventWithData.date,
              value: lastEventWithData.value,
            },
            {
              line: 'comparison',
              date: lastEventWithData.date,
              value: lastEventWithData.comparisonValue,
            },
          ]
        : [],
    );
    const gradientOffset = lastEventWithDataIndex / (events.length - 1);

    svgSelection
      .on('mousemove', (e) => {
        if (data === undefined) {
          return;
        }
        const p = pointer(e);
        pointerPosition.x = p[0];
        pointerPosition.y = p[1];

        const activeMarkerIndex = Math.round(
          ((events.length - 1) * pointerPosition.x) / plotWidth,
        );
        const activeMarker = events[Math.max(0, activeMarkerIndex)];
        labelData = {
          ...activeMarker,
          showLabel: true,
          valueLabel,
        };
        updateLabels({
          labelData,
          parent: labelLayer,
          theme,
          xScale,
          yScale,
          chartLabels,
        });
      })
      .on('mouseout', () => {
        labelData.showLabel = false;
        updateLabels({
          labelData,
          parent: labelLayer,
          theme,
          xScale,
          yScale,
          chartLabels,
        });
      });

    updateFilters({ gradientOffset, valuePoints, xScale, yScale });
    updateYAxisLines({ gridArea, plotHeight, plotWidth });
    updateXAxis({
      data: data as PerformancePredictionChartData,
      plotHeight,
      xAxis,
      xScale,
    });
    updatePlotLineAreas({
      lineData: lineAreaData,
      parent: foregroundLayer,
      xScale,
      yScale,
    });
    updatePlotLines({
      lineData,
      parent: foregroundLayer,
      xScale,
      yScale,
    });
    updateMarkers({
      markerData,
      parent: foregroundLayer,
      theme,
      xScale,
      yScale,
    });
    updateLabels({
      labelData,
      parent: labelLayer,
      theme,
      xScale,
      yScale,
      chartLabels,
    });
  };

  return {
    update,
  };
}
