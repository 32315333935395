
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4oiIsIlBlcmZvcm1hbmNlIjoi4Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQ29udmVyc2lvbiI6IuC4reC4seC4leC4o%2BC4suC4geC4suC4o%2BC5gOC4m%2BC4peC4teC5iOC4ouC4meC5geC4m%2BC4peC4hyIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiLguYDguITguKXguYfguJTguKXguLHguJrguYPguJnguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYPguKvguYnguJTguLXguILguLbguYnguJkiLCJUcmVuZHMiOiLguYDguJfguKPguJnguJTguYwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4oiIsIlBlcmZvcm1hbmNlIjoi4Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQ29udmVyc2lvbiI6IuC4reC4seC4leC4o%2BC4suC4geC4suC4o%2BC5gOC4m%2BC4peC4teC5iOC4ouC4meC5geC4m%2BC4peC4hyIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiLguYDguITguKXguYfguJTguKXguLHguJrguYPguJnguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYPguKvguYnguJTguLXguILguLbguYnguJkiLCJUcmVuZHMiOiLguYDguJfguKPguJnguJTguYwifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlBlbmdlbHVhcmFuIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYSIsIkNvbnZlcnNpb24iOiJLb252ZXJzaSIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiJUaXBzIHVudHVrIG1lbmluZ2thdGthbiBpa2xhbiIsIlRyZW5kcyI6IlRyZW4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlBlbmdlbHVhcmFuIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYSIsIkNvbnZlcnNpb24iOiJLb252ZXJzaSIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiJUaXBzIHVudHVrIG1lbmluZ2thdGthbiBpa2xhbiIsIlRyZW5kcyI6IlRyZW4ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4oiIsIlBlcmZvcm1hbmNlIjoi4Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQ29udmVyc2lvbiI6IuC4reC4seC4leC4o%2BC4suC4geC4suC4o%2BC5gOC4m%2BC4peC4teC5iOC4ouC4meC5geC4m%2BC4peC4hyIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiLguYDguITguKXguYfguJTguKXguLHguJrguYPguJnguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYPguKvguYnguJTguLXguILguLbguYnguJkiLCJUcmVuZHMiOiLguYDguJfguKPguJnguJTguYwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4oiIsIlBlcmZvcm1hbmNlIjoi4Lic4Lil4LiB4Liy4Lij4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQ29udmVyc2lvbiI6IuC4reC4seC4leC4o%2BC4suC4geC4suC4o%2BC5gOC4m%2BC4peC4teC5iOC4ouC4meC5geC4m%2BC4peC4hyIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiLguYDguITguKXguYfguJTguKXguLHguJrguYPguJnguIHguLLguKPguJvguKPguLHguJrguJvguKPguLjguIfguJvguKPguLDguIHguLLguKjguIfguLLguJnguYPguKvguYnguJTguLXguILguLbguYnguJkiLCJUcmVuZHMiOiLguYDguJfguKPguJnguJTguYwifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlBlbmdlbHVhcmFuIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYSIsIkNvbnZlcnNpb24iOiJLb252ZXJzaSIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiJUaXBzIHVudHVrIG1lbmluZ2thdGthbiBpa2xhbiIsIlRyZW5kcyI6IlRyZW4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlBlbmdlbHVhcmFuIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYSIsIkNvbnZlcnNpb24iOiJLb252ZXJzaSIsIlRpcHMgdG8gaW1wcm92ZSBhZHMiOiJUaXBzIHVudHVrIG1lbmluZ2thdGthbiBpa2xhbiIsIlRyZW5kcyI6IlRyZW4ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlNwZW5kIiwiUGVyZm9ybWFuY2UiOiJQZXJmb3JtYW5jZSIsIkNvbnZlcnNpb24iOiJDb252ZXJzaW9uIiwiVGlwcyB0byBpbXByb3ZlIGFkcyI6IlRpcHMgdG8gaW1wcm92ZSBhZHMiLCJUcmVuZHMiOiJUcmVuZHMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlvFoMal4bq94bq94bq94LiB4Li14LmJxoxdIiwiUGVyZm9ybWFuY2UiOiJbxqThur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vV0iLCJDb252ZXJzaW9uIjoiW8OHw7bDtuC4geC4teC5ieG5veG6veG6vcWZxaHDrMOsw7bDtuC4geC4teC5iV0iLCJUaXBzIHRvIGltcHJvdmUgYWRzIjoiW%2BG5rsOsw6zDrMalxaEg4bmvw7bDtsO2IMOsw6zDrG3MgsalxZnDtsO2w7bhub3hur3hur3hur0gxIPEg8SDxozFoV0iLCJUcmVuZHMiOiJb4bmuxZnhur3hur3hur3hur3guIHguLXguYnGjMWhXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTcGVuZCI6IlvFoMal4bq94bq94bq94LiB4Li14LmJxoxdIiwiUGVyZm9ybWFuY2UiOiJbxqThur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vV0iLCJDb252ZXJzaW9uIjoiW8OHw7bDtuC4geC4teC5ieG5veG6veG6vcWZxaHDrMOsw7bDtuC4geC4teC5iV0iLCJUaXBzIHRvIGltcHJvdmUgYWRzIjoiW%2BG5rsOsw6zDrMalxaEg4bmvw7bDtsO2IMOsw6zDrG3MgsalxZnDtsO2w7bhub3hur3hur3hur0gxIPEg8SDxozFoV0iLCJUcmVuZHMiOiJb4bmuxZnhur3hur3hur3hur3guIHguLXguYnGjMWhXSJ9!"
        )
      )
      });
  
      export { translations as default };
    