import type { Locale } from '@seek/melways-sites';

import { getLocale } from 'src/config';

interface FormatNumberOptions {
  round?: boolean;
  notation?: 'standard' | 'compact';
  locale?: Locale;
}

const formatNumber = (number: number, options?: FormatNumberOptions) => {
  const opts: FormatNumberOptions = {
    round: false,
    notation: 'standard',
    locale: getLocale(),
    ...options,
  };
  return new Intl.NumberFormat(opts.locale, {
    style: 'decimal',
    maximumFractionDigits: opts.round ? 0 : undefined,
    notation: opts.notation,
  }).format(opts.round ? Math.floor(number) : number);
};

export { formatNumber };
