
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIlNlYXJjaCBhY2NvdW50cyI6IuC4hOC5ieC4meC4q%2BC4suC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkFsbCBhY2NvdW50cyI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIlNlYXJjaCBhY2NvdW50cyI6IuC4hOC5ieC4meC4q%2BC4suC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkFsbCBhY2NvdW50cyI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkhpZXJhcmtpIGFrdW4iLCJTZWFyY2ggYWNjb3VudHMiOiJDYXJpIGFrdW4iLCJBbGwgYWNjb3VudHMiOiJTZW11YSBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkhpZXJhcmtpIGFrdW4iLCJTZWFyY2ggYWNjb3VudHMiOiJDYXJpIGFrdW4iLCJBbGwgYWNjb3VudHMiOiJTZW11YSBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIlNlYXJjaCBhY2NvdW50cyI6IuC4hOC5ieC4meC4q%2BC4suC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkFsbCBhY2NvdW50cyI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIlNlYXJjaCBhY2NvdW50cyI6IuC4hOC5ieC4meC4q%2BC4suC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkFsbCBhY2NvdW50cyI6IuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkhpZXJhcmtpIGFrdW4iLCJTZWFyY2ggYWNjb3VudHMiOiJDYXJpIGFrdW4iLCJBbGwgYWNjb3VudHMiOiJTZW11YSBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkhpZXJhcmtpIGFrdW4iLCJTZWFyY2ggYWNjb3VudHMiOiJDYXJpIGFrdW4iLCJBbGwgYWNjb3VudHMiOiJTZW11YSBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IkFjY291bnQgaGllcmFyY2h5IiwiU2VhcmNoIGFjY291bnRzIjoiU2VhcmNoIGFjY291bnRzIiwiQWxsIGFjY291bnRzIjoiQWxsIGFjY291bnRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IlvhuqzDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIOG4qcOsw6zDrOG6veG6veG6vcWZxIPEg8SDxZnDp%2BG4qcO9w73DvV0iLCJTZWFyY2ggYWNjb3VudHMiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoV0iLCJBbGwgYWNjb3VudHMiOiJb4bqsxprGmiDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGhpZXJhcmNoeSI6IlvhuqzDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIOG4qcOsw6zDrOG6veG6veG6vcWZxIPEg8SDxZnDp%2BG4qcO9w73DvV0iLCJTZWFyY2ggYWNjb3VudHMiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoV0iLCJBbGwgYWNjb3VudHMiOiJb4bqsxprGmiDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSJ9!"
        )
      )
      });
  
      export { translations as default };
    