import { useAccountContext } from '@seek/hirer-graphql-react';
import { getSiteFromZoneAndProduct } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  Drawer,
  Loader,
  Stack,
  Divider,
  TextLinkButton,
  Text,
} from 'braid-design-system';
import { endOfDay, subDays } from 'date-fns';
import { useCallback, useContext, useState, useEffect } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { AdUsageTabs } from 'src/blocks/AdUsageTabs/AdUsageTabs';
import { AdUsagePageFrame } from 'src/components/AdUsagePageFrame/AdUsagePageFrame';
import { AurSearchResultsMessage } from 'src/components/AurSearchResultsMessage/AurSearchResultsMessage';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { NoAccess } from 'src/components/NoAccess/NoAccess';
import { NoHistoricalDataMessage } from 'src/components/NoHistoricalDataMessage/NoHistoricalDataMessage';
import { NoResultMessage } from 'src/components/NoResultMessage/NoResultMessage';
import { TaxMessage } from 'src/components/TaxMessage/TaxMessage';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { LAUNCH_DATES, isHistoricalDataAvailable } from 'src/config';
import {
  IsFilterContext,
  IsFilterContextProvider,
} from 'src/context/IsFilterContext';
import {
  AdUsageFiltersProvider,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import { useAdUsageLastUpdated } from 'src/hooks/useAdUsageLastUpdated';
import { useGetAdvertiserId } from 'src/hooks/useGetAdvertiserId';
import { useRefFromUrl } from 'src/hooks/useRefFromUrl';
import { useResponsiveBreakpoints } from 'src/hooks/useResponsiveBreakpoints';
import { useSpendData } from 'src/hooks/useSpendData';
import {
  createDefaultAUREventData,
  toFormatAUDate,
  trackAURPage,
  trackWidgetView,
} from 'src/utils/tealiumAUREventTracker';
import { getBrandFromSiteName } from 'src/utils/tealiumEventTracker';
import { Filters } from 'src/widgets/Filters/Filters';
import { FiltersErrorBoundary } from 'src/widgets/Filters/FiltersErrorBoundary';
import { JobAdList } from 'src/widgets/JobAdList/JobAdList';
import { JobAdListErrorContainer } from 'src/widgets/JobAdList/JobAdListContainer';

import translations from './.vocab';
import { AdUsageReportFallback } from './AdUsageReportFallback';

import * as styles from './AdUsageReport.css';

export interface AdUsageSearchResultsSummaryProps {
  count: number;
  lastUpdated: string;
}

export const AdUsageReport = () => {
  const { t } = useTranslations(translations);

  const { country, language, zone } = useConfig();

  const { accountContext, loading: isAccountContextLoading } =
    useAccountContext();

  const advertiserId = useGetAdvertiserId();

  // Configuration
  const { isMobileOrTablet } = useResponsiveBreakpoints();
  const isCompactLayout = isMobileOrTablet;

  const [searchResultsSummary, updateSearchResultsSummary] = useState<
    AdUsageSearchResultsSummaryProps | undefined
  >();
  const { adUsageFilters } = useAdUsageFiltersContext();
  const { startDate, endDate, datePreset } = adUsageFilters.postingDates;
  const { isFilterAdPerformance } = useContext(IsFilterContext);

  const refFromUrl = useRefFromUrl();

  const siteName = getSiteFromZoneAndProduct(zone, 'employer');
  const brand = getBrandFromSiteName(siteName);

  useEffect(() => {
    trackAURPage(
      createDefaultAUREventData({
        userId: accountContext?.headerFooterContext?.user?.id || null,
        hirerUserFullName:
          accountContext?.headerFooterContext?.user?.name || null,
        userEmail: accountContext?.currentUser?.email || null,
        country,
        zone,
        language,
        advertiserId: advertiserId as string,
        brand,
        actionOrigin: refFromUrl,
      }),
    );
  }, [
    accountContext,
    country,
    zone,
    language,
    advertiserId,
    brand,
    refFromUrl,
  ]);
  useEffect(
    () =>
      trackWidgetView(
        {
          filterStartDate: toFormatAUDate(startDate),
          filterEndDate: toFormatAUDate(endDate),
          filterDateRange: datePreset
            ? datePreset?.replace('Last ', '')
            : `${toFormatAUDate(startDate)} to ${toFormatAUDate(endDate)}`,
          isFilterAdCost: false,
          isFilterAdPerformance: isFilterAdPerformance as boolean,
          isFilterAdSalary: false,
          isFilterAdStatus: false,
          isFilterAdType: false,
          isFilterCategory: false,
          isFilterChildAccount: false,
          isFilterLocation: false,
          isFilterPaidEdits: false,
          isFilterReposted: false,
          isFilterSubClassification: false,
          isFilterUser: false,
          isFilterWorkType: false,
          searchResultsTotal: (searchResultsSummary?.count ?? 0).toString(),
        },
        'Filters',
      ),

    [
      startDate,
      endDate,
      datePreset,
      isFilterAdPerformance,
      searchResultsSummary,
    ],
  );

  const { data: lastUpdatedData, isLoading, error } = useAdUsageLastUpdated();
  const { data: spendData, isLoading: isLoadingSpendData } = useSpendData();

  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);

  const openFilterDrawer = useCallback(() => {
    setFiltersDrawerOpen(true);
  }, []);

  const closeFilterDrawer = useCallback(() => {
    setFiltersDrawerOpen(false);
  }, []);

  const showNoResultMessage = Boolean(
    (searchResultsSummary && !searchResultsSummary.count) ||
      (!searchResultsSummary &&
        !isLoadingSpendData &&
        typeof spendData?.totalAds === 'number' &&
        spendData?.totalAds === 0),
  );

  if (isAccountContextLoading || (isLoading && !lastUpdatedData)) {
    return (
      <AdUsagePageFrame>
        <Box display="flex" justifyContent="center" padding="large">
          <Loader />
        </Box>
      </AdUsagePageFrame>
    );
  }

  const hasAccess = Boolean(
    accountContext?.headerFooterContext?.advertiser?.permissionOptions
      ?.canViewManagerReports,
  );

  if (!hasAccess) {
    return (
      <AdUsageReportFallback>
        <NoAccess />
      </AdUsageReportFallback>
    );
  }

  if (error || !lastUpdatedData) {
    return <AdUsageReportFallback />;
  }

  const hasLaunchDate = LAUNCH_DATES[siteName];
  if (
    hasLaunchDate &&
    !isHistoricalDataAvailable(siteName, lastUpdatedData.lastUpdated)
  ) {
    return (
      <AdUsagePageFrame>
        <NoHistoricalDataMessage />
      </AdUsagePageFrame>
    );
  }

  const lastDateForFilter = endOfDay(
    subDays(new Date(lastUpdatedData.lastUpdated), 1),
  );

  return (
    <IsFilterContextProvider>
      <AdUsageFiltersProvider lastDateForFilter={lastDateForFilter}>
        <AdUsagePageFrame numOfAds={searchResultsSummary?.count}>
          <Columns space="small">
            {!isCompactLayout ? (
              <Column width="content">
                <Box
                  className={styles.filtersContainer}
                  background="surface"
                  borderRadius="large"
                  padding={!isCompactLayout ? 'medium' : undefined}
                  paddingBottom="large"
                  boxShadow={
                    !isCompactLayout ? 'borderNeutralLight' : undefined
                  }
                >
                  <FiltersErrorBoundary>
                    <Filters
                      searchResultsTotal={searchResultsSummary?.count}
                      lastDateForFilter={lastDateForFilter}
                    />
                  </FiltersErrorBoundary>
                </Box>
              </Column>
            ) : null}
            <Column>
              <Box
                background="surface"
                borderRadius={{ mobile: 'none', desktop: 'large' }}
                boxShadow={isMobileOrTablet ? undefined : 'borderNeutralLight'}
              >
                {searchResultsSummary && (
                  <>
                    <Box padding={{ mobile: 'small', tablet: 'medium' }}>
                      <Stack space="medium">
                        {isCompactLayout && (
                          <Text size="small">
                            <TextLinkButton
                              weight="weak"
                              id="mobile-filters-button"
                              onClick={openFilterDrawer}
                            >
                              {t('Filter by')}
                            </TextLinkButton>
                          </Text>
                        )}
                        <AurSearchResultsMessage
                          count={searchResultsSummary.count}
                          lastUpdated={searchResultsSummary.lastUpdated}
                        />
                      </Stack>
                    </Box>
                    <Divider />
                  </>
                )}

                <Box
                  padding={{ mobile: 'small', tablet: 'medium' }}
                  paddingTop="none"
                >
                  <Stack space="medium">
                    {showNoResultMessage ? (
                      <Box>
                        <NoResultMessage />
                      </Box>
                    ) : (
                      <AdUsageTabs />
                    )}
                    <ErrorBoundary
                      fallback={
                        <JobAdListErrorContainer
                          lastUpdatedAgo={null}
                          testId="error-state"
                        >
                          <Box padding="medium">
                            <WidgetError />
                          </Box>
                        </JobAdListErrorContainer>
                      }
                    >
                      <JobAdList
                        updateSearchResultsSummary={updateSearchResultsSummary}
                      />
                    </ErrorBoundary>

                    {!showNoResultMessage ? <TaxMessage /> : null}
                  </Stack>
                </Box>
              </Box>
            </Column>
          </Columns>

          {isCompactLayout && (
            <Drawer
              id="ad-usage-filters-drawer"
              title={t('Filter by')}
              open={filtersDrawerOpen}
              onClose={closeFilterDrawer}
              width="small"
            >
              <FiltersErrorBoundary>
                <Filters
                  searchResultsTotal={searchResultsSummary?.count}
                  lastDateForFilter={lastDateForFilter}
                />
              </FiltersErrorBoundary>
            </Drawer>
          )}
        </AdUsagePageFrame>
      </AdUsageFiltersProvider>
    </IsFilterContextProvider>
  );
};
