import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import type { MarketInsightMonth } from 'src/types/MarketInsight';
import { formatDate } from 'src/utils/date';

import { MarketInsightTrendChart } from '../MarketInsightTrendChart/MarketInsightTrendChart';
import { RoundedCard } from '../RoundedCard/RoundedCard';
import { Trend } from '../Trend/Trend';

import translations from './.vocab';
import {
  getChartDataFromMarketInsights,
  hasPreviousYearHistoricalData,
  mapMarketInsightsToTrendParts,
} from './MarketInsight.util';

export interface MarketInsightProps {
  title: ReactNode | ReactNode[];
  comparisonPeriod: MarketInsightMonth[];
  currentPeriod: MarketInsightMonth[];
}

export const MarketInsight = ({
  title,
  comparisonPeriod,
  currentPeriod,
}: MarketInsightProps) => {
  const { t } = useTranslations(translations);
  const { site } = useConfig();
  const hasPreviousYearData = hasPreviousYearHistoricalData(site);

  const renderContent = () => {
    const lineData = getChartDataFromMarketInsights({
      comparisonPeriod,
      currentPeriod,
      hasPreviousYearData,
    });

    const trendParts = mapMarketInsightsToTrendParts(currentPeriod);

    if (trendParts === null) {
      return null;
    }

    const comparisonMonthLabel = formatDate({
      date: new Date(currentPeriod[0].calendarMonth),
      dateFormat: 'MMM yyyy',
    });

    return (
      <Stack space="gutter">
        <Text tone="secondary">
          {trendParts.type === 'PERCENTAGE'
            ? t(`PC compared to {comparisonMonth}`, {
                comparisonMonth: comparisonMonthLabel,
                percentage: trendParts.percentage,
                Trend: (label) => (
                  <Trend
                    isPositiveTrend={trendParts.isPositiveTrend}
                    size="standard"
                  >
                    {label}
                  </Trend>
                ),
              })
            : t(`${trendParts.type} compared to {comparisonMonth}`, {
                comparisonMonth: comparisonMonthLabel,
              })}
        </Text>
        <MarketInsightTrendChart data={lineData} height={164} width={560} />
      </Stack>
    );
  };

  return (
    <RoundedCard boxShadow="borderNeutralLight" height="full" padding="gutter">
      <Stack space="gutter">
        <Text>{title}</Text>
        {renderContent()}
      </Stack>
    </RoundedCard>
  );
};
