import { useTranslations } from '@vocab/react';
import { Box, Heading, PageBlock, Stack } from 'braid-design-system';

import { AdCentreFooter } from 'src/components/AdCentreFooter/AdCentreFooter';
import { AprBreadcrumb } from 'src/components/AprBreadcrumb/AprBreadcrumb';
import { Header } from 'src/components/Header/Header';
import { SystemBanner } from 'src/components/SystemBanner/SystemBanner';

import translations from './.vocab';

export interface AdPerformancePageFrameProps {
  children: ReactNodeNoStrings;
  hirerId?: number;
  stickyBar?: JSX.Element;
}

export const AdPerformancePageFrame = ({
  children,
  hirerId,
  stickyBar,
}: AdPerformancePageFrameProps) => {
  const { t } = useTranslations(translations);

  return (
    <Box>
      <Header
        hirerId={`${hirerId || ''}`}
        currentPage="ad performance report"
      />
      {stickyBar ?? null}
      <SystemBanner />
      <PageBlock width="large">
        <AprBreadcrumb />
        <Stack space="large">
          <Heading level="2">{t('My ad performance')}</Heading>
          {children}
        </Stack>
      </PageBlock>
      <AdCentreFooter currentPage="ad performance report" />
    </Box>
  );
};
