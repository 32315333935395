
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoi4LiV4Lix4Lin4LmA4Lil4LiC4LiZ4Li14LmJ4LmB4Liq4LiU4LiH4LiI4Liz4LiZ4Lin4LiZ4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4Li04LiB4Lib4Li44LmI4Lih4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIOC5geC4peC4sDxTdGF0dXM%2B4LmA4Lij4Li04LmI4LihPC9TdGF0dXM%2B4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiLguITguLjguJPguYTguJTguYnguYDguKXguLfguK3guIHguJvguLjguYjguKEg4oCc4Liq4Lih4Lix4LiE4Lij4oCdIOC4muC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4nOC4ueC5ieC4quC4oeC4seC4hOC4o%2BC4h%2BC4suC4meC5hOC4m%2BC4ouC4seC4h%2BC4q%2BC4meC5ieC4suC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4guC4reC4h%2BC4hOC4uOC4kyDguJTguLHguIfguJnguLHguYnguJkgU0VFSyDguIjguLDguYTguKHguYjguYDguKvguYfguJnguILguYnguK3guKHguLnguKXguKfguYjguLLguITguLjguJPguYTguJTguYnguKPguLHguJrguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguYDguJfguYjguLLguYPguJQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoi4LiV4Lix4Lin4LmA4Lil4LiC4LiZ4Li14LmJ4LmB4Liq4LiU4LiH4LiI4Liz4LiZ4Lin4LiZ4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4Li04LiB4Lib4Li44LmI4Lih4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIOC5geC4peC4sDxTdGF0dXM%2B4LmA4Lij4Li04LmI4LihPC9TdGF0dXM%2B4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiLguITguLjguJPguYTguJTguYnguYDguKXguLfguK3guIHguJvguLjguYjguKEg4oCc4Liq4Lih4Lix4LiE4Lij4oCdIOC4muC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4nOC4ueC5ieC4quC4oeC4seC4hOC4o%2BC4h%2BC4suC4meC5hOC4m%2BC4ouC4seC4h%2BC4q%2BC4meC5ieC4suC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4guC4reC4h%2BC4hOC4uOC4kyDguJTguLHguIfguJnguLHguYnguJkgU0VFSyDguIjguLDguYTguKHguYjguYDguKvguYfguJnguILguYnguK3guKHguLnguKXguKfguYjguLLguITguLjguJPguYTguJTguYnguKPguLHguJrguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguYDguJfguYjguLLguYPguJQifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoiSnVtbGFoIGluaSBtZW51bmp1a2thbiBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW5nZWtsaWsgdG9tYm9sIGRhZnRhciBkaSBpa2xhbiBBbmRhIGRhbiA8U3RhdHVzPm1lbXVsYWk8L1N0YXR1cz4gcGVuZGFmdGFyYW4uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJBbmRhIHRlbGFoIG1lbWlsaWggYWdhciB0b21ib2wgJ2RhZnRhcicgZGkgaWtsYW4gQW5kYSBtZW5nYXJhaGthbiBrYW5kaWRhdCBrZSBzaXR1cyB3ZWIsIHNlaGluZ2dhIFNFRUsgdGlkYWsgZGFwYXQgbWVsaWhhdCBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoiSnVtbGFoIGluaSBtZW51bmp1a2thbiBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW5nZWtsaWsgdG9tYm9sIGRhZnRhciBkaSBpa2xhbiBBbmRhIGRhbiA8U3RhdHVzPm1lbXVsYWk8L1N0YXR1cz4gcGVuZGFmdGFyYW4uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJBbmRhIHRlbGFoIG1lbWlsaWggYWdhciB0b21ib2wgJ2RhZnRhcicgZGkgaWtsYW4gQW5kYSBtZW5nYXJhaGthbiBrYW5kaWRhdCBrZSBzaXR1cyB3ZWIsIHNlaGluZ2dhIFNFRUsgdGlkYWsgZGFwYXQgbWVsaWhhdCBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoi4LiV4Lix4Lin4LmA4Lil4LiC4LiZ4Li14LmJ4LmB4Liq4LiU4LiH4LiI4Liz4LiZ4Lin4LiZ4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4Li04LiB4Lib4Li44LmI4Lih4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIOC5geC4peC4sDxTdGF0dXM%2B4LmA4Lij4Li04LmI4LihPC9TdGF0dXM%2B4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiLguITguLjguJPguYTguJTguYnguYDguKXguLfguK3guIHguJvguLjguYjguKEg4oCc4Liq4Lih4Lix4LiE4Lij4oCdIOC4muC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4nOC4ueC5ieC4quC4oeC4seC4hOC4o%2BC4h%2BC4suC4meC5hOC4m%2BC4ouC4seC4h%2BC4q%2BC4meC5ieC4suC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4guC4reC4h%2BC4hOC4uOC4kyDguJTguLHguIfguJnguLHguYnguJkgU0VFSyDguIjguLDguYTguKHguYjguYDguKvguYfguJnguILguYnguK3guKHguLnguKXguKfguYjguLLguITguLjguJPguYTguJTguYnguKPguLHguJrguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguYDguJfguYjguLLguYPguJQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoi4LiV4Lix4Lin4LmA4Lil4LiC4LiZ4Li14LmJ4LmB4Liq4LiU4LiH4LiI4Liz4LiZ4Lin4LiZ4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4Li04LiB4Lib4Li44LmI4Lih4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIOC5geC4peC4sDxTdGF0dXM%2B4LmA4Lij4Li04LmI4LihPC9TdGF0dXM%2B4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiLguITguLjguJPguYTguJTguYnguYDguKXguLfguK3guIHguJvguLjguYjguKEg4oCc4Liq4Lih4Lix4LiE4Lij4oCdIOC4muC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4nOC4ueC5ieC4quC4oeC4seC4hOC4o%2BC4h%2BC4suC4meC5hOC4m%2BC4ouC4seC4h%2BC4q%2BC4meC5ieC4suC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4guC4reC4h%2BC4hOC4uOC4kyDguJTguLHguIfguJnguLHguYnguJkgU0VFSyDguIjguLDguYTguKHguYjguYDguKvguYfguJnguILguYnguK3guKHguLnguKXguKfguYjguLLguITguLjguJPguYTguJTguYnguKPguLHguJrguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguYDguJfguYjguLLguYPguJQifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoiSnVtbGFoIGluaSBtZW51bmp1a2thbiBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW5nZWtsaWsgdG9tYm9sIGRhZnRhciBkaSBpa2xhbiBBbmRhIGRhbiA8U3RhdHVzPm1lbXVsYWk8L1N0YXR1cz4gcGVuZGFmdGFyYW4uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJBbmRhIHRlbGFoIG1lbWlsaWggYWdhciB0b21ib2wgJ2RhZnRhcicgZGkgaWtsYW4gQW5kYSBtZW5nYXJhaGthbiBrYW5kaWRhdCBrZSBzaXR1cyB3ZWIsIHNlaGluZ2dhIFNFRUsgdGlkYWsgZGFwYXQgbWVsaWhhdCBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBzdGFydGVkIjoiSnVtbGFoIGluaSBtZW51bmp1a2thbiBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW5nZWtsaWsgdG9tYm9sIGRhZnRhciBkaSBpa2xhbiBBbmRhIGRhbiA8U3RhdHVzPm1lbXVsYWk8L1N0YXR1cz4gcGVuZGFmdGFyYW4uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJBbmRhIHRlbGFoIG1lbWlsaWggYWdhciB0b21ib2wgJ2RhZnRhcicgZGkgaWtsYW4gQW5kYSBtZW5nYXJhaGthbiBrYW5kaWRhdCBrZSBzaXR1cyB3ZWIsIHNlaGluZ2dhIFNFRUsgdGlkYWsgZGFwYXQgbWVsaWhhdCBqdW1sYWgga2FuZGlkYXQgeWFuZyBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6IlRoZSBudW1iZXIgaGVyZSByZWZsZWN0cyBob3cgbWFueSBjYW5kaWRhdGVzIGNsaWNrZWQgdGhlIGFwcGx5IGJ1dHRvbiBvbiB5b3VyIGFkIGFuZCA8U3RhdHVzPnN0YXJ0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJZb3UndmUgY2hvc2VuIGZvciB0aGUgJ2FwcGx5JyBidXR0b24gb24geW91ciBhZCB0byB0YWtlIGNhbmRpZGF0ZXMgdG8geW91ciB3ZWJzaXRlLCBzbyBTRUVLIGNhbid0IHNlZSBob3cgbWFueSBjYW5kaWRhdGVzIGNvbXBsZXRlZCBhbiBhcHBsaWNhdGlvbi4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJbxbThuKnEg8SDxIPhua8nxaEg4bmv4bipw6zDrMOsxaEg4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmT9dIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6Ilvhua7huKnhur3hur3hur0g4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSDhuKnhur3hur3hur3FmeG6veG6veG6vSDFmeG6veG6veG6vcaSxprhur3hur3hur3Dp%2BG5r8WhIOG4qcO2w7bDtsW1IG3MgsSDxIPEg%2BC4geC4teC5icO9w73DvSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoSDDp8aaw6zDrMOsw6fEt%2BG6veG6veG6vcaMIOG5r%2BG4qeG6veG6veG6vSDEg8SDxIPGpcalxprDvcO9w70gw5%2FHmseax5rhua%2Fhua%2FDtsO2w7bguIHguLXguYkgw7bDtsO24LiB4Li14LmJIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMIMSDxIPEg%2BC4geC4teC5icaMIDxTdGF0dXM%2BxaHhua%2FEg8SDxIPFmeG5r%2BG6veG6veG6vcaMPC9TdGF0dXM%2BIMSDxIPEg%2BC4geC4teC5iSDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iS5dIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJbw53DtsO2w7bHmseax5on4bm94bq94bq94bq9IMOn4bipw7bDtsO2xaHhur3hur3hur3guIHguLXguYkgxpLDtsO2w7bFmSDhua%2FhuKnhur3hur3hur0gJ8SDxIPEg8alxqXGmsO9w73DvScgw5%2FHmseax5rhua%2Fhua%2FDtsO2w7bguIHguLXguYkgw7bDtsO24LiB4Li14LmJIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMIOG5r8O2w7bDtiDhua%2FEg8SDxIPEt%2BG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoSDhua%2FDtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaxZkgxbXhur3hur3hur3Dn8Whw6zDrMOs4bmv4bq94bq94bq9LCDFocO2w7bDtiDFoMOLw4vhuLAgw6fEg8SDxIPguIHguLXguYkn4bmvIMWh4bq94bq94bq94bq94bq94bq9IOG4qcO2w7bDtsW1IG3MgsSDxIPEg%2BC4geC4teC5icO9w73DvSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoSDDp8O2w7bDtm3Mgsalxprhur3hur3hur3hua%2Fhur3hur3hur3GjCDEg8SDxIPguIHguLXguYkgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJbxbThuKnEg8SDxIPhua8nxaEg4bmv4bipw6zDrMOsxaEg4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmT9dIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgc3RhcnRlZCI6Ilvhua7huKnhur3hur3hur0g4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSDhuKnhur3hur3hur3FmeG6veG6veG6vSDFmeG6veG6veG6vcaSxprhur3hur3hur3Dp%2BG5r8WhIOG4qcO2w7bDtsW1IG3MgsSDxIPEg%2BC4geC4teC5icO9w73DvSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoSDDp8aaw6zDrMOsw6fEt%2BG6veG6veG6vcaMIOG5r%2BG4qeG6veG6veG6vSDEg8SDxIPGpcalxprDvcO9w70gw5%2FHmseax5rhua%2Fhua%2FDtsO2w7bguIHguLXguYkgw7bDtsO24LiB4Li14LmJIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMIMSDxIPEg%2BC4geC4teC5icaMIDxTdGF0dXM%2BxaHhua%2FEg8SDxIPFmeG5r%2BG6veG6veG6vcaMPC9TdGF0dXM%2BIMSDxIPEg%2BC4geC4teC5iSDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iS5dIiwiQXBwcyBjb21wbGV0ZSBjb3VudCBub3QgcG9zc2libGUgZm9yIGluZGlyZWN0IGpvYnMiOiJbw53DtsO2w7bHmseax5on4bm94bq94bq94bq9IMOn4bipw7bDtsO2xaHhur3hur3hur3guIHguLXguYkgxpLDtsO2w7bFmSDhua%2FhuKnhur3hur3hur0gJ8SDxIPEg8alxqXGmsO9w73DvScgw5%2FHmseax5rhua%2Fhua%2FDtsO2w7bguIHguLXguYkgw7bDtsO24LiB4Li14LmJIMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8aMIOG5r8O2w7bDtiDhua%2FEg8SDxIPEt%2BG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoSDhua%2FDtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaxZkgxbXhur3hur3hur3Dn8Whw6zDrMOs4bmv4bq94bq94bq9LCDFocO2w7bDtiDFoMOLw4vhuLAgw6fEg8SDxIPguIHguLXguYkn4bmvIMWh4bq94bq94bq94bq94bq94bq9IOG4qcO2w7bDtsW1IG3MgsSDxIPEg%2BC4geC4teC5icO9w73DvSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoSDDp8O2w7bDtm3Mgsalxprhur3hur3hur3hua%2Fhur3hur3hur3GjCDEg8SDxIPguIHguLXguYkgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    