
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IuC4h%2BC4muC4m%2BC4o%2BC4sOC4oeC4suC4k%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYiLCJBZCBidWRnZXQiOiLguIfguJrguJvguKPguLDguKHguLLguJPguYLguIbguKnguJPguLIiLCJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9Ijoi4Lij4Liy4Lii4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4Lih4LmI4Lij4Lin4Lih4LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCDguIHguYjguK3guJkge2RhdGV9IiwiUHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0Ijoi4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoi4Lir4Lih4Liy4Lii4LmA4Lil4LiC4Liq4Lix4LiN4LiN4LiyIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiLguYPguIrguYnguYTguJTguYnguIjguJnguJbguLbguIcge2RhdGV9IiwiRXhwaXJlZCBvbiBkYXRlIjoi4Lir4Lih4LiU4Lit4Liy4Lii4Li44Lin4Lix4LiZ4LiX4Li14LmIIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IuC4h%2BC4muC4m%2BC4o%2BC4sOC4oeC4suC4k%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYiLCJBZCBidWRnZXQiOiLguIfguJrguJvguKPguLDguKHguLLguJPguYLguIbguKnguJPguLIiLCJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9Ijoi4Lij4Liy4Lii4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4Lih4LmI4Lij4Lin4Lih4LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCDguIHguYjguK3guJkge2RhdGV9IiwiUHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0Ijoi4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoi4Lir4Lih4Liy4Lii4LmA4Lil4LiC4Liq4Lix4LiN4LiN4LiyIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiLguYPguIrguYnguYTguJTguYnguIjguJnguJbguLbguIcge2RhdGV9IiwiRXhwaXJlZCBvbiBkYXRlIjoi4Lir4Lih4LiU4Lit4Liy4Lii4Li44Lin4Lix4LiZ4LiX4Li14LmIIHtkYXRlfSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IlNlbXVhIGFuZ2dhcmFuIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIkFkIGJ1ZGdldCI6IkFuZ2dhcmFuIGlrbGFuIiwiVGhpcyByZXBvcnQgZG9lc24ndCBpbmNsdWRlIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtkYXRlfSI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIGlrbGFuIHlhbmcgZGliZWxpIHRhbnBhIEFkIEJ1ZGdldCBzZWJlbHVtIHtkYXRlfS4iLCJQdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQiOiJEaWJlbGkgdGFucGEgQWQgQnVkZ2V0IiwiQ29udHJhY3Qgbm8uIGNvbnRyYWN0Tm8iOiJLb250cmFrIG5vbW9yIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJCZXJsYWt1IGhpbmdnYSB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJLZWRhbHV3YXJzYSBwYWRhIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IlNlbXVhIGFuZ2dhcmFuIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIkFkIGJ1ZGdldCI6IkFuZ2dhcmFuIGlrbGFuIiwiVGhpcyByZXBvcnQgZG9lc24ndCBpbmNsdWRlIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtkYXRlfSI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIGlrbGFuIHlhbmcgZGliZWxpIHRhbnBhIEFkIEJ1ZGdldCBzZWJlbHVtIHtkYXRlfS4iLCJQdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQiOiJEaWJlbGkgdGFucGEgQWQgQnVkZ2V0IiwiQ29udHJhY3Qgbm8uIGNvbnRyYWN0Tm8iOiJLb250cmFrIG5vbW9yIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJCZXJsYWt1IGhpbmdnYSB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJLZWRhbHV3YXJzYSBwYWRhIHtkYXRlfSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IuC4h%2BC4muC4m%2BC4o%2BC4sOC4oeC4suC4k%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYiLCJBZCBidWRnZXQiOiLguIfguJrguJvguKPguLDguKHguLLguJPguYLguIbguKnguJPguLIiLCJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9Ijoi4Lij4Liy4Lii4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4Lih4LmI4Lij4Lin4Lih4LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCDguIHguYjguK3guJkge2RhdGV9IiwiUHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0Ijoi4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoi4Lir4Lih4Liy4Lii4LmA4Lil4LiC4Liq4Lix4LiN4LiN4LiyIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiLguYPguIrguYnguYTguJTguYnguIjguJnguJbguLbguIcge2RhdGV9IiwiRXhwaXJlZCBvbiBkYXRlIjoi4Lir4Lih4LiU4Lit4Liy4Lii4Li44Lin4Lix4LiZ4LiX4Li14LmIIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IuC4h%2BC4muC4m%2BC4o%2BC4sOC4oeC4suC4k%2BC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYiLCJBZCBidWRnZXQiOiLguIfguJrguJvguKPguLDguKHguLLguJPguYLguIbguKnguJPguLIiLCJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9Ijoi4Lij4Liy4Lii4LiH4Liy4LiZ4LiZ4Li14LmJ4LmE4Lih4LmI4Lij4Lin4Lih4LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCDguIHguYjguK3guJkge2RhdGV9IiwiUHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0Ijoi4LiL4Li34LmJ4Lit4LmC4LiU4Lii4LmE4Lih4LmI4Lih4Li1IEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoi4Lir4Lih4Liy4Lii4LmA4Lil4LiC4Liq4Lix4LiN4LiN4LiyIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiLguYPguIrguYnguYTguJTguYnguIjguJnguJbguLbguIcge2RhdGV9IiwiRXhwaXJlZCBvbiBkYXRlIjoi4Lir4Lih4LiU4Lit4Liy4Lii4Li44Lin4Lix4LiZ4LiX4Li14LmIIHtkYXRlfSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IlNlbXVhIGFuZ2dhcmFuIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIkFkIGJ1ZGdldCI6IkFuZ2dhcmFuIGlrbGFuIiwiVGhpcyByZXBvcnQgZG9lc24ndCBpbmNsdWRlIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtkYXRlfSI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIGlrbGFuIHlhbmcgZGliZWxpIHRhbnBhIEFkIEJ1ZGdldCBzZWJlbHVtIHtkYXRlfS4iLCJQdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQiOiJEaWJlbGkgdGFucGEgQWQgQnVkZ2V0IiwiQ29udHJhY3Qgbm8uIGNvbnRyYWN0Tm8iOiJLb250cmFrIG5vbW9yIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJCZXJsYWt1IGhpbmdnYSB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJLZWRhbHV3YXJzYSBwYWRhIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IlNlbXVhIGFuZ2dhcmFuIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIkFkIGJ1ZGdldCI6IkFuZ2dhcmFuIGlrbGFuIiwiVGhpcyByZXBvcnQgZG9lc24ndCBpbmNsdWRlIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtkYXRlfSI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIGlrbGFuIHlhbmcgZGliZWxpIHRhbnBhIEFkIEJ1ZGdldCBzZWJlbHVtIHtkYXRlfS4iLCJQdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQiOiJEaWJlbGkgdGFucGEgQWQgQnVkZ2V0IiwiQ29udHJhY3Qgbm8uIGNvbnRyYWN0Tm8iOiJLb250cmFrIG5vbW9yIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJCZXJsYWt1IGhpbmdnYSB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJLZWRhbHV3YXJzYSBwYWRhIHtkYXRlfSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IkFsbCBidWRnZXRzIiwiT3RoZXJzIjoib3RoZXJzIiwiT3RoZXIiOiJvdGhlciIsIkFkIGJ1ZGdldCI6IkFkIGJ1ZGdldCIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSBhZHMgcHVyY2hhc2VkIHdpdGhvdXQgYW4gQWQgQnVkZ2V0IGJlZm9yZSB7ZGF0ZX0iOiJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2RhdGV9LiIsIlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCI6IlB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCIsIkNvbnRyYWN0IG5vLiBjb250cmFjdE5vIjoiQ29udHJhY3Qgbm8uIHtjb250cmFjdE5vfSIsIlZhbGlkIHVudGlsIGRhdGUiOiJWYWxpZCB1bnRpbCB7ZGF0ZX0iLCJFeHBpcmVkIG9uIGRhdGUiOiJFeHBpcmVkIG9uIHtkYXRlfSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IlvhuqzGmsaaIMOfx5rHmseax5rGjMSj4bq94bq94bq94bq94bmvxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIkFkIGJ1ZGdldCI6IlvhuqzGjCDDn8eax5rHmsaMxKPhur3hur3hur3hua9dIiwiVGhpcyByZXBvcnQgZG9lc24ndCBpbmNsdWRlIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtkYXRlfSI6Ilvhua7huKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxozDtsO2w7bhur3hur3hur3FoeC4geC4teC5iSfhua8gw6zDrMOs4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9IMSDxIPEg8aMxaEgxqXHmseax5rFmcOn4bipxIPEg8SDxaHhur3hur3hur3GjCDFtcOsw6zDrOG5r%2BG4qcO2w7bDtseax5rHmuG5ryDEg8SDxIPguIHguLXguYkg4bqsxowg4bqex5rHmseaxozEo%2BG6veG6veG6veG5ryDDn%2BG6veG6veG6vcaSw7bDtsO2xZnhur3hur3hur0ge2RhdGV9Ll0iLCJQdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQiOiJbxqTHmseax5rFmcOn4bipxIPEg8SDxaHhur3hur3hur3GjCDFtcOsw6zDrOG5r%2BG4qcO2w7bDtseax5rHmuG5ryDEg8SDxIPguIHguLXguYkg4bqsxowg4bqex5rHmseaxozEo%2BG6veG6veG6veG5r10iLCJDb250cmFjdCBuby4gY29udHJhY3RObyI6IlvDh8O2w7bDtuC4geC4teC5ieG5r8WZxIPEg8SDw6fhua8g4LiB4Li14LmJw7bDtsO2LiB7Y29udHJhY3ROb31dIiwiVmFsaWQgdW50aWwgZGF0ZSI6IlvhubzEg8SDxIPGmsOsw6zDrMaMIMeax5rHmuC4geC4teC5ieG5r8Osw6zDrMaaIHtkYXRlfV0iLCJFeHBpcmVkIG9uIGRhdGUiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgw7bDtsO24LiB4Li14LmJIHtkYXRlfV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYnVkZ2V0cyI6IlvhuqzGmsaaIMOfx5rHmseax5rGjMSj4bq94bq94bq94bq94bmvxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIkFkIGJ1ZGdldCI6IlvhuqzGjCDDn8eax5rHmsaMxKPhur3hur3hur3hua9dIiwiVGhpcyByZXBvcnQgZG9lc24ndCBpbmNsdWRlIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtkYXRlfSI6Ilvhua7huKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxozDtsO2w7bhur3hur3hur3FoeC4geC4teC5iSfhua8gw6zDrMOs4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9IMSDxIPEg8aMxaEgxqXHmseax5rFmcOn4bipxIPEg8SDxaHhur3hur3hur3GjCDFtcOsw6zDrOG5r%2BG4qcO2w7bDtseax5rHmuG5ryDEg8SDxIPguIHguLXguYkg4bqsxowg4bqex5rHmseaxozEo%2BG6veG6veG6veG5ryDDn%2BG6veG6veG6vcaSw7bDtsO2xZnhur3hur3hur0ge2RhdGV9Ll0iLCJQdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQiOiJbxqTHmseax5rFmcOn4bipxIPEg8SDxaHhur3hur3hur3GjCDFtcOsw6zDrOG5r%2BG4qcO2w7bDtseax5rHmuG5ryDEg8SDxIPguIHguLXguYkg4bqsxowg4bqex5rHmseaxozEo%2BG6veG6veG6veG5r10iLCJDb250cmFjdCBuby4gY29udHJhY3RObyI6IlvDh8O2w7bDtuC4geC4teC5ieG5r8WZxIPEg8SDw6fhua8g4LiB4Li14LmJw7bDtsO2LiB7Y29udHJhY3ROb31dIiwiVmFsaWQgdW50aWwgZGF0ZSI6IlvhubzEg8SDxIPGmsOsw6zDrMaMIMeax5rHmuC4geC4teC5ieG5r8Osw6zDrMaaIHtkYXRlfV0iLCJFeHBpcmVkIG9uIGRhdGUiOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowgw7bDtsO24LiB4Li14LmJIHtkYXRlfV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    