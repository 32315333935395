
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6IuC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5guC4lOC4ouC4o%2BC4p%2BC4oSDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguITguKXguYnguLLguKLguIHguLHguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiTm9ybWFsIjoi4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiTG93Ijoi4LiV4LmI4LizIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSDguYTguKHguYjguKHguLXguITguLDguYHguJnguJnguJzguKXguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6IuC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5guC4lOC4ouC4o%2BC4p%2BC4oSDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguITguKXguYnguLLguKLguIHguLHguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiTm9ybWFsIjoi4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiTG93Ijoi4LiV4LmI4LizIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSDguYTguKHguYjguKHguLXguITguLDguYHguJnguJnguJzguKXguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Iktlc2VsdXJ1aGFuIHBlbmlsYWlhbiBwZXJmb3JtYSBpa2xhbiBkaWJhbmRpbmdrYW4gaWtsYW4geWFuZyBzZXJ1cGEiLCJIaWdoIjoiVGluZ2dpIiwiTm9ybWFsIjoiTm9ybWFsIiwiTG93IjoiUmVuZGFoIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBpa2xhbn0gb3RoZXIge3thZENvdW50VGV4dH0gaWtsYW59fSAoe3BlcmNlbnRhZ2V9IGRhcmkgdG90YWwpIiwiTiBhZHMgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZyI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGlrbGFufSBvdGhlciB7e2FkQ291bnRUZXh0fSBpa2xhbn19ICh7cGVyY2VudGFnZX0gZGFyaSB0b3RhbCkgdGlkYWsgbWVtaWxpa2kgcGVuaWxhaWFuIHBlcmZvcm1hLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Iktlc2VsdXJ1aGFuIHBlbmlsYWlhbiBwZXJmb3JtYSBpa2xhbiBkaWJhbmRpbmdrYW4gaWtsYW4geWFuZyBzZXJ1cGEiLCJIaWdoIjoiVGluZ2dpIiwiTm9ybWFsIjoiTm9ybWFsIiwiTG93IjoiUmVuZGFoIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBpa2xhbn0gb3RoZXIge3thZENvdW50VGV4dH0gaWtsYW59fSAoe3BlcmNlbnRhZ2V9IGRhcmkgdG90YWwpIiwiTiBhZHMgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZyI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGlrbGFufSBvdGhlciB7e2FkQ291bnRUZXh0fSBpa2xhbn19ICh7cGVyY2VudGFnZX0gZGFyaSB0b3RhbCkgdGlkYWsgbWVtaWxpa2kgcGVuaWxhaWFuIHBlcmZvcm1hLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6IuC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5guC4lOC4ouC4o%2BC4p%2BC4oSDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguITguKXguYnguLLguKLguIHguLHguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiTm9ybWFsIjoi4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiTG93Ijoi4LiV4LmI4LizIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSDguYTguKHguYjguKHguLXguITguLDguYHguJnguJnguJzguKXguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6IuC4hOC4sOC5geC4meC4meC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5guC4lOC4ouC4o%2BC4p%2BC4oSDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguITguKXguYnguLLguKLguIHguLHguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiTm9ybWFsIjoi4Lib4Liy4LiZ4LiB4Lil4Liy4LiHIiwiTG93Ijoi4LiV4LmI4LizIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSDguJvguKPguLDguIHguLLguKjguIfguLLguJl9IG90aGVyIHt7YWRDb3VudFRleHR9IOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mX19ICh7cGVyY2VudGFnZX0g4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSDguYTguKHguYjguKHguLXguITguLDguYHguJnguJnguJzguKXguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Iktlc2VsdXJ1aGFuIHBlbmlsYWlhbiBwZXJmb3JtYSBpa2xhbiBkaWJhbmRpbmdrYW4gaWtsYW4geWFuZyBzZXJ1cGEiLCJIaWdoIjoiVGluZ2dpIiwiTm9ybWFsIjoiTm9ybWFsIiwiTG93IjoiUmVuZGFoIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBpa2xhbn0gb3RoZXIge3thZENvdW50VGV4dH0gaWtsYW59fSAoe3BlcmNlbnRhZ2V9IGRhcmkgdG90YWwpIiwiTiBhZHMgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZyI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGlrbGFufSBvdGhlciB7e2FkQ291bnRUZXh0fSBpa2xhbn19ICh7cGVyY2VudGFnZX0gZGFyaSB0b3RhbCkgdGlkYWsgbWVtaWxpa2kgcGVuaWxhaWFuIHBlcmZvcm1hLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Iktlc2VsdXJ1aGFuIHBlbmlsYWlhbiBwZXJmb3JtYSBpa2xhbiBkaWJhbmRpbmdrYW4gaWtsYW4geWFuZyBzZXJ1cGEiLCJIaWdoIjoiVGluZ2dpIiwiTm9ybWFsIjoiTm9ybWFsIiwiTG93IjoiUmVuZGFoIiwiTiBhZHMgb2YgdG90YWwiOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBpa2xhbn0gb3RoZXIge3thZENvdW50VGV4dH0gaWtsYW59fSAoe3BlcmNlbnRhZ2V9IGRhcmkgdG90YWwpIiwiTiBhZHMgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZyI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGlrbGFufSBvdGhlciB7e2FkQ291bnRUZXh0fSBpa2xhbn19ICh7cGVyY2VudGFnZX0gZGFyaSB0b3RhbCkgdGlkYWsgbWVtaWxpa2kgcGVuaWxhaWFuIHBlcmZvcm1hLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6Ik92ZXJhbGwgYWQgcGVyZm9ybWFuY2UgcmF0aW5nIGNvbXBhcmVkIHRvIHNpbWlsYXIgYWRzIiwiSGlnaCI6IkhpZ2giLCJOb3JtYWwiOiJOb3JtYWwiLCJMb3ciOiJMb3ciLCJOIGFkcyBvZiB0b3RhbCI6InthZENvdW50LCBwbHVyYWwsID0xIHsxIGFkfSBvdGhlciB7e2FkQ291bnRUZXh0fSBhZHN9fSAoe3BlcmNlbnRhZ2V9IG9mIHRvdGFsKSIsIk4gYWRzIGRvbid0IGhhdmUgYSBwZXJmb3JtYW5jZSByYXRpbmciOiJ7YWRDb3VudCwgcGx1cmFsLCA9MSB7MSBhZH0gb3RoZXIge3thZENvdW50VGV4dH0gYWRzfX0gKHtwZXJjZW50YWdlfSBvZiB0b3RhbCkgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZy4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6IlvDluG5veG6veG6veG6vcWZxIPEg8SDxprGmiDEg8SDxIPGjCDGpeG6veG6veG6vcWZxpLDtsO2w7bFmW3MgsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9IMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKMgw6fDtsO2w7ZtzILGpcSDxIPEg8WZ4bq94bq94bq9xowg4bmvw7bDtsO2IMWhw6zDrMOsbcyCw6zDrMOsxprEg8SDxIPFmSDEg8SDxIPGjMWhXSIsIkhpZ2giOiJb4biow6zDrMOsxKPhuKldIiwiTm9ybWFsIjoiW8ORw7bDtsO2xZltzILEg8SDxIPGml0iLCJMb3ciOiJbxYHDtsO2w7bFtV0iLCJOIGFkcyBvZiB0b3RhbCI6Ilt7YWRDb3VudCxwbHVyYWwsPTF7MSDEg8SDxIPGjH0gb3RoZXJ7e2FkQ291bnRUZXh0fSDEg8SDxIPGjMWhfX0gKHtwZXJjZW50YWdlfSDDtsO2w7bGkiDhua%2FDtsO2w7bhua%2FEg8SDxIPGmildIiwiTiBhZHMgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZyI6Ilt7YWRDb3VudCxwbHVyYWwsPTF7MSDEg8SDxIPGjH0gb3RoZXJ7e2FkQ291bnRUZXh0fSDEg8SDxIPGjMWhfX0gKHtwZXJjZW50YWdlfSDDtsO2w7bGkiDhua%2FDtsO2w7bhua%2FEg8SDxIPGmikgxozDtsO2w7bguIHguLXguYkn4bmvIOG4qcSDxIPEg%2BG5veG6veG6veG6vSDEg8SDxIMgxqXhur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vSDFmcSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdmVyYWxsIGFkIHBlcmZvcm1hbmNlIHJhdGluZyI6IlvDluG5veG6veG6veG6vcWZxIPEg8SDxprGmiDEg8SDxIPGjCDGpeG6veG6veG6vcWZxpLDtsO2w7bFmW3MgsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9IMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKMgw6fDtsO2w7ZtzILGpcSDxIPEg8WZ4bq94bq94bq9xowg4bmvw7bDtsO2IMWhw6zDrMOsbcyCw6zDrMOsxprEg8SDxIPFmSDEg8SDxIPGjMWhXSIsIkhpZ2giOiJb4biow6zDrMOsxKPhuKldIiwiTm9ybWFsIjoiW8ORw7bDtsO2xZltzILEg8SDxIPGml0iLCJMb3ciOiJbxYHDtsO2w7bFtV0iLCJOIGFkcyBvZiB0b3RhbCI6Ilt7YWRDb3VudCxwbHVyYWwsPTF7MSDEg8SDxIPGjH0gb3RoZXJ7e2FkQ291bnRUZXh0fSDEg8SDxIPGjMWhfX0gKHtwZXJjZW50YWdlfSDDtsO2w7bGkiDhua%2FDtsO2w7bhua%2FEg8SDxIPGmildIiwiTiBhZHMgZG9uJ3QgaGF2ZSBhIHBlcmZvcm1hbmNlIHJhdGluZyI6Ilt7YWRDb3VudCxwbHVyYWwsPTF7MSDEg8SDxIPGjH0gb3RoZXJ7e2FkQ291bnRUZXh0fSDEg8SDxIPGjMWhfX0gKHtwZXJjZW50YWdlfSDDtsO2w7bGkiDhua%2FDtsO2w7bhua%2FEg8SDxIPGmikgxozDtsO2w7bguIHguLXguYkn4bmvIOG4qcSDxIPEg%2BG5veG6veG6veG6vSDEg8SDxIMgxqXhur3hur3hur3FmcaSw7bDtsO2xZltzILEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vSDFmcSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    