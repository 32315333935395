
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IuC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJhY2sgdG8geW91ciBqb2IgbGlzdCI6IuC4ouC5ieC4reC4meC4geC4peC4seC4muC5hOC4m%2BC4l%2BC4teC5iOC4o%2BC4suC4ouC4h%2BC4suC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkpvYnMgbGlzdCI6IuC4o%2BC4suC4ouC4geC4suC4o%2BC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIllvdSBhcmUgaGVyZSI6IuC4hOC4uOC4k%2BC4reC4ouC4ueC5iOC4l%2BC4teC5iOC4meC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IuC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJhY2sgdG8geW91ciBqb2IgbGlzdCI6IuC4ouC5ieC4reC4meC4geC4peC4seC4muC5hOC4m%2BC4l%2BC4teC5iOC4o%2BC4suC4ouC4h%2BC4suC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkpvYnMgbGlzdCI6IuC4o%2BC4suC4ouC4geC4suC4o%2BC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIllvdSBhcmUgaGVyZSI6IuC4hOC4uOC4k%2BC4reC4ouC4ueC5iOC4l%2BC4teC5iOC4meC4teC5iCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IlBlcmZvcm1hIGlrbGFuIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiS2VtYmFsaSBrZSBkYWZ0YXIgbG93b25nYW4ga2VyamEgQW5kYSIsIkpvYnMgbGlzdCI6IkRhZnRhciBsb3dvbmdhbiBrZXJqYSIsIllvdSBhcmUgaGVyZSI6IkFuZGEgZGkgc2luaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IlBlcmZvcm1hIGlrbGFuIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiS2VtYmFsaSBrZSBkYWZ0YXIgbG93b25nYW4ga2VyamEgQW5kYSIsIkpvYnMgbGlzdCI6IkRhZnRhciBsb3dvbmdhbiBrZXJqYSIsIllvdSBhcmUgaGVyZSI6IkFuZGEgZGkgc2luaSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IuC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJhY2sgdG8geW91ciBqb2IgbGlzdCI6IuC4ouC5ieC4reC4meC4geC4peC4seC4muC5hOC4m%2BC4l%2BC4teC5iOC4o%2BC4suC4ouC4h%2BC4suC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkpvYnMgbGlzdCI6IuC4o%2BC4suC4ouC4geC4suC4o%2BC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIllvdSBhcmUgaGVyZSI6IuC4hOC4uOC4k%2BC4reC4ouC4ueC5iOC4l%2BC4teC5iOC4meC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IuC4nOC4peC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJhY2sgdG8geW91ciBqb2IgbGlzdCI6IuC4ouC5ieC4reC4meC4geC4peC4seC4muC5hOC4m%2BC4l%2BC4teC5iOC4o%2BC4suC4ouC4h%2BC4suC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkpvYnMgbGlzdCI6IuC4o%2BC4suC4ouC4geC4suC4o%2BC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIllvdSBhcmUgaGVyZSI6IuC4hOC4uOC4k%2BC4reC4ouC4ueC5iOC4l%2BC4teC5iOC4meC4teC5iCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IlBlcmZvcm1hIGlrbGFuIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiS2VtYmFsaSBrZSBkYWZ0YXIgbG93b25nYW4ga2VyamEgQW5kYSIsIkpvYnMgbGlzdCI6IkRhZnRhciBsb3dvbmdhbiBrZXJqYSIsIllvdSBhcmUgaGVyZSI6IkFuZGEgZGkgc2luaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IlBlcmZvcm1hIGlrbGFuIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiS2VtYmFsaSBrZSBkYWZ0YXIgbG93b25nYW4ga2VyamEgQW5kYSIsIkpvYnMgbGlzdCI6IkRhZnRhciBsb3dvbmdhbiBrZXJqYSIsIllvdSBhcmUgaGVyZSI6IkFuZGEgZGkgc2luaSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IkFkIHBlcmZvcm1hbmNlIiwiQmFjayB0byB5b3VyIGpvYiBsaXN0IjoiQmFjayB0byB5b3VyIGpvYiBsaXN0IiwiSm9icyBsaXN0IjoiSm9icyBsaXN0IiwiWW91IGFyZSBoZXJlIjoiWW91IGFyZSBoZXJlIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IlvhuqzGjCDGpeG6veG6veG6vcWZxpLDtsO2w7bFmW3MgsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9XSIsIkJhY2sgdG8geW91ciBqb2IgbGlzdCI6Ilvhup7Eg8SDxIPDp8S3IOG5r8O2w7bDtiDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIMaaw6zDrMOsxaHhua9dIiwiSm9icyBsaXN0IjoiW8S0w7bDtsO2w5%2FFoSDGmsOsw6zDrMWh4bmvXSIsIllvdSBhcmUgaGVyZSI6IlvDncO2w7bHmseaIMSDxIPFmeG6veG6vSDhuKnhur3hur3FmeG6veG6vV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBwZXJmb3JtYW5jZSI6IlvhuqzGjCDGpeG6veG6veG6vcWZxpLDtsO2w7bFmW3MgsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9XSIsIkJhY2sgdG8geW91ciBqb2IgbGlzdCI6Ilvhup7Eg8SDxIPDp8S3IOG5r8O2w7bDtiDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIMaaw6zDrMOsxaHhua9dIiwiSm9icyBsaXN0IjoiW8S0w7bDtsO2w5%2FFoSDGmsOsw6zDrMWh4bmvXSIsIllvdSBhcmUgaGVyZSI6IlvDncO2w7bHmseaIMSDxIPFmeG6veG6vSDhuKnhur3hur3FmeG6veG6vV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    