import { Box, Heading, Stack, Text } from 'braid-design-system';
import type React from 'react';

import type { Money } from 'src/gql/graphql';

import { AnimatedCurrency } from '../AnimatedText';
import { ClickableTooltip } from '../ClickableTooltip/ClickableTooltip';

export interface SpendCardProps {
  id: string;
  cost: Money;
  children?: ReactNodeNoStrings;
  description: string;
  title: string;
  titleTooltip: ReactNodeNoStrings;
}

export const SpendCard = ({
  id,
  cost,
  children,
  description,
  title,
  titleTooltip,
}: SpendCardProps) => (
  <Box
    background="surface"
    boxShadow="borderNeutralLight"
    padding="medium"
    borderRadius="large"
    width="full"
    height="full"
  >
    <Stack space="medium">
      <Box display="flex" gap="xsmall" alignItems="center" flexWrap="nowrap">
        <Text size="small" weight="strong" maxLines={1}>
          {title}
        </Text>
        <Box flexShrink={0}>
          <ClickableTooltip
            id={`${id}-tooltip`}
            title={title}
            contents={titleTooltip}
            size="small"
          />
        </Box>
      </Box>
      <Stack space="gutter">
        <Heading level="4">
          <AnimatedCurrency currency={cost.currency} value={cost.value} />
        </Heading>
        <Text tone="secondary" size="small">
          {description}
        </Text>
      </Stack>
      {children}
    </Stack>
  </Box>
);
