import { Box } from 'braid-design-system';

import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import {
  MarketPerformance,
  MarketPerformanceContainer,
} from 'src/widgets/MarketPerformance/MarketPerformance';
import {
  MostAdsByPerformance,
  MostAdsByPerformanceContainer,
} from 'src/widgets/MostAdsByPerformance/MostAdsByPerformance';

import * as styles from './PerformanceTab.css';

export const PerformanceTab = () => (
  <Box
    className={styles.performanceContainer}
    paddingBottom={{ mobile: 'small', tablet: 'medium', desktop: 'none' }}
  >
    <Box display="flex" flexWrap="nowrap" gap="medium">
      <Box
        className={styles.column}
        borderRadius="large"
        boxShadow="borderNeutralLight"
        padding="medium"
      >
        <ErrorBoundary
          fallback={
            <MarketPerformanceContainer>
              <WidgetError />
            </MarketPerformanceContainer>
          }
        >
          <MarketPerformance />
        </ErrorBoundary>
      </Box>
      <Box
        className={styles.column}
        borderRadius="large"
        boxShadow="borderNeutralLight"
        padding="medium"
      >
        <ErrorBoundary
          fallback={
            <MostAdsByPerformanceContainer>
              <WidgetError />
            </MostAdsByPerformanceContainer>
          }
        >
          <MostAdsByPerformance />
        </ErrorBoundary>
      </Box>
    </Box>
  </Box>
);
