
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4hOC4o%2BC4seC5ieC4h%2BC4guC4reC4h%2BC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mSkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4hOC4o%2BC4seC5ieC4h%2BC4guC4reC4h%2BC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mSkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4hOC4o%2BC4seC5ieC4h%2BC4guC4reC4h%2BC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mSkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4hOC4o%2BC4seC5ieC4h%2BC4guC4reC4h%2BC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mSkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0%2BIChzZWFyY2ggYWN0aXZpdHkpIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiWzxNZWRpdW0%2Bw4fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9IMWhx5rHmseaxqXGpcaaw73DvcO9PC9NZWRpdW0%2BICjFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SDw6fhua%2FDrMOsw6zhub3DrMOsw6zhua%2FDvcO9w70pXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiWzxNZWRpdW0%2Bw4fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9IMWhx5rHmseaxqXGpcaaw73DvcO9PC9NZWRpdW0%2BICjFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SDw6fhua%2FDrMOsw6zhub3DrMOsw6zhua%2FDvcO9w70pXSJ9!"
        )
      )
      });
  
      export { translations as default };
    