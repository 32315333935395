import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  Columns,
  Column,
  Text,
  Divider,
  List,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import { ClickableTooltip } from '../ClickableTooltip/ClickableTooltip';

import translations from './.vocab';

export interface JobAdListTableHeaderProps {
  lastUpdatedAgo: ReactNode | string | null;
}

export const JobAdListTableHeader = ({
  lastUpdatedAgo,
}: JobAdListTableHeaderProps) => {
  const { t } = useTranslations(translations);

  const JobAdDescriptionHeaderTitle = () => (
    <Box paddingLeft="medium">
      <Text weight="medium" size="small" tone="neutral">
        {t('Job')}
      </Text>
    </Box>
  );

  const JobAdApplicationHeaderTitle = () => (
    <Box
      display="flex"
      gap="xsmall"
      alignItems="center"
      justifyContent="flexStart"
      flexWrap="nowrap"
    >
      <Text
        weight="medium"
        size="small"
        align="left"
        tone="neutral"
        maxLines={1}
      >
        {t('Applications')}
      </Text>
      <ClickableTooltip
        id="job-ad-list-applications-header-tooltip"
        title={t('Applications')}
        contents={
          <Stack space="medium">
            <Text>
              {t(
                'This number reflects how many candidates completed an application when the application takes place on SEEK.',
              )}
            </Text>
            {lastUpdatedAgo !== null && (
              <Box data-testid="lastUpdatedAgo">
                <Text>
                  {t(
                    'Candidate application numbers may be different to other pages as this page was last updated',
                    {
                      HighLight: () => lastUpdatedAgo,
                    },
                  )}
                </Text>
              </Box>
            )}
          </Stack>
        }
      />
    </Box>
  );

  const performanceHeaderTooltipContent = t('Performance header tooltip', {
    Stack: (children: ReactNodeNoStrings) => (
      <Stack space="medium">{children}</Stack>
    ),
    List: (children: ReactNodeNoStrings) => <List>{children}</List>,
    Text: (children: ReactNodeNoStrings) => <Text>{children}</Text>,
  });

  const JobAdPerformanceHeaderTitle = () => (
    <Box
      display="flex"
      gap="xsmall"
      alignItems="center"
      justifyContent="flexStart"
      flexWrap="nowrap"
    >
      <Text weight="medium" size="small" tone="neutral" maxLines={1}>
        {t('Performance')}
      </Text>

      <ClickableTooltip
        id="job-ad-list-performance-header-tooltip"
        title={t('Performance')}
        contents={performanceHeaderTooltipContent}
      />
    </Box>
  );

  const JobAdPostByHeaderTitle = () => (
    <Box>
      <Text weight="medium" size="small" tone="neutral" maxLines={1}>
        {t('Posted by')}
      </Text>
    </Box>
  );

  const JobCostTypeHeaderTitle = () => (
    <Box>
      <Text weight="medium" size="small" tone="neutral" maxLines={1}>
        {t('Cost & type')}
      </Text>
    </Box>
  );

  return (
    <Box paddingBottom="xxsmall">
      <Columns space="large" alignY="bottom">
        <Column width="1/3">
          <JobAdDescriptionHeaderTitle />
        </Column>
        <Column width="2/3">
          <Columns space="large" alignY="bottom">
            <Column width="1/5">
              <JobAdApplicationHeaderTitle />
            </Column>
            <Column width="1/4">
              <JobAdPerformanceHeaderTitle />
            </Column>
            <Column width="1/5">
              <JobAdPostByHeaderTitle />
            </Column>
            <Column>
              <JobCostTypeHeaderTitle />
            </Column>
          </Columns>
        </Column>
      </Columns>
      <Box width="full" paddingTop="small">
        <Divider weight="regular" />
      </Box>
    </Box>
  );
};
