
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiLguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQWQgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJBU0lDIjoiQmFzaWMiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIkJSQU5ERUQiOiJCcmFuZGVkIiwiU1RBTkRPVVQiOiJTdGFuZE91dCIsIlBSRU1JVU0iOiJQcmVtaXVtIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiLguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQWQgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJBU0lDIjoiQmFzaWMiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIkJSQU5ERUQiOiJCcmFuZGVkIiwiU1RBTkRPVVQiOiJTdGFuZE91dCIsIlBSRU1JVU0iOiJQcmVtaXVtIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJTZW11YSBqZW5pcyBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJBZCB0eXBlIjoiSmVuaXMgaWtsYW4iLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJTZW11YSBqZW5pcyBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJBZCB0eXBlIjoiSmVuaXMgaWtsYW4iLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiLguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQWQgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJBU0lDIjoiQmFzaWMiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIkJSQU5ERUQiOiJCcmFuZGVkIiwiU1RBTkRPVVQiOiJTdGFuZE91dCIsIlBSRU1JVU0iOiJQcmVtaXVtIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiLguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQWQgdHlwZSI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIkJBU0lDIjoiQmFzaWMiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIkJSQU5ERUQiOiJCcmFuZGVkIiwiU1RBTkRPVVQiOiJTdGFuZE91dCIsIlBSRU1JVU0iOiJQcmVtaXVtIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJTZW11YSBqZW5pcyBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJBZCB0eXBlIjoiSmVuaXMgaWtsYW4iLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJTZW11YSBqZW5pcyBpa2xhbiIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJBZCB0eXBlIjoiSmVuaXMgaWtsYW4iLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJBbGwgYWQgdHlwZXMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiQWQgdHlwZSI6IkFkIHR5cGUiLCJCQVNJQyI6IkJhc2ljIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJb4bqsxprGmiDEg8SDxIPGjCDhua%2FDvcO9w73GpeG6veG6veG6vcWhXSIsIk90aGVycyI6IlvDtsO2w7bhua%2FhuKnhur3hur3hur3FmcWhXSIsIk90aGVyIjoiW8O2w7bhua%2FhuKnhur3hur3FmV0iLCJBZCB0eXBlIjoiW%2BG6rMaMIOG5r8O9w73Dvcal4bq94bq94bq9XSIsIkJBU0lDIjoiW%2BG6nsSDxIPFocOsw6zDp10iLCJDTEFTU0lDIjoiW8OHxprEg8SDxIPFocWhw6zDrMOsw6ddIiwiQlJBTkRFRCI6Ilvhup7FmcSDxIPEg%2BC4geC4teC5icaM4bq94bq94bq9xoxdIiwiU1RBTkRPVVQiOiJbxaDhua%2FEg8SDxIPguIHguLXguYnGjMOWx5rHmsea4bmvXSIsIlBSRU1JVU0iOiJbxqTFmeG6veG6vW3MgsOsw6zHmseabcyCXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgdHlwZXMiOiJb4bqsxprGmiDEg8SDxIPGjCDhua%2FDvcO9w73GpeG6veG6veG6vcWhXSIsIk90aGVycyI6IlvDtsO2w7bhua%2FhuKnhur3hur3hur3FmcWhXSIsIk90aGVyIjoiW8O2w7bhua%2FhuKnhur3hur3FmV0iLCJBZCB0eXBlIjoiW%2BG6rMaMIOG5r8O9w73Dvcal4bq94bq94bq9XSIsIkJBU0lDIjoiW%2BG6nsSDxIPFocOsw6zDp10iLCJDTEFTU0lDIjoiW8OHxprEg8SDxIPFocWhw6zDrMOsw6ddIiwiQlJBTkRFRCI6Ilvhup7FmcSDxIPEg%2BC4geC4teC5icaM4bq94bq94bq9xoxdIiwiU1RBTkRPVVQiOiJbxaDhua%2FEg8SDxIPguIHguLXguYnGjMOWx5rHmsea4bmvXSIsIlBSRU1JVU0iOiJbxqTFmeG6veG6vW3MgsOsw6zHmseabcyCXSJ9!"
        )
      )
      });
  
      export { translations as default };
    