
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4LmD4LiZ4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIkFsbCBhZHMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJSRVZJRVdfQURfU1VNTUFSWSI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4muC4muC4l%2BC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlJFVklFV19BRF9ERVRBSUxTIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiUkVWRUFMX1NBTEFSWSI6IuC5geC4quC4lOC4h%2BC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIklOQ1JFQVNFX1NBTEFSWSI6IuC5gOC4nuC4tOC5iOC4oeC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIkFERF9TUEVDSUZJQ19MT0NBVElPTiI6IuC5gOC4nuC4tOC5iOC4oeC4quC4luC4suC4meC4l%2BC4teC5iOC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5gOC4iOC4suC4sOC4iOC4hyIsIk1BWElNSVNFX0JSQU5ESU5HIjoi4LmA4Lie4Li04LmI4Lih4Lib4Lij4Liw4Liq4Li04LiX4LiY4Li04Lig4Liy4Lie4LiI4Liy4LiB4LiB4Liy4Lij4Liq4Lij4LmJ4Liy4LiH4LmB4Lia4Lij4LiZ4LiU4LmM4LmD4Lir4LmJ4Lih4Liy4LiB4LiX4Li14LmI4Liq4Li44LiUIiwiQUREX1FVRVNUSU9OUyI6IuC5gOC4nuC4tOC5iOC4oeC4hOC4s%2BC4luC4suC4oeC5hOC4m%2BC4ouC4seC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4h%2BC4suC4meC4luC4seC4lOC5hOC4myIsIk5PX1RJUFMiOiLguYTguKHguYjguKHguLXguYDguITguKXguYfguJTguKXguLHguJoifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4LmD4LiZ4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIkFsbCBhZHMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJSRVZJRVdfQURfU1VNTUFSWSI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4muC4muC4l%2BC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlJFVklFV19BRF9ERVRBSUxTIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiUkVWRUFMX1NBTEFSWSI6IuC5geC4quC4lOC4h%2BC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIklOQ1JFQVNFX1NBTEFSWSI6IuC5gOC4nuC4tOC5iOC4oeC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIkFERF9TUEVDSUZJQ19MT0NBVElPTiI6IuC5gOC4nuC4tOC5iOC4oeC4quC4luC4suC4meC4l%2BC4teC5iOC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5gOC4iOC4suC4sOC4iOC4hyIsIk1BWElNSVNFX0JSQU5ESU5HIjoi4LmA4Lie4Li04LmI4Lih4Lib4Lij4Liw4Liq4Li04LiX4LiY4Li04Lig4Liy4Lie4LiI4Liy4LiB4LiB4Liy4Lij4Liq4Lij4LmJ4Liy4LiH4LmB4Lia4Lij4LiZ4LiU4LmM4LmD4Lir4LmJ4Lih4Liy4LiB4LiX4Li14LmI4Liq4Li44LiUIiwiQUREX1FVRVNUSU9OUyI6IuC5gOC4nuC4tOC5iOC4oeC4hOC4s%2BC4luC4suC4oeC5hOC4m%2BC4ouC4seC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4h%2BC4suC4meC4luC4seC4lOC5hOC4myIsIk5PX1RJUFMiOiLguYTguKHguYjguKHguLXguYDguITguKXguYfguJTguKXguLHguJoifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB1bnR1ayBtZW5pbmdrYXRrYW4gaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiQWxsIGFkcyI6IlNlbXVhIGlrbGFuIiwiUkVWSUVXX0FEX1NVTU1BUlkiOiJUaW5qYXUgcmluZ2thc2FuIGlrbGFuIiwiUkVWSUVXX0FEX0RFVEFJTFMiOiJUaW5qYXUgZGV0YWlsIGlrbGFuIiwiUkVWRUFMX1NBTEFSWSI6IlBlcmxpaGF0a2FuIGdhamkiLCJJTkNSRUFTRV9TQUxBUlkiOiJOYWlra2FuIGdhamkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJUYW1iYWggbG9rYXNpIGtodXN1cyIsIk1BWElNSVNFX0JSQU5ESU5HIjoiTWFrc2ltYWxrYW4gYnJhbmRpbmciLCJBRERfUVVFU1RJT05TIjoiVGFtYmFoIHBlcnRhbnlhYW4gdW50dWsgaWtsYW4gbG93b25nYW4gYmVyaWt1dG55YSIsIk5PX1RJUFMiOiJUaWRhayBhZGEgdGlwcyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB1bnR1ayBtZW5pbmdrYXRrYW4gaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiQWxsIGFkcyI6IlNlbXVhIGlrbGFuIiwiUkVWSUVXX0FEX1NVTU1BUlkiOiJUaW5qYXUgcmluZ2thc2FuIGlrbGFuIiwiUkVWSUVXX0FEX0RFVEFJTFMiOiJUaW5qYXUgZGV0YWlsIGlrbGFuIiwiUkVWRUFMX1NBTEFSWSI6IlBlcmxpaGF0a2FuIGdhamkiLCJJTkNSRUFTRV9TQUxBUlkiOiJOYWlra2FuIGdhamkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJUYW1iYWggbG9rYXNpIGtodXN1cyIsIk1BWElNSVNFX0JSQU5ESU5HIjoiTWFrc2ltYWxrYW4gYnJhbmRpbmciLCJBRERfUVVFU1RJT05TIjoiVGFtYmFoIHBlcnRhbnlhYW4gdW50dWsgaWtsYW4gbG93b25nYW4gYmVyaWt1dG55YSIsIk5PX1RJUFMiOiJUaWRhayBhZGEgdGlwcyJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4LmD4LiZ4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIkFsbCBhZHMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJSRVZJRVdfQURfU1VNTUFSWSI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4muC4muC4l%2BC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlJFVklFV19BRF9ERVRBSUxTIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiUkVWRUFMX1NBTEFSWSI6IuC5geC4quC4lOC4h%2BC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIklOQ1JFQVNFX1NBTEFSWSI6IuC5gOC4nuC4tOC5iOC4oeC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIkFERF9TUEVDSUZJQ19MT0NBVElPTiI6IuC5gOC4nuC4tOC5iOC4oeC4quC4luC4suC4meC4l%2BC4teC5iOC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5gOC4iOC4suC4sOC4iOC4hyIsIk1BWElNSVNFX0JSQU5ESU5HIjoi4LmA4Lie4Li04LmI4Lih4Lib4Lij4Liw4Liq4Li04LiX4LiY4Li04Lig4Liy4Lie4LiI4Liy4LiB4LiB4Liy4Lij4Liq4Lij4LmJ4Liy4LiH4LmB4Lia4Lij4LiZ4LiU4LmM4LmD4Lir4LmJ4Lih4Liy4LiB4LiX4Li14LmI4Liq4Li44LiUIiwiQUREX1FVRVNUSU9OUyI6IuC5gOC4nuC4tOC5iOC4oeC4hOC4s%2BC4luC4suC4oeC5hOC4m%2BC4ouC4seC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4h%2BC4suC4meC4luC4seC4lOC5hOC4myIsIk5PX1RJUFMiOiLguYTguKHguYjguKHguLXguYDguITguKXguYfguJTguKXguLHguJoifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4LmD4LiZ4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIkFsbCBhZHMiOiLguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJSRVZJRVdfQURfU1VNTUFSWSI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4muC4muC4l%2BC4quC4o%2BC4uOC4m%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlJFVklFV19BRF9ERVRBSUxTIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiUkVWRUFMX1NBTEFSWSI6IuC5geC4quC4lOC4h%2BC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIklOQ1JFQVNFX1NBTEFSWSI6IuC5gOC4nuC4tOC5iOC4oeC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4mSIsIkFERF9TUEVDSUZJQ19MT0NBVElPTiI6IuC5gOC4nuC4tOC5iOC4oeC4quC4luC4suC4meC4l%2BC4teC5iOC4l%2BC4s%2BC4h%2BC4suC4meC4l%2BC4teC5iOC5gOC4iOC4suC4sOC4iOC4hyIsIk1BWElNSVNFX0JSQU5ESU5HIjoi4LmA4Lie4Li04LmI4Lih4Lib4Lij4Liw4Liq4Li04LiX4LiY4Li04Lig4Liy4Lie4LiI4Liy4LiB4LiB4Liy4Lij4Liq4Lij4LmJ4Liy4LiH4LmB4Lia4Lij4LiZ4LiU4LmM4LmD4Lir4LmJ4Lih4Liy4LiB4LiX4Li14LmI4Liq4Li44LiUIiwiQUREX1FVRVNUSU9OUyI6IuC5gOC4nuC4tOC5iOC4oeC4hOC4s%2BC4luC4suC4oeC5hOC4m%2BC4ouC4seC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4h%2BC4suC4meC4luC4seC4lOC5hOC4myIsIk5PX1RJUFMiOiLguYTguKHguYjguKHguLXguYDguITguKXguYfguJTguKXguLHguJoifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB1bnR1ayBtZW5pbmdrYXRrYW4gaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiQWxsIGFkcyI6IlNlbXVhIGlrbGFuIiwiUkVWSUVXX0FEX1NVTU1BUlkiOiJUaW5qYXUgcmluZ2thc2FuIGlrbGFuIiwiUkVWSUVXX0FEX0RFVEFJTFMiOiJUaW5qYXUgZGV0YWlsIGlrbGFuIiwiUkVWRUFMX1NBTEFSWSI6IlBlcmxpaGF0a2FuIGdhamkiLCJJTkNSRUFTRV9TQUxBUlkiOiJOYWlra2FuIGdhamkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJUYW1iYWggbG9rYXNpIGtodXN1cyIsIk1BWElNSVNFX0JSQU5ESU5HIjoiTWFrc2ltYWxrYW4gYnJhbmRpbmciLCJBRERfUVVFU1RJT05TIjoiVGFtYmFoIHBlcnRhbnlhYW4gdW50dWsgaWtsYW4gbG93b25nYW4gYmVyaWt1dG55YSIsIk5PX1RJUFMiOiJUaWRhayBhZGEgdGlwcyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB1bnR1ayBtZW5pbmdrYXRrYW4gaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiQWxsIGFkcyI6IlNlbXVhIGlrbGFuIiwiUkVWSUVXX0FEX1NVTU1BUlkiOiJUaW5qYXUgcmluZ2thc2FuIGlrbGFuIiwiUkVWSUVXX0FEX0RFVEFJTFMiOiJUaW5qYXUgZGV0YWlsIGlrbGFuIiwiUkVWRUFMX1NBTEFSWSI6IlBlcmxpaGF0a2FuIGdhamkiLCJJTkNSRUFTRV9TQUxBUlkiOiJOYWlra2FuIGdhamkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJUYW1iYWggbG9rYXNpIGtodXN1cyIsIk1BWElNSVNFX0JSQU5ESU5HIjoiTWFrc2ltYWxrYW4gYnJhbmRpbmciLCJBRERfUVVFU1RJT05TIjoiVGFtYmFoIHBlcnRhbnlhYW4gdW50dWsgaWtsYW4gbG93b25nYW4gYmVyaWt1dG55YSIsIk5PX1RJUFMiOiJUaWRhayBhZGEgdGlwcyJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiVGlwcyB0byBpbXByb3ZlIGFkcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJBbGwgYWRzIjoiQWxsIGFkcyIsIlJFVklFV19BRF9TVU1NQVJZIjoiUmV2aWV3IGFkIHN1bW1hcnkiLCJSRVZJRVdfQURfREVUQUlMUyI6IlJldmlldyBhZCBkZXRhaWxzIiwiUkVWRUFMX1NBTEFSWSI6IlJldmVhbCBzYWxhcnkiLCJJTkNSRUFTRV9TQUxBUlkiOiJJbmNyZWFzZSBzYWxhcnkiLCJBRERfU1BFQ0lGSUNfTE9DQVRJT04iOiJBZGQgc3BlY2lmaWMgbG9jYXRpb24iLCJNQVhJTUlTRV9CUkFORElORyI6Ik1heGltaXNlIGJyYW5kaW5nIiwiQUREX1FVRVNUSU9OUyI6IkFkZCBxdWVzdGlvbnMgdG8gbmV4dCBqb2IgYWRzIiwiTk9fVElQUyI6Ik5vIHRpcHMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiW%2BG5rsOsw6zDrMalxaEg4bmvw7bDtsO2IMOsw6zDrG3MgsalxZnDtsO2w7bhub3hur3hur3hur0gxIPEg8SDxozFoV0iLCJPdGhlcnMiOiJbw7bDtsO24bmv4bip4bq94bq94bq9xZnFoV0iLCJPdGhlciI6IlvDtsO24bmv4bip4bq94bq9xZldIiwiQWxsIGFkcyI6IlvhuqzGmsaaIMSDxIPEg8SDxozFoV0iLCJSRVZJRVdfQURfU1VNTUFSWSI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtSDEg8SDxowgxaHHmseabcyCbcyCxIPEg8WZw73DvV0iLCJSRVZJRVdfQURfREVUQUlMUyI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtSDEg8SDxowgxozhur3hur3hua%2FEg8SDw6zDrMaaxaFdIiwiUkVWRUFMX1NBTEFSWSI6IlvFmOG6veG6veG5veG6veG6vcSDxIPGmiDFocSDxIPGmsSDxIPFmcO9w71dIiwiSU5DUkVBU0VfU0FMQVJZIjoiW8OP4LiB4Li14LmJw6fFmeG6veG6vcSDxIPFoeG6veG6vSDFocSDxIPGmsSDxIPFmcO9w71dIiwiQUREX1NQRUNJRklDX0xPQ0FUSU9OIjoiW%2BG6rMaMxowgxaHGpeG6veG6veG6vcOnw6zDrMOsxpLDrMOsw6zDpyDGmsO2w7bDtsOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJXSIsIk1BWElNSVNFX0JSQU5ESU5HIjoiW%2BG5gsSDxIPEg%2BqtlcOsw6zDrG3MgsOsw6zDrMWh4bq94bq94bq9IMOfxZnEg8SDxIPguIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjXSIsIkFERF9RVUVTVElPTlMiOiJb4bqsxozGjCBxzIfHmseax5rhur3hur3hur3FoeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIOG5r8O2w7bDtiDguIHguLXguYnhur3hur3hur3qrZXhua8gxLXDtsO2w7bDnyDEg8SDxIPGjMWhXSIsIk5PX1RJUFMiOiJbw5HDtsO2w7Yg4bmvw6zDrMOsxqXFoV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIHRvIGltcHJvdmUgYWRzIjoiW%2BG5rsOsw6zDrMalxaEg4bmvw7bDtsO2IMOsw6zDrG3MgsalxZnDtsO2w7bhub3hur3hur3hur0gxIPEg8SDxozFoV0iLCJPdGhlcnMiOiJbw7bDtsO24bmv4bip4bq94bq94bq9xZnFoV0iLCJPdGhlciI6IlvDtsO24bmv4bip4bq94bq9xZldIiwiQWxsIGFkcyI6IlvhuqzGmsaaIMSDxIPEg8SDxozFoV0iLCJSRVZJRVdfQURfU1VNTUFSWSI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtSDEg8SDxowgxaHHmseabcyCbcyCxIPEg8WZw73DvV0iLCJSRVZJRVdfQURfREVUQUlMUyI6IlvFmOG6veG6veG5vcOsw6zhur3hur3FtSDEg8SDxowgxozhur3hur3hua%2FEg8SDw6zDrMaaxaFdIiwiUkVWRUFMX1NBTEFSWSI6IlvFmOG6veG6veG5veG6veG6vcSDxIPGmiDFocSDxIPGmsSDxIPFmcO9w71dIiwiSU5DUkVBU0VfU0FMQVJZIjoiW8OP4LiB4Li14LmJw6fFmeG6veG6vcSDxIPFoeG6veG6vSDFocSDxIPGmsSDxIPFmcO9w71dIiwiQUREX1NQRUNJRklDX0xPQ0FUSU9OIjoiW%2BG6rMaMxowgxaHGpeG6veG6veG6vcOnw6zDrMOsxpLDrMOsw6zDpyDGmsO2w7bDtsOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJXSIsIk1BWElNSVNFX0JSQU5ESU5HIjoiW%2BG5gsSDxIPEg%2BqtlcOsw6zDrG3MgsOsw6zDrMWh4bq94bq94bq9IMOfxZnEg8SDxIPguIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjXSIsIkFERF9RVUVTVElPTlMiOiJb4bqsxozGjCBxzIfHmseax5rhur3hur3hur3FoeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIOG5r8O2w7bDtiDguIHguLXguYnhur3hur3hur3qrZXhua8gxLXDtsO2w7bDnyDEg8SDxIPGjMWhXSIsIk5PX1RJUFMiOiJbw5HDtsO2w7Yg4bmvw6zDrMOsxqXFoV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    