import { useHirerAuth } from '@seek/hirer-auth-react';
import { useAccountContext } from '@seek/hirer-graphql-react';
import { UivBanner } from '@seek/uiv-banner';
import { useTranslations } from '@vocab/react';
import { Alert, Box, Card, Text } from 'braid-design-system';
import { useEffect, type ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import {
  type Location,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { useGetAdvertiserId } from 'src/hooks/useGetAdvertiserId';
import { AdPerformanceReport } from 'src/pages/AdPerformanceReport/AdPerformanceReport';
import { AdPerformanceReportFallback } from 'src/pages/AdPerformanceReport/AdPerformanceReportFallback';
import { AdUsageReport } from 'src/pages/AdUsageReport/AdUsageReport';
import { AdUsageReportFallback } from 'src/pages/AdUsageReport/AdUsageReportFallback';
import { ChartThemeProvider } from 'src/seek-charts/ChartThemeProvider';
import LoggerProvider from 'src/utils/logger/logger';

import translations from '../.vocab';

import { AppLoader } from './AppLoader';
import { useConfig } from './ConfigContext';
import { localizeRoutesForSite } from './RouterConfig';

export const Router = () => {
  const { t } = useTranslations(translations);
  const { urlResolver, site } = useConfig();
  const { login, loading: isLoadingAuth } = useHirerAuth();
  const currLocation = useLocation();

  const appName = 'hirer-ad-usage-ui';
  const { branch, version } = useConfig();

  const {
    accountContext,
    error: accountContextError,
    loading: isLoadingAccountContext,
  } = useAccountContext();

  const siteRoutes = localizeRoutesForSite(site);

  const advertiserId = useGetAdvertiserId();

  useEffect(() => {
    if (isLoadingAuth) {
      return;
    }

    const userNeedsLogin =
      accountContextError &&
      ['No selected advertiser ID', 'Invalid selected advertiser ID'].includes(
        accountContextError.message,
      );

    if (userNeedsLogin) {
      login();
    }
  }, [accountContextError, login, isLoadingAuth]);

  useEffect(() => {
    const mapRouteToExperience = (location: Location) => {
      if (
        location.pathname.includes('/ad-usage') ||
        location.pathname.includes('/ad-performance')
      ) {
        return 'new';
      }

      return 'old';
    };

    LoggerProvider.logger.configure({
      userId: accountContext?.headerFooterContext?.user?.id,
      advertiserId,
      experience: mapRouteToExperience(currLocation),
    });
  }, [accountContext, advertiserId, currLocation]);

  if (
    window.location.href.includes('/#/myyearwithseek') ||
    window.location.href.includes('/myyearwithseek') ||
    window.location.href.includes('/myseekreview')
  ) {
    window.location.assign(
      'https://ui.backoffice.prod.myseek.xyz/analytics-reports',
    );
    return null;
  }

  const NavigateToAdUsage = () => (
    <Navigate replace to={urlResolver('/analytics/ad-usage')} />
  );

  const NavigateToAdPerformance = () => {
    const { jobId } = useParams();
    return (
      <Navigate
        replace
        to={urlResolver(`/analytics/ad-performance/${jobId}`)}
      />
    );
  };

  const AdUsagePage = (
    <>
      <Helmet>
        <title>{t('Ad usage - Analytics | SEEK Employer')}</title>
      </Helmet>
      <ErrorBoundary fallback={<AdUsageReportFallback />}>
        <AdUsageReport />
      </ErrorBoundary>
    </>
  );

  // ChartThemeProvider is needed here so that the chart components can be styled correctly as it still depends on the provider in seek-charts. This potentially can be removed in the future when the chart components are migrated to Metropolis
  const AdPerformancePage = (
    <ChartThemeProvider>
      <Helmet>
        <title>{t('Ad performance - Analytics | SEEK Employer')}</title>
      </Helmet>
      <ErrorBoundary fallback={<AdPerformanceReportFallback />}>
        <AdPerformanceReport />
      </ErrorBoundary>
    </ChartThemeProvider>
  );
  const routeNameToPage: Record<string, ReactNode> = {
    '/analytics/ad-usage': !isLoadingAccountContext ? (
      AdUsagePage
    ) : (
      <AppLoader />
    ),
    '/analytics/ad-performance/$jobId': !isLoadingAccountContext ? (
      AdPerformancePage
    ) : (
      <AppLoader />
    ),
    '/analytics': <NavigateToAdUsage />,
    '/analytics/advertiser/$advertiserId': <NavigateToAdUsage />,
    '/analytics/job/$jobId': <NavigateToAdPerformance />,
  };

  return isLoadingAccountContext ? (
    <AppLoader />
  ) : (
    <div>
      <UivBanner applicationName={appName} branch={branch} version={version} />
      {accountContextError && (
        <Box>
          <Card>
            <Alert tone="critical">
              <Text>{`${accountContextError}`}</Text>
            </Alert>
          </Card>
        </Box>
      )}
      <Routes>
        {siteRoutes.map(({ name, route }) => (
          <Route
            path={route.replace('$', ':')}
            key={route}
            element={routeNameToPage[name]}
          />
        ))}
      </Routes>
    </div>
  );
};
