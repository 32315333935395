/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  '\n    query AccountContext($skipAdvertiserContext: Boolean = false) {\n  hirerOriginZone\n  currentAdvertiser @skip(if: $skipAdvertiserContext) {\n    ... on CurrentAdvertiserContext {\n      __typename\n      name\n      seekId\n      advertiserId\n      isAgency\n      billingId\n      claims {\n        type\n        value\n      }\n      permissionOptions {\n        canCreateJobs\n        canManageBrand\n        canManageCompanyProfile\n        canManageUsers\n        canPayInvoices\n        canViewInsightsRoleReport\n        canViewInvoiceHistory\n        canViewManagerReports\n      }\n      status\n      role\n      talentSearchAccess\n      profileProperties {\n        ExpressCajaOptOut\n        HasLoggedInBefore\n        HasOnlyUsedExpressCaja\n        HasSeenGuidedCallOut\n        HasSeenJobDetailsTour\n        UserStatusBeforeDeactivation\n      }\n    }\n    ... on Error {\n      __typename\n      message\n    }\n  }\n  currentUser {\n    ... on CurrentUserContext {\n      __typename\n      id\n      email\n      firstName\n      lastName\n      name\n      status\n    }\n    ... on Error {\n      __typename\n      message\n    }\n  }\n  headerFooterContext {\n    identityContext {\n      email\n      act {\n        operatorEmail\n        isCsView\n      }\n    }\n    user {\n      name\n      firstName\n      id\n    }\n    advertiser @skip(if: $skipAdvertiserContext) {\n      name\n      billingId\n      hasMultipleAccounts\n      permissionOptions {\n        canCreateJobs\n        canManageBrand\n        canManageCompanyProfile\n        canManageUsers\n        canViewInsightsRoleReport\n        canPayInvoices\n        canViewInvoiceHistory\n        canViewManagerReports\n      }\n      showOnCreditHoldMessage\n      isActivationPending\n      switchAccountsHref\n      isAgency\n    }\n  }\n}\n    ':
    types.AccountContextDocument,
  '\n  query GetAdPerformanceReport($input: AdPerformanceReportWidgetInput!) {\n    analyticsAdPerformanceReport(input: $input) {\n      success\n    }\n  }\n':
    types.GetAdPerformanceReportDocument,
  '\n  query GetAdRatingData($input: AdRatingWidgetInput!) {\n    analyticsAdRating(input: $input) {\n      adRatingConfidence\n      adRatingScore\n      applications\n      applicationsBenchmarkHigh\n      applicationsBenchmarkLow\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      incompleteApplications\n      isLinkOutAd\n      jobCreatedTimestampUTC\n      lastUpdatedTimestampUTC\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n    }\n  }\n':
    types.GetAdRatingDataDocument,
  '\n  query GetAdTypeTrend($input: AdUsageAdTypeTrendInput!) {\n    analyticsAdTypeTrend(input: $input) {\n      startDate\n      endDate\n      productDisplayNameDaily {\n        items {\n          key\n          count\n        }\n        day\n      }\n      productDisplayNameMonthly {\n        items {\n          key\n          count\n        }\n        month\n      }\n      totalProductDisplayNames {\n        key\n        count\n      }\n    }\n  }\n':
    types.GetAdTypeTrendDocument,
  '\n  query GetAdUsageFiltersData($input: AdUsageFiltersWidgetInput!) {\n    analyticsAdUsageFilters(input: $input) {\n      users {\n        key\n        value\n        count\n      }\n      locations {\n        key\n        value\n        level\n        parentId\n        count\n      }\n      classifications {\n        key\n        value\n        count\n        subClassifications {\n          key\n          value\n          count\n        }\n      }\n      jobTitles {\n        key\n        count\n      }\n      adIds\n      accountHierarchies {\n        key\n        value\n        count\n      }\n      adStatus {\n        key\n        count\n      }\n      adRatings {\n        key\n        count\n      }\n      tips {\n        key\n        count\n      }\n      reposts {\n        key\n        count\n      }\n      budgets {\n        key\n        count\n        data {\n          type\n          expiryTimestampUTC\n        }\n      }\n      productDisplayNames {\n        key\n        count\n      }\n      isChildAccount\n    }\n  }\n':
    types.GetAdUsageFiltersDataDocument,
  '\n  query GetAdUsageLastUpdated {\n    analyticsAdUsageLastUpdated {\n      lastUpdated\n    }\n  }\n':
    types.GetAdUsageLastUpdatedDocument,
  '\n  query GetApplicationPerformance($input: ApplicationPerformanceWidgetInput!) {\n    analyticsApplicationPerformance(input: $input) {\n      isLinkOutAd\n      adRatingConfidence\n      applications\n      lastUpdatedTimestampUTC\n      mobileViewsInPercentage\n      similarAdsAverage\n      hasAdRating\n    }\n  }\n':
    types.GetApplicationPerformanceDocument,
  '\n  query GetCandidateDemandData($input: CandidateDemandWidgetInput!) {\n    analyticsCandidateDemand(input: $input) {\n      comparisonPeriod {\n        count\n        calendarMonth\n      }\n      currentPeriod {\n        count\n        calendarMonth\n      }\n      hasCandidateDemand\n    }\n  }\n':
    types.GetCandidateDemandDataDocument,
  '\n  query GetCandidateSupplyData($input: CandidateSupplyWidgetInput!) {\n    analyticsCandidateSupply(input: $input) {\n      comparisonPeriod {\n        count\n        calendarMonth\n      }\n      currentPeriod {\n        count\n        calendarMonth\n      }\n      hasCandidateSupply\n    }\n  }\n':
    types.GetCandidateSupplyDataDocument,
  '\n  query GetChildAccountBanner {\n    analyticsChildAccountBanner {\n      isChild\n    }\n  }\n':
    types.GetChildAccountBannerDocument,
  '\n  query GetClassificationsAndUsersTrend(\n    $input: AdUsageTrendClassificationsAndUsersInput!\n  ) {\n    analyticsClassificationsAndUsersTrend(input: $input) {\n      classifications {\n        classificationName\n        totalAds\n        percentage\n      }\n      users {\n        userFullname\n        totalAds\n        percentage\n      }\n    }\n  }\n':
    types.GetClassificationsAndUsersTrendDocument,
  '\n  query GetClicksOnYourAd($input: ClicksOnYourAdWidgetInput!) {\n    analyticsClicksOnYourAd(input: $input) {\n      adRatingConfidence\n      appConversionInPercentage\n      totalClicks\n      isLinkOutAd\n      lastUpdatedTimestampUTC\n      mobileClicksInPercentage\n      similarAdsAverage\n      hasAdRating\n    }\n  }\n':
    types.GetClicksOnYourAdDocument,
  '\n  query GetConversionData($input: AdUsageConversionWidgetInput!) {\n    analyticsAdUsageConversion(input: $input) {\n      jobSearchViews {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      adClicks {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      applicationsStarted {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      applicationsCompleted {\n        total\n        similarAdAverage\n        mobileRate\n      }\n      adsWithoutRatingPercentage\n      linkOutAds\n    }\n  }\n':
    types.GetConversionDataDocument,
  '\n  query HeaderFooterContext($input: HeaderFooterContextInput) {\n    hirerOriginZone\n    headerFooterContext {\n      identityContext {\n        act(input: $input) {\n          operatorEmail\n          isCsView\n        }\n        email\n      }\n      advertiser {\n        billingId\n        isActivationPending\n        isAgency\n        name\n        showOnCreditHoldMessage\n        switchAccountsHref\n        permissionOptions {\n          canCreateJobs\n          canManageBrand\n          canManageCompanyProfile\n          canManageUsers\n          canPayInvoices\n          canViewInsightsRoleReport\n          canViewInvoiceHistory\n          canViewManagerReports\n        }\n      }\n      user {\n        name\n        firstName\n        id\n      }\n    }\n  }\n':
    types.HeaderFooterContextDocument,
  '\n  query GetJobAdListData($input: JobAdListWidgetInput!) {\n    analyticsJobAdList(input: $input) {\n      lastUpdated\n      count\n      hasMultipleCurrencies\n      jobAds {\n        adRating\n        applications\n        locationLabel\n        groupKeyApac\n        hidePrice\n        isLatestGroupApac\n        isRefresh\n        isRepost\n        jobId\n        jobTitle\n        jobCreatedTimestampUTC\n        status\n        totalNetAmount {\n          value\n          currency\n        }\n        otherBudgetAmount {\n          value\n          currency\n        }\n        userFullName\n        productDisplayName\n      }\n    }\n  }\n':
    types.GetJobAdListDataDocument,
  '\n  query GetMarketInsightsTitleData($input: MarketInsightsTitleWidgetInput!) {\n    analyticsMarketInsightsTitle(input: $input) {\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n    }\n  }\n':
    types.GetMarketInsightsTitleDataDocument,
  '\n  query GetMarketPerformanceData($input: MarketPerformanceWidgetInput!) {\n    analyticsMarketPerformance(input: $input) {\n      highAds\n      lowAds\n      normalAds\n      noRatingAds\n      totalAds\n    }\n  }\n':
    types.GetMarketPerformanceDataDocument,
  '\n  query GetMostAdsByPerformanceData($input: MostAdsByPerformanceWidgetInput!) {\n    analyticsMostAdsByPerformance(input: $input) {\n      low {\n        user {\n          label\n          number\n          percentage\n        }\n        classification {\n          label\n          number\n          percentage\n        }\n      }\n      high {\n        user {\n          label\n          number\n          percentage\n        }\n        classification {\n          label\n          number\n          percentage\n        }\n      }\n    }\n  }\n':
    types.GetMostAdsByPerformanceDataDocument,
  '\n  query GetMyJobInformationData($input: MyJobInformationWidgetInput!) {\n    analyticsMyJobInformation(input: $input) {\n      advertiserName\n      applyUrl\n      classificationName\n      createdByName\n      createdByEmail\n      isLinkOut\n      isPrivateAdvertiser\n      isSalaryHidden\n      jobCreatedTimestampUTC\n      jobLocationLabel\n      jobTitle\n      latestEdit {\n        name\n        email\n        timestampUTC\n      }\n      lastUpdatedTimestampUTC\n      mirrorClassificationName\n      mirrorSubClassificationName\n      postTypeCategory\n      analyticsProductDisplayName\n      salary {\n        additionalText\n        currency\n        maximum\n        minimum\n        type\n      }\n      subClassificationName\n      workType\n    }\n  }\n':
    types.GetMyJobInformationDataDocument,
  '\n  query GetPageTitleActions($input: PageTitleActionsWidgetInput!) {\n    analyticsPageTitleActions(input: $input) {\n      adType\n      advertiserName\n      classificationName\n      displayCurrency\n      isExpired\n      isPrivateAdvertiser\n      jobCreatedTimestampUTC\n      jobExpiryTimestampUTC\n      jobId\n      jobLocationLabel\n      jobSummary\n      jobTitle\n      keySellingPoints\n      lastUpdatedTimestampUTC\n      latestJobs {\n        jobCreatedTimestampUTC\n        jobId\n        jobLocationLabel\n        jobTitle\n        userFullName\n      }\n      postTypeCategory\n      salaryAdditionalText\n      serpLogoUrl\n      subClassificationName\n    }\n  }\n':
    types.GetPageTitleActionsDocument,
  '\n  query GetPerformancePrediction($input: PerformancePredictionWidgetInput!) {\n    analyticsPerformancePrediction(input: $input) {\n      events {\n        comparisonValue\n        date\n        day\n        editMade\n        value\n      }\n      predictionDate\n      predictionDayOptions {\n        text\n        prediction\n      }\n      valueLabel\n      xRange\n      xAxisLabels {\n        label\n        date\n      }\n      lastUpdatedTimestampUTC\n      yRange\n    }\n  }\n':
    types.GetPerformancePredictionDocument,
  '\n  query GetRecommendations($input: RecommendationsWidgetInput!) {\n    analyticsRecommendations(input: $input) {\n      isExpired\n      postTypeCategory\n      lastUpdatedTimestampUTC\n      recommendations {\n        ... on LowClick {\n          type\n          conversionRate\n          averageConversionRate\n        }\n        ... on LowApply {\n          type\n          conversionRate\n          averageConversionRate\n        }\n        ... on RevealSalary {\n          type\n          currency\n        }\n        ... on LowSalary {\n          type\n          currency\n          salaryType\n          salaryRangeMin\n          salaryRangeMax\n          suggestedRangeMin\n          suggestedRangeMax\n          suggestedAverageRangeMin\n          suggestedAverageRangeMax\n          normalisedRoleTitle\n          benchmarkLocationLabel\n        }\n        ... on GranularLocation {\n          type\n          nonSpecificLocation\n          specificLocation\n        }\n        ... on BrandedAd {\n          type\n          bulletPointsUsed\n          logoUsed\n          coverImageUsed\n        }\n        ... on RoleRequirements {\n          type\n          roleReqsQuestions\n        }\n        ... on TalentSearch {\n          type\n          jobId\n          hirerId\n        }\n      }\n    }\n  }\n':
    types.GetRecommendationsDocument,
  '\n  query GetSimilarData($input: SimilarAdsWidgetInput!) {\n    analyticsSimilarAdsDetails(input: $input) {\n      ads {\n        adType\n        advertiserName\n        hasKeySellingPoints\n        isPrivateAdvertiser\n        isSalaryHidden\n        jobCreatedTimestampUTC\n        jobId\n        jobLocationLabel\n        jobTitle\n        productDisplayName\n        serpLogoUrl\n      }\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n      lastUpdatedTimestampUTC\n    }\n  }\n':
    types.GetSimilarDataDocument,
  '\n  query GetSpendData($input: AdUsageSpendWidgetInput!) {\n    analyticsAdUsageSpend(input: $input) {\n      totalPostByProductDisplayNames {\n        type\n        adsCount\n      }\n      totalSpend {\n        currency\n        value\n      }\n      averageCostByProductDisplayNames {\n        type\n        cost {\n          currency\n          value\n        }\n      }\n      averageApplications\n      averageCostPerAd {\n        currency\n        value\n      }\n      totalApplications\n      averageCostPerApplication {\n        currency\n        value\n      }\n      repostedAdsCount\n      repostedAdsSpend {\n        currency\n        value\n      }\n      hidePrice\n      totalAds\n      otherCurrencyAdsCount\n    }\n  }\n':
    types.GetSpendDataDocument,
  '\n  query GetTipsToImproveAds($input: TipsToImproveAdsWidgetInput!) {\n    analyticsAdUsageTipsToImproveAds(input: $input) {\n      numOfAds\n      tips {\n        ... on ReviewAdSummary {\n          type\n          adCount\n          conversionRate\n          benchmarkConversionRate\n        }\n        ... on ReviewAdDetails {\n          type\n          adCount\n          conversionRate\n          benchmarkConversionRate\n        }\n        ... on RevealAdSalary {\n          type\n          adCount\n        }\n        ... on MaximiseBranding {\n          type\n          adCount\n          bulletPointsUsed\n          logoUsed\n          coverImageUsed\n        }\n        ... on AddSpecificLocation {\n          type\n          adCount\n          specificLocation\n          nonSpecificLocation\n        }\n        ... on AddQuestions {\n          type\n          adCount\n          questions\n        }\n      }\n    }\n  }\n':
    types.GetTipsToImproveAdsDocument,
  '\n  query GetViewsInJobData($input: ViewsInJobSearchWidgetInput!) {\n    analyticsViewsInJobSearch(input: $input) {\n      adRatingConfidence\n      clickConversionInPercentage\n      lastUpdatedTimestampUTC\n      mobileViewsInPercentage\n      similarAdsAverage\n      views\n      hasAdRating\n    }\n  }\n':
    types.GetViewsInJobDataDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query AccountContext($skipAdvertiserContext: Boolean = false) {\n  hirerOriginZone\n  currentAdvertiser @skip(if: $skipAdvertiserContext) {\n    ... on CurrentAdvertiserContext {\n      __typename\n      name\n      seekId\n      advertiserId\n      isAgency\n      billingId\n      claims {\n        type\n        value\n      }\n      permissionOptions {\n        canCreateJobs\n        canManageBrand\n        canManageCompanyProfile\n        canManageUsers\n        canPayInvoices\n        canViewInsightsRoleReport\n        canViewInvoiceHistory\n        canViewManagerReports\n      }\n      status\n      role\n      talentSearchAccess\n      profileProperties {\n        ExpressCajaOptOut\n        HasLoggedInBefore\n        HasOnlyUsedExpressCaja\n        HasSeenGuidedCallOut\n        HasSeenJobDetailsTour\n        UserStatusBeforeDeactivation\n      }\n    }\n    ... on Error {\n      __typename\n      message\n    }\n  }\n  currentUser {\n    ... on CurrentUserContext {\n      __typename\n      id\n      email\n      firstName\n      lastName\n      name\n      status\n    }\n    ... on Error {\n      __typename\n      message\n    }\n  }\n  headerFooterContext {\n    identityContext {\n      email\n      act {\n        operatorEmail\n        isCsView\n      }\n    }\n    user {\n      name\n      firstName\n      id\n    }\n    advertiser @skip(if: $skipAdvertiserContext) {\n      name\n      billingId\n      hasMultipleAccounts\n      permissionOptions {\n        canCreateJobs\n        canManageBrand\n        canManageCompanyProfile\n        canManageUsers\n        canViewInsightsRoleReport\n        canPayInvoices\n        canViewInvoiceHistory\n        canViewManagerReports\n      }\n      showOnCreditHoldMessage\n      isActivationPending\n      switchAccountsHref\n      isAgency\n    }\n  }\n}\n    ',
): (typeof documents)['\n    query AccountContext($skipAdvertiserContext: Boolean = false) {\n  hirerOriginZone\n  currentAdvertiser @skip(if: $skipAdvertiserContext) {\n    ... on CurrentAdvertiserContext {\n      __typename\n      name\n      seekId\n      advertiserId\n      isAgency\n      billingId\n      claims {\n        type\n        value\n      }\n      permissionOptions {\n        canCreateJobs\n        canManageBrand\n        canManageCompanyProfile\n        canManageUsers\n        canPayInvoices\n        canViewInsightsRoleReport\n        canViewInvoiceHistory\n        canViewManagerReports\n      }\n      status\n      role\n      talentSearchAccess\n      profileProperties {\n        ExpressCajaOptOut\n        HasLoggedInBefore\n        HasOnlyUsedExpressCaja\n        HasSeenGuidedCallOut\n        HasSeenJobDetailsTour\n        UserStatusBeforeDeactivation\n      }\n    }\n    ... on Error {\n      __typename\n      message\n    }\n  }\n  currentUser {\n    ... on CurrentUserContext {\n      __typename\n      id\n      email\n      firstName\n      lastName\n      name\n      status\n    }\n    ... on Error {\n      __typename\n      message\n    }\n  }\n  headerFooterContext {\n    identityContext {\n      email\n      act {\n        operatorEmail\n        isCsView\n      }\n    }\n    user {\n      name\n      firstName\n      id\n    }\n    advertiser @skip(if: $skipAdvertiserContext) {\n      name\n      billingId\n      hasMultipleAccounts\n      permissionOptions {\n        canCreateJobs\n        canManageBrand\n        canManageCompanyProfile\n        canManageUsers\n        canViewInsightsRoleReport\n        canPayInvoices\n        canViewInvoiceHistory\n        canViewManagerReports\n      }\n      showOnCreditHoldMessage\n      isActivationPending\n      switchAccountsHref\n      isAgency\n    }\n  }\n}\n    '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAdPerformanceReport($input: AdPerformanceReportWidgetInput!) {\n    analyticsAdPerformanceReport(input: $input) {\n      success\n    }\n  }\n',
): (typeof documents)['\n  query GetAdPerformanceReport($input: AdPerformanceReportWidgetInput!) {\n    analyticsAdPerformanceReport(input: $input) {\n      success\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAdRatingData($input: AdRatingWidgetInput!) {\n    analyticsAdRating(input: $input) {\n      adRatingConfidence\n      adRatingScore\n      applications\n      applicationsBenchmarkHigh\n      applicationsBenchmarkLow\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      incompleteApplications\n      isLinkOutAd\n      jobCreatedTimestampUTC\n      lastUpdatedTimestampUTC\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n    }\n  }\n',
): (typeof documents)['\n  query GetAdRatingData($input: AdRatingWidgetInput!) {\n    analyticsAdRating(input: $input) {\n      adRatingConfidence\n      adRatingScore\n      applications\n      applicationsBenchmarkHigh\n      applicationsBenchmarkLow\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      incompleteApplications\n      isLinkOutAd\n      jobCreatedTimestampUTC\n      lastUpdatedTimestampUTC\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAdTypeTrend($input: AdUsageAdTypeTrendInput!) {\n    analyticsAdTypeTrend(input: $input) {\n      startDate\n      endDate\n      productDisplayNameDaily {\n        items {\n          key\n          count\n        }\n        day\n      }\n      productDisplayNameMonthly {\n        items {\n          key\n          count\n        }\n        month\n      }\n      totalProductDisplayNames {\n        key\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetAdTypeTrend($input: AdUsageAdTypeTrendInput!) {\n    analyticsAdTypeTrend(input: $input) {\n      startDate\n      endDate\n      productDisplayNameDaily {\n        items {\n          key\n          count\n        }\n        day\n      }\n      productDisplayNameMonthly {\n        items {\n          key\n          count\n        }\n        month\n      }\n      totalProductDisplayNames {\n        key\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAdUsageFiltersData($input: AdUsageFiltersWidgetInput!) {\n    analyticsAdUsageFilters(input: $input) {\n      users {\n        key\n        value\n        count\n      }\n      locations {\n        key\n        value\n        level\n        parentId\n        count\n      }\n      classifications {\n        key\n        value\n        count\n        subClassifications {\n          key\n          value\n          count\n        }\n      }\n      jobTitles {\n        key\n        count\n      }\n      adIds\n      accountHierarchies {\n        key\n        value\n        count\n      }\n      adStatus {\n        key\n        count\n      }\n      adRatings {\n        key\n        count\n      }\n      tips {\n        key\n        count\n      }\n      reposts {\n        key\n        count\n      }\n      budgets {\n        key\n        count\n        data {\n          type\n          expiryTimestampUTC\n        }\n      }\n      productDisplayNames {\n        key\n        count\n      }\n      isChildAccount\n    }\n  }\n',
): (typeof documents)['\n  query GetAdUsageFiltersData($input: AdUsageFiltersWidgetInput!) {\n    analyticsAdUsageFilters(input: $input) {\n      users {\n        key\n        value\n        count\n      }\n      locations {\n        key\n        value\n        level\n        parentId\n        count\n      }\n      classifications {\n        key\n        value\n        count\n        subClassifications {\n          key\n          value\n          count\n        }\n      }\n      jobTitles {\n        key\n        count\n      }\n      adIds\n      accountHierarchies {\n        key\n        value\n        count\n      }\n      adStatus {\n        key\n        count\n      }\n      adRatings {\n        key\n        count\n      }\n      tips {\n        key\n        count\n      }\n      reposts {\n        key\n        count\n      }\n      budgets {\n        key\n        count\n        data {\n          type\n          expiryTimestampUTC\n        }\n      }\n      productDisplayNames {\n        key\n        count\n      }\n      isChildAccount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAdUsageLastUpdated {\n    analyticsAdUsageLastUpdated {\n      lastUpdated\n    }\n  }\n',
): (typeof documents)['\n  query GetAdUsageLastUpdated {\n    analyticsAdUsageLastUpdated {\n      lastUpdated\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetApplicationPerformance($input: ApplicationPerformanceWidgetInput!) {\n    analyticsApplicationPerformance(input: $input) {\n      isLinkOutAd\n      adRatingConfidence\n      applications\n      lastUpdatedTimestampUTC\n      mobileViewsInPercentage\n      similarAdsAverage\n      hasAdRating\n    }\n  }\n',
): (typeof documents)['\n  query GetApplicationPerformance($input: ApplicationPerformanceWidgetInput!) {\n    analyticsApplicationPerformance(input: $input) {\n      isLinkOutAd\n      adRatingConfidence\n      applications\n      lastUpdatedTimestampUTC\n      mobileViewsInPercentage\n      similarAdsAverage\n      hasAdRating\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCandidateDemandData($input: CandidateDemandWidgetInput!) {\n    analyticsCandidateDemand(input: $input) {\n      comparisonPeriod {\n        count\n        calendarMonth\n      }\n      currentPeriod {\n        count\n        calendarMonth\n      }\n      hasCandidateDemand\n    }\n  }\n',
): (typeof documents)['\n  query GetCandidateDemandData($input: CandidateDemandWidgetInput!) {\n    analyticsCandidateDemand(input: $input) {\n      comparisonPeriod {\n        count\n        calendarMonth\n      }\n      currentPeriod {\n        count\n        calendarMonth\n      }\n      hasCandidateDemand\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCandidateSupplyData($input: CandidateSupplyWidgetInput!) {\n    analyticsCandidateSupply(input: $input) {\n      comparisonPeriod {\n        count\n        calendarMonth\n      }\n      currentPeriod {\n        count\n        calendarMonth\n      }\n      hasCandidateSupply\n    }\n  }\n',
): (typeof documents)['\n  query GetCandidateSupplyData($input: CandidateSupplyWidgetInput!) {\n    analyticsCandidateSupply(input: $input) {\n      comparisonPeriod {\n        count\n        calendarMonth\n      }\n      currentPeriod {\n        count\n        calendarMonth\n      }\n      hasCandidateSupply\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetChildAccountBanner {\n    analyticsChildAccountBanner {\n      isChild\n    }\n  }\n',
): (typeof documents)['\n  query GetChildAccountBanner {\n    analyticsChildAccountBanner {\n      isChild\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetClassificationsAndUsersTrend(\n    $input: AdUsageTrendClassificationsAndUsersInput!\n  ) {\n    analyticsClassificationsAndUsersTrend(input: $input) {\n      classifications {\n        classificationName\n        totalAds\n        percentage\n      }\n      users {\n        userFullname\n        totalAds\n        percentage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetClassificationsAndUsersTrend(\n    $input: AdUsageTrendClassificationsAndUsersInput!\n  ) {\n    analyticsClassificationsAndUsersTrend(input: $input) {\n      classifications {\n        classificationName\n        totalAds\n        percentage\n      }\n      users {\n        userFullname\n        totalAds\n        percentage\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetClicksOnYourAd($input: ClicksOnYourAdWidgetInput!) {\n    analyticsClicksOnYourAd(input: $input) {\n      adRatingConfidence\n      appConversionInPercentage\n      totalClicks\n      isLinkOutAd\n      lastUpdatedTimestampUTC\n      mobileClicksInPercentage\n      similarAdsAverage\n      hasAdRating\n    }\n  }\n',
): (typeof documents)['\n  query GetClicksOnYourAd($input: ClicksOnYourAdWidgetInput!) {\n    analyticsClicksOnYourAd(input: $input) {\n      adRatingConfidence\n      appConversionInPercentage\n      totalClicks\n      isLinkOutAd\n      lastUpdatedTimestampUTC\n      mobileClicksInPercentage\n      similarAdsAverage\n      hasAdRating\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetConversionData($input: AdUsageConversionWidgetInput!) {\n    analyticsAdUsageConversion(input: $input) {\n      jobSearchViews {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      adClicks {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      applicationsStarted {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      applicationsCompleted {\n        total\n        similarAdAverage\n        mobileRate\n      }\n      adsWithoutRatingPercentage\n      linkOutAds\n    }\n  }\n',
): (typeof documents)['\n  query GetConversionData($input: AdUsageConversionWidgetInput!) {\n    analyticsAdUsageConversion(input: $input) {\n      jobSearchViews {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      adClicks {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      applicationsStarted {\n        total\n        similarAdAverage\n        adConversionRate\n        similarAdConversionRate\n        mobileRate\n      }\n      applicationsCompleted {\n        total\n        similarAdAverage\n        mobileRate\n      }\n      adsWithoutRatingPercentage\n      linkOutAds\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HeaderFooterContext($input: HeaderFooterContextInput) {\n    hirerOriginZone\n    headerFooterContext {\n      identityContext {\n        act(input: $input) {\n          operatorEmail\n          isCsView\n        }\n        email\n      }\n      advertiser {\n        billingId\n        isActivationPending\n        isAgency\n        name\n        showOnCreditHoldMessage\n        switchAccountsHref\n        permissionOptions {\n          canCreateJobs\n          canManageBrand\n          canManageCompanyProfile\n          canManageUsers\n          canPayInvoices\n          canViewInsightsRoleReport\n          canViewInvoiceHistory\n          canViewManagerReports\n        }\n      }\n      user {\n        name\n        firstName\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  query HeaderFooterContext($input: HeaderFooterContextInput) {\n    hirerOriginZone\n    headerFooterContext {\n      identityContext {\n        act(input: $input) {\n          operatorEmail\n          isCsView\n        }\n        email\n      }\n      advertiser {\n        billingId\n        isActivationPending\n        isAgency\n        name\n        showOnCreditHoldMessage\n        switchAccountsHref\n        permissionOptions {\n          canCreateJobs\n          canManageBrand\n          canManageCompanyProfile\n          canManageUsers\n          canPayInvoices\n          canViewInsightsRoleReport\n          canViewInvoiceHistory\n          canViewManagerReports\n        }\n      }\n      user {\n        name\n        firstName\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetJobAdListData($input: JobAdListWidgetInput!) {\n    analyticsJobAdList(input: $input) {\n      lastUpdated\n      count\n      hasMultipleCurrencies\n      jobAds {\n        adRating\n        applications\n        locationLabel\n        groupKeyApac\n        hidePrice\n        isLatestGroupApac\n        isRefresh\n        isRepost\n        jobId\n        jobTitle\n        jobCreatedTimestampUTC\n        status\n        totalNetAmount {\n          value\n          currency\n        }\n        otherBudgetAmount {\n          value\n          currency\n        }\n        userFullName\n        productDisplayName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetJobAdListData($input: JobAdListWidgetInput!) {\n    analyticsJobAdList(input: $input) {\n      lastUpdated\n      count\n      hasMultipleCurrencies\n      jobAds {\n        adRating\n        applications\n        locationLabel\n        groupKeyApac\n        hidePrice\n        isLatestGroupApac\n        isRefresh\n        isRepost\n        jobId\n        jobTitle\n        jobCreatedTimestampUTC\n        status\n        totalNetAmount {\n          value\n          currency\n        }\n        otherBudgetAmount {\n          value\n          currency\n        }\n        userFullName\n        productDisplayName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMarketInsightsTitleData($input: MarketInsightsTitleWidgetInput!) {\n    analyticsMarketInsightsTitle(input: $input) {\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n    }\n  }\n',
): (typeof documents)['\n  query GetMarketInsightsTitleData($input: MarketInsightsTitleWidgetInput!) {\n    analyticsMarketInsightsTitle(input: $input) {\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMarketPerformanceData($input: MarketPerformanceWidgetInput!) {\n    analyticsMarketPerformance(input: $input) {\n      highAds\n      lowAds\n      normalAds\n      noRatingAds\n      totalAds\n    }\n  }\n',
): (typeof documents)['\n  query GetMarketPerformanceData($input: MarketPerformanceWidgetInput!) {\n    analyticsMarketPerformance(input: $input) {\n      highAds\n      lowAds\n      normalAds\n      noRatingAds\n      totalAds\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMostAdsByPerformanceData($input: MostAdsByPerformanceWidgetInput!) {\n    analyticsMostAdsByPerformance(input: $input) {\n      low {\n        user {\n          label\n          number\n          percentage\n        }\n        classification {\n          label\n          number\n          percentage\n        }\n      }\n      high {\n        user {\n          label\n          number\n          percentage\n        }\n        classification {\n          label\n          number\n          percentage\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetMostAdsByPerformanceData($input: MostAdsByPerformanceWidgetInput!) {\n    analyticsMostAdsByPerformance(input: $input) {\n      low {\n        user {\n          label\n          number\n          percentage\n        }\n        classification {\n          label\n          number\n          percentage\n        }\n      }\n      high {\n        user {\n          label\n          number\n          percentage\n        }\n        classification {\n          label\n          number\n          percentage\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMyJobInformationData($input: MyJobInformationWidgetInput!) {\n    analyticsMyJobInformation(input: $input) {\n      advertiserName\n      applyUrl\n      classificationName\n      createdByName\n      createdByEmail\n      isLinkOut\n      isPrivateAdvertiser\n      isSalaryHidden\n      jobCreatedTimestampUTC\n      jobLocationLabel\n      jobTitle\n      latestEdit {\n        name\n        email\n        timestampUTC\n      }\n      lastUpdatedTimestampUTC\n      mirrorClassificationName\n      mirrorSubClassificationName\n      postTypeCategory\n      analyticsProductDisplayName\n      salary {\n        additionalText\n        currency\n        maximum\n        minimum\n        type\n      }\n      subClassificationName\n      workType\n    }\n  }\n',
): (typeof documents)['\n  query GetMyJobInformationData($input: MyJobInformationWidgetInput!) {\n    analyticsMyJobInformation(input: $input) {\n      advertiserName\n      applyUrl\n      classificationName\n      createdByName\n      createdByEmail\n      isLinkOut\n      isPrivateAdvertiser\n      isSalaryHidden\n      jobCreatedTimestampUTC\n      jobLocationLabel\n      jobTitle\n      latestEdit {\n        name\n        email\n        timestampUTC\n      }\n      lastUpdatedTimestampUTC\n      mirrorClassificationName\n      mirrorSubClassificationName\n      postTypeCategory\n      analyticsProductDisplayName\n      salary {\n        additionalText\n        currency\n        maximum\n        minimum\n        type\n      }\n      subClassificationName\n      workType\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPageTitleActions($input: PageTitleActionsWidgetInput!) {\n    analyticsPageTitleActions(input: $input) {\n      adType\n      advertiserName\n      classificationName\n      displayCurrency\n      isExpired\n      isPrivateAdvertiser\n      jobCreatedTimestampUTC\n      jobExpiryTimestampUTC\n      jobId\n      jobLocationLabel\n      jobSummary\n      jobTitle\n      keySellingPoints\n      lastUpdatedTimestampUTC\n      latestJobs {\n        jobCreatedTimestampUTC\n        jobId\n        jobLocationLabel\n        jobTitle\n        userFullName\n      }\n      postTypeCategory\n      salaryAdditionalText\n      serpLogoUrl\n      subClassificationName\n    }\n  }\n',
): (typeof documents)['\n  query GetPageTitleActions($input: PageTitleActionsWidgetInput!) {\n    analyticsPageTitleActions(input: $input) {\n      adType\n      advertiserName\n      classificationName\n      displayCurrency\n      isExpired\n      isPrivateAdvertiser\n      jobCreatedTimestampUTC\n      jobExpiryTimestampUTC\n      jobId\n      jobLocationLabel\n      jobSummary\n      jobTitle\n      keySellingPoints\n      lastUpdatedTimestampUTC\n      latestJobs {\n        jobCreatedTimestampUTC\n        jobId\n        jobLocationLabel\n        jobTitle\n        userFullName\n      }\n      postTypeCategory\n      salaryAdditionalText\n      serpLogoUrl\n      subClassificationName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPerformancePrediction($input: PerformancePredictionWidgetInput!) {\n    analyticsPerformancePrediction(input: $input) {\n      events {\n        comparisonValue\n        date\n        day\n        editMade\n        value\n      }\n      predictionDate\n      predictionDayOptions {\n        text\n        prediction\n      }\n      valueLabel\n      xRange\n      xAxisLabels {\n        label\n        date\n      }\n      lastUpdatedTimestampUTC\n      yRange\n    }\n  }\n',
): (typeof documents)['\n  query GetPerformancePrediction($input: PerformancePredictionWidgetInput!) {\n    analyticsPerformancePrediction(input: $input) {\n      events {\n        comparisonValue\n        date\n        day\n        editMade\n        value\n      }\n      predictionDate\n      predictionDayOptions {\n        text\n        prediction\n      }\n      valueLabel\n      xRange\n      xAxisLabels {\n        label\n        date\n      }\n      lastUpdatedTimestampUTC\n      yRange\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetRecommendations($input: RecommendationsWidgetInput!) {\n    analyticsRecommendations(input: $input) {\n      isExpired\n      postTypeCategory\n      lastUpdatedTimestampUTC\n      recommendations {\n        ... on LowClick {\n          type\n          conversionRate\n          averageConversionRate\n        }\n        ... on LowApply {\n          type\n          conversionRate\n          averageConversionRate\n        }\n        ... on RevealSalary {\n          type\n          currency\n        }\n        ... on LowSalary {\n          type\n          currency\n          salaryType\n          salaryRangeMin\n          salaryRangeMax\n          suggestedRangeMin\n          suggestedRangeMax\n          suggestedAverageRangeMin\n          suggestedAverageRangeMax\n          normalisedRoleTitle\n          benchmarkLocationLabel\n        }\n        ... on GranularLocation {\n          type\n          nonSpecificLocation\n          specificLocation\n        }\n        ... on BrandedAd {\n          type\n          bulletPointsUsed\n          logoUsed\n          coverImageUsed\n        }\n        ... on RoleRequirements {\n          type\n          roleReqsQuestions\n        }\n        ... on TalentSearch {\n          type\n          jobId\n          hirerId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetRecommendations($input: RecommendationsWidgetInput!) {\n    analyticsRecommendations(input: $input) {\n      isExpired\n      postTypeCategory\n      lastUpdatedTimestampUTC\n      recommendations {\n        ... on LowClick {\n          type\n          conversionRate\n          averageConversionRate\n        }\n        ... on LowApply {\n          type\n          conversionRate\n          averageConversionRate\n        }\n        ... on RevealSalary {\n          type\n          currency\n        }\n        ... on LowSalary {\n          type\n          currency\n          salaryType\n          salaryRangeMin\n          salaryRangeMax\n          suggestedRangeMin\n          suggestedRangeMax\n          suggestedAverageRangeMin\n          suggestedAverageRangeMax\n          normalisedRoleTitle\n          benchmarkLocationLabel\n        }\n        ... on GranularLocation {\n          type\n          nonSpecificLocation\n          specificLocation\n        }\n        ... on BrandedAd {\n          type\n          bulletPointsUsed\n          logoUsed\n          coverImageUsed\n        }\n        ... on RoleRequirements {\n          type\n          roleReqsQuestions\n        }\n        ... on TalentSearch {\n          type\n          jobId\n          hirerId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetSimilarData($input: SimilarAdsWidgetInput!) {\n    analyticsSimilarAdsDetails(input: $input) {\n      ads {\n        adType\n        advertiserName\n        hasKeySellingPoints\n        isPrivateAdvertiser\n        isSalaryHidden\n        jobCreatedTimestampUTC\n        jobId\n        jobLocationLabel\n        jobTitle\n        productDisplayName\n        serpLogoUrl\n      }\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n      lastUpdatedTimestampUTC\n    }\n  }\n',
): (typeof documents)['\n  query GetSimilarData($input: SimilarAdsWidgetInput!) {\n    analyticsSimilarAdsDetails(input: $input) {\n      ads {\n        adType\n        advertiserName\n        hasKeySellingPoints\n        isPrivateAdvertiser\n        isSalaryHidden\n        jobCreatedTimestampUTC\n        jobId\n        jobLocationLabel\n        jobTitle\n        productDisplayName\n        serpLogoUrl\n      }\n      benchmarkLocationLabel\n      benchmarkScenario\n      classificationName\n      normalisedRoleTitle\n      subClassificationName\n      benchmarkLocationCountry\n      lastUpdatedTimestampUTC\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetSpendData($input: AdUsageSpendWidgetInput!) {\n    analyticsAdUsageSpend(input: $input) {\n      totalPostByProductDisplayNames {\n        type\n        adsCount\n      }\n      totalSpend {\n        currency\n        value\n      }\n      averageCostByProductDisplayNames {\n        type\n        cost {\n          currency\n          value\n        }\n      }\n      averageApplications\n      averageCostPerAd {\n        currency\n        value\n      }\n      totalApplications\n      averageCostPerApplication {\n        currency\n        value\n      }\n      repostedAdsCount\n      repostedAdsSpend {\n        currency\n        value\n      }\n      hidePrice\n      totalAds\n      otherCurrencyAdsCount\n    }\n  }\n',
): (typeof documents)['\n  query GetSpendData($input: AdUsageSpendWidgetInput!) {\n    analyticsAdUsageSpend(input: $input) {\n      totalPostByProductDisplayNames {\n        type\n        adsCount\n      }\n      totalSpend {\n        currency\n        value\n      }\n      averageCostByProductDisplayNames {\n        type\n        cost {\n          currency\n          value\n        }\n      }\n      averageApplications\n      averageCostPerAd {\n        currency\n        value\n      }\n      totalApplications\n      averageCostPerApplication {\n        currency\n        value\n      }\n      repostedAdsCount\n      repostedAdsSpend {\n        currency\n        value\n      }\n      hidePrice\n      totalAds\n      otherCurrencyAdsCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetTipsToImproveAds($input: TipsToImproveAdsWidgetInput!) {\n    analyticsAdUsageTipsToImproveAds(input: $input) {\n      numOfAds\n      tips {\n        ... on ReviewAdSummary {\n          type\n          adCount\n          conversionRate\n          benchmarkConversionRate\n        }\n        ... on ReviewAdDetails {\n          type\n          adCount\n          conversionRate\n          benchmarkConversionRate\n        }\n        ... on RevealAdSalary {\n          type\n          adCount\n        }\n        ... on MaximiseBranding {\n          type\n          adCount\n          bulletPointsUsed\n          logoUsed\n          coverImageUsed\n        }\n        ... on AddSpecificLocation {\n          type\n          adCount\n          specificLocation\n          nonSpecificLocation\n        }\n        ... on AddQuestions {\n          type\n          adCount\n          questions\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetTipsToImproveAds($input: TipsToImproveAdsWidgetInput!) {\n    analyticsAdUsageTipsToImproveAds(input: $input) {\n      numOfAds\n      tips {\n        ... on ReviewAdSummary {\n          type\n          adCount\n          conversionRate\n          benchmarkConversionRate\n        }\n        ... on ReviewAdDetails {\n          type\n          adCount\n          conversionRate\n          benchmarkConversionRate\n        }\n        ... on RevealAdSalary {\n          type\n          adCount\n        }\n        ... on MaximiseBranding {\n          type\n          adCount\n          bulletPointsUsed\n          logoUsed\n          coverImageUsed\n        }\n        ... on AddSpecificLocation {\n          type\n          adCount\n          specificLocation\n          nonSpecificLocation\n        }\n        ... on AddQuestions {\n          type\n          adCount\n          questions\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetViewsInJobData($input: ViewsInJobSearchWidgetInput!) {\n    analyticsViewsInJobSearch(input: $input) {\n      adRatingConfidence\n      clickConversionInPercentage\n      lastUpdatedTimestampUTC\n      mobileViewsInPercentage\n      similarAdsAverage\n      views\n      hasAdRating\n    }\n  }\n',
): (typeof documents)['\n  query GetViewsInJobData($input: ViewsInJobSearchWidgetInput!) {\n    analyticsViewsInJobSearch(input: $input) {\n      adRatingConfidence\n      clickConversionInPercentage\n      lastUpdatedTimestampUTC\n      mobileViewsInPercentage\n      similarAdsAverage\n      views\n      hasAdRating\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
