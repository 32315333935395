
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSAo4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZICB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiWW91ciBjYW5kaWRhdGVzIHBlcmZvcm1hbmNlIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIHthcHBsaWNhdGlvbnMsIG51bWJlcn0iLCJTaW1pbGFyIGFkcyBhdmVyYWdlIGxhYmVsIjoi4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4LmJ4Liy4Lii4LiB4Lix4LiZIHthdmVyYWdlLCBudW1iZXJ9IiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMiOiLguIjguLPguJnguKfguJnguITguKXguLTguIHguKrguKHguLHguITguKPguIfguLLguJkiLCJZb3VyIGNhbmRpZGF0ZXMiOiLguJzguLnguYnguKrguKHguLHguITguKPguIfguLLguJnguILguK3guIfguITguLjguJMiLCJTaW1pbGFyIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC4hOC4peC5ieC4suC4ouC4geC4seC4mSIsIlByZWRpY3Rpb24iOiLguIHguLLguKPguITguLLguJTguIHguLLguKPguJPguYwiLCJEYXkiOiLguKfguLHguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSAo4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZICB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiWW91ciBjYW5kaWRhdGVzIHBlcmZvcm1hbmNlIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIHthcHBsaWNhdGlvbnMsIG51bWJlcn0iLCJTaW1pbGFyIGFkcyBhdmVyYWdlIGxhYmVsIjoi4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4LmJ4Liy4Lii4LiB4Lix4LiZIHthdmVyYWdlLCBudW1iZXJ9IiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMiOiLguIjguLPguJnguKfguJnguITguKXguLTguIHguKrguKHguLHguITguKPguIfguLLguJkiLCJZb3VyIGNhbmRpZGF0ZXMiOiLguJzguLnguYnguKrguKHguLHguITguKPguIfguLLguJnguILguK3guIfguITguLjguJMiLCJTaW1pbGFyIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC4hOC4peC5ieC4suC4ouC4geC4seC4mSIsIlByZWRpY3Rpb24iOiLguIHguLLguKPguITguLLguJTguIHguLLguKPguJPguYwiLCJEYXkiOiLguKfguLHguJkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJIYXJpIHtkYXksIG51bWJlcn0iLCJEYXkgZWRpdGVkIGxhYmVsIjoiSGFyaSB7ZGF5LCBudW1iZXJ9IChMb3dvbmdhbiBrZXJqYSBkaWVkaXQpIiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMgcGVyZm9ybWFuY2UiOiJMYW1hcmFuIGtlcmphIGRpbXVsYWkge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IkthbmRpZGF0IEFuZGEge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIlNpbWlsYXIgYWRzIGF2ZXJhZ2UgbGFiZWwiOiJSYXRhLXJhdGEgbG93b25nYW4ga2VyamEgeWFuZyBzZXJ1cGEgbWVtaWxpa2kge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IkxhbWFyYW4ga2VyamEgZGltdWxhaSIsIllvdXIgY2FuZGlkYXRlcyI6IkthbmRpZGF0IEFuZGEiLCJTaW1pbGFyIGFkcyI6Ikxvd29uZ2FuIGtlcmphIHlhbmcgc2VydXBhIiwiUHJlZGljdGlvbiI6IlByZWRpa3NpIiwiRGF5IjoiSGFyaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJIYXJpIHtkYXksIG51bWJlcn0iLCJEYXkgZWRpdGVkIGxhYmVsIjoiSGFyaSB7ZGF5LCBudW1iZXJ9IChMb3dvbmdhbiBrZXJqYSBkaWVkaXQpIiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMgcGVyZm9ybWFuY2UiOiJMYW1hcmFuIGtlcmphIGRpbXVsYWkge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IkthbmRpZGF0IEFuZGEge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIlNpbWlsYXIgYWRzIGF2ZXJhZ2UgbGFiZWwiOiJSYXRhLXJhdGEgbG93b25nYW4ga2VyamEgeWFuZyBzZXJ1cGEgbWVtaWxpa2kge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IkxhbWFyYW4ga2VyamEgZGltdWxhaSIsIllvdXIgY2FuZGlkYXRlcyI6IkthbmRpZGF0IEFuZGEiLCJTaW1pbGFyIGFkcyI6Ikxvd29uZ2FuIGtlcmphIHlhbmcgc2VydXBhIiwiUHJlZGljdGlvbiI6IlByZWRpa3NpIiwiRGF5IjoiSGFyaSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSAo4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZICB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiWW91ciBjYW5kaWRhdGVzIHBlcmZvcm1hbmNlIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIHthcHBsaWNhdGlvbnMsIG51bWJlcn0iLCJTaW1pbGFyIGFkcyBhdmVyYWdlIGxhYmVsIjoi4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4LmJ4Liy4Lii4LiB4Lix4LiZIHthdmVyYWdlLCBudW1iZXJ9IiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMiOiLguIjguLPguJnguKfguJnguITguKXguLTguIHguKrguKHguLHguITguKPguIfguLLguJkiLCJZb3VyIGNhbmRpZGF0ZXMiOiLguJzguLnguYnguKrguKHguLHguITguKPguIfguLLguJnguILguK3guIfguITguLjguJMiLCJTaW1pbGFyIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC4hOC4peC5ieC4suC4ouC4geC4seC4mSIsIlByZWRpY3Rpb24iOiLguIHguLLguKPguITguLLguJTguIHguLLguKPguJPguYwiLCJEYXkiOiLguKfguLHguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiLguKfguLHguJkge2RheSwgbnVtYmVyfSAo4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZICB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiWW91ciBjYW5kaWRhdGVzIHBlcmZvcm1hbmNlIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIHthcHBsaWNhdGlvbnMsIG51bWJlcn0iLCJTaW1pbGFyIGFkcyBhdmVyYWdlIGxhYmVsIjoi4LiE4LmI4Liy4LmA4LiJ4Lil4Li14LmI4Lii4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LiE4Lil4LmJ4Liy4Lii4LiB4Lix4LiZIHthdmVyYWdlLCBudW1iZXJ9IiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMiOiLguIjguLPguJnguKfguJnguITguKXguLTguIHguKrguKHguLHguITguKPguIfguLLguJkiLCJZb3VyIGNhbmRpZGF0ZXMiOiLguJzguLnguYnguKrguKHguLHguITguKPguIfguLLguJnguILguK3guIfguITguLjguJMiLCJTaW1pbGFyIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC4hOC4peC5ieC4suC4ouC4geC4seC4mSIsIlByZWRpY3Rpb24iOiLguIHguLLguKPguITguLLguJTguIHguLLguKPguJPguYwiLCJEYXkiOiLguKfguLHguJkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJIYXJpIHtkYXksIG51bWJlcn0iLCJEYXkgZWRpdGVkIGxhYmVsIjoiSGFyaSB7ZGF5LCBudW1iZXJ9IChMb3dvbmdhbiBrZXJqYSBkaWVkaXQpIiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMgcGVyZm9ybWFuY2UiOiJMYW1hcmFuIGtlcmphIGRpbXVsYWkge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IkthbmRpZGF0IEFuZGEge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIlNpbWlsYXIgYWRzIGF2ZXJhZ2UgbGFiZWwiOiJSYXRhLXJhdGEgbG93b25nYW4ga2VyamEgeWFuZyBzZXJ1cGEgbWVtaWxpa2kge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IkxhbWFyYW4ga2VyamEgZGltdWxhaSIsIllvdXIgY2FuZGlkYXRlcyI6IkthbmRpZGF0IEFuZGEiLCJTaW1pbGFyIGFkcyI6Ikxvd29uZ2FuIGtlcmphIHlhbmcgc2VydXBhIiwiUHJlZGljdGlvbiI6IlByZWRpa3NpIiwiRGF5IjoiSGFyaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJIYXJpIHtkYXksIG51bWJlcn0iLCJEYXkgZWRpdGVkIGxhYmVsIjoiSGFyaSB7ZGF5LCBudW1iZXJ9IChMb3dvbmdhbiBrZXJqYSBkaWVkaXQpIiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMgcGVyZm9ybWFuY2UiOiJMYW1hcmFuIGtlcmphIGRpbXVsYWkge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IkthbmRpZGF0IEFuZGEge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIlNpbWlsYXIgYWRzIGF2ZXJhZ2UgbGFiZWwiOiJSYXRhLXJhdGEgbG93b25nYW4ga2VyamEgeWFuZyBzZXJ1cGEgbWVtaWxpa2kge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IkxhbWFyYW4ga2VyamEgZGltdWxhaSIsIllvdXIgY2FuZGlkYXRlcyI6IkthbmRpZGF0IEFuZGEiLCJTaW1pbGFyIGFkcyI6Ikxvd29uZ2FuIGtlcmphIHlhbmcgc2VydXBhIiwiUHJlZGljdGlvbiI6IlByZWRpa3NpIiwiRGF5IjoiSGFyaSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSIsIkRheSBlZGl0ZWQgbGFiZWwiOiJEYXkge2RheSwgbnVtYmVyfSAoQWQgZWRpdGVkKSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMge2FwcGxpY2F0aW9ucywgbnVtYmVyfSIsIllvdXIgY2FuZGlkYXRlcyBwZXJmb3JtYW5jZSI6IllvdXIgY2FuZGlkYXRlcyB7YXBwbGljYXRpb25zLCBudW1iZXJ9IiwiU2ltaWxhciBhZHMgYXZlcmFnZSBsYWJlbCI6IlNpbWlsYXIgYWRzIGF2ZXJhZ2Uge2F2ZXJhZ2UsIG51bWJlcn0iLCJZb3VyIGFwcGxpY2F0aW9uIHN0YXJ0cyI6IllvdXIgYXBwbGljYXRpb24gc3RhcnRzIiwiWW91ciBjYW5kaWRhdGVzIjoiWW91ciBjYW5kaWRhdGVzIiwiU2ltaWxhciBhZHMiOiJTaW1pbGFyIGFkcyIsIlByZWRpY3Rpb24iOiJQcmVkaWN0aW9uIiwiRGF5IjoiRGF5In0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJb4biKxIPEg8O9w70ge2RheSwgbnVtYmVyfV0iLCJEYXkgZWRpdGVkIGxhYmVsIjoiW%2BG4isSDxIPDvcO9IHtkYXksIG51bWJlcn0gKOG6rMaMIOG6veG6veG6vcaMw6zDrMOs4bmv4bq94bq94bq9xowpXSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiW8Odw7bDtsO2x5rHmseaxZkgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkgxaHhua%2FEg8SDxIPFmeG5r8WhIHthcHBsaWNhdGlvbnMsIG51bWJlcn1dIiwiWW91ciBjYW5kaWRhdGVzIHBlcmZvcm1hbmNlIjoiW8Odw7bDtsO2x5rHmseaxZkgw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9xaEge2FwcGxpY2F0aW9ucywgbnVtYmVyfV0iLCJTaW1pbGFyIGFkcyBhdmVyYWdlIGxhYmVsIjoiW8Wgw6zDrG3MgsOsw6zGmsSDxIPFmSDEg8SDxozFoSDEg8SD4bm94bq94bq9xZnEg8SDxKPhur3hur0ge2F2ZXJhZ2UsIG51bWJlcn1dIiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMiOiJbw53DtsO2w7bHmseax5rFmSDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDFoeG5r8SDxIPEg8WZ4bmvxaFdIiwiWW91ciBjYW5kaWRhdGVzIjoiW8Odw7bDtseax5rFmSDDp8SDxIPguIHguLXguYnGjMOsw6zGjMSDxIPhua%2Fhur3hur3FoV0iLCJTaW1pbGFyIGFkcyI6IlvFoMOsw6zDrG3MgsOsw6zDrMaaxIPEg8SDxZkgxIPEg8SDxozFoV0iLCJQcmVkaWN0aW9uIjoiW8akxZnhur3hur3GjMOsw6zDp%2BG5r8Osw6zDtsO24LiB4Li14LmJXSIsIkRheSI6IlvhuIrEg8SDw73DvV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXkgbGFiZWwiOiJb4biKxIPEg8O9w70ge2RheSwgbnVtYmVyfV0iLCJEYXkgZWRpdGVkIGxhYmVsIjoiW%2BG4isSDxIPDvcO9IHtkYXksIG51bWJlcn0gKOG6rMaMIOG6veG6veG6vcaMw6zDrMOs4bmv4bq94bq94bq9xowpXSIsIllvdXIgYXBwbGljYXRpb24gc3RhcnRzIHBlcmZvcm1hbmNlIjoiW8Odw7bDtsO2x5rHmseaxZkgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYkgxaHhua%2FEg8SDxIPFmeG5r8WhIHthcHBsaWNhdGlvbnMsIG51bWJlcn1dIiwiWW91ciBjYW5kaWRhdGVzIHBlcmZvcm1hbmNlIjoiW8Odw7bDtsO2x5rHmseaxZkgw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9xaEge2FwcGxpY2F0aW9ucywgbnVtYmVyfV0iLCJTaW1pbGFyIGFkcyBhdmVyYWdlIGxhYmVsIjoiW8Wgw6zDrG3MgsOsw6zGmsSDxIPFmSDEg8SDxozFoSDEg8SD4bm94bq94bq9xZnEg8SDxKPhur3hur0ge2F2ZXJhZ2UsIG51bWJlcn1dIiwiWW91ciBhcHBsaWNhdGlvbiBzdGFydHMiOiJbw53DtsO2w7bHmseax5rFmSDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDFoeG5r8SDxIPEg8WZ4bmvxaFdIiwiWW91ciBjYW5kaWRhdGVzIjoiW8Odw7bDtseax5rFmSDDp8SDxIPguIHguLXguYnGjMOsw6zGjMSDxIPhua%2Fhur3hur3FoV0iLCJTaW1pbGFyIGFkcyI6IlvFoMOsw6zDrG3MgsOsw6zDrMaaxIPEg8SDxZkgxIPEg8SDxozFoV0iLCJQcmVkaWN0aW9uIjoiW8akxZnhur3hur3GjMOsw6zDp%2BG5r8Osw6zDtsO24LiB4Li14LmJXSIsIkRheSI6IlvhuIrEg8SDw73DvV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    