import { useAccountContext } from '@seek/hirer-graphql-react';
import {
  MultiSelectDropdown,
  type MultiSelectDropdownOnSelectState,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { AccountHierarchyOption } from 'src/types/AdUsageFiltersResponse';
import {
  mapDropdownFilterMethod,
  trackEvent,
} from 'src/utils/tealiumAUREventTracker';

import { getDisplayValue } from '../../Filters.formatters';

import translations from './.vocab';
import { buildAccountHierarchyData } from './AccountHierarchyFilter.formatters';

interface AccountHierarchyFilterProps {
  accountHierarchies: AccountHierarchyOption[];
  isChildAccount: boolean;
}

export const AccountHierarchyFilter = ({
  accountHierarchies,
  isChildAccount,
}: AccountHierarchyFilterProps) => {
  const {
    adUsageFilters: { accountIds: selectedAccountKeys },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);
  const { accountContext } = useAccountContext();

  const { options: accountHierarchyOptions, leafNodeKeysMap } =
    buildAccountHierarchyData(accountHierarchies);

  const displayedValue = getDisplayValue(
    selectedAccountKeys,
    leafNodeKeysMap,
    accountHierarchyOptions,
    t,
    t('All accounts'),
  );

  const {
    setIsFilterAccountHierachy,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelectGroupNode = (
    selectedKeys: string[],
    leafNodeKeys: string[],
  ) => {
    const nodeIsSelected = selectedKeys.find((leafNodeKey: string) =>
      leafNodeKeys.includes(leafNodeKey),
    );

    if (nodeIsSelected) {
      // deselect children
      return selectedKeys.filter(
        (leafNodeKey: string) => !leafNodeKeys.includes(leafNodeKey),
      );
    }

    // select children
    return [...selectedKeys, ...leafNodeKeys];
  };

  const onSelect = (
    value: string,
    dropdownState: MultiSelectDropdownOnSelectState,
  ) => {
    const leafNodeKeys = leafNodeKeysMap?.[value];
    const isGroupedNode = leafNodeKeys !== undefined;

    let newSelectedKeys: string[] = [];

    if (isGroupedNode) {
      newSelectedKeys = onSelectGroupNode(selectedAccountKeys, leafNodeKeys);
    } else if (selectedAccountKeys.includes(value)) {
      // deselect node
      newSelectedKeys = [...selectedAccountKeys].filter(
        (leafNodeKey) => leafNodeKey !== value,
      );
    } else {
      // select node
      newSelectedKeys = [...selectedAccountKeys, value];
    }

    updateAdUsageFilters({
      type: actionTypes.UPDATE_ACCOUNT_HIERARCHY,
      value: newSelectedKeys,
    });

    setIsFilterAccountHierachy(newSelectedKeys.length > 0);

    trackEvent('filter_pressed', {
      isFilterAccountHierachy: newSelectedKeys.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'child account',
      filterMethod: mapDropdownFilterMethod(dropdownState),
    });
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_ACCOUNT_HIERARCHY,
      value: [],
    });

  return (
    <MultiSelectDropdown
      disabled={isChildAccount}
      options={accountHierarchyOptions}
      label={t('Account hierarchy')}
      selected={selectedAccountKeys}
      value={
        !isChildAccount
          ? displayedValue
          : `${accountContext?.headerFooterContext?.advertiser?.billingId} - ${
              accountContext?.headerFooterContext?.advertiser?.name || ''
            }`
      }
      onSelect={onSelect}
      searchPlaceholder={`${t('Search accounts')}...`}
      clearSelection={clearSelection}
      isMultipleLevel
      isNvlEnabled={true}
    />
  );
};
