
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT7guITguKfguLLguKHguJXguYnguK3guIfguIHguLLguKPguJrguLjguITguKXguLLguIHguKM8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT7guITguKfguLLguKHguJXguYnguK3guIfguIHguLLguKPguJrguLjguITguKXguLLguIHguKM8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5QZXJtaW50YWFuIHVudHVrIGthbmRpZGF0PC9NZWRpdW0%2BIChsb3dvbmdhbiBrZXJqYSkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5QZXJtaW50YWFuIHVudHVrIGthbmRpZGF0PC9NZWRpdW0%2BIChsb3dvbmdhbiBrZXJqYSkifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT7guITguKfguLLguKHguJXguYnguK3guIfguIHguLLguKPguJrguLjguITguKXguLLguIHguKM8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT7guITguKfguLLguKHguJXguYnguK3guIfguIHguLLguKPguJrguLjguITguKXguLLguIHguKM8L01lZGl1bT4gKOC4iOC4s%2BC4meC4p%2BC4meC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5QZXJtaW50YWFuIHVudHVrIGthbmRpZGF0PC9NZWRpdW0%2BIChsb3dvbmdhbiBrZXJqYSkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5QZXJtaW50YWFuIHVudHVrIGthbmRpZGF0PC9NZWRpdW0%2BIChsb3dvbmdhbiBrZXJqYSkifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiPE1lZGl1bT5EZW1hbmQgZm9yIGNhbmRpZGF0ZXM8L01lZGl1bT4gKGpvYiBhZHMpIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiWzxNZWRpdW0%2B4biK4bq94bq94bq9bcyCxIPEg8SD4LiB4Li14LmJxowgxpLDtsO2w7bFmSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoTwvTWVkaXVtPiAoxLXDtsO2w7bDnyDEg8SDxIPGjMWhKV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEZW1hbmQgZm9yIGNhbmRpZGF0ZXMgKGpvYiBhZHMpIjoiWzxNZWRpdW0%2B4biK4bq94bq94bq9bcyCxIPEg8SD4LiB4Li14LmJxowgxpLDtsO2w7bFmSDDp8SDxIPEg%2BC4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua%2Fhur3hur3hur3FoTwvTWVkaXVtPiAoxLXDtsO2w7bDnyDEg8SDxIPGjMWhKV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    