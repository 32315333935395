
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldlJ3JlIHdvcmtpbmcgb24gZ2V0dGluZyB0aGlzIGJhY2sgdXAuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiI8U3RhY2s%2BPFRleHQ%2BV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC48L1RleHQ%2BPFRleHQ%2BUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLjwvVGV4dD48L1N0YWNrPiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldlJ3JlIHdvcmtpbmcgb24gZ2V0dGluZyB0aGlzIGJhY2sgdXAuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiI8U3RhY2s%2BPFRleHQ%2BV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC48L1RleHQ%2BPFRleHQ%2BUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLjwvVGV4dD48L1N0YWNrPiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldlJ3JlIHdvcmtpbmcgb24gZ2V0dGluZyB0aGlzIGJhY2sgdXAuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiI8U3RhY2s%2BPFRleHQ%2BV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC48L1RleHQ%2BPFRleHQ%2BUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLjwvVGV4dD48L1N0YWNrPiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldlJ3JlIHdvcmtpbmcgb24gZ2V0dGluZyB0aGlzIGJhY2sgdXAuIFJlZnJlc2ggdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiI8U3RhY2s%2BPFRleHQ%2BV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC48L1RleHQ%2BPFRleHQ%2BUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLjwvVGV4dD48L1N0YWNrPiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IjxTdGFjaz48VGV4dD5XZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLjwvVGV4dD48VGV4dD5SZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IlvFtOG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua%2FhuKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxZnDrMOsw6zEo%2BG4qeG5ryDguIHguLXguYnDtsO2w7bFtV0iLCJXZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiWzxTdGFjaz48VGV4dD7FtOG6veG6veG6vSfFmeG6veG6veG6vSDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgxKPhur3hur3hur3hua%2Fhua%2FDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnDrMOsw6zFoSDDn8SDxIPEg8OnxLcgx5rHmseaxqUuPC9UZXh0PjxUZXh0PsWY4bq94bq94bq9xpLFmeG6veG6veG6vcWh4bipIOG5r%2BG4qeG6veG6veG6vSDGpcSDxIPEg8Sj4bq94bq94bq9IMO2w7bDtsWZIMOn4bip4bq94bq94bq9w6fEtyDDn8SDxIPEg8OnxLcgxprEg8SDxIPhua%2Fhur3hur3hur3FmS48L1RleHQ%2BPC9TdGFjaz5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBjYW4ndCBsb2FkIHRoaXMgcmVwb3J0IHJpZ2h0IG5vdyI6IlvFtOG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua%2FhuKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxZnDrMOsw6zEo%2BG4qeG5ryDguIHguLXguYnDtsO2w7bFtV0iLCJXZSdyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiWzxTdGFjaz48VGV4dD7FtOG6veG6veG6vSfFmeG6veG6veG6vSDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgxKPhur3hur3hur3hua%2Fhua%2FDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnDrMOsw6zFoSDDn8SDxIPEg8OnxLcgx5rHmseaxqUuPC9UZXh0PjxUZXh0PsWY4bq94bq94bq9xpLFmeG6veG6veG6vcWh4bipIOG5r%2BG4qeG6veG6veG6vSDGpcSDxIPEg8Sj4bq94bq94bq9IMO2w7bDtsWZIMOn4bip4bq94bq94bq9w6fEtyDDn8SDxIPEg8OnxLcgxprEg8SDxIPhua%2Fhur3hur3hur3FmS48L1RleHQ%2BPC9TdGFjaz5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    