import type { RepostOption } from 'src/types/AdUsageFiltersResponse';

interface TranslatedRepostOption {
  key: string;
  count: number;
  value: string;
}

export const buildRepostData = (
  data: RepostOption[],
  t: (value: string) => string,
) => {
  const translatedData = data.map((option) => ({
    ...option,
    value: t(
      option.key === 'REPOST' ? 'Reposted ads' : 'Ads that are not a repost',
    ),
    key: option.key.toString(),
  }));

  // Sorting data after translation being applied
  return translatedData.sort(
    (a: TranslatedRepostOption, b: TranslatedRepostOption) =>
      a.value <= b.value ? -1 : 1,
  );
};
