import type { MultiSelectDropdownOption } from '@seek/multi-select-dropdown';
import { groupBy, sortBy } from 'lodash';

import type { AccountHierarchyOption } from 'src/types/AdUsageFiltersResponse';

type leafNodeKeysMap = Record<string, string[]>;

interface MultiSelectDropdownOptionGroup extends MultiSelectDropdownOption {
  level: number;
}

interface AccountGroup extends AccountHierarchyCount {
  childAccounts?: AccountHierarchyCount[];
}

interface AccountHierarchyCount extends Omit<AccountHierarchyOption, 'key'> {
  key: string;
  childAccounts?: AccountHierarchyCount[];
}

const groupChildren = (accounts: AccountHierarchyCount[]): AccountGroup[] => {
  if (accounts.length <= 1) {
    return accounts;
  }

  const sortedAccounts = sortBy(accounts, 'value');
  const groupedDuplicates = groupBy<AccountHierarchyCount>(
    sortedAccounts,
    'value',
  );

  return Object.keys(groupedDuplicates).map((groupName) => {
    const duplicateGroup = groupedDuplicates[groupName];

    if (duplicateGroup.length <= 1) {
      const account = duplicateGroup[0];
      return {
        ...account,
        value: `${account.key} - ${account.value}`,
      };
    }

    return {
      key: `${groupName}`,
      value: `${groupName} (${duplicateGroup.length})`,
      count: duplicateGroup.reduce((acc, curr) => acc + curr.count, 0),
      childAccounts: duplicateGroup.map((account) => ({
        ...account,
        value: `${account.key} - ${account.value}`,
      })),
    };
  });
};

function traverseTree(
  account: AccountGroup,
  parentId: number,
  level: number,
): MultiSelectDropdownOptionGroup {
  const result: MultiSelectDropdownOptionGroup = {
    key: account.key.toString(),
    value: account.value,
    count: account.count,
    parentId,
    level,
  };

  if (!account.childAccounts) {
    return result;
  }

  if (account.childAccounts.length === 0) {
    // Leaf node
    return result;
  }

  result.children = [];
  result.leafNodeKeys = [];

  // Branch node
  for (const child of account.childAccounts) {
    const childResult = traverseTree(
      { ...child, key: child.key.toString() },
      parseInt(account.key, 10),
      level + 1,
    );
    result.children!.push(childResult);
    result.leafNodeKeys!.push(childResult.key);
  }

  return result;
}

export const buildAccountHierarchyData = (
  accounts: AccountHierarchyOption[],
): {
  options: MultiSelectDropdownOption[];
  leafNodeKeysMap: leafNodeKeysMap;
} => {
  const transformedAccounts = accounts.map((account) => ({
    ...account,
    key: account.key.toString(),
  }));

  const options = groupChildren(transformedAccounts).map((accountGroup) =>
    traverseTree(accountGroup, 0, 1),
  );

  return {
    options,
    leafNodeKeysMap: options.reduce(
      (acc: Record<string, string[]>, curr: MultiSelectDropdownOptionGroup) => {
        if (curr.leafNodeKeys) {
          acc[curr.key] = curr.leafNodeKeys.filter(Boolean);
        }
        return acc;
      },
      {},
    ),
  };
};
