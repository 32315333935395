import { datadogRum } from '@datadog/browser-rum';

import type { Environment } from 'src/config';

interface DatadogRumSetupConfig {
  environment: Environment;
  version: string;
  sessionReplayEnabled?: boolean;
}

const setupDatadogRum = ({
  environment,
  version,
  sessionReplayEnabled = false,
}: DatadogRumSetupConfig) => {
  datadogRum.init({
    applicationId: 'c30e4837-3a02-4bf6-baf2-faefacdf1b81',
    clientToken: 'puba3d59c2081f19b4738c82e5151ad4020',
    site: 'datadoghq.com',
    service: 'hirer-analytics-ui',
    env: environment,

    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: sessionReplayEnabled ? 20 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      /https:\/\/talent\.seek\.com\.au(?!\/graphql).*/,
      /https:\/\/talent\.seek\.co\.nz(?!\/graphql).*/,
      /https:\/\/talent\.staging\.seek\.com\.au(?!\/graphql).*/,
      /https:\/\/talent\.staging\.seek\.co\.nz(?!\/graphql).*/,
      // Asia Domains
      /https:\/\/ph\.employer\.seek\.com(?!\/graphql).*/,
      /https:\/\/ph\.employer\.staging\.seek\.com(?!\/graphql).*/,
      /https:\/\/th\.employer\.seek\.com(?!\/graphql).*/,
      /https:\/\/th\.employer\.staging\.seek\.com(?!\/graphql).*/,
      /https:\/\/my\.employer\.seek\.com(?!\/graphql).*/,
      /https:\/\/my\.employer\.staging\.seek\.com(?!\/graphql).*/,
      /https:\/\/hk\.employer\.seek\.com(?!\/graphql).*/,
      /https:\/\/hk\.employer\.staging\.seek\.com(?!\/graphql).*/,
      /https:\/\/id\.employer\.seek\.com(?!\/graphql).*/,
      /https:\/\/id\.employer\.staging\.seek\.com(?!\/graphql).*/,
      /https:\/\/sg\.employer\.seek\.com(?!\/graphql).*/,
      /https:\/\/sg\.employer\.staging\.seek\.com(?!\/graphql).*/,
      /http:\/\/hirer-graphql-api-.*\.hirer\.dev\.outfra\.xyz/,
    ],
  });
};

export { setupDatadogRum };
export type { DatadogRumSetupConfig };
