import { ProgressBarChart } from '@seek/charts';
import { useTranslations } from '@vocab/react';
import { Box, List, Loader, Stack, Text } from 'braid-design-system';
import { useEffect, useMemo, useRef, useState } from 'react';

import performanceTabTranslations from 'src/blocks/PerformanceTab/.vocab';
import { ClickableTooltip } from 'src/components/ClickableTooltip/ClickableTooltip';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { useMarketPerformanceData } from 'src/hooks/useMarketPerformanceData';
import { formatNumber } from 'src/utils/number';
import {
  calculatePercentage,
  formatPercentage,
} from 'src/utils/percentage/percentageFormatter';

import translations from './.vocab';

export const MarketPerformanceContainer = ({
  children,
}: {
  children: ReactNodeNoStrings;
}) => {
  const { t } = useTranslations(translations);
  const { t: tPerformanceTab } = useTranslations(performanceTabTranslations);

  return (
    <Stack space="medium">
      <Box display="flex" gap="xsmall" alignItems="center" flexWrap="nowrap">
        <Text size="small" weight="strong" maxLines={1}>
          {t('Overall ad performance rating')}
        </Text>
        <Box flexShrink={0}>
          <ClickableTooltip
            id="market-performance-performance-rating-tooltip"
            title={tPerformanceTab('Performance rating')}
            contents={
              <Stack space="medium">
                <Text>
                  {tPerformanceTab(
                    'We rate the performance of your ads by comparing the number of applications against other ads with a similar job title and location.',
                  )}
                </Text>
                <List>
                  <Text>
                    {tPerformanceTab(
                      'Ads rated as high got more applications than similar ads.',
                    )}
                  </Text>
                  <Text>
                    {tPerformanceTab(
                      'Ads rated as normal got about the same applications than similar ads.',
                    )}
                  </Text>
                  <Text>
                    {tPerformanceTab(
                      'Ads rated as low got less applications than similar ads.',
                    )}
                  </Text>
                  <Text>
                    {tPerformanceTab(
                      "Some ads have no rating because they were posted recently or ad comparisons aren't available",
                    )}
                  </Text>
                </List>
              </Stack>
            }
            size="small"
          />
        </Box>
      </Box>
      {children}
    </Stack>
  );
};

export const MarketPerformance = () => {
  const { t } = useTranslations(translations);

  const {
    data: marketPerformanceData,
    isLoading,
    error,
  } = useMarketPerformanceData();

  const chartData: Array<{
    ads: number;
    color: string;
    progress: number;
    title: string;
  }> = useMemo(() => {
    if (!marketPerformanceData) {
      return [];
    }

    const { totalAds, highAds, lowAds, normalAds } = marketPerformanceData;

    return [
      {
        ads: highAds,
        progress: calculatePercentage(highAds, totalAds),
        color: '#1FA575',
        title: t('High'),
      },
      {
        ads: normalAds,
        color: '#1D559D',
        progress: calculatePercentage(normalAds, totalAds),
        title: t('Normal'),
      },
      {
        ads: lowAds,
        color: '#D5292A',
        progress: calculatePercentage(lowAds, totalAds),
        title: t('Low'),
      },
    ];
  }, [t, marketPerformanceData]);

  const refs = useRef<Array<HTMLElement | null>>([]);

  const [maxColumnWidth, setMaxColumnWidth] = useState(0);

  useEffect(() => {
    let maxWidth = 0;

    refs.current.forEach((ref) => {
      if (ref) {
        const width = ref.getBoundingClientRect().width;
        if (width > maxWidth) {
          maxWidth = width;
        }
      }
    });

    setMaxColumnWidth(maxWidth);
  }, [marketPerformanceData]);

  if (isLoading && !marketPerformanceData) {
    return (
      <MarketPerformanceContainer>
        <Box display="flex" justifyContent="center">
          <Loader size="small" />
        </Box>
      </MarketPerformanceContainer>
    );
  }

  if (error || !marketPerformanceData) {
    return (
      <MarketPerformanceContainer>
        <Box display="flex" justifyContent="center">
          <WidgetError />
        </Box>
      </MarketPerformanceContainer>
    );
  }

  const { totalAds, noRatingAds } = marketPerformanceData;

  return (
    <MarketPerformanceContainer>
      <Stack space="large">
        {chartData.map((row, rowIndex) => (
          <Box
            key={`ad-usage-performance-tab-market-performance-${rowIndex}`}
            display="flex"
            alignItems="flexEnd"
            gap="xsmall"
          >
            <Box flexGrow={1}>
              <Stack space="xsmall">
                <Text size="small">{row.title}</Text>
                <ProgressBarChart
                  color={row.color}
                  progress={row.progress < 3 ? 3 : row.progress}
                />
              </Stack>
            </Box>
            <Box
              position="relative"
              style={{ width: maxColumnWidth ? maxColumnWidth : undefined }}
              flexShrink={0}
            >
              <Box
                ref={(el) => (refs.current[rowIndex] = el)}
                position="absolute"
                left={0}
                bottom={0}
              >
                <Text size="small" maxLines={1}>
                  {t('N ads of total', {
                    adCount: row.ads,
                    adCountText: formatNumber(row.ads),
                    percentage: formatPercentage(
                      calculatePercentage(row.ads, totalAds),
                      0,
                    ),
                  })}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
        <Text size="small">
          {t("N ads don't have a performance rating", {
            adCount: noRatingAds,
            adCountText: formatNumber(noRatingAds),
            percentage: formatPercentage(
              calculatePercentage(noRatingAds, totalAds),
              0,
            ),
          })}
        </Text>
      </Stack>
    </MarketPerformanceContainer>
  );
};
