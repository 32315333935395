import { useTranslations } from '@vocab/react';
import { Box, Heading, Stack, Text } from 'braid-design-system';

import { NoResultIcon } from 'src/assets/icons/NoResultIcon/NoResultIcon';

import translations from './.vocab';

export const NoResultMessage = () => {
  const { t } = useTranslations(translations);
  return (
    <Box width="full" padding="xxlarge">
      <Stack space="large">
        <Box textAlign="center">
          <NoResultIcon />
        </Box>
        <Heading level="2" weight="weak" align="center">
          {t('No ads found title')}
        </Heading>
        <Stack space="xsmall" align="center">
          <Text tone="secondary">
            {t('No ads found description first line')}
          </Text>
          <Text tone="secondary">
            {t('No ads found description second line')}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
