import { useQuery } from '@apollo/client';

import { adPerformanceReportQuery } from '../queries/adPerformanceReportQuery';

export const useAdPerformanceReport = ({ jobId = '' }) => {
  const { loading, data, error, previousData } = useQuery(
    adPerformanceReportQuery,
    {
      errorPolicy: 'all',
      variables: {
        input: {
          jobId,
        },
      },
    },
  );

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsAdPerformanceReport,
    isLoading: loading,
    error,
  };
};
