import type { AdRatingOption } from 'src/types/AdUsageFiltersResponse';
import type { FiltersAdRatingType } from 'src/types/AdUsageTypes';

export const sortAdPerformanceTypes = (
  adPerformanceTypes: AdRatingOption[],
): AdRatingOption[] => {
  // setting up ad performance types order as per business rule
  // to ensure correct order
  // first High; then Normal; then Low; then No Rating;
  const orderMapping: FiltersAdRatingType[] = [
    'HIGH',
    'NORMAL',
    'LOW',
    'NO_RATING',
  ];
  const newArr: AdRatingOption[] = [];
  adPerformanceTypes.forEach((type) => {
    const index = orderMapping.indexOf(type.key);
    newArr[index] = type;
  });

  return newArr;
};
