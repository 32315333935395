import { Box, ContentBlock, Columns, Column } from 'braid-design-system';
import { useState, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { JobTitleNav } from 'src/components/JobTitleNav/JobTitleNav';
import { PageTitleActions } from 'src/components/PageTitleActions/PageTitleActions';
import { usePageTitleActions } from 'src/hooks/usePageTitleActions';

import { stickyOffset } from './PageTitleStickySection.constants';

import * as styles from './pageTitleStickySection.css';

export const PageTitleStickySection = () => {
  // setup refs
  const requestRef = useRef<number>();
  const stickyRef = useRef<HTMLDivElement>(null);

  // setup states
  const [isSticky, setSticky] = useState(
    stickyRef.current !== null &&
      stickyRef.current.getBoundingClientRect().bottom < stickyOffset,
  );

  // react to events
  useLayoutEffect(() => {
    const onFrame = () => {
      if (stickyRef.current) {
        setSticky(
          stickyRef.current.getBoundingClientRect().bottom < stickyOffset,
        );
      }
    };
    const startRaf = () => {
      requestRef.current = window.requestAnimationFrame(onFrame);
    };
    const stopRaf = () => {
      if (requestRef.current !== undefined) {
        window.cancelAnimationFrame(requestRef.current);
      }
    };
    const onScrollWindow = () => {
      stopRaf();
      startRaf();
    };
    window.addEventListener('scroll', onScrollWindow, false);
    return () => {
      window.removeEventListener('scroll', onScrollWindow, false);
      stopRaf();
    };
  }, []);

  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const { data, isLoading, error } = usePageTitleActions({
    jobId: jobIdParam!,
  });

  if (isLoading && !data) {
    return null;
  }

  if (error || !data) {
    return null;
  }

  return (
    <>
      <Box
        data={{ id: 'ui-page-title-sticky-section-inline' }}
        ref={stickyRef}
      />
      <Box
        aria-hidden={!isSticky}
        boxShadow="small"
        className={styles.sticky}
        background="customLight"
        style={{ backgroundColor: 'white' }}
        data={{
          id: `ui-page-title-sticky-section-sticky-${
            isSticky ? 'active' : 'inactive'
          }`,
        }}
        paddingX={['small', 'xlarge']}
        paddingY="medium"
        top={0}
        display={isSticky ? 'block' : 'none'}
        zIndex="sticky"
      >
        <ContentBlock width="large">
          <Columns space="medium" alignY="center" collapseBelow="tablet">
            <Column>
              <JobTitleNav
                activeJobId={data?.jobId}
                jobTitle={data?.jobTitle}
                postedViaUploader={data?.postTypeCategory !== 'DIRECT'}
                latestJobs={data?.latestJobs}
                isLoading={isLoading}
              />
            </Column>
            <Column width="content">
              <PageTitleActions
                data={data}
                error={error}
                isLoading={isLoading}
              />
            </Column>
          </Columns>
        </ContentBlock>
      </Box>
    </>
  );
};
