import { ManagedContentBanner } from '@seek/cms-ui';

import { getLocale, isProduction } from 'src/config';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

const environment = isProduction() ? 'production' : 'staging';

export type SystemBannerContext = 'hirer_analytics_global';

export const SystemBanner = ({
  context = 'hirer_analytics_global',
}: {
  context?: SystemBannerContext;
}) => (
  <ErrorBoundary fallback={null}>
    <ManagedContentBanner
      locale={getLocale()}
      platform="employer"
      environment={environment}
      context={context}
    />
  </ErrorBoundary>
);
