import { formatNumber } from 'src/utils/number';
import { formatPercentage } from 'src/utils/percentage/percentageFormatter';

const DISPLAY_PERCENTAGE_UPPER_LIMIT = 1.05;
const INLINE_PERCENTAGE_LIMIT = 0.2;

function mapComparisonRateToTrendParts(comparisonRate: number): {
  metricLabel: string;
  metricSuffix: 'PC More than' | 'PC Less than' | 'Times';
  isPositiveTrend: boolean;
  isInlineTrend: boolean;
} {
  const isInlineComparison = Math.abs(comparisonRate) < INLINE_PERCENTAGE_LIMIT;
  const isPositiveComparison = comparisonRate > 0;

  if (comparisonRate < DISPLAY_PERCENTAGE_UPPER_LIMIT) {
    return {
      metricLabel: formatPercentage(Math.abs(comparisonRate * 100), 0),
      metricSuffix: isPositiveComparison ? 'PC More than' : 'PC Less than',
      isPositiveTrend: isPositiveComparison,
      isInlineTrend: isInlineComparison,
    };
  }

  return {
    metricLabel: `${formatNumber(Math.round(comparisonRate * 10) / 10 + 1)}x`,
    metricSuffix: 'Times',
    isPositiveTrend: true,
    isInlineTrend: false,
  };
}

export { mapComparisonRateToTrendParts };
