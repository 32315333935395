import { Box, Stack } from 'braid-design-system';

import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import {
  AdTypeTrends,
  AdTypeTrendsContainer,
} from 'src/widgets/AdTypeTrends/AdTypeTrends';
import {
  ClassificationsAndUsersTrends,
  ClassificationsUsersContainer,
} from 'src/widgets/ClassificationsAndUsersTrends/ClassificationsAndUsersTrends';

import * as styles from './TrendsTab.css';

export const TrendsTab = () => (
  <Box display="flex" gap="medium">
    <Box width="full" style={{ maxWidth: '50%' }}>
      <ErrorBoundary
        fallback={
          <AdTypeTrendsContainer>
            <WidgetError />
          </AdTypeTrendsContainer>
        }
      >
        <AdTypeTrends />
      </ErrorBoundary>
    </Box>
    <Box width="full" style={{ maxWidth: '50%' }}>
      <ErrorBoundary
        fallback={
          <ClassificationsUsersContainer>
            <WidgetError />
          </ClassificationsUsersContainer>
        }
      >
        <ClassificationsAndUsersTrends />
      </ErrorBoundary>
    </Box>
  </Box>
);

export const TrendsTabCompact = () => (
  <Stack space="medium">
    <Box
      display="flex"
      overflow="scroll"
      gap="medium"
      style={{ overflowY: 'hidden' }}
    >
      <Box className={styles.compactStyle}>
        <ErrorBoundary
          fallback={
            <AdTypeTrendsContainer>
              <WidgetError />
            </AdTypeTrendsContainer>
          }
        >
          <AdTypeTrends />
        </ErrorBoundary>
      </Box>
      <Box className={styles.compactStyle}>
        <ErrorBoundary
          fallback={
            <ClassificationsUsersContainer>
              <WidgetError />
            </ClassificationsUsersContainer>
          }
        >
          <ClassificationsAndUsersTrends />
        </ErrorBoundary>
      </Box>
    </Box>
  </Stack>
);
