import { StackBarChart } from '@seek/charts';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Loader,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
  Text,
} from 'braid-design-system';
import { useContext, useEffect, useState } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { IsFilterContext } from 'src/context/IsFilterContext';
import { useAdTypeTrendsData } from 'src/hooks/useAdTypeTrendsData';
import { useIsBelowMinAdUsageReportWidth } from 'src/hooks/useCustomMediaQueries';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import translations from './.vocab';
import {
  generateGroupedTotalProductDisplayNames,
  generateProductDisplayNameDailyChartData,
  generateProductDisplayNameMonthlyChartData,
  getTopProductDisplayNames,
} from './AdTypeTrends.formatters';

// New color for trend chart when productDisplayName is ready
const fullProductDisplayNameColorMap: Record<string, string> = {
  Branded: '#EF672A',
  Classic: '#3E8FE0',
  Premium: '#5B2084',
  Basic: '#072254',
  StandOut: '#10727F',
  Others: '#838FA5',
  Performance: '#A04BCB',
  'Branded Performance': '#A00043',
  Advanced: '#A04BCB',
  'Branded Advanced': '#A00043',
};

// New label for trend chart when productDisplayName is ready
const productDisplayNameLabelMap: Record<string, string> = {
  Branded: 'Branded',
  Classic: 'Classic',
  Premium: 'Premium',
  Basic: 'Basic',
  StandOut: 'StandOut',
  Others: 'Others',
  Performance: 'Performance',
  'Branded Performance': 'Branded Performance',
  Advanced: 'Advanced',
  'Branded Advanced': 'Branded Advanced',
};

// This formula is used to set the coordinate of X-ticks evenly regardless of the number of data points
const deriveTicks = (chartData: Array<{ name: string }>, maxTicks: number) => {
  const ticks: string[] = [];

  const step = Math.max(1, Math.floor(chartData.length / maxTicks));

  for (let i = 1; i < chartData.length - 1; i += step) {
    ticks.push(chartData[i].name);
  }

  return ticks.slice(0, maxTicks);
};

export const AdTypeTrendsContainer = ({
  children,
}: {
  children: ReactNodeNoStrings;
}) => {
  const { t } = useTranslations(translations);

  return (
    <Box
      width="full"
      boxShadow="borderNeutralLight"
      borderRadius="large"
      height="full"
    >
      <Box padding="medium">
        <Stack space="medium">
          <Text size="small" weight="strong">
            {t('Breakdown by ad type over time')}
          </Text>
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

export const AdTypeTrends = () => {
  const { t } = useTranslations(translations);
  const { locale } = useConfig();
  const [currentTab, setCurrentTab] = useState<string | undefined>('daily');
  const isBelowMinAdUsageReportWidth = useIsBelowMinAdUsageReportWidth();

  const { isLoading, data, error } = useAdTypeTrendsData();
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  useEffect(() => {
    if (data && data.productDisplayNameDaily.length > 90) {
      setCurrentTab('monthly');
    } else {
      setCurrentTab('daily');
    }
  }, [data]);

  if (isLoading && !data) {
    return (
      <AdTypeTrendsContainer>
        <Box display="flex" justifyContent="center" data-id="loader">
          <Loader size="small" />
        </Box>
      </AdTypeTrendsContainer>
    );
  }

  if (error || !data) {
    return (
      <AdTypeTrendsContainer>
        <WidgetError />
      </AdTypeTrendsContainer>
    );
  }

  const {
    endDate,
    startDate,
    productDisplayNameDaily,
    productDisplayNameMonthly,
    totalProductDisplayNames,
  } = data;

  const productDisplayNameGroups = getTopProductDisplayNames(
    totalProductDisplayNames,
  );

  const dailyChartData = generateProductDisplayNameDailyChartData(
    productDisplayNameDaily,
    productDisplayNameGroups,
  );

  const monthlyChartData = generateProductDisplayNameMonthlyChartData(
    productDisplayNameMonthly,
    productDisplayNameGroups,
  );

  const productDisplayNameColorMap: Record<string, string> =
    productDisplayNameGroups.reduce(
      (acc, curr) => ({ ...acc, [curr]: fullProductDisplayNameColorMap[curr] }),
      {},
    );

  const groupedTotalProductDisplayNames =
    generateGroupedTotalProductDisplayNames(
      totalProductDisplayNames,
      productDisplayNameGroups,
    );

  return (
    <AdTypeTrendsContainer>
      <TabsProvider
        id="ad-type-trends-tab"
        onChange={(_index, item) => {
          trackEvent('ad_trends_view_option_pressed', {
            viewOption: _index === 0 ? 'daily' : 'monthly',
            isFilterAdPerformance,
            isFilterAdType,
            isFilterClassification,
            isFilterJobTitle,
            isFilterLocation,
            isFilterAccountHierachy,
            isFilterAdId,
            isFilterAdStatus,
            isFilterRepost,
            isFilterTipsToImproveAds,
            isFilterUser,
            isFilterBudget,
          });
          setCurrentTab(item);
        }}
        selectedItem={currentTab}
      >
        <Stack space="medium">
          <Tabs label="Trends" size="small">
            <Tab item="daily">{t('Daily')}</Tab>
            <Tab item="monthly">{t('Monthly')}</Tab>
          </Tabs>
          <TabPanels>
            <TabPanel>
              <Stack space="gutter">
                <StackBarChart
                  locale={locale}
                  data={dailyChartData}
                  colorMap={productDisplayNameColorMap}
                  labelMap={productDisplayNameLabelMap}
                  totalDataCount={groupedTotalProductDisplayNames}
                  noDataTooltip={t('No ads posted')}
                  isNVLEnabled={true}
                  xTicks={deriveTicks(
                    dailyChartData,
                    isBelowMinAdUsageReportWidth ? 3 : 5,
                  )}
                  height={315}
                />
                <Text size="xsmall" tone="neutral">
                  {t('Showing ads posted date', {
                    endDate,
                    startDate,
                  })}
                </Text>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack space="medium">
                <StackBarChart
                  locale={locale}
                  data={monthlyChartData}
                  colorMap={productDisplayNameColorMap}
                  labelMap={productDisplayNameLabelMap}
                  totalDataCount={groupedTotalProductDisplayNames}
                  noDataTooltip={t('No ads posted')}
                  isNVLEnabled={true}
                  height={315}
                />
                <Text size="xsmall" tone="neutral">
                  {t('Showing ads posted date', {
                    endDate,
                    startDate,
                  })}
                </Text>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Stack>
      </TabsProvider>
    </AdTypeTrendsContainer>
  );
};
