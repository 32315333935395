import { subYears } from 'date-fns';
import { useCallback } from 'react';

import { LAUNCH_DATES } from 'src/config';

export const useSiteLaunchDateChecker = (site: string, lastDate: Date) => {
  const withinNumDays = useCallback(
    (numOfDays: number): boolean => {
      const siteLaunchDate = LAUNCH_DATES[site];
      const launchDate = new Date(siteLaunchDate);
      // TODO: Replace direct reference to lastDate by creating new Date
      // TODO: object. This is because .setHours() is directly mutating
      const currentDate = lastDate;

      // Set the time of both dates to midnight for an accurate comparison
      launchDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      // Calculate the difference in days
      const diffInDays =
        (currentDate.valueOf() - launchDate.valueOf()) / (24 * 60 * 60 * 1000);

      // Check if the current date is within numOfDays from siteLaunchDate
      return diffInDays < numOfDays;
    },
    [lastDate, site],
  );

  const hasLaunchedAndWithin = useCallback(
    (numOfDays: number) => LAUNCH_DATES[site] && withinNumDays(numOfDays),
    [site, withinNumDays],
  );

  const hasLaunchedAndWithinTwoYears = useCallback(
    (siteLaunchDate: string): boolean => {
      if (!siteLaunchDate) return false;

      const launchDate = new Date(siteLaunchDate);
      // Add two years to the launch date
      launchDate.setFullYear(launchDate.getFullYear() + 2);

      const currentDate = new Date();

      // Check if the current date is before or on the launch date plus 2 years
      return currentDate <= launchDate;
    },
    [],
  );

  const getMinPostingDate = () => subYears(lastDate, 2);

  return {
    withinNumDays,
    hasLaunchedAndWithin,
    hasLaunchedAndWithinTwoYears,
    getMinPostingDate,
  };
};
