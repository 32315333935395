import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from 'src/App/ConfigContext';
import type { SimilarAdsResponse } from 'src/gql/graphql';

import { similarAdsQuery } from '../queries/similarAdsQuery';

interface SimilarAdsQueryParams {
  input: {
    jobId: string;
    locale: string;
  };
}

interface queryResponse {
  analyticsSimilarAdsDetails: SimilarAdsResponse;
}

export const useSimilarAds = ({
  jobId = '',
}: {
  jobId: string;
}): {
  data: SimilarAdsResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const { data, loading, error, previousData } = useQuery<
    queryResponse,
    SimilarAdsQueryParams
  >(similarAdsQuery, {
    variables: {
      input: {
        jobId,
        locale,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsSimilarAdsDetails,
    isLoading: loading,
    error,
  };
};
