
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBjbGFzc2lmaWNhdGlvbnMiOiLguITguYnguJnguKvguLLguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBjbGFzc2lmaWNhdGlvbnMiOiLguITguYnguJnguKvguLLguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiU2VtdWEga2xhc2lmaWthc2kiLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyI6IkNhcmkga2xhc2lmaWthc2kiLCJDbGFzc2lmaWNhdGlvbiI6IktsYXNpZmlrYXNpIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiU2VtdWEga2xhc2lmaWthc2kiLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyI6IkNhcmkga2xhc2lmaWthc2kiLCJDbGFzc2lmaWNhdGlvbiI6IktsYXNpZmlrYXNpIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBjbGFzc2lmaWNhdGlvbnMiOiLguITguYnguJnguKvguLLguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBjbGFzc2lmaWNhdGlvbnMiOiLguITguYnguJnguKvguLLguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiU2VtdWEga2xhc2lmaWthc2kiLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyI6IkNhcmkga2xhc2lmaWthc2kiLCJDbGFzc2lmaWNhdGlvbiI6IktsYXNpZmlrYXNpIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiU2VtdWEga2xhc2lmaWthc2kiLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIiwiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyI6IkNhcmkga2xhc2lmaWthc2kiLCJDbGFzc2lmaWNhdGlvbiI6IktsYXNpZmlrYXNpIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiQWxsIGNsYXNzaWZpY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggY2xhc3NpZmljYXRpb25zIjoiU2VhcmNoIGNsYXNzaWZpY2F0aW9ucyIsIkNsYXNzaWZpY2F0aW9uIjoiQ2xhc3NpZmljYXRpb24ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiW%2BG6rMaaxpogw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIlNlYXJjaCBjbGFzc2lmaWNhdGlvbnMiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOnxprEg8SDxIPFocWhw6zDrMOsxpLDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSIsIkNsYXNzaWZpY2F0aW9uIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5iV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgY2xhc3NpZmljYXRpb25zIjoiW%2BG6rMaaxpogw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIlNlYXJjaCBjbGFzc2lmaWNhdGlvbnMiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOnxprEg8SDxIPFocWhw6zDrMOsxpLDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSIsIkNsYXNzaWZpY2F0aW9uIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5iV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    