import { useTranslations } from '@vocab/react';

import { GenericTitleContainer } from 'src/components/GenericTitleContainer/GenericTitleContainer';
import { PrimarySection } from 'src/components/PrimarySection/PrimarySection';
import { WidgetError } from 'src/components/WidgetError/WidgetError';

import translations from './.vocab';

export const MyJobInformationFallback = () => {
  const { t } = useTranslations(translations);
  return (
    <PrimarySection>
      <GenericTitleContainer title={t('Job information')}>
        <WidgetError />
      </GenericTitleContainer>
    </PrimarySection>
  );
};
