import { useState, useCallback, useLayoutEffect } from 'react';

import { useChartSize } from 'src/hooks/useChartSize';
import { useChartTheme } from 'src/hooks/useChartTheme';
import { useTextStyles } from 'src/hooks/useTextStyles';

import { createMarketInsightTrendRenderer } from './MarketInsightTrendChart.renderer';
import type {
  MarketInsightTrendChartData,
  MarketInsightTrendRenderer,
} from './MarketInsightTrendChart.types';

export function useMarketInsightTrendChartRenderer({
  containerRef,
  data,
  height,
  width: initialWidth,
  widthBehaviour = 'dynamic',
}: {
  containerRef: HTMLDivElement | null;
  data: MarketInsightTrendChartData;
  height: number;
  width: number;
  widthBehaviour?: 'fixed' | 'dynamic';
}): MarketInsightTrendRenderer | null {
  const theme = useChartTheme();
  const [chartRenderer, setChartRenderer] =
    useState<MarketInsightTrendRenderer | null>(null);
  const createRendererMemo = useCallback(createMarketInsightTrendRenderer, []);
  const textStyles = useTextStyles();

  useLayoutEffect(() => {
    const newChartRenderer = createRendererMemo({
      container: containerRef,
      containerHeight: height,
      containerWidth: initialWidth,
      textStyles,
    });
    setChartRenderer(newChartRenderer);
  }, [containerRef, createRendererMemo, height, initialWidth, textStyles]);

  const { width } = useChartSize({
    containerRef,
    width: initialWidth,
    widthBehaviour,
  });

  useLayoutEffect(() => {
    chartRenderer?.update({ data, width, theme });
  }, [chartRenderer, data, width, theme]);

  return chartRenderer;
}
