import type { ApolloError } from '@apollo/client';
import { type Country, createAbsoluteUrl } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Actions,
  Box,
  Button,
  ButtonIcon,
  Column,
  Columns,
  IconChevron,
  IconClear,
  IconEdit,
  IconSearch,
  IconVisibility,
  Inline,
  Loader,
  Notice,
  Stack,
  Text,
} from 'braid-design-system';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { useMobileView } from 'src/hooks/useMobileView';
import type { PageTitleActionsResponse } from 'src/types/PageTitleActionsResponse';
import { trackEvent } from 'src/utils/tealiumEventTracker';

import { ExternalLink } from '../ExternalLink/ExternalLink';
import { JobAdSummary } from '../JobAdSummary/JobAdSummary';
import { Modal } from '../Modal/Modal';
import { WidgetError } from '../WidgetError/WidgetError';

import translations from './.vocab';

import * as styles from './pageTitleActions.css';

interface PageTitleActionsProps {
  data?: PageTitleActionsResponse;
  isLoading?: boolean;
  error?: ApolloError;
}

export const PageTitleActions = ({
  data,
  isLoading,
  error,
}: PageTitleActionsProps) => {
  const { t } = useTranslations(translations);
  const { country, language, urlResolver } = useConfig();

  const subMenuRef = useRef<HTMLDivElement | null>(null);
  const [showViewAdSubMenu, setShowViewAdSubMenu] = useState(false);
  const [showViewJobSummaryModal, setViewJobSummaryModal] = useState(false);
  const [editAdWarningMessage, setEditAdWarningMessage] = useState('');
  const [showEditAdWarningModal, setShowEditAdWarningModal] = useState(false);

  const isMobile = useMobileView();

  const onWindowClicked = useCallback(
    (e: any) => {
      if (
        subMenuRef.current === null ||
        subMenuRef.current.contains(e.target)
      ) {
        return;
      }
      if (showViewAdSubMenu) {
        setShowViewAdSubMenu(false);
      }
    },
    [showViewAdSubMenu],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onWindowClicked);
    return () => {
      document.removeEventListener('mousedown', onWindowClicked);
    };
  }, [onWindowClicked]);

  if (isLoading) {
    return (
      <Stack align="center" space="small">
        <Loader />
      </Stack>
    );
  }

  if (error || !data) {
    return (
      <Stack space="small">
        <WidgetError />
      </Stack>
    );
  }

  const {
    adType,
    isExpired,
    displayCurrency,
    keySellingPoints,
    serpLogoUrl,
    jobId,
    postTypeCategory,
    classificationName,
    subClassificationName,
    advertiserName,
    jobCreatedTimestampUTC,
    isPrivateAdvertiser,
    jobSummary,
    jobTitle,
    jobLocationLabel,
    salaryAdditionalText,
  } = data;

  const bulletPoints = keySellingPoints ? keySellingPoints : [];
  const editAdUrl = urlResolver(`/job/managejob/express/edit?jobId=${jobId}`);
  const jobUrl = createAbsoluteUrl({
    product: 'candidate',
    country: country as Country,
    language,
    path: `/${isExpired ? 'expiredjob' : 'job'}/${jobId.replace('demo', '')}`,
  });

  const onViewAdMenuClicked = () => {
    trackEvent('view_ad_menu_pressed', {
      objectInteraction: 'apr-view-ad-menu',
    });
    setShowViewAdSubMenu(!showViewAdSubMenu);
  };

  const onEditAdButtonClicked = () => {
    trackEvent('edit_ad_pressed', {
      objectInteraction: 'apr-edit-ad',
    });
    setTimeout(() => {
      window.location.href = editAdUrl;
    }, 500);
  };

  const onViewAdButtonClicked = () => {
    trackEvent('view_ad_pressed', {
      objectInteraction: 'apr-view-ad',
    });
  };

  const onViewJobSummaryClicked = () => {
    trackEvent('view_job_summary_pressed', {
      objectInteraction: 'apr-view-job-summary',
    });
    setShowViewAdSubMenu(false);
    setViewJobSummaryModal(!showViewJobSummaryModal);
  };

  const onViewJobSummaryModalShouldDismiss = () => {
    setViewJobSummaryModal(false);
  };

  const onEditExpiredDirectAdButtonClicked = () => {
    trackEvent('edit_ad_pressed', {
      objectInteraction: 'apr-edit-ad',
    });
    setEditAdWarningMessage(
      t('This ad has expired and can no longer be edited.'),
    );
    setShowEditAdWarningModal(true);
  };

  const onEditAdWarningModalShouldDismiss = () => {
    setShowEditAdWarningModal(false);
  };

  const onEditAdIndirectButtonClicked = () => {
    trackEvent('edit_ad_pressed', {
      objectInteraction: 'apr-edit-ad',
    });
    setEditAdWarningMessage(
      t(
        "To edit this ad, you'll need to go back to the system you used to post it.",
      ),
    );
    setShowEditAdWarningModal(true);
  };

  return (
    <Box data={{ id: 'ui-page-title-actions' }}>
      <Actions>
        <Box position="relative" ref={subMenuRef}>
          <Button variant="ghost" onClick={onViewAdMenuClicked}>
            {t('View ad')} <IconChevron />
          </Button>
          {showViewAdSubMenu && (
            <Box
              background="surface"
              boxShadow="medium"
              className={styles.subMenu}
              zIndex="dropdown"
              style={{ width: isMobile ? '100%' : undefined }}
            >
              <ExternalLink
                className={classNames(
                  styles.subMenuItemRoot,
                  styles.subMenuItem,
                )}
                data-id="ui-page-title-actions-view-ad-button"
                href={jobUrl}
                target="_blank"
                onClick={onViewAdButtonClicked}
              >
                <Box className={styles.subMenuHoverOverlay} />
                <Inline space="small" alignY="center">
                  <Box className={styles.subMenuIconWrapper}>
                    <IconVisibility size="fill" tone="secondary" />
                  </Box>
                  <Text>{t('View ad')}</Text>
                </Inline>
              </ExternalLink>
              <Box
                data={{ id: 'ui-page-title-actions-view-job-summary-button' }}
                onClick={onViewJobSummaryClicked}
              >
                <Box
                  className={classNames(
                    styles.subMenuItemRoot,
                    styles.subMenuItem,
                  )}
                >
                  <Box className={styles.subMenuHoverOverlay} />
                  <Box display="flex" alignItems="center">
                    <Box className={styles.subMenuIconWrapper} flexShrink={0}>
                      <IconSearch size="fill" tone="secondary" />
                    </Box>
                    <Box paddingLeft="small">
                      <Text>{t('View job summary (search page)')}</Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {postTypeCategory === 'DIRECT' && !isExpired ? (
          <Button
            variant="ghost"
            icon={<IconEdit />}
            iconPosition="trailing"
            onClick={onEditAdButtonClicked}
          >
            {t('Edit ad')}
          </Button>
        ) : null}
        {postTypeCategory === 'INDIRECT' && !isExpired ? (
          <Button
            variant="ghost"
            icon={<IconEdit />}
            iconPosition="trailing"
            onClick={onEditAdIndirectButtonClicked}
          >
            {t('Edit ad')}
          </Button>
        ) : null}
        {isExpired ? (
          <Button
            variant="ghost"
            icon={<IconEdit />}
            iconPosition="trailing"
            onClick={onEditExpiredDirectAdButtonClicked}
          >
            {t('Edit ad')}
          </Button>
        ) : null}
      </Actions>
      {showEditAdWarningModal && (
        <Modal
          id="modal-base"
          onModalShouldDismiss={onEditAdWarningModalShouldDismiss}
        >
          <Box paddingX="large" paddingY="large">
            <Columns space="xlarge">
              <Column>
                <Notice tone="info">
                  <Text>{editAdWarningMessage}</Text>
                </Notice>
              </Column>
              <Column width="content">
                <ButtonIcon
                  id="dismiss-button-icon"
                  label="Dismiss"
                  icon={<IconClear />}
                  size="large"
                  variant="transparent"
                  onClick={onEditAdWarningModalShouldDismiss}
                />
              </Column>
            </Columns>
            <Box
              background="info"
              paddingLeft="xxsmall"
              position="absolute"
              left={0}
              top={0}
              bottom={0}
            />
          </Box>
        </Modal>
      )}
      {showViewJobSummaryModal ? (
        <Modal
          id="modal-base"
          onModalShouldDismiss={onViewJobSummaryModalShouldDismiss}
        >
          <Box paddingX="large" paddingY="large">
            <Stack space="xlarge">
              <Box
                component="button"
                style={{ cursor: 'pointer' }}
                onClick={onViewJobSummaryModalShouldDismiss}
              >
                <IconClear size="large" />
              </Box>
              <Box marginX="xxlarge">
                <JobAdSummary
                  bulletPoints={bulletPoints}
                  classificationTier1={classificationName}
                  classificationTier2={subClassificationName}
                  companyName={advertiserName}
                  createDate={jobCreatedTimestampUTC}
                  displayCurrency={displayCurrency}
                  isPremium={adType === 'PREMIUM'}
                  isPrivateAdvertiser={isPrivateAdvertiser}
                  isStandOut={adType === 'BRANDED'}
                  jobSummaryText={jobSummary || null}
                  jobId={jobId}
                  jobTitle={jobTitle}
                  locationLabel={jobLocationLabel}
                  logoUrl={serpLogoUrl}
                  salaryText={salaryAdditionalText || null}
                />
              </Box>
            </Stack>
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};
