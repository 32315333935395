import { ProgressBarChart } from '@seek/charts';
import { useTranslations } from '@vocab/react';
import { Box, Stack, Text } from 'braid-design-system';
import { useState } from 'react';

import { getLocale } from 'src/config';

import { Pagination } from '../Pagination/Pagination';

import translations from './.vocab';

export interface ProgressBarData {
  name: string;
  percentage: number;
  total: number;
}

export interface TrendsProgressBarChartProps {
  data: ProgressBarData[];
  itemsPerPage?: number;
  color?: string;
}

export const TrendsProgressBarChart = ({
  color = '#3E8FE0',
  data,
  itemsPerPage = 5,
}: TrendsProgressBarChartProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslations(translations);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const getCurrentPageData = () => {
    if (currentPage === 1) {
      return data.slice(currentPage - 1, currentPage * itemsPerPage);
    }

    if (currentPage * itemsPerPage >= data.length) {
      return data.slice((currentPage - 1) * itemsPerPage, data.length + 1);
    }

    return data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  };

  const currentPageData = getCurrentPageData();
  const hidePagination = totalPages < 2;
  const numberFormatter = new Intl.NumberFormat(getLocale());

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        paddingBottom={hidePagination ? 'none' : 'medium'}
        width="full"
      >
        <Box width="full">
          <Stack space="medium">
            {currentPageData.map(({ name, percentage }) => (
              <Box
                display="flex"
                key={name}
                position="relative"
                flexDirection="column"
                style={{ gap: 10 }}
                width="full"
              >
                <Box position="absolute" style={{ maxWidth: '100%' }}>
                  <Text maxLines={1} size="xsmall">
                    {name}
                  </Text>
                </Box>
                <Box paddingTop="medium" width="full">
                  <ProgressBarChart color={color} progress={percentage || 1} />
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
        <Box paddingLeft="medium">
          <Stack space="medium">
            {currentPageData.map(({ name, percentage, total }) => (
              <Box
                display="flex"
                alignItems="flexEnd"
                key={name}
                style={{ height: 36, whiteSpace: 'nowrap' }} // Aligning the height with the progress bar height
              >
                <Text size="xsmall">
                  {t('N ads of total', {
                    adsCount: total,
                    adsCountText: numberFormatter.format(total),
                    percentage: percentage.toString(),
                  })}
                </Text>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
      {!hidePagination && (
        <Pagination
          currentPage={currentPage}
          onPageClick={({ page }) => setCurrentPage(page)}
          totalPages={totalPages}
          pageLimit={4}
        />
      )}
    </Box>
  );
};
