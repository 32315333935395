
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiSUQg4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWQgSUQiOiJJRCDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJTZWFyY2ggYWQgSURzIjoi4LiE4LmJ4LiZ4Lir4LiyIElEIOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiSUQg4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWQgSUQiOiJJRCDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJTZWFyY2ggYWQgSURzIjoi4LiE4LmJ4LiZ4Lir4LiyIElEIOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiU2VtdWEgSUQgaWtsYW4iLCJBZCBJRCI6IklEIGlrbGFuIiwiU2VhcmNoIGFkIElEcyI6IkNhcmkgSUQgaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiU2VtdWEgSUQgaWtsYW4iLCJBZCBJRCI6IklEIGlrbGFuIiwiU2VhcmNoIGFkIElEcyI6IkNhcmkgSUQgaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiSUQg4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWQgSUQiOiJJRCDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJTZWFyY2ggYWQgSURzIjoi4LiE4LmJ4LiZ4Lir4LiyIElEIOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiSUQg4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWQgSUQiOiJJRCDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJTZWFyY2ggYWQgSURzIjoi4LiE4LmJ4LiZ4Lir4LiyIElEIOC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIk90aGVycyI6IuC4reC4t%2BC5iOC4mSDguYYiLCJPdGhlciI6IuC4reC4t%2BC5iOC4mSDguYYifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiU2VtdWEgSUQgaWtsYW4iLCJBZCBJRCI6IklEIGlrbGFuIiwiU2VhcmNoIGFkIElEcyI6IkNhcmkgSUQgaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiU2VtdWEgSUQgaWtsYW4iLCJBZCBJRCI6IklEIGlrbGFuIiwiU2VhcmNoIGFkIElEcyI6IkNhcmkgSUQgaWtsYW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiQWxsIGFkIElEcyIsIkFkIElEIjoiQWQgSUQiLCJTZWFyY2ggYWQgSURzIjoiU2VhcmNoIGFkIElEcyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiW%2BG6rMaaxpogxIPEg8SDxIPEg8aMIMOP4biKxaFdIiwiQWQgSUQiOiJb4bqsxowgw4%2FhuIpdIiwiU2VhcmNoIGFkIElEcyI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SDxowgw4%2FhuIrFoV0iLCJPdGhlcnMiOiJbw7bDtsO24bmv4bip4bq94bq94bq9xZnFoV0iLCJPdGhlciI6IlvDtsO24bmv4bip4bq94bq9xZldIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgYWQgSURzIjoiW%2BG6rMaaxpogxIPEg8SDxIPEg8aMIMOP4biKxaFdIiwiQWQgSUQiOiJb4bqsxowgw4%2FhuIpdIiwiU2VhcmNoIGFkIElEcyI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SDxowgw4%2FhuIrFoV0iLCJPdGhlcnMiOiJbw7bDtsO24bmv4bip4bq94bq94bq9xZnFoV0iLCJPdGhlciI6IlvDtsO24bmv4bip4bq94bq9xZldIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    