import { useContext } from 'react';

import { ChartThemeContext } from 'src/seek-charts/ChartThemeContext';

export const useChartTheme = (): ChartTheme => {
  const chartThemeContext = useContext(ChartThemeContext);
  if (chartThemeContext === null) {
    throw new Error('React tree is missing a ChartThemeProvider');
  }
  return chartThemeContext;
};
