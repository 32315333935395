import { isLocal, type Environment } from 'src/config';

export const trackerEnvironmentFromEnvironment = (environment: Environment) => {
  if (isLocal()) {
    return 'dev';
  }
  if (environment === 'production') {
    return 'prod';
  }
  return 'qa';
};
