import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from 'src/App/ConfigContext';

import { recommendationsQuery } from '../queries/recommendationsQuery';
import type { RecommendationsResponse } from '../types/Recommendations';

interface RecommendationsParam {
  input: {
    jobId: string;
    locale: string;
  };
}

interface queryResponse {
  analyticsRecommendations: RecommendationsResponse;
}

export const useRecommendations = function ({
  jobId = '',
}: {
  jobId: string;
}): {
  data: RecommendationsResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} {
  const { locale } = useConfig();
  const { error, loading, data, previousData } = useQuery<
    queryResponse,
    RecommendationsParam
  >(recommendationsQuery, {
    variables: {
      input: {
        jobId,
        locale,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsRecommendations,
    isLoading: loading,
    error,
  };
};
