
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguYHguKrguJTguIfguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguJfguLXguYjguKrguKHguLHguITguKPguIfguLLguJk8U3RhdHVzPuC5gOC4quC4o%2BC5h%2BC4iOC4quC4oeC4muC4ueC4o%2BC4k%2BC5jDwvU3RhdHVzPuC5geC4peC5ieC4pyIsIk51bWJlciBvZiBhcHBzIG5vdCBjb21wbGV0ZWQiOiLguITguLjguJPguKLguLHguIfguKHguLUgPE1lZGl1bT57aW5jb21wbGV0ZUFwcHNUZXh0fTwvTWVkaXVtPiB7aW5jb21wbGV0ZUFwcHMsIHBsdXJhbCwgb25lIHvguJzguLnguYnguKrguKHguLHguITguKN9IG90aGVyIHvguJzguLnguYnguKrguKHguLHguITguKN9fSDguJfguLXguYggPE1lZGl1bT7guITguKXguLTguIE8L01lZGl1bT7guKrguKHguLHguITguKPguIfguLLguJnguYHguJXguYjguKLguLHguIfguYTguKHguYjguKrguLPguYDguKPguYfguIgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguYHguKrguJTguIfguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguJfguLXguYjguKrguKHguLHguITguKPguIfguLLguJk8U3RhdHVzPuC5gOC4quC4o%2BC5h%2BC4iOC4quC4oeC4muC4ueC4o%2BC4k%2BC5jDwvU3RhdHVzPuC5geC4peC5ieC4pyIsIk51bWJlciBvZiBhcHBzIG5vdCBjb21wbGV0ZWQiOiLguITguLjguJPguKLguLHguIfguKHguLUgPE1lZGl1bT57aW5jb21wbGV0ZUFwcHNUZXh0fTwvTWVkaXVtPiB7aW5jb21wbGV0ZUFwcHMsIHBsdXJhbCwgb25lIHvguJzguLnguYnguKrguKHguLHguITguKN9IG90aGVyIHvguJzguLnguYnguKrguKHguLHguITguKN9fSDguJfguLXguYggPE1lZGl1bT7guITguKXguLTguIE8L01lZGl1bT7guKrguKHguLHguITguKPguIfguLLguJnguYHguJXguYjguKLguLHguIfguYTguKHguYjguKrguLPguYDguKPguYfguIgifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiJKdW1sYWggaW5pIG1lbnVuanVra2FuIGp1bWxhaCBrYW5kaWRhdCB5YW5nIDxTdGF0dXM%2BbWVueWVsZXNhaWthbjwvU3RhdHVzPiBwZW5kYWZ0YXJhbi4iLCJOdW1iZXIgb2YgYXBwcyBub3QgY29tcGxldGVkIjoiQW5kYSBqdWdhIG1lbmRhcGF0a2FuIDxNZWRpdW0%2Be2luY29tcGxldGVBcHBzVGV4dH08L01lZGl1bT4ge2luY29tcGxldGVBcHBzLCBwbHVyYWwsIG9uZSB7a2FuZGlkYXR9IG90aGVyIHtrYW5kaWRhdH19IHlhbmcgPE1lZGl1bT5tdWxhaTwvTWVkaXVtPiBtZW5kYWZ0YXIsIG5hbXVuIHRpZGFrIG1lbnllbGVzYWlrYW5ueWEuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiJKdW1sYWggaW5pIG1lbnVuanVra2FuIGp1bWxhaCBrYW5kaWRhdCB5YW5nIDxTdGF0dXM%2BbWVueWVsZXNhaWthbjwvU3RhdHVzPiBwZW5kYWZ0YXJhbi4iLCJOdW1iZXIgb2YgYXBwcyBub3QgY29tcGxldGVkIjoiQW5kYSBqdWdhIG1lbmRhcGF0a2FuIDxNZWRpdW0%2Be2luY29tcGxldGVBcHBzVGV4dH08L01lZGl1bT4ge2luY29tcGxldGVBcHBzLCBwbHVyYWwsIG9uZSB7a2FuZGlkYXR9IG90aGVyIHtrYW5kaWRhdH19IHlhbmcgPE1lZGl1bT5tdWxhaTwvTWVkaXVtPiBtZW5kYWZ0YXIsIG5hbXVuIHRpZGFrIG1lbnllbGVzYWlrYW5ueWEuIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguYHguKrguJTguIfguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguJfguLXguYjguKrguKHguLHguITguKPguIfguLLguJk8U3RhdHVzPuC5gOC4quC4o%2BC5h%2BC4iOC4quC4oeC4muC4ueC4o%2BC4k%2BC5jDwvU3RhdHVzPuC5geC4peC5ieC4pyIsIk51bWJlciBvZiBhcHBzIG5vdCBjb21wbGV0ZWQiOiLguITguLjguJPguKLguLHguIfguKHguLUgPE1lZGl1bT57aW5jb21wbGV0ZUFwcHNUZXh0fTwvTWVkaXVtPiB7aW5jb21wbGV0ZUFwcHMsIHBsdXJhbCwgb25lIHvguJzguLnguYnguKrguKHguLHguITguKN9IG90aGVyIHvguJzguLnguYnguKrguKHguLHguITguKN9fSDguJfguLXguYggPE1lZGl1bT7guITguKXguLTguIE8L01lZGl1bT7guKrguKHguLHguITguKPguIfguLLguJnguYHguJXguYjguKLguLHguIfguYTguKHguYjguKrguLPguYDguKPguYfguIgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguITguLfguK3guK3guLDguYTguKMiLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiLguJXguLHguKfguYDguKXguILguJnguLXguYnguYHguKrguJTguIfguIjguLPguJnguKfguJnguJzguLnguYnguKrguKHguLHguITguKPguJfguLXguYjguKrguKHguLHguITguKPguIfguLLguJk8U3RhdHVzPuC5gOC4quC4o%2BC5h%2BC4iOC4quC4oeC4muC4ueC4o%2BC4k%2BC5jDwvU3RhdHVzPuC5geC4peC5ieC4pyIsIk51bWJlciBvZiBhcHBzIG5vdCBjb21wbGV0ZWQiOiLguITguLjguJPguKLguLHguIfguKHguLUgPE1lZGl1bT57aW5jb21wbGV0ZUFwcHNUZXh0fTwvTWVkaXVtPiB7aW5jb21wbGV0ZUFwcHMsIHBsdXJhbCwgb25lIHvguJzguLnguYnguKrguKHguLHguITguKN9IG90aGVyIHvguJzguLnguYnguKrguKHguLHguITguKN9fSDguJfguLXguYggPE1lZGl1bT7guITguKXguLTguIE8L01lZGl1bT7guKrguKHguLHguITguKPguIfguLLguJnguYHguJXguYjguKLguLHguIfguYTguKHguYjguKrguLPguYDguKPguYfguIgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiJKdW1sYWggaW5pIG1lbnVuanVra2FuIGp1bWxhaCBrYW5kaWRhdCB5YW5nIDxTdGF0dXM%2BbWVueWVsZXNhaWthbjwvU3RhdHVzPiBwZW5kYWZ0YXJhbi4iLCJOdW1iZXIgb2YgYXBwcyBub3QgY29tcGxldGVkIjoiQW5kYSBqdWdhIG1lbmRhcGF0a2FuIDxNZWRpdW0%2Be2luY29tcGxldGVBcHBzVGV4dH08L01lZGl1bT4ge2luY29tcGxldGVBcHBzLCBwbHVyYWwsIG9uZSB7a2FuZGlkYXR9IG90aGVyIHtrYW5kaWRhdH19IHlhbmcgPE1lZGl1bT5tdWxhaTwvTWVkaXVtPiBtZW5kYWZ0YXIsIG5hbXVuIHRpZGFrIG1lbnllbGVzYWlrYW5ueWEuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJBcGEgbWFrc3VkIGFuZ2thIGluaT8iLCJOdW1iZXIgcmVmbGVjdHMgYXBwcyBjb21wbGV0ZWQiOiJKdW1sYWggaW5pIG1lbnVuanVra2FuIGp1bWxhaCBrYW5kaWRhdCB5YW5nIDxTdGF0dXM%2BbWVueWVsZXNhaWthbjwvU3RhdHVzPiBwZW5kYWZ0YXJhbi4iLCJOdW1iZXIgb2YgYXBwcyBub3QgY29tcGxldGVkIjoiQW5kYSBqdWdhIG1lbmRhcGF0a2FuIDxNZWRpdW0%2Be2luY29tcGxldGVBcHBzVGV4dH08L01lZGl1bT4ge2luY29tcGxldGVBcHBzLCBwbHVyYWwsIG9uZSB7a2FuZGlkYXR9IG90aGVyIHtrYW5kaWRhdH19IHlhbmcgPE1lZGl1bT5tdWxhaTwvTWVkaXVtPiBtZW5kYWZ0YXIsIG5hbXVuIHRpZGFrIG1lbnllbGVzYWlrYW5ueWEuIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJXaGF0J3MgdGhpcyBudW1iZXI%2FIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiVGhlIG51bWJlciBoZXJlIHJlZmxlY3RzIGhvdyBtYW55IGNhbmRpZGF0ZXMgPFN0YXR1cz5jb21wbGV0ZWQ8L1N0YXR1cz4gYW4gYXBwbGljYXRpb24uIiwiTnVtYmVyIG9mIGFwcHMgbm90IGNvbXBsZXRlZCI6IllvdSBhbHNvIGhhZCA8TWVkaXVtPntpbmNvbXBsZXRlQXBwc1RleHR9PC9NZWRpdW0%2BIHtpbmNvbXBsZXRlQXBwcywgcGx1cmFsLCBvbmUge2NhbmRpZGF0ZX0gb3RoZXIge2NhbmRpZGF0ZXN9fSB3aG8gPE1lZGl1bT5zdGFydGVkPC9NZWRpdW0%2BIGFuIGFwcGxpY2F0aW9uIGJ1dCBkaWRuJ3QgY29tcGxldGUgaXQuIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJbxbThuKnEg8SDxIPhua8nxaEg4bmv4bipw6zDrMOsxaEg4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmT9dIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiW%2BG5ruG4qeG6veG6veG6vSDguIHguLXguYnHmseax5ptzILDn%2BG6veG6veG6vcWZIOG4qeG6veG6veG6vcWZ4bq94bq94bq9IMWZ4bq94bq94bq9xpLGmuG6veG6veG6vcOn4bmvxaEg4bipw7bDtsO2xbUgbcyCxIPEg8SD4LiB4Li14LmJw73DvcO9IMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg%2BG5r%2BG6veG6veG6vcWhIDxTdGF0dXM%2Bw6fDtsO2w7ZtzILGpcaa4bq94bq94bq94bmv4bq94bq94bq9xow8L1N0YXR1cz4gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8alxqXGmsOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJLl0iLCJOdW1iZXIgb2YgYXBwcyBub3QgY29tcGxldGVkIjoiW8Odw7bDtsO2x5rHmseaIMSDxIPEg8aaxaHDtsO2w7Yg4bipxIPEg8SDxowgPE1lZGl1bT57aW5jb21wbGV0ZUFwcHNUZXh0fTwvTWVkaXVtPiB7aW5jb21wbGV0ZUFwcHMscGx1cmFsLG9uZXvDp8SDxIPguIHguLXguYnGjMOsw6zGjMSDxIPhua%2Fhur3hur19IG90aGVye8OnxIPEg%2BC4geC4teC5icaMw6zDrMaMxIPEg%2BG5r%2BG6veG6vcWhfX0gxbXhuKnDtsO2w7YgPE1lZGl1bT7FoeG5r8SDxIPEg8WZ4bmv4bq94bq94bq9xow8L01lZGl1bT4gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8alxqXGmsOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMOfx5rHmsea4bmvIMaMw6zDrMOsxozguIHguLXguYkn4bmvIMOnw7bDtsO2bcyCxqXGmuG6veG6veG6veG5r%2BG6veG6veG6vSDDrMOsw6zhua8uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0J3MgdGhpcyBudW1iZXIiOiJbxbThuKnEg8SDxIPhua8nxaEg4bmv4bipw6zDrMOsxaEg4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmT9dIiwiTnVtYmVyIHJlZmxlY3RzIGFwcHMgY29tcGxldGVkIjoiW%2BG5ruG4qeG6veG6veG6vSDguIHguLXguYnHmseax5ptzILDn%2BG6veG6veG6vcWZIOG4qeG6veG6veG6vcWZ4bq94bq94bq9IMWZ4bq94bq94bq9xpLGmuG6veG6veG6vcOn4bmvxaEg4bipw7bDtsO2xbUgbcyCxIPEg8SD4LiB4Li14LmJw73DvcO9IMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg%2BG5r%2BG6veG6veG6vcWhIDxTdGF0dXM%2Bw6fDtsO2w7ZtzILGpcaa4bq94bq94bq94bmv4bq94bq94bq9xow8L1N0YXR1cz4gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8alxqXGmsOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJLl0iLCJOdW1iZXIgb2YgYXBwcyBub3QgY29tcGxldGVkIjoiW8Odw7bDtsO2x5rHmseaIMSDxIPEg8aaxaHDtsO2w7Yg4bipxIPEg8SDxowgPE1lZGl1bT57aW5jb21wbGV0ZUFwcHNUZXh0fTwvTWVkaXVtPiB7aW5jb21wbGV0ZUFwcHMscGx1cmFsLG9uZXvDp8SDxIPguIHguLXguYnGjMOsw6zGjMSDxIPhua%2Fhur3hur19IG90aGVye8OnxIPEg%2BC4geC4teC5icaMw6zDrMaMxIPEg%2BG5r%2BG6veG6vcWhfX0gxbXhuKnDtsO2w7YgPE1lZGl1bT7FoeG5r8SDxIPEg8WZ4bmv4bq94bq94bq9xow8L01lZGl1bT4gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8alxqXGmsOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMOfx5rHmsea4bmvIMaMw6zDrMOsxozguIHguLXguYkn4bmvIMOnw7bDtsO2bcyCxqXGmuG6veG6veG6veG5r%2BG6veG6veG6vSDDrMOsw6zhua8uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    