
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiLguKrguJbguLTguJXguLTguJTguYnguLLguJnguKXguYjguLLguIfguK3guLHguJvguYDguJTguJXguKXguYjguLLguKrguLjguJQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDogWCBkYXkocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguKfguLHguJl9IG9uZSB74Lin4Lix4LiZfSBvdGhlciB74Lin4Lix4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggbW9udGgocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiLguKrguKPguYnguLLguIfguYDguKHguLfguYjguK0ge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCB7ZXhwaXJlZERhdGV9IiwiRXhwaXJlcyBpbiBYIGRheSh4KSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5g%2BC4mSB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19In0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiLguKrguJbguLTguJXguLTguJTguYnguLLguJnguKXguYjguLLguIfguK3guLHguJvguYDguJTguJXguKXguYjguLLguKrguLjguJQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDogWCBkYXkocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguKfguLHguJl9IG9uZSB74Lin4Lix4LiZfSBvdGhlciB74Lin4Lix4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggbW9udGgocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiLguKrguKPguYnguLLguIfguYDguKHguLfguYjguK0ge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCB7ZXhwaXJlZERhdGV9IiwiRXhwaXJlcyBpbiBYIGRheSh4KSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5g%2BC4mSB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlcmlrdXQgYWRhbGFoIHBlbWJhcnVhbiB0ZXJha2hpciBzdGF0aXN0aWs6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUiLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJEaWJ1YXQge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IkJlcmFraGlyIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiQmVyYWtoaXIgZGFsYW0ge2RheXNSZW1haW5pbmcsIG51bWJlcn0ge2RheXNSZW1haW5pbmcsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlcmlrdXQgYWRhbGFoIHBlbWJhcnVhbiB0ZXJha2hpciBzdGF0aXN0aWs6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUiLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJEaWJ1YXQge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IkJlcmFraGlyIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiQmVyYWtoaXIgZGFsYW0ge2RheXNSZW1haW5pbmcsIG51bWJlcn0ge2RheXNSZW1haW5pbmcsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiLguKrguJbguLTguJXguLTguJTguYnguLLguJnguKXguYjguLLguIfguK3guLHguJvguYDguJTguJXguKXguYjguLLguKrguLjguJQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDogWCBkYXkocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguKfguLHguJl9IG9uZSB74Lin4Lix4LiZfSBvdGhlciB74Lin4Lix4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggbW9udGgocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiLguKrguKPguYnguLLguIfguYDguKHguLfguYjguK0ge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCB7ZXhwaXJlZERhdGV9IiwiRXhwaXJlcyBpbiBYIGRheSh4KSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5g%2BC4mSB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19In0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiLguKrguJbguLTguJXguLTguJTguYnguLLguJnguKXguYjguLLguIfguK3guLHguJvguYDguJTguJXguKXguYjguLLguKrguLjguJQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDogWCBkYXkocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguKfguLHguJl9IG9uZSB74Lin4Lix4LiZfSBvdGhlciB74Lin4Lix4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggbW9udGgocykgYWdvIjoi4Liq4LiW4Li04LiV4Li04LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lit4Lix4Lib4LmA4LiU4LiV4Lil4LmI4Liy4Liq4Li44LiUOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHvguYDguJTguLfguK3guJl9IG9uZSB74LmA4LiU4Li34Lit4LiZfSBvdGhlciB74LmA4LiU4Li34Lit4LiZfX3guJfguLXguYjguYHguKXguYnguKciLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiLguKrguKPguYnguLLguIfguYDguKHguLfguYjguK0ge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCB7ZXhwaXJlZERhdGV9IiwiRXhwaXJlcyBpbiBYIGRheSh4KSI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5g%2BC4mSB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19In0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlcmlrdXQgYWRhbGFoIHBlbWJhcnVhbiB0ZXJha2hpciBzdGF0aXN0aWs6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUiLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJEaWJ1YXQge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IkJlcmFraGlyIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiQmVyYWtoaXIgZGFsYW0ge2RheXNSZW1haW5pbmcsIG51bWJlcn0ge2RheXNSZW1haW5pbmcsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtqYW19IG9uZSB7amFtfSBvdGhlciB7amFtfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZXJpa3V0IGFkYWxhaCBwZW1iYXJ1YW4gdGVyYWtoaXIgc3RhdGlzdGlrOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtoYXJpfSBvbmUge2hhcml9IG90aGVyIHtoYXJpfX0geWFuZyBsYWx1IiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlcmlrdXQgYWRhbGFoIHBlbWJhcnVhbiB0ZXJha2hpciBzdGF0aXN0aWs6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUiLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJEaWJ1YXQge2NyZWF0ZWREYXRlfSIsIkV4cGlyZWQge2V4cGlyZWREYXRlfSI6IkJlcmFraGlyIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiQmVyYWtoaXIgZGFsYW0ge2RheXNSZW1haW5pbmcsIG51bWJlcn0ge2RheXNSZW1haW5pbmcsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIGRheShzKSBhZ28iOiJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2RheX0gb25lIHtkYXl9IG90aGVyIHtkYXlzfX0gYWdvIiwiQmVsb3cgc3RhdHMgbGFzdCB1cGRhdGVkOiBYIG1vbnRoKHMpIGFnbyI6IkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDoge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28iLCJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iOiJDcmVhdGVkIHtjcmVhdGVkRGF0ZX0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiRXhwaXJlcyBpbiB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZywgcGx1cmFsLCA9MCB7ZGF5fSBvbmUge2RheX0gb3RoZXIge2RheXN9fSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJb4bqe4bq94bq94bq9xprDtsO2w7bFtSDFoeG5r8SDxIPEg%2BG5r8WhIMaaxIPEg8SDxaHhua8gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvhuKnDtsO2x5rHmsWZfSBvbmV74bipw7bDtseax5rFmX0gb3RoZXJ74bipw7bDtseax5rFmcWhfX0gxIPEg8Sjw7bDtl0iLCJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggZGF5KHMpIGFnbyI6Ilvhup7hur3hur3hur3GmsO2w7bDtsW1IMWh4bmvxIPEg8SD4bmvxaEgxprEg8SDxIPFoeG5ryDHmseax5rGpcaMxIPEg8SD4bmv4bq94bq94bq9xow6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we8aMxIPEg8O9w719IG9uZXvGjMSDxIPDvcO9fSBvdGhlcnvGjMSDxIPDvcO9xaF9fSDEg8SDxKPDtsO2XSIsIkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDogWCBtb250aChzKSBhZ28iOiJb4bqe4bq94bq94bq9xprDtsO2w7bFtSDFoeG5r8SDxIPEg%2BG5r8WhIMaaxIPEg8SDxaHhua8gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG9uZXttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG90aGVye23MgsO2w7bDtsO24LiB4Li14LmJ4bmv4bipxaF9fSDEg8SDxKPDtsO2XSIsIkNyZWF0ZWQge2NyZWF0ZWREYXRlfSI6IlvDh8WZ4bq94bq94bq9xIPEg8SD4bmv4bq94bq94bq9xowge2NyZWF0ZWREYXRlfV0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowge2V4cGlyZWREYXRlfV0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcWhIMOsw6zDrOC4geC4teC5iSB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZyxwbHVyYWwsPTB7xozEg8SDw73DvX0gb25le8aMxIPEg8O9w719IG90aGVye8aMxIPEg8O9w73FoX19XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggaG91cihzKSBhZ28iOiJb4bqe4bq94bq94bq9xprDtsO2w7bFtSDFoeG5r8SDxIPEg%2BG5r8WhIMaaxIPEg8SDxaHhua8gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvhuKnDtsO2x5rHmsWZfSBvbmV74bipw7bDtseax5rFmX0gb3RoZXJ74bipw7bDtseax5rFmcWhfX0gxIPEg8Sjw7bDtl0iLCJCZWxvdyBzdGF0cyBsYXN0IHVwZGF0ZWQ6IFggZGF5KHMpIGFnbyI6Ilvhup7hur3hur3hur3GmsO2w7bDtsW1IMWh4bmvxIPEg8SD4bmvxaEgxprEg8SDxIPFoeG5ryDHmseax5rGpcaMxIPEg8SD4bmv4bq94bq94bq9xow6IHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we8aMxIPEg8O9w719IG9uZXvGjMSDxIPDvcO9fSBvdGhlcnvGjMSDxIPDvcO9xaF9fSDEg8SDxKPDtsO2XSIsIkJlbG93IHN0YXRzIGxhc3QgdXBkYXRlZDogWCBtb250aChzKSBhZ28iOiJb4bqe4bq94bq94bq9xprDtsO2w7bFtSDFoeG5r8SDxIPEg%2BG5r8WhIMaaxIPEg8SDxaHhua8gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMOiB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG9uZXttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG90aGVye23MgsO2w7bDtsO24LiB4Li14LmJ4bmv4bipxaF9fSDEg8SDxKPDtsO2XSIsIkNyZWF0ZWQge2NyZWF0ZWREYXRlfSI6IlvDh8WZ4bq94bq94bq9xIPEg8SD4bmv4bq94bq94bq9xowge2NyZWF0ZWREYXRlfV0iLCJFeHBpcmVkIHtleHBpcmVkRGF0ZX0iOiJbw4vqrZXGpcOsw6zDrMWZ4bq94bq94bq9xowge2V4cGlyZWREYXRlfV0iLCJFeHBpcmVzIGluIFggZGF5KHgpIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcWhIMOsw6zDrOC4geC4teC5iSB7ZGF5c1JlbWFpbmluZywgbnVtYmVyfSB7ZGF5c1JlbWFpbmluZyxwbHVyYWwsPTB7xozEg8SDw73DvX0gb25le8aMxIPEg8O9w719IG90aGVye8aMxIPEg8O9w73FoX19XSJ9!"
        )
      )
      });
  
      export { translations as default };
    