import { generateDatePresets } from 'src/App/primitives/DateRangeControl/DateRangeControl.generators';
import type { DateRangeControlValue } from 'src/App/primitives/DateRangeControl/DateRangeControl.types';

export function trackedAdRatingFromAdRating(
  adRating: 'HIGH' | 'LOW' | 'NORMAL' | null,
): 'high' | 'low' | 'normal' | null {
  switch (adRating) {
    case 'HIGH':
      return 'high';
    case 'LOW':
      return 'low';
    case 'NORMAL':
      return 'normal';
    default:
      return null;
  }
}

export function trackedDateFilterFromValue(
  value: DateRangeControlValue,
): '7 days' | '30 days' | '90 days' | 'this year' | 'last year' | 'date' {
  const datePresets = generateDatePresets();

  const newActivePreset = datePresets.find(
    (i) =>
      i.startDate.getTime() === value.startDate?.getTime() &&
      i.endDate.getTime() === value.endDate?.getTime(),
  );
  switch (newActivePreset?.label) {
    case 'Last 7 days':
      return '7 days';
    case 'Last 30 days':
      return '30 days';
    case 'Last 90 days':
      return '90 days';
    case 'This year':
      return 'this year';
    case 'Last year':
      return 'last year';
    default:
      return 'date';
  }
}
