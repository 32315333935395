
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4ouC5g%2BC4meC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSDguYHguKXguLDguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYTguKHguYjguYTguJTguYnguIvguLfguYnguK3guYHguKXguLDguYLguJ7guKrguJXguYzguIjguLLguIEgQWQgQnVkZ2V0IOC4geC5iOC4reC4meC4p%2BC4seC4meC4l%2BC4teC5iCB7bGF1bmNoRGF0ZX0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4ouC5g%2BC4meC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSDguYHguKXguLDguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYTguKHguYjguYTguJTguYnguIvguLfguYnguK3guYHguKXguLDguYLguJ7guKrguJXguYzguIjguLLguIEgQWQgQnVkZ2V0IOC4geC5iOC4reC4meC4p%2BC4seC4meC4l%2BC4teC5iCB7bGF1bmNoRGF0ZX0ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkJpYXlhIGRhbGFtIGxhcG9yYW4gaW5pIGJlbHVtIHRlcm1hc3VrIHBhamFrLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIHBhamFrIGRhbiBpa2xhbiB5YW5nIGRpYmVsaSB0YW5wYSBBZCBCdWRnZXQgc2ViZWx1bSB7bGF1bmNoRGF0ZX0uIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkJpYXlhIGRhbGFtIGxhcG9yYW4gaW5pIGJlbHVtIHRlcm1hc3VrIHBhamFrLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIHBhamFrIGRhbiBpa2xhbiB5YW5nIGRpYmVsaSB0YW5wYSBBZCBCdWRnZXQgc2ViZWx1bSB7bGF1bmNoRGF0ZX0uIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4ouC5g%2BC4meC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSDguYHguKXguLDguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYTguKHguYjguYTguJTguYnguIvguLfguYnguK3guYHguKXguLDguYLguJ7guKrguJXguYzguIjguLLguIEgQWQgQnVkZ2V0IOC4geC5iOC4reC4meC4p%2BC4seC4meC4l%2BC4teC5iCB7bGF1bmNoRGF0ZX0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IuC4hOC5iOC4suC5g%2BC4iuC5ieC4iOC5iOC4suC4ouC5g%2BC4meC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IuC4o%2BC4suC4ouC4h%2BC4suC4meC4meC4teC5ieC5hOC4oeC5iOC4o%2BC4p%2BC4oeC4oOC4suC4qeC4tSDguYHguKXguLDguJvguKPguLDguIHguLLguKjguIfguLLguJnguJfguLXguYjguYTguKHguYjguYTguJTguYnguIvguLfguYnguK3guYHguKXguLDguYLguJ7guKrguJXguYzguIjguLLguIEgQWQgQnVkZ2V0IOC4geC5iOC4reC4meC4p%2BC4seC4meC4l%2BC4teC5iCB7bGF1bmNoRGF0ZX0ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkJpYXlhIGRhbGFtIGxhcG9yYW4gaW5pIGJlbHVtIHRlcm1hc3VrIHBhamFrLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIHBhamFrIGRhbiBpa2xhbiB5YW5nIGRpYmVsaSB0YW5wYSBBZCBCdWRnZXQgc2ViZWx1bSB7bGF1bmNoRGF0ZX0uIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkJpYXlhIGRhbGFtIGxhcG9yYW4gaW5pIGJlbHVtIHRlcm1hc3VrIHBhamFrLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IkxhcG9yYW4gaW5pIHRpZGFrIG1lbmNha3VwIHBhamFrIGRhbiBpa2xhbiB5YW5nIGRpYmVsaSB0YW5wYSBBZCBCdWRnZXQgc2ViZWx1bSB7bGF1bmNoRGF0ZX0uIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IkNvc3RzIGluIHRoaXMgcmVwb3J0IGRvIG5vdCBpbmNsdWRlIHRheGVzLiIsIlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiI6IlRoaXMgcmVwb3J0IGRvZXNuJ3QgaW5jbHVkZSB0YXhlcyBhbmQgYWRzIHB1cmNoYXNlZCB3aXRob3V0IGFuIEFkIEJ1ZGdldCBiZWZvcmUge2xhdW5jaERhdGV9LiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IlvDh8O2w7bDtsWh4bmvxaEgw6zDrMOs4LiB4Li14LmJIOG5r%2BG4qcOsw6zDrMWhIMWZ4bq94bq94bq9xqXDtsO2w7bFmeG5ryDGjMO2w7bDtiDguIHguLXguYnDtsO2w7bhua8gw6zDrMOs4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9IOG5r8SDxIPEg%2BqtleG6veG6veG6vcWhLl0iLCJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgdGF4ZXMgYW5kIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtsYXVuY2hEYXRlfS4iOiJb4bmu4bipw6zDrMOsxaEgxZnhur3hur3hur3GpcO2w7bDtsWZ4bmvIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMOsw6zDrOC4geC4teC5icOnxprHmseax5rGjOG6veG6veG6vSDhua%2FEg8SDxIPqrZXhur3hur3hur3FoSDEg8SDxIPguIHguLXguYnGjCDEg8SDxIPGjMWhIMalx5rHmseaxZnDp%2BG4qcSDxIPEg8Wh4bq94bq94bq9xowgxbXDrMOsw6zhua%2FhuKnDtsO2w7bHmseax5rhua8gxIPEg8SD4LiB4Li14LmJIOG6rMaMIOG6nseax5rHmsaMxKPhur3hur3hur3hua8gw5%2Fhur3hur3hur3GksO2w7bDtsWZ4bq94bq94bq9IHtsYXVuY2hEYXRlfS5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3N0cyBpbiB0aGlzIHJlcG9ydCBkbyBub3QgaW5jbHVkZSB0YXhlcyI6IlvDh8O2w7bDtsWh4bmvxaEgw6zDrMOs4LiB4Li14LmJIOG5r%2BG4qcOsw6zDrMWhIMWZ4bq94bq94bq9xqXDtsO2w7bFmeG5ryDGjMO2w7bDtiDguIHguLXguYnDtsO2w7bhua8gw6zDrMOs4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9IOG5r8SDxIPEg%2BqtleG6veG6veG6vcWhLl0iLCJUaGlzIHJlcG9ydCBkb2Vzbid0IGluY2x1ZGUgdGF4ZXMgYW5kIGFkcyBwdXJjaGFzZWQgd2l0aG91dCBhbiBBZCBCdWRnZXQgYmVmb3JlIHtsYXVuY2hEYXRlfS4iOiJb4bmu4bipw6zDrMOsxaEgxZnhur3hur3hur3GpcO2w7bDtsWZ4bmvIMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYkn4bmvIMOsw6zDrOC4geC4teC5icOnxprHmseax5rGjOG6veG6veG6vSDhua%2FEg8SDxIPqrZXhur3hur3hur3FoSDEg8SDxIPguIHguLXguYnGjCDEg8SDxIPGjMWhIMalx5rHmseaxZnDp%2BG4qcSDxIPEg8Wh4bq94bq94bq9xowgxbXDrMOsw6zhua%2FhuKnDtsO2w7bHmseax5rhua8gxIPEg8SD4LiB4Li14LmJIOG6rMaMIOG6nseax5rHmsaMxKPhur3hur3hur3hua8gw5%2Fhur3hur3hur3GksO2w7bDtsWZ4bq94bq94bq9IHtsYXVuY2hEYXRlfS5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    