import { Box, Divider, Loader, Stack } from 'braid-design-system';
import { useEffect, useRef, useState } from 'react';

import { JobAdListItem } from 'src/components/JobAdListItem/JobAdListItem';
import { Pagination } from 'src/components/Pagination/Pagination';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { useAdUsageQueryFilters } from 'src/context/adUsageFilters';
import { useJobAdListData } from 'src/hooks/useJobAdListData';
import { useJobAdListPayload } from 'src/hooks/useJobAdListPayload';
import { useResponsiveBreakpoints } from 'src/hooks/useResponsiveBreakpoints';
import { useScrollToElement } from 'src/hooks/useScrollToHash';
import type { AdUsageSearchResultsSummaryProps } from 'src/pages/AdUsageReport/AdUsageReport';
import type { AdRatingType, AdStatus } from 'src/types/AdUsageTypes';
import type { JobAd } from 'src/types/JobAdListResponse';
import { formatDate } from 'src/utils/date';
import { trackEvent, trackWidgetView } from 'src/utils/tealiumAUREventTracker';

import {
  JobAdListContainer,
  JobAdListErrorContainer,
} from './JobAdListContainer';

import * as styles from './JobAdList.css';

interface JobAdListProps {
  updateSearchResultsSummary: (
    summary: AdUsageSearchResultsSummaryProps | undefined,
  ) => void;
}

export const JobAdList = ({ updateSearchResultsSummary }: JobAdListProps) => {
  const jobAdListRef = useRef<HTMLElement>(null);
  const clientRectTop = useScrollToElement(jobAdListRef);
  const { isMobileOrTablet } = useResponsiveBreakpoints();
  const itemsPerPage = isMobileOrTablet ? 5 : 10;
  const adUsageQueryFilters = useAdUsageQueryFilters();

  const {
    payload,
    payload: { pageNumber },
    setPayload,
  } = useJobAdListPayload(adUsageQueryFilters);
  const { data, isLoading, error } = useJobAdListData({
    ...payload,
    size: itemsPerPage,
  });

  const [hoveredGroupKey, setHoveredGroupKey] = useState<string | undefined>(
    undefined,
  );

  const onPageClick = ({ page }: { page: number }) => {
    setPayload({
      pageNumber: page,
      filters: adUsageQueryFilters,
    });
    window.scrollTo({
      top: clientRectTop,
    });
  };

  const onItemClicked = () => {
    trackEvent('job_link_pressed', {
      objectInteraction: 'ad-performance-job-interacted',
      pageNumber,
    });
  };

  const onJobAdHover = (key?: string) => {
    setHoveredGroupKey(key);
  };

  useEffect(() => {
    updateSearchResultsSummary(
      error || !data
        ? undefined
        : {
            count: data.count,
            lastUpdated: data.lastUpdated,
          },
    );
  }, [error, isLoading, data, updateSearchResultsSummary]);

  if (isLoading && !data) {
    return (
      <JobAdListContainer
        jobAdListRef={jobAdListRef}
        testId="loader"
        lastUpdatedAgo={null}
      >
        <Box display="flex" justifyContent="center" paddingTop="large">
          <Loader />
        </Box>
      </JobAdListContainer>
    );
  }

  if (error || !data) {
    trackWidgetView(
      {
        searchResultsTotal: '0',
      },
      'JobAdList',
    );

    return (
      <JobAdListErrorContainer lastUpdatedAgo={null} testId="error-state">
        <Box padding="medium">
          <WidgetError />
        </Box>
      </JobAdListErrorContainer>
    );
  }

  if (!data.count) return null;

  const { count, lastUpdated, jobAds } = data;
  const dateFormat = 'd MMM yyyy';
  const lastUpdatedAgo = lastUpdated
    ? formatDate({
        date: new Date(lastUpdated),
        dateFormat,
      })
    : null;
  const totalPages = Math.ceil(count / itemsPerPage);

  const isMultiplePages: boolean = totalPages > 1;

  trackWidgetView(
    {
      searchResultsTotal: `${count}`,
    },
    'JobAdList',
  );

  /* Sort jobAds by latest jobCreatedTimestampUTC */
  const sortedJobAds = [...(jobAds ?? [])].sort(
    (a: JobAd, b: JobAd) =>
      new Date(b.jobCreatedTimestampUTC).getTime() -
      new Date(a.jobCreatedTimestampUTC).getTime(),
  );

  return (
    <Box marginBottom="large">
      <Box
        className={isMobileOrTablet ? styles.JobAdListScrollContainer : ''}
        marginBottom="large"
      >
        <JobAdListContainer
          jobAdListRef={jobAdListRef}
          lastUpdatedAgo={lastUpdatedAgo}
        >
          <Stack space="none">
            {sortedJobAds.map((jobAd, index) => (
              <Box key={jobAd.groupKeyApac} data-testid={`job-ad-${index}`}>
                <JobAdListItem
                  adRating={jobAd.adRating as AdRatingType}
                  applications={jobAd.applications}
                  jobCreatedDate={jobAd.jobCreatedTimestampUTC}
                  jobId={jobAd.jobId}
                  jobTitle={jobAd.jobTitle}
                  groupKeyApac={jobAd.groupKeyApac}
                  hidePrice={jobAd.hidePrice}
                  isLatestGroupApac={jobAd.isLatestGroupApac}
                  isRefresh={jobAd.isRefresh}
                  isRepost={jobAd.isRepost}
                  locationLabel={jobAd.locationLabel}
                  status={jobAd.status as AdStatus}
                  totalNetAmount={jobAd.totalNetAmount}
                  otherBudgetAmount={jobAd.otherBudgetAmount}
                  userFullName={jobAd.userFullName}
                  onItemClicked={onItemClicked}
                  onJobAdHover={onJobAdHover}
                  hoveredGroupKey={hoveredGroupKey}
                  productDisplayName={jobAd.productDisplayName}
                />
                {index + 1 !== sortedJobAds.length ? <Divider /> : null}
              </Box>
            ))}
          </Stack>
        </JobAdListContainer>
      </Box>
      <Box>
        {isMultiplePages ? (
          <Pagination
            totalPages={totalPages}
            onPageClick={onPageClick}
            currentPage={pageNumber}
          />
        ) : null}
      </Box>
    </Box>
  );
};
