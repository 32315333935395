import { Box, Loader } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useConfig } from 'src/App/ConfigContext';
import { AdRating as AdRatingComponent } from 'src/components/AdRating/AdRating';
import { useAdRatingData } from 'src/hooks/useAdRatingData';
import { useAprMostRecentLastUpdatedTimestamp } from 'src/hooks/useAprMostRecentLastUpdatedTimestamp';
import { scrollTo, useScrollToHash } from 'src/hooks/useScrollToHash';
import { chartUtils } from 'src/seek-charts';
import { trackedAdRatingFromAdRating } from 'src/tracker';
import type { AdReportDataLayer } from 'src/types/TealiumDataLayer';
import {
  getTrackerData,
  trackEvent,
  trackWidgetView,
} from 'src/utils/tealiumEventTracker';

export interface AdRatingProps {
  onQueryError?: () => void;
}

export const AdRating = ({ onQueryError }: AdRatingProps) => {
  const { urlResolver } = useConfig();
  const { jobId } = useParams<{ jobId: string }>();

  const {
    data,
    isLoading: isLoadingAdRating,
    error,
  } = useAdRatingData({
    jobId: jobId!,
  });
  const { mostRecentTimestamp, isLoading: isLoadingLastUpdatedTimestamp } =
    useAprMostRecentLastUpdatedTimestamp({
      jobId: jobId!,
    });

  const isLoading = isLoadingAdRating || isLoadingLastUpdatedTimestamp;
  const similarAdsUrl = urlResolver(
    `/analytics/ad-performance/${jobId}#similar-ads`,
  );

  const candidateApplicationsUrl = urlResolver(`/candidates?jobid=${jobId}`);

  const hasQueryError = error || !data;

  useEffect(() => {
    if (!isLoading && hasQueryError && onQueryError) {
      onQueryError();
    }
  }, [isLoading, hasQueryError, onQueryError]);

  const trackSimilarAdsNavPress = () => {
    trackEvent('similar_ads_nav_pressed', {
      objectInteraction: 'apr-similar-ads-nav',
    });
  };

  /* istanbul ignore next */
  const handleSimilarAdsScroll = (e: HTMLElement) => {
    trackSimilarAdsNavPress();
    scrollTo({ element: e, offset: 110 });
  };
  useScrollToHash({ elementId: 'similar-ads', offset: 110 });

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="full"
        justifyContent="center"
        padding="medium"
      >
        <Loader />
      </Box>
    );
  }
  const getAnalyticsData: AdReportDataLayer =
    getTrackerData() as AdReportDataLayer;

  if (hasQueryError) {
    trackWidgetView(getAnalyticsData, 'AdRating');
    return null;
  }

  const { adRatingScore, jobCreatedTimestampUTC } = data;

  trackWidgetView(
    {
      ...getAnalyticsData,
      adPerformance: trackedAdRatingFromAdRating(adRatingScore),
      adDaysLive: chartUtils.dateFormatter.daysAgoFromString(
        jobCreatedTimestampUTC,
      ),
    },
    'AdRating',
  );

  return (
    <AdRatingComponent
      {...data}
      lastUpdatedTimestampUTC={mostRecentTimestamp!}
      similarAdsUrl={similarAdsUrl}
      candidateApplicationsUrl={candidateApplicationsUrl}
      onMarketPerformanceTooltipClick={trackSimilarAdsNavPress}
      onSimilarAdsScroll={handleSimilarAdsScroll}
    />
  );
};
