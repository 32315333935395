
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiLguKPguLXguYLguJ7guKrguJXguYwiLCJBbGwgcG9zdGVkIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5guC4nuC4quC4leC5jOC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2B4LmA4Lij4Liy4Lie4Li04LiI4Liy4Lij4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lin4LmI4Liy4LmA4Lib4LmH4LiZ4LiB4Liy4LijIOKAnOC4o%2BC4teC5guC4nuC4quC4leC5jFwiIOC5gOC4oeC4t%2BC5iOC4reC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4oeC4teC4iuC4t%2BC5iOC4reC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSDguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkg4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZ4Lir4Lih4Li34Lit4LiZ4LiB4Lix4Lia4Lit4Li14LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmM4Lig4Liy4Lii4LmD4LiZIDMwIOC4p%2BC4seC4meC4geC5iOC4reC4meC4q%2BC4meC5ieC4sjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0Ijoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmE4Lih4LmI4LmE4LiU4LmJ4Lij4Li14LmC4Lie4Liq4LiV4LmMIiwiUmVwb3N0ZWQgYWRzIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4Lij4Li14LmC4Lie4Liq4LiV4LmMIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiLguKPguLXguYLguJ7guKrguJXguYwiLCJBbGwgcG9zdGVkIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5guC4nuC4quC4leC5jOC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2B4LmA4Lij4Liy4Lie4Li04LiI4Liy4Lij4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lin4LmI4Liy4LmA4Lib4LmH4LiZ4LiB4Liy4LijIOKAnOC4o%2BC4teC5guC4nuC4quC4leC5jFwiIOC5gOC4oeC4t%2BC5iOC4reC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4oeC4teC4iuC4t%2BC5iOC4reC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSDguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkg4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZ4Lir4Lih4Li34Lit4LiZ4LiB4Lix4Lia4Lit4Li14LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmM4Lig4Liy4Lii4LmD4LiZIDMwIOC4p%2BC4seC4meC4geC5iOC4reC4meC4q%2BC4meC5ieC4sjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0Ijoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmE4Lih4LmI4LmE4LiU4LmJ4Lij4Li14LmC4Lie4Liq4LiV4LmMIiwiUmVwb3N0ZWQgYWRzIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4Lij4Li14LmC4Lie4Liq4LiV4LmMIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJQb3N0aW5nIHVsYW5nIiwiQWxsIHBvc3RlZCBhZHMiOiJTZW11YSBpa2xhbiB5YW5nIGRpcG9zdGluZyIsIk90aGVyIjoiTGFpbm55YSIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BS2FtaSBtZW5nYW5nZ2FwIGlrbGFuIGJhcnUgc2ViYWdhaSBcInBvc3RpbmcgdWxhbmdcIiBqaWthIG1lbWlsaWtpIGp1ZHVsLCBsb2thc2ksIGRhbiBkZXNrcmlwc2kgcGVrZXJqYWFuIHlhbmcgc2FtYSBwZXJzaXMgZGVuZ2FuIGlrbGFuIGxhaW4sIHlhbmcgZGlwb3NrYW4gZGFsYW0gMzAgaGFyaSBzZWJlbHVtbnlhLjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IjoiSWtsYW4geWFuZyBidWthbiBwb3N0aW5nYW4gdWxhbmciLCJSZXBvc3RlZCBhZHMiOiJJa2xhbiB5YW5nIGRpcG9zdGluZyB1bGFuZyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJQb3N0aW5nIHVsYW5nIiwiQWxsIHBvc3RlZCBhZHMiOiJTZW11YSBpa2xhbiB5YW5nIGRpcG9zdGluZyIsIk90aGVyIjoiTGFpbm55YSIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BS2FtaSBtZW5nYW5nZ2FwIGlrbGFuIGJhcnUgc2ViYWdhaSBcInBvc3RpbmcgdWxhbmdcIiBqaWthIG1lbWlsaWtpIGp1ZHVsLCBsb2thc2ksIGRhbiBkZXNrcmlwc2kgcGVrZXJqYWFuIHlhbmcgc2FtYSBwZXJzaXMgZGVuZ2FuIGlrbGFuIGxhaW4sIHlhbmcgZGlwb3NrYW4gZGFsYW0gMzAgaGFyaSBzZWJlbHVtbnlhLjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IjoiSWtsYW4geWFuZyBidWthbiBwb3N0aW5nYW4gdWxhbmciLCJSZXBvc3RlZCBhZHMiOiJJa2xhbiB5YW5nIGRpcG9zdGluZyB1bGFuZyJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiLguKPguLXguYLguJ7guKrguJXguYwiLCJBbGwgcG9zdGVkIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5guC4nuC4quC4leC5jOC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2B4LmA4Lij4Liy4Lie4Li04LiI4Liy4Lij4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lin4LmI4Liy4LmA4Lib4LmH4LiZ4LiB4Liy4LijIOKAnOC4o%2BC4teC5guC4nuC4quC4leC5jFwiIOC5gOC4oeC4t%2BC5iOC4reC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4oeC4teC4iuC4t%2BC5iOC4reC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSDguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkg4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZ4Lir4Lih4Li34Lit4LiZ4LiB4Lix4Lia4Lit4Li14LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmM4Lig4Liy4Lii4LmD4LiZIDMwIOC4p%2BC4seC4meC4geC5iOC4reC4meC4q%2BC4meC5ieC4sjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0Ijoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmE4Lih4LmI4LmE4LiU4LmJ4Lij4Li14LmC4Lie4Liq4LiV4LmMIiwiUmVwb3N0ZWQgYWRzIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4Lij4Li14LmC4Lie4Liq4LiV4LmMIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiLguKPguLXguYLguJ7guKrguJXguYwiLCJBbGwgcG9zdGVkIGFkcyI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4l%2BC4teC5iOC5guC4nuC4quC4leC5jOC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2B4LmA4Lij4Liy4Lie4Li04LiI4Liy4Lij4LiT4Liy4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lin4LmI4Liy4LmA4Lib4LmH4LiZ4LiB4Liy4LijIOKAnOC4o%2BC4teC5guC4nuC4quC4leC5jFwiIOC5gOC4oeC4t%2BC5iOC4reC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4oeC4teC4iuC4t%2BC5iOC4reC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSDguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkg4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZ4Lir4Lih4Li34Lit4LiZ4LiB4Lix4Lia4Lit4Li14LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmM4Lig4Liy4Lii4LmD4LiZIDMwIOC4p%2BC4seC4meC4geC5iOC4reC4meC4q%2BC4meC5ieC4sjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0Ijoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4LmE4Lih4LmI4LmE4LiU4LmJ4Lij4Li14LmC4Lie4Liq4LiV4LmMIiwiUmVwb3N0ZWQgYWRzIjoi4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Li14LmI4Lij4Li14LmC4Lie4Liq4LiV4LmMIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJQb3N0aW5nIHVsYW5nIiwiQWxsIHBvc3RlZCBhZHMiOiJTZW11YSBpa2xhbiB5YW5nIGRpcG9zdGluZyIsIk90aGVyIjoiTGFpbm55YSIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BS2FtaSBtZW5nYW5nZ2FwIGlrbGFuIGJhcnUgc2ViYWdhaSBcInBvc3RpbmcgdWxhbmdcIiBqaWthIG1lbWlsaWtpIGp1ZHVsLCBsb2thc2ksIGRhbiBkZXNrcmlwc2kgcGVrZXJqYWFuIHlhbmcgc2FtYSBwZXJzaXMgZGVuZ2FuIGlrbGFuIGxhaW4sIHlhbmcgZGlwb3NrYW4gZGFsYW0gMzAgaGFyaSBzZWJlbHVtbnlhLjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IjoiSWtsYW4geWFuZyBidWthbiBwb3N0aW5nYW4gdWxhbmciLCJSZXBvc3RlZCBhZHMiOiJJa2xhbiB5YW5nIGRpcG9zdGluZyB1bGFuZyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJQb3N0aW5nIHVsYW5nIiwiQWxsIHBvc3RlZCBhZHMiOiJTZW11YSBpa2xhbiB5YW5nIGRpcG9zdGluZyIsIk90aGVyIjoiTGFpbm55YSIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BS2FtaSBtZW5nYW5nZ2FwIGlrbGFuIGJhcnUgc2ViYWdhaSBcInBvc3RpbmcgdWxhbmdcIiBqaWthIG1lbWlsaWtpIGp1ZHVsLCBsb2thc2ksIGRhbiBkZXNrcmlwc2kgcGVrZXJqYWFuIHlhbmcgc2FtYSBwZXJzaXMgZGVuZ2FuIGlrbGFuIGxhaW4sIHlhbmcgZGlwb3NrYW4gZGFsYW0gMzAgaGFyaSBzZWJlbHVtbnlhLjwvVGV4dD4iLCJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IjoiSWtsYW4geWFuZyBidWthbiBwb3N0aW5nYW4gdWxhbmciLCJSZXBvc3RlZCBhZHMiOiJJa2xhbiB5YW5nIGRpcG9zdGluZyB1bGFuZyJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJSZXBvc3QiLCJBbGwgcG9zdGVkIGFkcyI6IkFsbCBwb3N0ZWQgYWRzIiwiT3RoZXIiOiJvdGhlciIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiPFRleHQ%2BV2UgY29uc2lkZXIgYSBuZXcgYWQgdG8gYmUgXCJyZXBvc3RlZFwiIHdoZW4gaXQgaGFzIHRoZSBleGFjdCBzYW1lIGpvYiB0aXRsZSwgbG9jYXRpb24gYW5kIGRlc2NyaXB0aW9uIGFzIGFub3RoZXIgYWQsIHBvc3RlZCB3aXRoaW4gdGhlIHByZXZpb3VzIDMwIGRheXMuPC9UZXh0PiIsIkFkcyB0aGF0IGFyZSBub3QgYSByZXBvc3QiOiJBZHMgdGhhdCBhcmUgbm90IGEgcmVwb3N0IiwiUmVwb3N0ZWQgYWRzIjoiUmVwb3N0ZWQgYWRzIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJbxZjhur3hur3hur3GpcO2w7bDtsWh4bmvXSIsIkFsbCBwb3N0ZWQgYWRzIjoiW%2BG6rMaaxpogxqXDtsO2w7bFoeG5r%2BG6veG6veG6vcaMIMSDxIPEg8aMxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiWzxUZXh0PsW04bq94bq94bq9IMOnw7bDtsO24LiB4Li14LmJxaHDrMOsw6zGjOG6veG6veG6vcWZIMSDxIPEgyDguIHguLXguYnhur3hur3hur3FtSDEg8SDxIPGjCDhua%2FDtsO2w7Ygw5%2Fhur3hur3hur0gXCLFmeG6veG6veG6vcalw7bDtsO2xaHhua%2Fhur3hur3hur3GjFwiIMW14bip4bq94bq94bq94LiB4Li14LmJIMOsw6zDrOG5ryDhuKnEg8SDxIPFoSDhua%2FhuKnhur3hur3hur0g4bq94bq94bq96q2VxIPEg8SDw6fhua8gxaHEg8SDxINtzILhur3hur3hur0gxLXDtsO2w7bDnyDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSwgxprDtsO2w7bDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDEg8SDxIPguIHguLXguYnGjCDGjOG6veG6veG6vcWhw6fFmcOsw6zDrMal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMSDxIPEg8WhIMSDxIPEg%2BC4geC4teC5icO2w7bDtuG5r%2BG4qeG6veG6veG6vcWZIMSDxIPEg8aMLCDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowgxbXDrMOsw6zhua%2FhuKnDrMOsw6zguIHguLXguYkg4bmv4bip4bq94bq94bq9IMalxZnhur3hur3hur3hub3DrMOsw6zDtsO2w7bHmseax5rFoSAzMCDGjMSDxIPEg8O9w73DvcWhLjwvVGV4dD5dIiwiQWRzIHRoYXQgYXJlIG5vdCBhIHJlcG9zdCI6IlvhuqzGjMWhIOG5r%2BG4qcSDxIPEg%2BG5ryDEg8SDxIPFmeG6veG6veG6vSDguIHguLXguYnDtsO2w7bhua8gxIPEg8SDIMWZ4bq94bq94bq9xqXDtsO2w7bFoeG5r10iLCJSZXBvc3RlZCBhZHMiOiJbxZjhur3hur3hur3GpcO2w7bDtsWh4bmv4bq94bq94bq9xowgxIPEg8SDxozFoV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXBvc3QiOiJbxZjhur3hur3hur3GpcO2w7bDtsWh4bmvXSIsIkFsbCBwb3N0ZWQgYWRzIjoiW%2BG6rMaaxpogxqXDtsO2w7bFoeG5r%2BG6veG6veG6vcaMIMSDxIPEg8aMxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIldlIGNvbnNpZGVyIGEgbmV3IGFkIHRvIGJlIHJlcG9zdGVkIjoiWzxUZXh0PsW04bq94bq94bq9IMOnw7bDtsO24LiB4Li14LmJxaHDrMOsw6zGjOG6veG6veG6vcWZIMSDxIPEgyDguIHguLXguYnhur3hur3hur3FtSDEg8SDxIPGjCDhua%2FDtsO2w7Ygw5%2Fhur3hur3hur0gXCLFmeG6veG6veG6vcalw7bDtsO2xaHhua%2Fhur3hur3hur3GjFwiIMW14bip4bq94bq94bq94LiB4Li14LmJIMOsw6zDrOG5ryDhuKnEg8SDxIPFoSDhua%2FhuKnhur3hur3hur0g4bq94bq94bq96q2VxIPEg8SDw6fhua8gxaHEg8SDxINtzILhur3hur3hur0gxLXDtsO2w7bDnyDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSwgxprDtsO2w7bDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDEg8SDxIPguIHguLXguYnGjCDGjOG6veG6veG6vcWhw6fFmcOsw6zDrMal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMSDxIPEg8WhIMSDxIPEg%2BC4geC4teC5icO2w7bDtuG5r%2BG4qeG6veG6veG6vcWZIMSDxIPEg8aMLCDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowgxbXDrMOsw6zhua%2FhuKnDrMOsw6zguIHguLXguYkg4bmv4bip4bq94bq94bq9IMalxZnhur3hur3hur3hub3DrMOsw6zDtsO2w7bHmseax5rFoSAzMCDGjMSDxIPEg8O9w73DvcWhLjwvVGV4dD5dIiwiQWRzIHRoYXQgYXJlIG5vdCBhIHJlcG9zdCI6IlvhuqzGjMWhIOG5r%2BG4qcSDxIPEg%2BG5ryDEg8SDxIPFmeG6veG6veG6vSDguIHguLXguYnDtsO2w7bhua8gxIPEg8SDIMWZ4bq94bq94bq9xqXDtsO2w7bFoeG5r10iLCJSZXBvc3RlZCBhZHMiOiJbxZjhur3hur3hur3GpcO2w7bDtsWh4bmv4bq94bq94bq9xowgxIPEg8SDxozFoV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    