import { orderBy } from 'lodash';

import type {
  DailyProductDisplayNameTrendsItem,
  MonthlyProductDisplayNameTrendsItem,
  TrendsProductDisplayNameOption,
} from 'src/types/AdTypeTrends';

const transformProductDisplayNames = (
  items: TrendsProductDisplayNameOption[],
  productDisplayNameGroups: string[],
) => {
  const baseMap: Record<string, number> = productDisplayNameGroups.reduce(
    (acc, curr) => ({ ...acc, [curr]: 0 }),
    {},
  );
  return items.reduce((accum, { key, count }) => {
    if (productDisplayNameGroups.includes(key) && key !== 'Others') {
      return {
        ...accum,
        [key]: count,
      };
    }

    return {
      ...accum,
      Others: accum.Others + count,
    };
  }, baseMap);
};

const generateProductDisplayNameDailyChartData = (
  data: DailyProductDisplayNameTrendsItem[],
  productDisplayNameGroups: string[],
) =>
  data.map(({ items, day }) => ({
    ...transformProductDisplayNames(items, productDisplayNameGroups),
    name: day,
  }));

const generateProductDisplayNameMonthlyChartData = (
  data: MonthlyProductDisplayNameTrendsItem[],
  productDisplayNameGroups: string[],
) =>
  data.map(({ items, month }) => ({
    ...transformProductDisplayNames(items, productDisplayNameGroups),
    name: month,
  }));

const translateColorMap = (
  colorMap: Record<string, string>,
  t: (value: string) => string,
) =>
  Object.keys(colorMap).reduce(
    (accum, key) => ({
      ...accum,
      [t(key)]: colorMap[key],
    }),
    {},
  );

// Regroup the total product display names data
const generateGroupedTotalProductDisplayNames = (
  data: TrendsProductDisplayNameOption[],
  productDisplayNameGroups: string[],
): TrendsProductDisplayNameOption[] => {
  const groupedProductDisplayNamesMap = transformProductDisplayNames(
    data,
    productDisplayNameGroups,
  );

  return Object.entries(groupedProductDisplayNamesMap).map(
    ([productDisplayName, count]) => ({
      key: productDisplayName,
      count,
    }),
  );
};

// This function is to return the top 5 product display names when there are more then 5
// and group the fifth and additional to Others
// When there are less than 5, return all
const getTopProductDisplayNames = (
  productDisplayNamesData: TrendsProductDisplayNameOption[],
) => {
  if (productDisplayNamesData.length > 5) {
    return [
      ...orderBy(
        [...productDisplayNamesData].filter(
          (singleData) => singleData.key !== 'Others',
        ),
        'count',
        'desc',
      )
        .slice(0, 4)
        .map((singleData) => singleData.key),
      'Others',
    ];
  }

  const groupNames = orderBy([...productDisplayNamesData], 'count', 'desc').map(
    (singleData) => singleData.key,
  );

  return groupNames.includes('Others')
    ? [...groupNames.filter((name) => name !== 'Others'), 'Others']
    : groupNames;
};

export {
  generateProductDisplayNameDailyChartData,
  generateProductDisplayNameMonthlyChartData,
  generateGroupedTotalProductDisplayNames,
  getTopProductDisplayNames,
  transformProductDisplayNames,
  translateColorMap,
};
