import { useTranslations } from '@vocab/react';
import { Loader, Stack, Tiles, Text, Strong } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { GenericTitleContainer } from 'src/components/GenericTitleContainer/GenericTitleContainer';
import { PageSectionTracker } from 'src/components/PageSectionTracker/PageSectionTracker';
import { PrimarySection } from 'src/components/PrimarySection/PrimarySection';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { useHasMarketInsights } from 'src/context/hasMarketInsights';
import { useMarketInsightsTitleData } from 'src/hooks/useMarketInsightsTitleData';
import {
  doScenarioMatch,
  mapScenarioToGroupLabelParts,
} from 'src/utils/roleTitleHelper';
import { CandidateDemand } from 'src/widgets/CandidateDemand/CandidateDemand';
import { CandidateSupply } from 'src/widgets/CandidateSupply/CandidateSupply';

import translations from './.vocab';

export const MarketInsights = () => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const { hasMarketInsights } = useHasMarketInsights();

  const {
    data: marketInsights,
    error,
    isLoading,
  } = useMarketInsightsTitleData({
    jobId: jobIdParam!,
  });

  const genericTitle = t('Market insights for similar ads');

  const renderContent = () => {
    if (isLoading && !marketInsights) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <Stack align="center" space="large">
            <Loader />
          </Stack>
        </GenericTitleContainer>
      );
    }

    if (error) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <WidgetError />
        </GenericTitleContainer>
      );
    }

    if (!marketInsights) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <Text tone="secondary">
            {t(
              "There isn't enough information based on your ad's role title and location to show market insights.",
            )}
          </Text>
        </GenericTitleContainer>
      );
    }

    const {
      normalisedRoleTitle,
      benchmarkLocationLabel,
      classificationName,
      subClassificationName,
      benchmarkScenario,
      benchmarkLocationCountry,
    } = marketInsights;

    if (
      !benchmarkScenario ||
      !doScenarioMatch(benchmarkScenario, [
        'EXACT',
        'EXACT_REGIONAL',
        'EXACT_COUNTRY',
      ])
    ) {
      return (
        <GenericTitleContainer title={genericTitle}>
          <Text tone="secondary">
            {t(
              "There isn't enough information based on your ad's role title and location to show market insights.",
            )}
          </Text>
        </GenericTitleContainer>
      );
    }

    const benchmarkGroupLabelParts = mapScenarioToGroupLabelParts({
      normalisedRoleTitle,
      benchmarkScenario,
      classificationName,
      subClassificationName,
      benchmarkLocationCountry,
    });

    const hasBenchmarkGroupLabel = benchmarkGroupLabelParts !== null;
    const hasCustomTitle = hasBenchmarkGroupLabel && benchmarkLocationLabel;
    const marketInsightsTitle =
      hasMarketInsights && hasCustomTitle
        ? t(
            `Market insights for ${benchmarkGroupLabelParts.type} benchmark ads in location`,
            {
              Strong: (text) => <Strong>{text}</Strong>,
              benchmarkGroupLabel: benchmarkGroupLabelParts.groupLabel,
              benchmarkLocationLabel: benchmarkGroupLabelParts.isRegional
                ? t('Regional {benchmarkLocationLabel}', {
                    benchmarkLocationLabel,
                  })
                : benchmarkLocationLabel,
            },
          )
        : genericTitle;

    return (
      <GenericTitleContainer title={marketInsightsTitle} weight="weak">
        <Stack space="medium">
          {hasMarketInsights || hasMarketInsights === null ? (
            <Tiles
              columns={{ mobile: 1, tablet: 1, desktop: 2, wide: 2 }}
              space="medium"
            >
              <CandidateSupply />
              <CandidateDemand />
            </Tiles>
          ) : (
            <Text tone="secondary">
              {t(
                "There isn't enough information based on your ad's role title and location to show market insights.",
              )}
            </Text>
          )}
        </Stack>
      </GenericTitleContainer>
    );
  };

  return (
    <PrimarySection data-id="ui-market-insights">
      <PageSectionTracker
        eventName="ad_performance_page_section_displayed"
        props={{
          currentPageSection: 'market insights',
        }}
      />
      {renderContent()}
    </PrimarySection>
  );
};
