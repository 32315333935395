import type { MultiSelectDropdownOption } from '@seek/multi-select-dropdown';

import type { ClassificationOption } from 'src/types/AdUsageFiltersResponse';

/**
 * @param ClassificationOption[]
 * @returns { MultiSelectDropdownOption[] }
 *
 * This function is to:
 *  1. standardise the classification tree to meet the strcture required for MultiSelectDropdownOption[]
 *  2. build leafNodeKeysMap to use as a reference when update selected classification keys in filter.
 */
export const buildClassificationData = (
  data: ClassificationOption[],
  leafNodeKeysMap: Record<string, string[]>,
) => {
  const result: MultiSelectDropdownOption[] = [...(data ?? [])]
    .sort((a, b) => (a.value <= b.value ? -1 : 1))
    .map((classification) => {
      const leafNodeKeys = classification.subClassifications?.map(
        (subClassification) => subClassification.key.toString(),
      );

      leafNodeKeysMap[classification.key.toString()] = leafNodeKeys || [];

      return {
        ...classification,
        key: classification.key.toString(),
        leafNodeKeys,
        children: [...(classification.subClassifications || [])]
          .sort((a, b) => (a.value <= b.value ? -1 : 1))
          .map((subClassification) => ({
            ...subClassification,
            key: subClassification.key.toString(),
          })),
      };
    });

  result.forEach((classification) => {
    const subClassificationKeys =
      classification.children?.map((child) => child.key) || [];
    leafNodeKeysMap[classification.key] = subClassificationKeys;
  });

  return result;
};
