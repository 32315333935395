import { useTranslations } from '@vocab/react';
import { Notice, Text } from 'braid-design-system';

import { useConfig } from 'src/App/ConfigContext';
import { LAUNCH_DATES } from 'src/config';
import { formatDate } from 'src/utils/date';

import translations from './.vocab';

export const TaxMessage = () => {
  const { t } = useTranslations(translations);
  const { site } = useConfig();

  return (
    <Notice tone="info">
      <Text>
        {LAUNCH_DATES[site]
          ? t(
              "This report doesn't include taxes and ads purchased without an Ad Budget before {launchDate}.",
              {
                launchDate: formatDate({
                  date: new Date(LAUNCH_DATES[site]),
                }),
              },
            )
          : t('Costs in this report do not include taxes')}
      </Text>
    </Notice>
  );
};
