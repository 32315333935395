
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IuC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4leC4suC4oeC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC5geC4peC4sOC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIlVzZXJzIjoi4Lic4Li54LmJ4LmD4LiK4LmJ4LiH4Liy4LiZIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC5hOC4oeC5iOC4o%2BC4sOC4muC4uOC4leC4seC4p%2BC4leC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IuC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4leC4suC4oeC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC5geC4peC4sOC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIlVzZXJzIjoi4Lic4Li54LmJ4LmD4LiK4LmJ4LiH4Liy4LiZIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC5hOC4oeC5iOC4o%2BC4sOC4muC4uOC4leC4seC4p%2BC4leC4mSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IlBlcmluY2lhbiBiZXJkYXNhcmthbiBrbGFzaWZpa2FzaSBkYW4gcGVuZ2d1bmEiLCJDbGFzc2lmaWNhdGlvbnMiOiJLbGFzaWZpa2FzaSIsIlVzZXJzIjoiUGVuZ2d1bmEiLCJVbnNwZWNpZmllZCB1c2VyIjoiUGVuZ2d1bmEgdGlkYWsgc3Blc2lmaWsifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IlBlcmluY2lhbiBiZXJkYXNhcmthbiBrbGFzaWZpa2FzaSBkYW4gcGVuZ2d1bmEiLCJDbGFzc2lmaWNhdGlvbnMiOiJLbGFzaWZpa2FzaSIsIlVzZXJzIjoiUGVuZ2d1bmEiLCJVbnNwZWNpZmllZCB1c2VyIjoiUGVuZ2d1bmEgdGlkYWsgc3Blc2lmaWsifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IuC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4leC4suC4oeC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC5geC4peC4sOC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIlVzZXJzIjoi4Lic4Li54LmJ4LmD4LiK4LmJ4LiH4Liy4LiZIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC5hOC4oeC5iOC4o%2BC4sOC4muC4uOC4leC4seC4p%2BC4leC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IuC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4leC4suC4oeC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC5geC4peC4sOC4nOC4ueC5ieC5g%2BC4iuC5iSIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIlVzZXJzIjoi4Lic4Li54LmJ4LmD4LiK4LmJ4LiH4Liy4LiZIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC5hOC4oeC5iOC4o%2BC4sOC4muC4uOC4leC4seC4p%2BC4leC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IlBlcmluY2lhbiBiZXJkYXNhcmthbiBrbGFzaWZpa2FzaSBkYW4gcGVuZ2d1bmEiLCJDbGFzc2lmaWNhdGlvbnMiOiJLbGFzaWZpa2FzaSIsIlVzZXJzIjoiUGVuZ2d1bmEiLCJVbnNwZWNpZmllZCB1c2VyIjoiUGVuZ2d1bmEgdGlkYWsgc3Blc2lmaWsifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IlBlcmluY2lhbiBiZXJkYXNhcmthbiBrbGFzaWZpa2FzaSBkYW4gcGVuZ2d1bmEiLCJDbGFzc2lmaWNhdGlvbnMiOiJLbGFzaWZpa2FzaSIsIlVzZXJzIjoiUGVuZ2d1bmEiLCJVbnNwZWNpZmllZCB1c2VyIjoiUGVuZ2d1bmEgdGlkYWsgc3Blc2lmaWsifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6IkJyZWFrZG93biBieSBjbGFzc2lmaWNhdGlvbnMgYW5kIHVzZXJzIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiVXNlcnMiOiJVc2VycyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6Ilvhup7FmeG6veG6veG6vcSDxIPEg8S3xozDtsO2w7bFteC4geC4teC5iSDDn8O9w73DvSDDp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoSDEg8SDxIPguIHguLXguYnGjCDHmseax5rFoeG6veG6veG6vcWZxaFdIiwiQ2xhc3NpZmljYXRpb25zIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIlVzZXJzIjoiW8eZxaHhur3hur3hur3FmcWhXSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJbx5nguIHguLXguYnFocal4bq94bq94bq9w6fDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMeax5rHmsWh4bq94bq94bq9xZldIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgY2xhc3NpZmljYXRpb25zIGFuZCB1c2VycyI6Ilvhup7FmeG6veG6veG6vcSDxIPEg8S3xozDtsO2w7bFteC4geC4teC5iSDDn8O9w73DvSDDp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoSDEg8SDxIPguIHguLXguYnGjCDHmseax5rFoeG6veG6veG6vcWZxaFdIiwiQ2xhc3NpZmljYXRpb25zIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIlVzZXJzIjoiW8eZxaHhur3hur3hur3FmcWhXSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJbx5nguIHguLXguYnFocal4bq94bq94bq9w6fDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMeax5rHmsWh4bq94bq94bq9xZldIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    