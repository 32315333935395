
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfSBvdGhlciB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfX0gKHtwZXJjZW50YWdlfSUg4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfSBvdGhlciB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfX0gKHtwZXJjZW50YWdlfSUg4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7aWtsYW59IG90aGVyIHtpa2xhbn19ICh7cGVyY2VudGFnZX0lIG9mIHRvdGFsKSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7aWtsYW59IG90aGVyIHtpa2xhbn19ICh7cGVyY2VudGFnZX0lIG9mIHRvdGFsKSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfSBvdGhlciB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfX0gKHtwZXJjZW50YWdlfSUg4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfSBvdGhlciB74Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZfX0gKHtwZXJjZW50YWdlfSUg4LiI4Liy4LiB4LiX4Lix4LmJ4LiH4Lir4Lih4LiUKSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7aWtsYW59IG90aGVyIHtpa2xhbn19ICh7cGVyY2VudGFnZX0lIG9mIHRvdGFsKSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7aWtsYW59IG90aGVyIHtpa2xhbn19ICh7cGVyY2VudGFnZX0lIG9mIHRvdGFsKSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6InthZHNDb3VudFRleHR9IHthZHNDb3VudCwgcGx1cmFsLCA9MSB7YWR9IG90aGVyIHthZHN9fSAoe3BlcmNlbnRhZ2V9JSBvZiB0b3RhbCkifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6Ilt7YWRzQ291bnRUZXh0fSB7YWRzQ291bnQscGx1cmFsLD0xe8SDxIPGjH0gb3RoZXJ7xIPEg8SDxozFoX19ICh7cGVyY2VudGFnZX0lIMO2w7bDtsaSIOG5r8O2w7bDtuG5r8SDxIPEg8aaKV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOIGFkcyBvZiB0b3RhbCI6Ilt7YWRzQ291bnRUZXh0fSB7YWRzQ291bnQscGx1cmFsLD0xe8SDxIPGjH0gb3RoZXJ7xIPEg8SDxozFoX19ICh7cGVyY2VudGFnZX0lIMO2w7bDtsaSIOG5r8O2w7bDtuG5r8SDxIPEg8aaKV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    