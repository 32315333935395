import { useTranslations } from '@vocab/react';
import {
  Box,
  Loader,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
  Text,
} from 'braid-design-system';
import { useContext } from 'react';

import { TrendsProgressBarChart } from 'src/components/TrendsProgressBarChart/TrendsProgressBarChart';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { IsFilterContext } from 'src/context/IsFilterContext';
import { useClassificationsAndUsersTrendsData } from 'src/hooks/useClassificationsAndUsersTrendsData';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import translations from './.vocab';

export const ClassificationsUsersContainer = ({
  children,
}: {
  children: ReactNodeNoStrings;
}) => {
  const { t } = useTranslations(translations);

  return (
    <Box
      width="full"
      boxShadow="borderNeutralLight"
      borderRadius="large"
      height="full"
    >
      <Box padding="medium">
        <Stack space="medium">
          <Text size="small" weight="strong" tone="neutral">
            {t('Breakdown by classifications and users')}
          </Text>
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

export const ClassificationsAndUsersTrends = () => {
  const { t } = useTranslations(translations);

  const { isLoading, data, error } = useClassificationsAndUsersTrendsData();
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  if (isLoading && !data) {
    return (
      <ClassificationsUsersContainer>
        <Box display="flex" justifyContent="center" data-id="loader">
          <Loader size="small" />
        </Box>
      </ClassificationsUsersContainer>
    );
  }

  if (error || !data) {
    return (
      <ClassificationsUsersContainer>
        <WidgetError />
      </ClassificationsUsersContainer>
    );
  }

  const sortedClassifications = [...(data.classifications ?? [])].sort(
    (a, b) => {
      if (b.totalAds === a.totalAds) {
        return a.classificationName <= b.classificationName ? -1 : 1;
      }

      return b.totalAds - a.totalAds;
    },
  );
  const sortedUsers = [...(data.users ?? [])].sort((a, b) => {
    if (b.totalAds === a.totalAds && a.userFullname && b.userFullname) {
      return a.userFullname <= b.userFullname ? -1 : 1;
    }

    return b.totalAds - a.totalAds;
  });

  return (
    <ClassificationsUsersContainer>
      <TabsProvider
        id="classifications-users-tab"
        onChange={(index) => {
          trackEvent('ad_trends_view_option_pressed', {
            viewOption: index === 0 ? 'classification' : 'user',
            isFilterAdPerformance,
            isFilterAdType,
            isFilterClassification,
            isFilterJobTitle,
            isFilterLocation,
            isFilterAccountHierachy,
            isFilterAdId,
            isFilterAdStatus,
            isFilterRepost,
            isFilterTipsToImproveAds,
            isFilterUser,
            isFilterBudget,
          });
        }}
      >
        <Stack space="medium">
          <Tabs label="Trends" size="small">
            <Tab>{t('Classifications')}</Tab>
            <Tab>{t('Users')}</Tab>
          </Tabs>
        </Stack>
        <TabPanels>
          <TabPanel>
            <TrendsProgressBarChart
              data={sortedClassifications.map(
                ({ classificationName, percentage, totalAds }) => ({
                  name: classificationName,
                  percentage,
                  total: totalAds,
                }),
              )}
            />
          </TabPanel>
          <TabPanel>
            <TrendsProgressBarChart
              data={sortedUsers.map(
                ({ userFullname, percentage, totalAds }) => ({
                  name: userFullname || t('Unspecified user'),
                  percentage,
                  total: totalAds,
                }),
              )}
            />
          </TabPanel>
        </TabPanels>
      </TabsProvider>
    </ClassificationsUsersContainer>
  );
};
