import { Bleed, Box, ContentBlock } from 'braid-design-system';

import { AdUsagePageFrame } from 'src/components/AdUsagePageFrame/AdUsagePageFrame';
import { PageLoadError } from 'src/components/PageLoadError/PageLoadError';
export interface AdUsageReportFallbackProps {
  children?: ReactNodeNoStrings;
}

export const AdUsageReportFallback = ({
  children = <PageLoadError />,
}: AdUsageReportFallbackProps) => (
  <AdUsagePageFrame isFallback={true}>
    <Box paddingTop="gutter" paddingBottom="large">
      <ContentBlock>
        <Bleed horizontal="large">{children}</Bleed>
      </ContentBlock>
    </Box>
  </AdUsagePageFrame>
);
