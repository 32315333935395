import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from 'src/App/ConfigContext';
import { getSiteCurrency } from 'src/utils/currency';

import { useAdUsageQueryFilters } from '../context/adUsageFilters';
import { spendQuery } from '../queries/spendQuery';
import type { AdUsageSpendResponse } from '../types/AdUsageSpendResponse';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';

interface SpendTabQueryParams {
  input: {
    filters: AdUsageQueryFilters;
    siteCurrency: string;
    isAdBudgetFilterEnabled: boolean;
  };
}

export const useSpendData = (): {
  data: AdUsageSpendResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const filters = useAdUsageQueryFilters();
  const { site } = useConfig();

  const { data, loading, error, previousData } = useQuery<
    { analyticsAdUsageSpend: AdUsageSpendResponse },
    SpendTabQueryParams
  >(spendQuery, {
    variables: {
      input: {
        filters,
        siteCurrency: getSiteCurrency(site),
        isAdBudgetFilterEnabled: true,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsAdUsageSpend,
    isLoading: loading,
    error,
  };
};
