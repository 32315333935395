
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldvcmtpbmcgdG8gZ2V0IHRoZSByZXBvcnQgYmFjayI6IuC5gOC4o%2BC4suC4geC4s%2BC4peC4seC4h%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4guC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4geC4peC4seC4muC4oeC4suC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5ieC4leC4suC4oeC4m%2BC4geC4leC4tCDguKXguK3guIfguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIiwiRmlsdGVycyI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4hyIsIkZpbHRlciBieSI6IkZpbHRlciBieSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldvcmtpbmcgdG8gZ2V0IHRoZSByZXBvcnQgYmFjayI6IuC5gOC4o%2BC4suC4geC4s%2BC4peC4seC4h%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4guC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4geC4peC4seC4muC4oeC4suC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5ieC4leC4suC4oeC4m%2BC4geC4leC4tCDguKXguK3guIfguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIiwiRmlsdGVycyI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4hyIsIkZpbHRlciBieSI6IkZpbHRlciBieSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBjb2JhIGxhZ2kgbmFudGkuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXIiLCJGaWx0ZXIgYnkiOiJGaWx0ZXIgYnkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBjb2JhIGxhZ2kgbmFudGkuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXIiLCJGaWx0ZXIgYnkiOiJGaWx0ZXIgYnkifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldvcmtpbmcgdG8gZ2V0IHRoZSByZXBvcnQgYmFjayI6IuC5gOC4o%2BC4suC4geC4s%2BC4peC4seC4h%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4guC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4geC4peC4seC4muC4oeC4suC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5ieC4leC4suC4oeC4m%2BC4geC4leC4tCDguKXguK3guIfguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIiwiRmlsdGVycyI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4hyIsIkZpbHRlciBieSI6IkZpbHRlciBieSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o%2BC4luC5guC4q%2BC4peC4lOC4o%2BC4suC4ouC4h%2BC4suC4meC5hOC4lOC5ieC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIldvcmtpbmcgdG8gZ2V0IHRoZSByZXBvcnQgYmFjayI6IuC5gOC4o%2BC4suC4geC4s%2BC4peC4seC4h%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4guC5gOC4nuC4t%2BC5iOC4reC5g%2BC4q%2BC5ieC4geC4peC4seC4muC4oeC4suC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4lOC5ieC4leC4suC4oeC4m%2BC4geC4leC4tCDguKXguK3guIfguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguKDguLLguKLguKvguKXguLHguIciLCJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IldlIGNhbuKAmXQgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93IiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIiwiRmlsdGVycyI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4hyIsIkZpbHRlciBieSI6IkZpbHRlciBieSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBjb2JhIGxhZ2kgbmFudGkuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXIiLCJGaWx0ZXIgYnkiOiJGaWx0ZXIgYnkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IlNhYXQgaW5pIGthbWkgdGlkYWsgZGFwYXQgbWVtdWF0IGxhcG9yYW4gaW5pIiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiS2FtaSBzZWRhbmcgYmVydXBheWEgbWVuZ2F0YXNpIG1hc2FsYWggaW5pLiBNdWF0IHVsYW5nIGhhbGFtYW4gYXRhdSBjb2JhIGxhZ2kgbmFudGkuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXIiLCJGaWx0ZXIgYnkiOiJGaWx0ZXIgYnkifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IldlIGNhbid0IGxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiV29ya2luZyB0byBnZXQgdGhlIHJlcG9ydCBiYWNrIjoiV2UncmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIuIiwiV2UgY2Fu4oCZdCBzaG93IHRoaXMgZGF0YSByaWdodCBub3ciOiJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyIsIldl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ildl4oCZcmUgd29ya2luZyBvbiBnZXR0aW5nIHRoaXMgYmFjayB1cC4gUmVmcmVzaCB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiIsIkZpbHRlcnMiOiJGaWx0ZXJzIiwiRmlsdGVyIGJ5IjoiRmlsdGVyIGJ5In0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IlvFtOG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua%2FhuKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxZnDrMOsw6zEo%2BG4qeG5ryDguIHguLXguYnDtsO2w7bFtV0iLCJXb3JraW5nIHRvIGdldCB0aGUgcmVwb3J0IGJhY2siOiJbxbThur3hur3hur0nxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMSj4bq94bq94bq94bmv4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw6zDrMOsxaEgw5%2FEg8SDxIPDp8S3IMeax5rHmsalLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua%2FhuKnhur3hur3hur0gxqXEg8SDxIPEo%2BG6veG6veG6vSDDtsO2w7bFmSDhua%2FFmcO9w73DvSDEg8SDxIPEo8SDxIPEg8Osw6zDrOC4geC4teC5iSDGmsSDxIPEg%2BG5r%2BG6veG6veG6vcWZLl0iLCJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IlvFtOG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5ieKAmeG5ryDFoeG4qcO2w7bDtsW1IOG5r%2BG4qcOsw6zDrMWhIMaMxIPEg8SD4bmvxIPEg8SDIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbVdIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW8W04bq94bq94bq94oCZxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMSj4bq94bq94bq94bmv4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw6zDrMOsxaEgw5%2FEg8SDxIPDp8S3IMeax5rHmsalLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua%2FhuKnhur3hur3hur0gxqXEg8SDxIPEo%2BG6veG6veG6vSDDtsO2w7bFmSDDp%2BG4qeG6veG6veG6vcOnxLcgw5%2FEg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSIsIkZpbHRlcnMiOiJbxpHDrMOsw6zGmuG5r%2BG6veG6veG6vcWZxaFdIiwiRmlsdGVyIGJ5IjoiW8aRw6zDrMOsxprhua%2Fhur3hur3hur3FmSDDn8O9w73DvV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW4ndCBsb2FkIHJlcG9ydCI6IlvFtOG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua%2FhuKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxZnDrMOsw6zEo%2BG4qeG5ryDguIHguLXguYnDtsO2w7bFtV0iLCJXb3JraW5nIHRvIGdldCB0aGUgcmVwb3J0IGJhY2siOiJbxbThur3hur3hur0nxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMSj4bq94bq94bq94bmv4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw6zDrMOsxaEgw5%2FEg8SDxIPDp8S3IMeax5rHmsalLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua%2FhuKnhur3hur3hur0gxqXEg8SDxIPEo%2BG6veG6veG6vSDDtsO2w7bFmSDhua%2FFmcO9w73DvSDEg8SDxIPEo8SDxIPEg8Osw6zDrOC4geC4teC5iSDGmsSDxIPEg%2BG5r%2BG6veG6veG6vcWZLl0iLCJXZSBjYW7igJl0IHNob3cgdGhpcyBkYXRhIHJpZ2h0IG5vdyI6IlvFtOG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5ieKAmeG5ryDFoeG4qcO2w7bDtsW1IOG5r%2BG4qcOsw6zDrMWhIMaMxIPEg8SD4bmvxIPEg8SDIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbVdIiwiV2XigJlyZSB3b3JraW5nIG9uIGdldHRpbmcgdGhpcyBiYWNrIHVwLiBSZWZyZXNoIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW8W04bq94bq94bq94oCZxZnhur3hur3hur0gxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMSj4bq94bq94bq94bmv4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bipw6zDrMOsxaEgw5%2FEg8SDxIPDp8S3IMeax5rHmsalLiDFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua%2FhuKnhur3hur3hur0gxqXEg8SDxIPEo%2BG6veG6veG6vSDDtsO2w7bFmSDDp%2BG4qeG6veG6veG6vcOnxLcgw5%2FEg8SDxIPDp8S3IMaaxIPEg8SD4bmv4bq94bq94bq9xZkuXSIsIkZpbHRlcnMiOiJbxpHDrMOsw6zGmuG5r%2BG6veG6veG6vcWZxaFdIiwiRmlsdGVyIGJ5IjoiW8aRw6zDrMOsxprhua%2Fhur3hur3hur3FmSDDn8O9w73DvV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    