import classNames from 'classnames';
import { forceSimulation, forceCollide, forceY, forceX } from 'd3-force';
import {
  scaleLinear,
  scaleTime,
  type ScaleLinear,
  type ScaleTime,
} from 'd3-scale';
import { select, type Selection } from 'd3-selection';
import { line } from 'd3-shape';

import { chartUtils } from 'src/seek-charts';

import type {
  ForceSimNodeDatum,
  MarketInsightTrendChartData,
  MarketInsightTrendChartDataEntry,
  MarketInsightTrendMarker,
  MarketInsightTrendRenderer,
  YAxisChartLabel,
} from './MarketInsightTrendChart.types';

function backgroundColorFromTone(tone: 'primary' | 'secondary') {
  switch (tone) {
    case 'primary':
      return '#FFFFFF';
    default:
      return '#898989';
  }
}

function foregroundColorFromTone(tone: 'primary' | 'secondary') {
  switch (tone) {
    case 'primary':
      return '#2765CF';
    default:
      return '#898989';
  }
}

function strokeWidthFromTone(tone: 'primary' | 'secondary') {
  switch (tone) {
    case 'primary':
      return 2;
    default:
      return 1;
  }
}

function markerSizeFromTone(tone: 'primary' | 'secondary') {
  switch (tone) {
    case 'primary':
      return 4;
    default:
      return 2.5;
  }
}

function applyPathStyle(
  s: Selection<SVGPathElement, LineDatum[], SVGGElement, unknown>,
) {
  s.attr('fill', 'none')
    .attr('stroke', (d) => foregroundColorFromTone(d[0].tone || 'secondary'))
    .attr('stroke-width', (d) => strokeWidthFromTone(d[0].tone || 'secondary'));
}

function applyMarkerStyle(
  s: Selection<
    SVGCircleElement,
    MarketInsightTrendMarker,
    SVGGElement,
    unknown
  >,
) {
  s.attr('fill', (d) => backgroundColorFromTone(d.tone))
    .attr('stroke', (d) => foregroundColorFromTone(d.tone))
    .attr('stroke-width', (d) => strokeWidthFromTone(d.tone))
    .attr('r', (d) => markerSizeFromTone(d.tone));
}

function createLayer({
  s,
}: {
  s: Selection<SVGGElement, unknown, null, undefined>;
}) {
  return s.append('g');
}

function updateYAxis({
  data,
  theme,
  yAxis,
  yScale,
}: {
  data: MarketInsightTrendChartData;
  theme: ChartTheme;
  yAxis: Selection<SVGGElement, unknown, null, undefined>;
  yScale: ScaleLinear<number, number>;
}): {
  forceSimNodes: ForceSimNodeDatum[];
  forceSimNodeElements: Selection<
    SVGGElement,
    YAxisChartLabel & ForceSimNodeDatum,
    SVGGElement,
    unknown
  >;
} {
  yAxis.selectAll('*').remove();
  const yAxisNodeData = data.yAxisLabels.map((l) => ({
    ...l,
    x: -chartUtils.grid.gUnit(2),
    y: Math.max(
      theme.tokens.typography.caption2.capHeight,
      yScale(l.value) || 0,
    ),
    pinX: -chartUtils.grid.gUnit(2),
    pinY: Math.max(
      theme.tokens.typography.caption2.capHeight,
      yScale(l.value) || 0,
    ),
  }));

  const yAxisNodes = yAxis
    .selectAll('.tick')
    .data(yAxisNodeData)
    .enter()
    .append('g')
    .attr('class', 'tick')
    .attr('transform', (d) => `translate(0,${yScale(d.value)})`);
  yAxisNodes
    .append('text')
    .text((d) => d.label)
    .attr('y', theme.tokens.typography.caption2.capHeight / 2)
    .attr('fill', (d) => foregroundColorFromTone(d.tone));

  return {
    forceSimNodes: yAxisNodeData,
    forceSimNodeElements: yAxisNodes,
  };
}

function updateXAxis({
  data,
  plotHeight,
  xAxis,
  xScale,
}: {
  data: MarketInsightTrendChartData;
  plotHeight: number;
  xAxis: Selection<SVGGElement, unknown, null, undefined>;
  xScale: ScaleTime<number, number>;
}) {
  xAxis
    .selectAll('g.xAxis .tick')
    .data(data.xAxisLabels)
    .join(
      (enter) =>
        enter
          .append('g')
          .attr('class', 'tick')
          .attr(
            'transform',
            (d) => `translate(${xScale(new Date(d.value))},${plotHeight})`,
          )
          .append('text')
          .text((d) => d.label)
          .attr('alignment-baseline', 'hanging')
          .attr('text-anchor', (_, i) => {
            if (i === 0) {
              return 'start';
            } else if (i === data.xAxisLabels.length - 1) {
              return 'end';
            }
            return 'middle';
          })
          .attr('y', chartUtils.grid.gUnit(2))
          .attr('fill', (d) => foregroundColorFromTone(d.tone)),
      (update) =>
        update.attr(
          'transform',
          (d) => `translate(${xScale(new Date(d.value))},${plotHeight})`,
        ),
      (exit) => exit.remove(),
    );
}

function updateYAxisLines({
  gridArea,
  plotHeight,
  plotWidth,
}: {
  gridArea: Selection<SVGGElement, unknown, null, undefined>;
  plotHeight: number;
  plotWidth: number;
}) {
  gridArea
    .selectAll('line.yLine')
    .data(Array(4).fill(0))
    .join(
      (enter) =>
        enter
          .append('line')
          .attr('class', 'yLine')
          .attr('x1', 0)
          .attr('x2', plotWidth)
          .attr('y1', (_, i) => (i * plotHeight) / 3)
          .attr('y2', (_, i) => (i * plotHeight) / 3)
          .attr('stroke', '#ECECEC'),
      (update) =>
        update
          .attr('x2', plotWidth)
          .attr('y1', (_, i) => (i * plotHeight) / 3)
          .attr('y2', (_, i) => (i * plotHeight) / 3),
      (exit) => exit.remove(),
    );
}

interface LineDatum extends MarketInsightTrendChartDataEntry {
  tone: 'primary' | 'secondary';
}

function updatePlotLines({
  data,
  plotArea,
  xScale,
  yScale,
}: {
  data: MarketInsightTrendChartData;
  plotArea: Selection<SVGGElement, unknown, null, undefined>;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  const lineData = data.dataSets.reduce<LineDatum[][]>(
    (acc, ds) => [
      ...acc,
      ds.dataPoints.map((d) => ({
        ...d,
        tone: ds.tone,
      })),
    ],
    [],
  );
  plotArea
    .selectAll<SVGPathElement, LineDatum[][]>('.plotLine')
    .data(lineData)
    .join(
      (enter) =>
        enter
          .append('path')
          .attr('class', 'plotLine')
          .call(applyPathStyle)
          .attr(
            'd',
            line<LineDatum>()
              .x((d) => xScale(new Date(d.date)) || 0)
              .y((d) => yScale(d.value) || 0),
          ),
      (update) =>
        update.call(applyPathStyle).attr(
          'd',
          line<LineDatum>()
            .x((d) => xScale(new Date(d.date)) || 0)
            .y((d) => yScale(d.value) || 0),
        ),
      (exit) => exit.remove(),
    );
}

function updateMarkers({
  data,
  plotArea,
  xScale,
  yScale,
}: {
  data: MarketInsightTrendChartData;
  plotArea: Selection<SVGGElement, unknown, null, undefined>;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  plotArea
    .selectAll<SVGCircleElement, MarketInsightTrendMarker[]>('circle')
    .data(data.markers)
    .join(
      (enter) =>
        enter
          .append('circle')
          .call(applyMarkerStyle)
          .attr('cx', (m) => xScale(new Date(m.date)) || 0)
          .attr('cy', (m) => yScale(m.value) || 0),
      (update) =>
        update
          .call(applyMarkerStyle)
          .attr('cx', (m) => xScale(new Date(m.date)) || 0)
          .attr('cy', (m) => yScale(m.value) || 0),
      (exit) => exit.remove(),
    );
}

export function createMarketInsightTrendRenderer({
  container,
  containerHeight,
  containerWidth,
  textStyles,
}: {
  container: HTMLDivElement | null;
  containerHeight: number;
  containerWidth: number;
  textStyles: TypographyStyles;
}): MarketInsightTrendRenderer | null {
  if (!container) {
    return null;
  }
  const plotMargin = { top: 0, right: 8, bottom: 17, left: 32 };

  let forceSimNodes: ForceSimNodeDatum[] | null = null;
  let forceSimNodeElements: Selection<
    SVGGElement,
    YAxisChartLabel & ForceSimNodeDatum,
    SVGGElement,
    unknown
  > | null = null;

  const onTick = () => {
    if (forceSimNodeElements !== null) {
      forceSimNodeElements.attr('transform', (d) => `translate(${d.x},${d.y})`);
    }
  };
  const forceSim = forceSimulation<ForceSimNodeDatum>();

  const rootSelection = select(container);
  const svgSelection = rootSelection
    .append('svg')
    .attr('width', containerWidth)
    .attr('height', containerHeight);
  const rootNode = svgSelection
    .append('g')
    .attr('transform', `translate(${plotMargin.left}, ${plotMargin.top})`);
  const gridArea = createLayer({ s: rootNode }).attr('class', 'gridArea');
  const yAxis = createLayer({ s: rootNode })
    .attr('class', classNames('yAxis', textStyles.caption2))
    .attr('text-anchor', 'end')
    .attr('font-weight', 500);
  const xAxis = createLayer({ s: rootNode }).attr(
    'class',
    classNames('xAxis', textStyles.caption2),
  );
  const plotArea = createLayer({ s: rootNode }).attr('class', 'plotArea');

  forceSim
    .force('x', forceX<ForceSimNodeDatum>((d) => d.pinX).strength(1))
    .force('y', forceY<ForceSimNodeDatum>((d) => d.pinY).strength(1))
    .force('collide', forceCollide(5).strength(1).iterations(3))
    // draw updates
    .on('tick', onTick)
    .restart();

  let plotWidth = containerWidth - plotMargin.left - plotMargin.right;
  const plotHeight = containerHeight - plotMargin.top - plotMargin.bottom;
  const update = ({
    data,
    theme,
    width,
  }: {
    data: MarketInsightTrendChartData;
    theme: ChartTheme;
    width: number;
  }) => {
    svgSelection.attr('width', width);
    plotWidth = width - plotMargin.left - plotMargin.right;

    const yScale = scaleLinear().domain(data.range).range([plotHeight, 0]);
    const xScale = scaleTime()
      .domain(data.domain.map((d) => new Date(d)))
      .range([0, plotWidth]);

    updateYAxisLines({ gridArea, plotHeight, plotWidth });
    const {
      forceSimNodes: yAxisNodes,
      forceSimNodeElements: yAxisNodeElements,
    } = updateYAxis({
      data,
      theme,
      yAxis,
      yScale,
    });
    forceSimNodes = yAxisNodes;
    forceSimNodeElements = yAxisNodeElements;
    updateXAxis({ data, plotHeight, xAxis, xScale });
    updatePlotLines({ data, plotArea, xScale, yScale });
    updateMarkers({ data, plotArea, xScale, yScale });

    forceSimNodeElements?.exit().remove();
    forceSim.nodes(forceSimNodes || []);
    forceSim.alpha(0.5).restart();
  };

  return {
    update,
  };
}
