
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvbmUge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb3RoZXIge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h3194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiWCBkYXkocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19IOC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LmA4LiU4Li34Lit4LiZfSBvbmUge%2BC5gOC4lOC4t%2BC4reC4mX0gb3RoZXIge%2BC5gOC4lOC4t%2BC4reC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvbmUge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb3RoZXIge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h3194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiWCBkYXkocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19IOC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LmA4LiU4Li34Lit4LiZfSBvbmUge%2BC5gOC4lOC4t%2BC4reC4mX0gb3RoZXIge%2BC5gOC4lOC4t%2BC4reC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlggZGF5KHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSB5YW5nIGxhbHUiLCJYIG1vbnRoKHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlggZGF5KHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSB5YW5nIGxhbHUiLCJYIG1vbnRoKHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvbmUge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb3RoZXIge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h3194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiWCBkYXkocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19IOC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LmA4LiU4Li34Lit4LiZfSBvbmUge%2BC5gOC4lOC4t%2BC4reC4mX0gb3RoZXIge%2BC5gOC4lOC4t%2BC4reC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LiK4Lix4LmI4Lin4LmC4Lih4LiHfSBvbmUge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb3RoZXIge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h3194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiWCBkYXkocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX19IOC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74LmA4LiU4Li34Lit4LiZfSBvbmUge%2BC5gOC4lOC4t%2BC4reC4mX0gb3RoZXIge%2BC5gOC4lOC4t%2BC4reC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlggZGF5KHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSB5YW5nIGxhbHUiLCJYIG1vbnRoKHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlggZGF5KHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hhcml9IG9uZSB7aGFyaX0gb3RoZXIge2hhcml9fSB5YW5nIGxhbHUiLCJYIG1vbnRoKHMpIGFnbyI6Int2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2J1bGFufSBvbmUge2J1bGFufSBvdGhlciB7YnVsYW59fSB5YW5nIGxhbHUifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aG91cn0gb25lIHtob3VyfSBvdGhlciB7aG91cnN9fSBhZ28iLCJYIGRheShzKSBhZ28iOiJ7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlggbW9udGgocykgYWdvIjoie3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7bW9udGh9IG9uZSB7bW9udGh9IG90aGVyIHttb250aHN9fSBhZ28ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoiW3t2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we%2BG4qcO2w7bHmseaxZl9IG9uZXvhuKnDtsO2x5rHmsWZfSBvdGhlcnvhuKnDtsO2x5rHmsWZxaF9fSDEg8SDxKPDtsO2XSIsIlggZGF5KHMpIGFnbyI6Ilt7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvGjMSDxIPDvcO9fSBvbmV7xozEg8SDw73DvX0gb3RoZXJ7xozEg8SDw73DvcWhfX0gxIPEg8Sjw7bDtl0iLCJYIG1vbnRoKHMpIGFnbyI6Ilt7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG9uZXttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG90aGVye23MgsO2w7bDtsO24LiB4Li14LmJ4bmv4bipxaF9fSDEg8SDxKPDtsO2XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJYIGhvdXIocykgYWdvIjoiW3t2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we%2BG4qcO2w7bHmseaxZl9IG9uZXvhuKnDtsO2x5rHmsWZfSBvdGhlcnvhuKnDtsO2x5rHmsWZxaF9fSDEg8SDxKPDtsO2XSIsIlggZGF5KHMpIGFnbyI6Ilt7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvGjMSDxIPDvcO9fSBvbmV7xozEg8SDw73DvX0gb3RoZXJ7xozEg8SDw73DvcWhfX0gxIPEg8Sjw7bDtl0iLCJYIG1vbnRoKHMpIGFnbyI6Ilt7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG9uZXttzILDtsO2w7bguIHguLXguYnhua%2FhuKl9IG90aGVye23MgsO2w7bDtsO24LiB4Li14LmJ4bmv4bipxaF9fSDEg8SDxKPDtsO2XSJ9!"
        )
      )
      });
  
      export { translations as default };
    