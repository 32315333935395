import { formatSalary } from 'src/utils/formatters/salaryFormatter';

import type { SalaryBarData, SalaryBarProps } from './SalaryBar.types';

const barSliderPadding = 20;
const indicatorSize = 22;

const getPosition = (selectedRange: number, range: number): number =>
  selectedRange === 0 ? selectedRange : (selectedRange / range) * 100;

export const calculateSelectedPosition = (
  scaleValueMax: number,
  scaleValueMin: number,
  selectedValue: number = 0,
): string => {
  const range = scaleValueMax - scaleValueMin;
  const selectedRange = selectedValue - scaleValueMin;
  const position = getPosition(selectedRange, range);

  if (position < 0) {
    return '0%';
  } else if (position < 15) {
    return `calc(${position}% + ${barSliderPadding}px)`;
  } else if (position > 85 && position <= 100) {
    return `calc(${position}% - ${barSliderPadding + indicatorSize}px)`;
  } else if (position > 100) {
    return `calc(100% - ${indicatorSize}px)`;
  }
  return `calc(${position}% - ${indicatorSize / 2}px)`;
};

export const calculateSelectedWidth = (
  scaleValueMax: number,
  scaleValueMin: number,
  selectedValueMax: number,
  selectedValueMin: number,
): string => {
  if (
    selectedValueMax === 0 ||
    selectedValueMax < scaleValueMin ||
    selectedValueMin > scaleValueMax
  ) {
    return '0%';
  }

  const range = scaleValueMax - scaleValueMin;
  const selectedRangeMin = selectedValueMin - scaleValueMin;
  const minPosition = getPosition(selectedRangeMin, range);

  const selectedRangeMax = selectedValueMax - scaleValueMin;
  const maxPosition = getPosition(selectedRangeMax, range);

  if (maxPosition > 0 && minPosition < 100) {
    const roundMinPosition = minPosition < 0 ? 0 : minPosition;
    const roundMaxPosition = maxPosition > 100 ? 100 : maxPosition;
    const width = roundMaxPosition - roundMinPosition;

    if (minPosition < 0 && maxPosition < 15) {
      const offset = barSliderPadding;
      return `calc(${width}% + ${offset}px)`;
    }

    if (minPosition < 0 && maxPosition >= 15) {
      const offset = indicatorSize / 2;
      return `calc(${width}% - ${offset}px)`;
    }

    if (minPosition === 0 && maxPosition < 15) {
      return `${width}%`;
    }

    if (minPosition === 0 && maxPosition >= 15) {
      const offset = barSliderPadding + indicatorSize / 2;
      return `calc(${width}% - ${offset}px)`;
    }

    if (minPosition < 15 && maxPosition < 15) {
      return `calc(${width}%)`;
    }

    if (minPosition < 15) {
      const offset = barSliderPadding + indicatorSize / 2;
      return `calc(${width}% - ${offset}px)`;
    }

    if (minPosition >= 85 && maxPosition < 100 && maxPosition >= 85) {
      return `calc(${width}%)`;
    }

    if (minPosition >= 15 && maxPosition <= 100 && maxPosition >= 85) {
      const offset = barSliderPadding + indicatorSize / 2;
      return `calc(${width}% - ${offset}px)`;
    }

    if (minPosition >= 85 && roundMaxPosition === 100) {
      return `calc(${width}% + ${barSliderPadding}px)`;
    }

    if (minPosition >= 15 && roundMaxPosition === 100) {
      const offset = indicatorSize / 2;
      return `calc(${width}% - ${offset}px)`;
    }

    if (minPosition >= 15) {
      return `${width}%`;
    }
  }

  return `${indicatorSize}px`;
};

export function salaryBarFormatter({
  salaryRangeMin,
  salaryRangeMax,
  suggestedRangeMin,
  suggestedRangeMax,
  suggestedAverageRangeMin,
  suggestedAverageRangeMax,
  currency,
}: SalaryBarProps): SalaryBarData {
  return {
    marker: {
      minPosition: calculateSelectedPosition(
        suggestedRangeMax,
        suggestedRangeMin,
        salaryRangeMin,
      ),
      maxPosition: calculateSelectedPosition(
        suggestedRangeMax,
        suggestedRangeMin,
        salaryRangeMax,
      ),
      width: calculateSelectedWidth(
        suggestedRangeMax,
        suggestedRangeMin,
        salaryRangeMax,
        salaryRangeMin,
      ),
    },
    xAxisLabels: {
      min: formatSalary(suggestedRangeMin, {
        currency,
      }),
      averageMin: formatSalary(suggestedAverageRangeMin, {
        currency,
      }),
      averageMax: formatSalary(suggestedAverageRangeMax, {
        currency,
      }),
      max: formatSalary(suggestedRangeMax, {
        currency,
      }),
    },
  };
}

export const exportTest = {
  getPosition,
};
