import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { ProductDisplayNameOption } from 'src/types/AdUsageFiltersResponse';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import { getOptionLabel } from '../Filters.formatters';

import translations from './.vocab';
import { sortAdTypes } from './AdTypeFilter.formatters';

export interface AdTypeFilterProps {
  productDisplayNames: ProductDisplayNameOption[];
}

export const AdTypeFilter = ({ productDisplayNames }: AdTypeFilterProps) => {
  const { t } = useTranslations(translations);
  const {
    adUsageFilters: { adTypes: selectedAdTypes },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const {
    setIsFilterAdType,
    isFilterAdPerformance,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const adTypeProductDisplayNamesMap: Record<string, string> = {
    CLASSIC: 'Classic',
    PREMIUM: 'Premium',
    BRANDED: 'Branded',
    BASIC: 'Basic',
    STANDOUT: 'StandOut',
    Classic: 'CLASSIC',
    Premium: 'PREMIUM',
    Branded: 'BRANDED',
    Basic: 'BASIC',
    StandOut: 'STANDOUT',
  };

  const onSelect = (value: string) => {
    // const typedValue = value as AdUsageJobAdType;
    const matchedInterchangeableValue = adTypeProductDisplayNamesMap?.[value];
    const newSelected: string[] = selectedAdTypes.includes(value)
      ? [
          ...selectedAdTypes.filter(
            (singleSelected) =>
              singleSelected !== value &&
              singleSelected !== matchedInterchangeableValue,
          ),
        ]
      : [...selectedAdTypes, value, matchedInterchangeableValue].filter(
          Boolean,
        );

    trackEvent('filter_pressed', {
      isFilterAdPerformance,
      isFilterAdType: newSelected.length > 0,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'ad type',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_TYPES,
      value: newSelected,
    });
    setIsFilterAdType(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_TYPES,
      value: [],
    });

  const formattedAdTypes = sortAdTypes(productDisplayNames).map((data) => ({
    ...data,
    value: data.key,
  }));

  const displayedValue = getOptionLabel(
    formattedAdTypes,
    selectedAdTypes,
    t,
    t('All ad types'),
  );

  return (
    <MultiSelectDropdown
      options={formattedAdTypes}
      label={t('Ad type')}
      selected={selectedAdTypes}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      searchPlaceholder="search"
      clearSelection={clearSelection}
      isNvlEnabled={true}
    />
  );
};
