import { useTranslations } from '@vocab/react';
import type React from 'react';

import { AdStatErrorOrLoading } from 'src/components/AdStatErrorOrLoading/AdStatErrorOrLoading';

import translations from './.vocab';

export const ClicksOnYourAdFallback = () => {
  const { t } = useTranslations(translations);
  return (
    <AdStatErrorOrLoading
      headingLabel={t('Clicks on your ad')}
      headingTooltip={t('Candidate clicked to visit your job ad')}
      isLoading={false}
    />
  );
};
