import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  IconTick,
  IconVisibility,
  List,
  Stack,
  Text,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import type { PostTypeCategory } from 'src/types/AdPerformanceTypes';
import { formatSalary } from 'src/utils/formatters/salaryFormatter';
import { trackEvent } from 'src/utils/tealiumEventTracker';

import { CircleBadge } from '../CircleBadge/CircleBadge';
import { JobSkeleton } from '../JobSkeleton/JobSkeleton';
import { RecommendationSkeleton } from '../RecommendationSkeleton/RecommendationSkeleton';
import { StandardBox } from '../StandardBox/StandardBox';

import localTranslations from './.vocab';

export interface RevealSalaryRecommendationProps {
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  currency: string;
  editAdUrl: string;
  coverTitle?: string;
  coverSubTitle?: string | ReactNode;
  direction?: 'column' | 'row';
  trackInteractionObject?: string;
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
  footerNotice?: ReactNodeNoStrings;
  onTipClicked?: () => void;
}

export const RevealSalaryRecommendation = ({
  isExpired,
  postTypeCategory,
  currency,
  editAdUrl,
  coverTitle,
  coverSubTitle,
  direction,
  footerNotice = null,
  trackInteractionObject,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
  onTipClicked,
}: RevealSalaryRecommendationProps) => {
  const { t } = useTranslations(localTranslations);

  const salaryContent = t('{fromSalary} to {toSalary}', {
    fromSalary: formatSalary(80000, {
      currency,
    }),
    toSalary: formatSalary(100000, {
      currency,
    }),
  }).toString();

  const toggleModalTrackEvent = (isOpen: boolean) => {
    if (isOpen) {
      if (onTipClicked) {
        onTipClicked();
      } else {
        trackEvent('view_tip_pressed', {
          tipName: 'reveal salary',
          objectInteraction:
            trackInteractionObject ||
            'apr-recommendations-reveal-salary-section',
        });
      }
    }
  };

  const title = t('Reveal the salary');

  const renderExampleContent = () => {
    const salary = (
      <Box data={{ testid: 'list-1' }}>
        <Text tone="neutral">{salaryContent}</Text>
      </Box>
    );

    return (
      <Stack space="medium">
        <Stack space="small">
          <Box data={{ testid: 'example-1' }}>
            <Text tone="secondary">{t('Example: Including the salary')}</Text>
          </Box>
          <List>{salary}</List>
        </Stack>
      </Stack>
    );
  };

  return (
    <RecommendationSkeleton
      titleContent={title}
      subtitleContent={t(
        'Candidates are less likely to apply for a job with a hidden salary.',
      )}
      isExpired={isExpired}
      postTypeCategory={postTypeCategory}
      editAdUrl={editAdUrl}
      onEditAdButtonClicked={onEditAdButtonClicked}
      onEditAdIndirectButtonClicked={onEditAdIndirectButtonClicked}
      coverIcon={<IconVisibility tone="info" />}
      toggleModalTrackEvent={toggleModalTrackEvent}
      dataId="ui-reveal-salary-recommendation"
      coverTitle={coverTitle}
      coverSubTitle={coverSubTitle}
      direction={direction}
    >
      <Stack space="large">
        <Columns
          collapseBelow="tablet"
          space={{ mobile: 'large', tablet: 'small' }}
        >
          <Column>
            <Stack space="large">
              <Text>
                {t('You have chosen to hide the salary range for this job ad.')}
              </Text>
              <Text>
                {t(
                  'Consider displaying the salary range for this job ad to attract more relevant candidates.',
                )}
              </Text>
            </Stack>
          </Column>
          <Column width="content">
            <Box marginTop={{ mobile: 'xxsmall', tablet: 'none' }}>
              <JobSkeleton
                activeSection="salary"
                salaryContent={salaryContent}
              />
            </Box>
          </Column>
        </Columns>
        <Stack space="medium">
          <Text weight="medium">{t('Example of salary range')}</Text>
          <StandardBox
            accessory={
              <CircleBadge tone="positive">
                <IconTick size="small" tone="positive" />
              </CircleBadge>
            }
            borderTone="positive"
            height="full"
            paddingY="medium"
            subTitleComponent={renderExampleContent()}
          />
        </Stack>
      </Stack>
      {footerNotice}
    </RecommendationSkeleton>
  );
};
