import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from 'src/App/ConfigContext';

import { myJobInformationQuery } from '../queries/myJobInformationQuery';
import type { MyJobInformationResponse } from '../types/MyJobInformationResponse';

interface MyJobInformationQueryParams {
  input: {
    jobId: string;
    locale: string;
  };
}

export const useMyJobInformationData = ({
  jobId,
}: {
  jobId: string;
}): {
  data: MyJobInformationResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const { data, loading, error, previousData } = useQuery<
    { analyticsMyJobInformation: MyJobInformationResponse },
    MyJobInformationQueryParams
  >(myJobInformationQuery, {
    variables: {
      input: {
        jobId,
        locale,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsMyJobInformation,
    isLoading: loading,
    error,
  };
};
