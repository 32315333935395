import type { CalendarFieldOutput } from '@seek/date-picker';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { createContext, useContext, useReducer } from 'react';

import { useGetAdvertiserId } from 'src/hooks/useGetAdvertiserId';
import { usePostingFilter } from 'src/hooks/usePostingFilter';

import type { AdStatus } from '../types/AdPerformanceTypes';
import type {
  FiltersAdRatingType,
  AdRepostType,
  AdTipType,
  AdUsageQueryFilters,
} from '../types/AdUsageTypes';

export const AUR_FILTERS_LOCAL_STORAGE_KEY = 'hirer-analytics-aur-filters';
export const AUR_SELECTION_BILLING_ID = 'aur-account-selection-billing-id';
interface IAdUsageFiltersContextProvider {
  children: ReactNodeNoStrings;
  lastDateForFilter: Date;
}

export interface AdUsageFiltersState {
  locationIds: string[];
  subClassificationIds: string[];
  adTypes: string[];
  jobTitles: string[];
  postingDates: CalendarFieldOutput;
  userHashes: string[];
  adRatings: FiltersAdRatingType[];
  adIds: string[];
  accountIds: string[];
  adStatuses: AdStatus[];
  adTips: AdTipType[];
  repostTypes: AdRepostType[];
  budgetIds: string[];
}

export enum actionTypes {
  RESET = 'RESET',
  UPDATE_LOCATIONS = 'UPDATE_LOCATIONS',
  UPDATE_SUB_CLASSIFICATIONS = 'UPDATE_CLASSIFICATIONS',
  UPDATE_AD_TYPES = 'UPDATE_AD_TYPES',
  UPDATE_JOB_TITLES = 'UPDATE_JOB_TITLES',
  UPDATE_POSTING_DATES = 'UPDATE_POSTING_DATES',
  UPDATE_USERS = 'UPDATE_USERS',
  UPDATE_AD_PERFORMANCE_TYPES = 'UPDATE_AD_PERFORMANCE_TYPES',
  UPDATE_AD_IDS = 'UPDATE_AD_IDS',
  UPDATE_ACCOUNT_HIERARCHY = 'UPDATE_ACCOUNT_HIERARCHY',
  UPDATE_AD_STATUS = 'UPDATE_AD_STATUS',
  UPDATE_TIPS_TO_IMPROVE_ADS = 'UPDATE_TIPS_TO_IMPROVE_ADS',
  UPDATE_REPOST = 'UPDATE_REPOST',
  UPDATE_BUDGET_IDS = 'UPDATE_BUDGET_IDS',
}

export type AdUsageFiltersAction =
  | {
      type: actionTypes.RESET;
      value: CalendarFieldOutput;
    }
  | { type: actionTypes.UPDATE_LOCATIONS; value: string[] }
  | { type: actionTypes.UPDATE_SUB_CLASSIFICATIONS; value: string[] }
  | { type: actionTypes.UPDATE_AD_TYPES; value: string[] }
  | { type: actionTypes.UPDATE_JOB_TITLES; value: string[] }
  | { type: actionTypes.UPDATE_POSTING_DATES; value: CalendarFieldOutput }
  | { type: actionTypes.UPDATE_USERS; value: string[] }
  | {
      type: actionTypes.UPDATE_AD_PERFORMANCE_TYPES;
      value: FiltersAdRatingType[];
    }
  | { type: actionTypes.UPDATE_AD_IDS; value: string[] }
  | { type: actionTypes.UPDATE_ACCOUNT_HIERARCHY; value: string[] }
  | { type: actionTypes.UPDATE_AD_STATUS; value: AdStatus[] }
  | { type: actionTypes.UPDATE_TIPS_TO_IMPROVE_ADS; value: AdTipType[] }
  | { type: actionTypes.UPDATE_REPOST; value: AdRepostType[] }
  | { type: actionTypes.UPDATE_BUDGET_IDS; value: string[] };

export interface IAdUsageFiltersContext {
  adUsageFilters: AdUsageFiltersState;
  updateAdUsageFilters: (action: AdUsageFiltersAction) => void;
}

export const defaultDatePreset = 'Last 30 days';

const getPostingDateFromLocalStorage = (advertiserId: string) => {
  const filtersDataFromLocalStorage = localStorage.getItem(
    AUR_FILTERS_LOCAL_STORAGE_KEY,
  )!;
  if (advertiserId && filtersDataFromLocalStorage) {
    const parsedFiltersDataFromLocalStorage = JSON.parse(
      filtersDataFromLocalStorage,
    )[advertiserId];
    if (parsedFiltersDataFromLocalStorage) {
      return {
        startDate: new Date(
          parsedFiltersDataFromLocalStorage.postingDates.startDate,
        ),
        endDate: endOfDay(
          new Date(parsedFiltersDataFromLocalStorage.postingDates.endDate!),
        ),
        datePreset: parsedFiltersDataFromLocalStorage.postingDates.datePreset,
      };
    }
  }
  return { startDate: null, endDate: null, datePreset: null };
};

const getDefaultFilters = (advertiserId: string): AdUsageFiltersState => {
  const filtersDataFromLocalStorage = localStorage.getItem(
    AUR_FILTERS_LOCAL_STORAGE_KEY,
  )!;
  if (advertiserId && filtersDataFromLocalStorage) {
    const parsedFiltersDataFromLocalStorage = JSON.parse(
      filtersDataFromLocalStorage,
    )[advertiserId];
    if (parsedFiltersDataFromLocalStorage) {
      return {
        ...defaultFilters,
        ...parsedFiltersDataFromLocalStorage,
        postingDates: {
          startDate: new Date(
            parsedFiltersDataFromLocalStorage.postingDates.startDate,
          ),
          endDate: endOfDay(
            new Date(parsedFiltersDataFromLocalStorage.postingDates.endDate!),
          ),
        },
      };
    }
  }
  return defaultFilters;
};

export const defaultFilters: AdUsageFiltersState = {
  subClassificationIds: [],
  locationIds: [],
  adTypes: [],
  jobTitles: [],
  postingDates: {
    datePreset: defaultDatePreset,
    startDate: startOfDay(subDays(new Date(), 29)),
    endDate: endOfDay(subDays(new Date(), 1)),
  },
  userHashes: [],
  adRatings: [],
  adIds: [],
  accountIds: [],
  adStatuses: [],
  adTips: [],
  repostTypes: [],
  budgetIds: [],
};

const reducer = (
  state: AdUsageFiltersState,
  action: AdUsageFiltersAction,
): AdUsageFiltersState => {
  switch (action.type) {
    case actionTypes.RESET:
      return {
        ...defaultFilters,
        postingDates: {
          datePreset: action.value.datePreset,
          startDate: action.value.startDate,
          endDate: action.value.endDate,
        },
      };

    case actionTypes.UPDATE_LOCATIONS:
      return {
        ...state,
        locationIds: action.value,
      };
    case actionTypes.UPDATE_SUB_CLASSIFICATIONS:
      return {
        ...state,
        subClassificationIds: action.value,
      };

    case actionTypes.UPDATE_AD_TYPES:
      return {
        ...state,
        adTypes: action.value,
      };

    case actionTypes.UPDATE_POSTING_DATES:
      return {
        ...state,
        postingDates: action.value,
      };
    case actionTypes.UPDATE_JOB_TITLES:
      return {
        ...state,
        jobTitles: action.value,
      };
    case actionTypes.UPDATE_USERS:
      return {
        ...state,
        userHashes: action.value,
      };
    case actionTypes.UPDATE_AD_PERFORMANCE_TYPES:
      return {
        ...state,
        adRatings: action.value,
      };
    case actionTypes.UPDATE_AD_IDS:
      return {
        ...state,
        adIds: action.value,
      };
    case actionTypes.UPDATE_ACCOUNT_HIERARCHY:
      return {
        ...state,
        accountIds: action.value,
      };
    case actionTypes.UPDATE_AD_STATUS:
      return {
        ...state,
        adStatuses: action.value,
      };
    case actionTypes.UPDATE_REPOST:
      return {
        ...state,
        repostTypes: action.value,
      };

    case actionTypes.UPDATE_TIPS_TO_IMPROVE_ADS:
      return {
        ...state,
        adTips: action.value,
      };

    case actionTypes.UPDATE_BUDGET_IDS:
      return {
        ...state,
        budgetIds: action.value,
      };

    default: {
      // eslint-disable-next-line no-console
      console.error(new Error(`Invalid ad usage filter action: ${action}`));
      return state;
    }
  }
};

const defaultContext = {
  adUsageFilters: defaultFilters,
  updateAdUsageFilters: () => {},
};

export const AdUsageFiltersContext =
  createContext<IAdUsageFiltersContext>(defaultContext);

export const AdUsageFiltersProvider = ({
  children,
  lastDateForFilter,
}: IAdUsageFiltersContextProvider) => {
  const advertiserId = useGetAdvertiserId();

  const { startDate, endDate, datePreset } =
    getPostingDateFromLocalStorage(advertiserId);

  const { startDateValue, endDateValue, datePresetValue } = usePostingFilter(
    datePreset,
    startDate!,
    endDate!,
    lastDateForFilter,
  );

  const context = {
    ...getDefaultFilters(advertiserId),
    postingDates: {
      ...defaultContext.adUsageFilters.postingDates,
      startDate: startDateValue,
      endDate: endDateValue,
      datePreset: datePreset ?? datePresetValue,
    },
  };

  const [adUsageFilters, updateAdUsageFilters] = useReducer(reducer, context);

  return (
    <AdUsageFiltersContext.Provider
      value={{
        adUsageFilters,
        updateAdUsageFilters,
      }}
    >
      {children}
    </AdUsageFiltersContext.Provider>
  );
};

export const useAdUsageFiltersContext = () => useContext(AdUsageFiltersContext);

export const useAdUsageQueryFilters = (): AdUsageQueryFilters => {
  const { adUsageFilters } = useContext(AdUsageFiltersContext);
  const {
    postingDates: { startDate, endDate },
    locationIds,
    subClassificationIds,
    adIds,
    accountIds,
  } = adUsageFilters;

  return {
    ...adUsageFilters,
    locationIds: locationIds.map(
      (locationId) => Number(locationId.replace('-others', '')), // this is to cover the `other locations` that are introduced in frontend.
    ),
    subClassificationIds: subClassificationIds.map((subClassificationId) =>
      Number(subClassificationId),
    ),
    adIds: adIds.map((adId) => Number(adId)),
    accountIds: accountIds.map((accountId) => Number(accountId)),
    postingDates: {
      startDate: startDate.toISOString(),
      endDate: endOfDay(endDate!).toISOString(),
    },
  };
};

export { reducer as adUsageFiltersReducer };
