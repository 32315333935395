import { useTranslations } from '@vocab/react';
import { Badge, Box, Disclosure, Stack } from 'braid-design-system';
import { useState } from 'react';

import './MoreFilters.css';

import { useAdUsageFiltersContext } from 'src/context/adUsageFilters';
import type {
  RepostOption,
  AdStatusOption,
  AccountHierarchyOption,
  BudgetOption,
} from 'src/types/AdUsageFiltersResponse';

import { BudgetFilter } from '../BudgetFilter/BudgetFilter';

import translations from './.vocab';
import { AccountHierarchyFilter } from './AccountHierarchyFilter/AccountHierarchyFilter';
import { AdIdFilter } from './AdIdFilter/AdIdFilter';
import { AdStatusFilter } from './AdStatusFilter/AdStatusFilter';
import { RepostFilter } from './RepostFilter/RepostFilter';

export interface MoreFiltersProps {
  adIds: string[];
  accountHierarchies: AccountHierarchyOption[];
  adStatus: AdStatusOption[];
  reposts: RepostOption[];
  budgets: BudgetOption[];
  isChildAccount: boolean;
}

export const MoreFilters = ({
  adIds,
  accountHierarchies,
  adStatus,
  reposts,
  budgets,
  isChildAccount,
}: MoreFiltersProps) => {
  const { t } = useTranslations(translations);
  const [openMoreFilters, setOpenMoreFilters] = useState(false);
  const {
    adUsageFilters: {
      accountIds,
      adIds: selectedAdIds,
      adStatuses: selectedAdStatus,
      repostTypes: selectedReposts,
    },
  } = useAdUsageFiltersContext();

  const moreFilters = [
    accountIds,
    selectedAdIds,
    selectedAdStatus,
    selectedReposts,
  ];
  const appliedFiltersCount = moreFilters.filter((item) => item.length).length;

  const toggleMoreFiltersDialog = () => setOpenMoreFilters(!openMoreFilters);

  return (
    <Box position="relative">
      <Disclosure
        expanded={openMoreFilters}
        expandLabel={t('More filters')}
        collapseLabel={t('Less filters')}
        id="ad-usage-more-filter"
        onToggle={toggleMoreFiltersDialog}
        weight="weak"
      >
        <Box>
          <Stack space="medium">
            <AccountHierarchyFilter
              accountHierarchies={accountHierarchies}
              isChildAccount={isChildAccount}
            />
            <BudgetFilter budgets={budgets} />
            <AdIdFilter adIds={adIds} enableLazyLoading={true} />
            <AdStatusFilter adStatus={adStatus} />
            <RepostFilter reposts={reposts} />
          </Stack>
        </Box>
      </Disclosure>
      {appliedFiltersCount > 0 ? (
        <Box position="absolute" top={0} right={0}>
          <Badge tone="info">{appliedFiltersCount.toString()}</Badge>
        </Box>
      ) : null}
    </Box>
  );
};
