import type { ProductDisplayNameOption } from 'src/types/AdUsageFiltersResponse';

export const sortAdTypes = (
  adTypes: ProductDisplayNameOption[],
): ProductDisplayNameOption[] => {
  // setting up ad types order as per business rule
  // to ensure correct order
  // first Basic; then Classic; then Branded; then StandOut; then Premium last
  const orderMapping: string[] = [
    'Basic',
    'Classic',
    'Branded',
    'StandOut',
    'Premium',
    'Advanced',
    'Performance',
    'Branded Advanced',
    'Branded Performance',
  ];
  const newArr: ProductDisplayNameOption[] = [];
  adTypes.forEach((type) => {
    const index = orderMapping.indexOf(type.key);
    newArr[index] = type;
  });

  return newArr;
};
