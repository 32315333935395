
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiLguJzguLnguYnguYPguIrguYnguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiU2VhcmNoIHVzZXJzIjoi4LiE4LmJ4LiZ4Lir4Liy4Lic4Li54LmJ4LmD4LiK4LmJIiwiVXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4h%2BC4suC4mSIsIlVuc3BlY2lmaWVkIHVzZXIiOiLguJzguLnguYnguYPguIrguYnguJfguLXguYjguYTguKHguYjguKPguLDguJrguLjguJXguLHguKfguJXguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiLguJzguLnguYnguYPguIrguYnguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiU2VhcmNoIHVzZXJzIjoi4LiE4LmJ4LiZ4Lir4Liy4Lic4Li54LmJ4LmD4LiK4LmJIiwiVXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4h%2BC4suC4mSIsIlVuc3BlY2lmaWVkIHVzZXIiOiLguJzguLnguYnguYPguIrguYnguJfguLXguYjguYTguKHguYjguKPguLDguJrguLjguJXguLHguKfguJXguJkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJTZW11YSBwZW5nZ3VuYSIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJTZWFyY2ggdXNlcnMiOiJDYXJpIHBlbmdndW5hIiwiVXNlciI6IlBlbmdndW5hIiwiVW5zcGVjaWZpZWQgdXNlciI6IlBlbmdndW5hIHRpZGFrIHNwZXNpZmlrIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJTZW11YSBwZW5nZ3VuYSIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJTZWFyY2ggdXNlcnMiOiJDYXJpIHBlbmdndW5hIiwiVXNlciI6IlBlbmdndW5hIiwiVW5zcGVjaWZpZWQgdXNlciI6IlBlbmdndW5hIHRpZGFrIHNwZXNpZmlrIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiLguJzguLnguYnguYPguIrguYnguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiU2VhcmNoIHVzZXJzIjoi4LiE4LmJ4LiZ4Lir4Liy4Lic4Li54LmJ4LmD4LiK4LmJIiwiVXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4h%2BC4suC4mSIsIlVuc3BlY2lmaWVkIHVzZXIiOiLguJzguLnguYnguYPguIrguYnguJfguLXguYjguYTguKHguYjguKPguLDguJrguLjguJXguLHguKfguJXguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiLguJzguLnguYnguYPguIrguYnguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJPdGhlcnMiOiLguK3guLfguYjguJkg4LmGIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiU2VhcmNoIHVzZXJzIjoi4LiE4LmJ4LiZ4Lir4Liy4Lic4Li54LmJ4LmD4LiK4LmJIiwiVXNlciI6IuC4nOC4ueC5ieC5g%2BC4iuC5ieC4h%2BC4suC4mSIsIlVuc3BlY2lmaWVkIHVzZXIiOiLguJzguLnguYnguYPguIrguYnguJfguLXguYjguYTguKHguYjguKPguLDguJrguLjguJXguLHguKfguJXguJkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJTZW11YSBwZW5nZ3VuYSIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJTZWFyY2ggdXNlcnMiOiJDYXJpIHBlbmdndW5hIiwiVXNlciI6IlBlbmdndW5hIiwiVW5zcGVjaWZpZWQgdXNlciI6IlBlbmdndW5hIHRpZGFrIHNwZXNpZmlrIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJTZW11YSBwZW5nZ3VuYSIsIk90aGVycyI6IkxhaW5ueWEiLCJPdGhlciI6IkxhaW5ueWEiLCJTZWFyY2ggdXNlcnMiOiJDYXJpIHBlbmdndW5hIiwiVXNlciI6IlBlbmdndW5hIiwiVW5zcGVjaWZpZWQgdXNlciI6IlBlbmdndW5hIHRpZGFrIHNwZXNpZmlrIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJBbGwgdXNlcnMiLCJPdGhlcnMiOiJvdGhlcnMiLCJPdGhlciI6Im90aGVyIiwiU2VhcmNoIHVzZXJzIjoiU2VhcmNoIHVzZXJzIiwiVXNlciI6IlVzZXIiLCJVbnNwZWNpZmllZCB1c2VyIjoiVW5zcGVjaWZpZWQgdXNlciJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJb4bqsxprGmiDHmseax5rFoeG6veG6veG6vcWZxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIlNlYXJjaCB1c2VycyI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgx5rHmseaxaHhur3hur3hur3FmcWhXSIsIlVzZXIiOiJbx5nFoeG6veG6veG6vcWZXSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJbx5nguIHguLXguYnFocal4bq94bq94bq9w6fDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMeax5rHmsWh4bq94bq94bq9xZldIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgdXNlcnMiOiJb4bqsxprGmiDHmseax5rFoeG6veG6veG6vcWZxaFdIiwiT3RoZXJzIjoiW8O2w7bDtuG5r%2BG4qeG6veG6veG6vcWZxaFdIiwiT3RoZXIiOiJbw7bDtuG5r%2BG4qeG6veG6vcWZXSIsIlNlYXJjaCB1c2VycyI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgx5rHmseaxaHhur3hur3hur3FmcWhXSIsIlVzZXIiOiJbx5nFoeG6veG6veG6vcWZXSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJbx5nguIHguLXguYnFocal4bq94bq94bq9w6fDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMeax5rHmsWh4bq94bq94bq9xZldIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    