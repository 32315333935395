import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AdStatCard } from 'src/components/AdStatCard/AdStatCard';
import { AdStatErrorOrLoading } from 'src/components/AdStatErrorOrLoading/AdStatErrorOrLoading';
import { Medium } from 'src/components/Medium/Medium';
import { useViewsInJobSearchData } from 'src/hooks/useViewsInJobSearchData';
import { formatPercentage } from 'src/utils/percentage/percentageFormatter';

import translations from './.vocab';

export interface ViewsInJobSearchProps {
  updateHasAdRating?: (hasAdRating: boolean) => void;
}

export const ViewsInJobSearch = ({
  updateHasAdRating,
}: ViewsInJobSearchProps) => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const { data, error, isLoading } = useViewsInJobSearchData({
    jobId: jobIdParam!,
  });

  useEffect(() => {
    if (data?.hasAdRating) {
      updateHasAdRating?.(true);
    }
  }, [updateHasAdRating, data]);

  if ((isLoading && !data) || error || !data) {
    return (
      <AdStatErrorOrLoading
        headingLabel={t('Views in job search')}
        headingTooltip={t('Your ad snippet appeared in the SEEK job search')}
        isLoading={isLoading}
      />
    );
  }

  const {
    adRatingConfidence,
    clickConversionInPercentage,
    mobileViewsInPercentage,
    similarAdsAverage,
    views,
    hasAdRating,
  } = data;

  return (
    <AdStatCard
      adRatingConfidence={adRatingConfidence}
      conversionLabel={t('{percentage} view-to-click rate', {
        percentage: formatPercentage(clickConversionInPercentage, 1),
        Medium: (text) => <Medium>{text}</Medium>,
      })}
      title={t('Views in job search')}
      mobileInPercentage={mobileViewsInPercentage}
      similarAdsAverage={similarAdsAverage}
      comparisonToSimilarAds={
        similarAdsAverage !== null && similarAdsAverage > 0
          ? (views - similarAdsAverage) / similarAdsAverage
          : null
      }
      statCount={views}
      displayMarketPerformance={hasAdRating}
      tooltip={{
        id: 'views-in-job-search-tooltip',
        title: t('Views in job search'),
        contents: (
          <Stack space="large">
            <Text size="standard">
              {t('Your ad snippet appeared in the SEEK job search')}
            </Text>
            <Text size="standard">{t('View-to-click rate formula')}</Text>
          </Stack>
        ),
      }}
    />
  );
};
