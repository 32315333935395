import useMediaQuery from './useMediaQuery';

export const minAdUsageReportWidth = 1400;

// Since Braid doesn't export this, we are obtaining these values from the Braid documentation
// Ref: https://seek-oss.github.io/braid-design-system/css/breakpoints
const braidBreakpoints = {
  mobile: 0,
  tablet: 740,
  desktop: 992,
  wide: 1200,
};

/**
 * This hook is used to determine whether a screen is for desktop but smaller than 1400px as the experience defers for users with smaller screens
 */
export const useIsDesktopAndBelowMinAdUsageReportWidth = () =>
  useMediaQuery(
    `(min-width: ${braidBreakpoints.desktop}) and (max-width: ${minAdUsageReportWidth}px)`,
  );

/**
 * This hook is used to determine whether a screen is smaller than 1400px as the experience defers for users with smaller screens
 */
export const useIsBelowMinAdUsageReportWidth = () =>
  useMediaQuery(`(max-width: ${minAdUsageReportWidth}px)`);
