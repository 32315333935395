const isHotjarLoaded = () =>
  typeof window !== 'undefined' && typeof window.hj === 'function';

const triggerHotjar = (trigger: string): void => {
  if (typeof window.hj !== 'undefined') {
    window.hj('trigger', `${trigger}`);
  }
};

const getHotJarScriptTag = (hotjarId: number) =>
  isHotjarLoaded()
    ? ''
    : `<!-- Hotjar Tracking Code for Hirer Insights -->
      <script>
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${hotjarId},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      </script>`;

export { getHotJarScriptTag, triggerHotjar };
