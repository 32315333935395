
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiLguKrguJbguLLguJnguLDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJBbGwgYWQgc3RhdHVzIjoi4Liq4LiW4Liy4LiZ4Liw4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQUNUSVZFIjoi4Lit4Lit4LiZ4LmE4Lil4LiZ4LmMIiwiRVhQSVJFRCI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiLguKrguJbguLLguJnguLDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJBbGwgYWQgc3RhdHVzIjoi4Liq4LiW4Liy4LiZ4Liw4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQUNUSVZFIjoi4Lit4Lit4LiZ4LmE4Lil4LiZ4LmMIiwiRVhQSVJFRCI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJTdGF0dXMgaWtsYW4iLCJBbGwgYWQgc3RhdHVzIjoiU2VtdWEgc3RhdHVzIGlrbGFuIiwiT3RoZXIiOiJMYWlubnlhIiwiQUNUSVZFIjoiSWtsYW4gQWt0aWYiLCJFWFBJUkVEIjoiS2FkYWx1YXJzYSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJTdGF0dXMgaWtsYW4iLCJBbGwgYWQgc3RhdHVzIjoiU2VtdWEgc3RhdHVzIGlrbGFuIiwiT3RoZXIiOiJMYWlubnlhIiwiQUNUSVZFIjoiSWtsYW4gQWt0aWYiLCJFWFBJUkVEIjoiS2FkYWx1YXJzYSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiLguKrguJbguLLguJnguLDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJBbGwgYWQgc3RhdHVzIjoi4Liq4LiW4Liy4LiZ4Liw4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQUNUSVZFIjoi4Lit4Lit4LiZ4LmE4Lil4LiZ4LmMIiwiRVhQSVJFRCI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiLguKrguJbguLLguJnguLDguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJBbGwgYWQgc3RhdHVzIjoi4Liq4LiW4Liy4LiZ4Liw4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXIiOiLguK3guLfguYjguJkg4LmGIiwiQUNUSVZFIjoi4Lit4Lit4LiZ4LmE4Lil4LiZ4LmMIiwiRVhQSVJFRCI6IuC4q%2BC4oeC4lOC4reC4suC4ouC4uCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJTdGF0dXMgaWtsYW4iLCJBbGwgYWQgc3RhdHVzIjoiU2VtdWEgc3RhdHVzIGlrbGFuIiwiT3RoZXIiOiJMYWlubnlhIiwiQUNUSVZFIjoiSWtsYW4gQWt0aWYiLCJFWFBJUkVEIjoiS2FkYWx1YXJzYSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJTdGF0dXMgaWtsYW4iLCJBbGwgYWQgc3RhdHVzIjoiU2VtdWEgc3RhdHVzIGlrbGFuIiwiT3RoZXIiOiJMYWlubnlhIiwiQUNUSVZFIjoiSWtsYW4gQWt0aWYiLCJFWFBJUkVEIjoiS2FkYWx1YXJzYSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJBZCBzdGF0dXMiLCJBbGwgYWQgc3RhdHVzIjoiQWxsIGFkIHN0YXR1cyIsIk90aGVyIjoib3RoZXIiLCJBQ1RJVkUiOiJMaXZlIiwiRVhQSVJFRCI6IkV4cGlyZWQifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJb4bqsxowgxaHhua%2FEg8SDxIPhua%2FHmseax5rFoV0iLCJBbGwgYWQgc3RhdHVzIjoiW%2BG6rMaaxpogxIPEg8SDxowgxaHhua%2FEg8SDxIPhua%2FHmseax5rFoV0iLCJPdGhlciI6IlvDtsO24bmv4bip4bq94bq9xZldIiwiQUNUSVZFIjoiW8WBw6zDrOG5veG6veG6vV0iLCJFWFBJUkVEIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCBzdGF0dXMiOiJb4bqsxowgxaHhua%2FEg8SDxIPhua%2FHmseax5rFoV0iLCJBbGwgYWQgc3RhdHVzIjoiW%2BG6rMaaxpogxIPEg8SDxowgxaHhua%2FEg8SDxIPhua%2FHmseax5rFoV0iLCJPdGhlciI6IlvDtsO24bmv4bip4bq94bq9xZldIiwiQUNUSVZFIjoiW8WBw6zDrOG5veG6veG6vV0iLCJFWFBJUkVEIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMXSJ9!"
        )
      )
      });
  
      export { translations as default };
    