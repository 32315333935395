import { startOfDay, subDays } from 'date-fns';
import { useCallback } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { LAUNCH_DATES } from 'src/config';

import { useSiteLaunchDateChecker } from './useSiteLaunchDateChecker';

export const usePostingFilter = (
  datePreset: string,
  startDate: Date,
  endDate: Date,
  lastDateForFilter: Date,
) => {
  const { site } = useConfig();
  const { hasLaunchedAndWithin, getMinPostingDate } = useSiteLaunchDateChecker(
    site,
    lastDateForFilter!,
  );

  const firstDate = getMinPostingDate();

  const isLastDateForFilterChanged = useCallback(
    (lastDateUpdated: Date, lastDateFromStorage: Date) =>
      lastDateUpdated.getFullYear() === lastDateFromStorage.getFullYear() &&
      lastDateUpdated.getMonth() === lastDateFromStorage.getMonth() &&
      lastDateUpdated.getDate() === lastDateFromStorage.getDate(),
    [],
  );

  const isStartDateValidFromCache = useCallback(
    (start: Date, first: Date) =>
      start ? start.getTime() >= first.getTime() : false,
    [],
  );

  const getStartDateByDatePreset = useCallback(
    (preset: string, lastDateUpdated: Date) => {
      switch (preset) {
        case 'Last 7 days':
          return startOfDay(subDays(lastDateUpdated, 6));
        case 'Last 30 days':
          return startOfDay(subDays(lastDateUpdated, 29));
        case 'Last 90 days':
          return startOfDay(subDays(lastDateUpdated, 89));
        case 'Last 365 days':
          return startOfDay(subDays(lastDateUpdated, 364));
        default:
          return startOfDay(subDays(lastDateUpdated, 29));
      }
    },
    [],
  );

  let startDateValue = null;
  let endDateValue = null;
  let datePresetValue = null;

  if (datePreset && !isLastDateForFilterChanged(lastDateForFilter, endDate!)) {
    startDateValue = getStartDateByDatePreset(datePreset, lastDateForFilter);
    endDateValue = lastDateForFilter;
  } else {
    if (startDate && isStartDateValidFromCache(startDate, firstDate)) {
      startDateValue = startDate;
    } else if (hasLaunchedAndWithin(30)) {
      startDateValue = new Date(LAUNCH_DATES[site]);
    } else {
      datePresetValue = 'Last 30 days';
      startDateValue = startOfDay(subDays(lastDateForFilter!, 29));
    }

    if (endDate && isStartDateValidFromCache(startDate, firstDate)) {
      endDateValue = endDate;
    } else {
      endDateValue = lastDateForFilter;
    }
  }

  return { startDateValue, endDateValue, datePresetValue };
};
