
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiRWRpdCBhZCI6IuC5geC4geC5ieC5hOC4guC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlZpZXcgam9iIHN1bW1hcnkgKHNlYXJjaCBwYWdlKSI6IuC4lOC4ueC4muC4l%2BC4quC4o%2BC4uOC4m%2BC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4h%2BC4suC4mSAo4Lir4LiZ4LmJ4Liy4LiE4LmJ4LiZ4Lir4LiyKSIsIlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIjoi4LmA4Lie4Li34LmI4Lit4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJIOC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4peC4seC4muC5hOC4m%2BC4ouC4seC4h%2BC4o%2BC4sOC4muC4muC4l%2BC4teC5iOC4hOC4uOC4k%2BC5g%2BC4iuC5ieC5g%2BC4meC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4meC4teC5ieC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyDguYHguKXguLDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguIHguYnguYTguILguYTguJTguYkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiRWRpdCBhZCI6IuC5geC4geC5ieC5hOC4guC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlZpZXcgam9iIHN1bW1hcnkgKHNlYXJjaCBwYWdlKSI6IuC4lOC4ueC4muC4l%2BC4quC4o%2BC4uOC4m%2BC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4h%2BC4suC4mSAo4Lir4LiZ4LmJ4Liy4LiE4LmJ4LiZ4Lir4LiyKSIsIlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIjoi4LmA4Lie4Li34LmI4Lit4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJIOC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4peC4seC4muC5hOC4m%2BC4ouC4seC4h%2BC4o%2BC4sOC4muC4muC4l%2BC4teC5iOC4hOC4uOC4k%2BC5g%2BC4iuC5ieC5g%2BC4meC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4meC4teC5ieC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyDguYHguKXguLDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguIHguYnguYTguILguYTguJTguYkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiTWVsaWhhdCBpa2xhbiIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiTWVsaWhhdCByaW5na2FzYW4gaWtsYW4iLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlVudHVrIG1lbmdlZGl0bnlhLCBBbmRhIGhhcnVzIGtlbWJhbGkga2Ugc2lzdGVtIHRlbXBhdCBBbmRhIG1lbXBvc3RpbmcgbG93b25nYW4ga2VyamEuIiwiVGhpcyBhZCBoYXMgZXhwaXJlZCBhbmQgY2FuIG5vIGxvbmdlciBiZSBlZGl0ZWQuIjoiTWFzYSBiZXJsYWt1IGxvd29uZ2FuIGtlcmphIGluaSBoYWJpcyBkYW4gdGlkYWsgZGFwYXQgZGllZGl0IGxhZ2kuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiTWVsaWhhdCBpa2xhbiIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiTWVsaWhhdCByaW5na2FzYW4gaWtsYW4iLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlVudHVrIG1lbmdlZGl0bnlhLCBBbmRhIGhhcnVzIGtlbWJhbGkga2Ugc2lzdGVtIHRlbXBhdCBBbmRhIG1lbXBvc3RpbmcgbG93b25nYW4ga2VyamEuIiwiVGhpcyBhZCBoYXMgZXhwaXJlZCBhbmQgY2FuIG5vIGxvbmdlciBiZSBlZGl0ZWQuIjoiTWFzYSBiZXJsYWt1IGxvd29uZ2FuIGtlcmphIGluaSBoYWJpcyBkYW4gdGlkYWsgZGFwYXQgZGllZGl0IGxhZ2kuIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiRWRpdCBhZCI6IuC5geC4geC5ieC5hOC4guC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlZpZXcgam9iIHN1bW1hcnkgKHNlYXJjaCBwYWdlKSI6IuC4lOC4ueC4muC4l%2BC4quC4o%2BC4uOC4m%2BC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4h%2BC4suC4mSAo4Lir4LiZ4LmJ4Liy4LiE4LmJ4LiZ4Lir4LiyKSIsIlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIjoi4LmA4Lie4Li34LmI4Lit4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJIOC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4peC4seC4muC5hOC4m%2BC4ouC4seC4h%2BC4o%2BC4sOC4muC4muC4l%2BC4teC5iOC4hOC4uOC4k%2BC5g%2BC4iuC5ieC5g%2BC4meC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4meC4teC5ieC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyDguYHguKXguLDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguIHguYnguYTguILguYTguJTguYkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiRWRpdCBhZCI6IuC5geC4geC5ieC5hOC4guC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlZpZXcgam9iIHN1bW1hcnkgKHNlYXJjaCBwYWdlKSI6IuC4lOC4ueC4muC4l%2BC4quC4o%2BC4uOC4m%2BC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4h%2BC4suC4mSAo4Lir4LiZ4LmJ4Liy4LiE4LmJ4LiZ4Lir4LiyKSIsIlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIjoi4LmA4Lie4Li34LmI4Lit4LmB4LiB4LmJ4LmE4LiC4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJIOC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4peC4seC4muC5hOC4m%2BC4ouC4seC4h%2BC4o%2BC4sOC4muC4muC4l%2BC4teC5iOC4hOC4uOC4k%2BC5g%2BC4iuC5ieC5g%2BC4meC4geC4suC4o%2BC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC4meC4teC5ieC4q%2BC4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyDguYHguKXguLDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguIHguYnguYTguILguYTguJTguYkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiTWVsaWhhdCBpa2xhbiIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiTWVsaWhhdCByaW5na2FzYW4gaWtsYW4iLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlVudHVrIG1lbmdlZGl0bnlhLCBBbmRhIGhhcnVzIGtlbWJhbGkga2Ugc2lzdGVtIHRlbXBhdCBBbmRhIG1lbXBvc3RpbmcgbG93b25nYW4ga2VyamEuIiwiVGhpcyBhZCBoYXMgZXhwaXJlZCBhbmQgY2FuIG5vIGxvbmdlciBiZSBlZGl0ZWQuIjoiTWFzYSBiZXJsYWt1IGxvd29uZ2FuIGtlcmphIGluaSBoYWJpcyBkYW4gdGlkYWsgZGFwYXQgZGllZGl0IGxhZ2kuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiTWVsaWhhdCBpa2xhbiIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiTWVsaWhhdCByaW5na2FzYW4gaWtsYW4iLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlVudHVrIG1lbmdlZGl0bnlhLCBBbmRhIGhhcnVzIGtlbWJhbGkga2Ugc2lzdGVtIHRlbXBhdCBBbmRhIG1lbXBvc3RpbmcgbG93b25nYW4ga2VyamEuIiwiVGhpcyBhZCBoYXMgZXhwaXJlZCBhbmQgY2FuIG5vIGxvbmdlciBiZSBlZGl0ZWQuIjoiTWFzYSBiZXJsYWt1IGxvd29uZ2FuIGtlcmphIGluaSBoYWJpcyBkYW4gdGlkYWsgZGFwYXQgZGllZGl0IGxhZ2kuIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiVmlldyBhZCIsIkVkaXQgYWQiOiJFZGl0IGFkIiwiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIjoiVmlldyBqb2Igc3VtbWFyeSAoc2VhcmNoIHBhZ2UpIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiIsIlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiI6IlRoaXMgYWQgaGFzIGV4cGlyZWQgYW5kIGNhbiBubyBsb25nZXIgYmUgZWRpdGVkLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiW%2BG5vMOsw6zhur3hur3FtSDEg8SDxoxdIiwiRWRpdCBhZCI6IlvDi8aMw6zDrMOs4bmvIMSDxIPEg8aMXSIsIlZpZXcgam9iIHN1bW1hcnkgKHNlYXJjaCBwYWdlKSI6IlvhubzDrMOsw6zhur3hur3hur3FtSDEtcO2w7bDtsOfIMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gKMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qSDGpcSDxIPEg8Sj4bq94bq94bq9KV0iLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6Ilvhua7DtsO2w7Yg4bq94bq94bq9xozDrMOsw6zhua8g4bmv4bipw6zDrMOsxaEgxIPEg8SDxowsIMO9w73DvcO2w7bDtseax5rHmifGmsaaIOC4geC4teC5ieG6veG6veG6veG6veG6veG6vcaMIOG5r8O2w7bDtiDEo8O2w7bDtiDDn8SDxIPEg8OnxLcg4bmvw7bDtsO2IOG5r%2BG4qeG6veG6veG6vSDFocO9w73DvcWh4bmv4bq94bq94bq9bcyCIMO9w73DvcO2w7bDtseax5rHmiDHmseax5rFoeG6veG6veG6vcaMIOG5r8O2w7bDtiDGpcO2w7bDtsWh4bmvIMOsw6zDrOG5ry5dIiwiVGhpcyBhZCBoYXMgZXhwaXJlZCBhbmQgY2FuIG5vIGxvbmdlciBiZSBlZGl0ZWQuIjoiW%2BG5ruG4qcOsw6zDrMWhIMSDxIPEg8aMIOG4qcSDxIPEg8WhIOG6veG6veG6veqtlcalw6zDrMOsxZnhur3hur3hur3GjCDEg8SDxIPguIHguLXguYnGjCDDp8SDxIPEg%2BC4geC4teC5iSDguIHguLXguYnDtsO2w7YgxprDtsO2w7bguIHguLXguYnEo%2BG6veG6veG6vcWZIMOf4bq94bq94bq9IOG6veG6veG6vcaMw6zDrMOs4bmv4bq94bq94bq9xowuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFkIjoiW%2BG5vMOsw6zhur3hur3FtSDEg8SDxoxdIiwiRWRpdCBhZCI6IlvDi8aMw6zDrMOs4bmvIMSDxIPEg8aMXSIsIlZpZXcgam9iIHN1bW1hcnkgKHNlYXJjaCBwYWdlKSI6IlvhubzDrMOsw6zhur3hur3hur3FtSDEtcO2w7bDtsOfIMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gKMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qSDGpcSDxIPEg8Sj4bq94bq94bq9KV0iLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6Ilvhua7DtsO2w7Yg4bq94bq94bq9xozDrMOsw6zhua8g4bmv4bipw6zDrMOsxaEgxIPEg8SDxowsIMO9w73DvcO2w7bDtseax5rHmifGmsaaIOC4geC4teC5ieG6veG6veG6veG6veG6veG6vcaMIOG5r8O2w7bDtiDEo8O2w7bDtiDDn8SDxIPEg8OnxLcg4bmvw7bDtsO2IOG5r%2BG4qeG6veG6veG6vSDFocO9w73DvcWh4bmv4bq94bq94bq9bcyCIMO9w73DvcO2w7bDtseax5rHmiDHmseax5rFoeG6veG6veG6vcaMIOG5r8O2w7bDtiDGpcO2w7bDtsWh4bmvIMOsw6zDrOG5ry5dIiwiVGhpcyBhZCBoYXMgZXhwaXJlZCBhbmQgY2FuIG5vIGxvbmdlciBiZSBlZGl0ZWQuIjoiW%2BG5ruG4qcOsw6zDrMWhIMSDxIPEg8aMIOG4qcSDxIPEg8WhIOG6veG6veG6veqtlcalw6zDrMOsxZnhur3hur3hur3GjCDEg8SDxIPguIHguLXguYnGjCDDp8SDxIPEg%2BC4geC4teC5iSDguIHguLXguYnDtsO2w7YgxprDtsO2w7bguIHguLXguYnEo%2BG6veG6veG6vcWZIMOf4bq94bq94bq9IOG6veG6veG6vcaMw6zDrMOs4bmv4bq94bq94bq9xowuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    