
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiLguJXguYjguLMiLCJOT1JNQUwiOiLguJvguLLguJnguIHguKXguLLguIciLCJISUdIIjoi4Liq4Li54LiHIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoi4LiV4LmI4LizOiDguJnguYnguK3guKLguIHguKfguYjguLIge21pbn0iLCJOT1JNQUwgdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguJvguLLguJnguIHguKXguLLguIc6IHttaW59IOC4luC4tuC4hyB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguKrguLnguIc6IOC4oeC4suC4geC4geC4p%2BC5iOC4siB7bWF4fSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiLguJXguYjguLMiLCJOT1JNQUwiOiLguJvguLLguJnguIHguKXguLLguIciLCJISUdIIjoi4Liq4Li54LiHIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoi4LiV4LmI4LizOiDguJnguYnguK3guKLguIHguKfguYjguLIge21pbn0iLCJOT1JNQUwgdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguJvguLLguJnguIHguKXguLLguIc6IHttaW59IOC4luC4tuC4hyB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguKrguLnguIc6IOC4oeC4suC4geC4geC4p%2BC5iOC4siB7bWF4fSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJSRU5EQUgiLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiVElOR0dJIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiUkVOREFIOiBLdXJhbmcgZGFyaSB7bWlufSIsIk5PUk1BTCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6Ik5PUk1BTDoge21pbn0gaGluZ2dhIHttYXh9IiwiSElHSCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IlRJTkdHSTogTGViaWggZGFyaSB7bWF4fSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJSRU5EQUgiLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiVElOR0dJIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiUkVOREFIOiBLdXJhbmcgZGFyaSB7bWlufSIsIk5PUk1BTCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6Ik5PUk1BTDoge21pbn0gaGluZ2dhIHttYXh9IiwiSElHSCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IlRJTkdHSTogTGViaWggZGFyaSB7bWF4fSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiLguJXguYjguLMiLCJOT1JNQUwiOiLguJvguLLguJnguIHguKXguLLguIciLCJISUdIIjoi4Liq4Li54LiHIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoi4LiV4LmI4LizOiDguJnguYnguK3guKLguIHguKfguYjguLIge21pbn0iLCJOT1JNQUwgdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguJvguLLguJnguIHguKXguLLguIc6IHttaW59IOC4luC4tuC4hyB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguKrguLnguIc6IOC4oeC4suC4geC4geC4p%2BC5iOC4siB7bWF4fSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiLguJXguYjguLMiLCJOT1JNQUwiOiLguJvguLLguJnguIHguKXguLLguIciLCJISUdIIjoi4Liq4Li54LiHIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoi4LiV4LmI4LizOiDguJnguYnguK3guKLguIHguKfguYjguLIge21pbn0iLCJOT1JNQUwgdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguJvguLLguJnguIHguKXguLLguIc6IHttaW59IOC4luC4tuC4hyB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiLguKrguLnguIc6IOC4oeC4suC4geC4geC4p%2BC5iOC4siB7bWF4fSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJSRU5EQUgiLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiVElOR0dJIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiUkVOREFIOiBLdXJhbmcgZGFyaSB7bWlufSIsIk5PUk1BTCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6Ik5PUk1BTDoge21pbn0gaGluZ2dhIHttYXh9IiwiSElHSCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IlRJTkdHSTogTGViaWggZGFyaSB7bWF4fSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJSRU5EQUgiLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiVElOR0dJIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiUkVOREFIOiBLdXJhbmcgZGFyaSB7bWlufSIsIk5PUk1BTCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6Ik5PUk1BTDoge21pbn0gaGluZ2dhIHttYXh9IiwiSElHSCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IlRJTkdHSTogTGViaWggZGFyaSB7bWF4fSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJMT1ciLCJOT1JNQUwiOiJOT1JNQUwiLCJISUdIIjoiSElHSCIsIkxPVyB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IkxPVzogTGVzcyB0aGFuIHttaW59IiwiTk9STUFMIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiTk9STUFMOiB7bWlufSB0byB7bWF4fSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJISUdIOiBNb3JlIHRoYW4ge21heH0ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJbxYHDlsW0XSIsIk5PUk1BTCI6IlvDkcOWxZjhuYLhuqzFgV0iLCJISUdIIjoiW%2BG4qMOPx6bhuKhdIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiW8WBw5bFtDogxYHhur3hur3hur3hur3FocWhIOG5r%2BG4qcSDxIPEg8SD4LiB4Li14LmJIHttaW59XSIsIk5PUk1BTCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IlvDkcOWxZjhuYLhuqzFgToge21pbn0g4bmvw7bDtsO2IHttYXh9XSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJb4biow4%2FHpuG4qDog4bmCw7bDtsO2w7bFmeG6veG6veG6veG6vSDhua%2FhuKnEg8SDxIPEg%2BC4geC4teC5iSB7bWF4fV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJMT1ciOiJbxYHDlsW0XSIsIk5PUk1BTCI6IlvDkcOWxZjhuYLhuqzFgV0iLCJISUdIIjoiW%2BG4qMOPx6bhuKhdIiwiTE9XIHRvb2x0aXAgY29udGVudCBmb3IgQWQgUmF0aW5nIGdhdWdlIjoiW8WBw5bFtDogxYHhur3hur3hur3hur3FocWhIOG5r%2BG4qcSDxIPEg8SD4LiB4Li14LmJIHttaW59XSIsIk5PUk1BTCB0b29sdGlwIGNvbnRlbnQgZm9yIEFkIFJhdGluZyBnYXVnZSI6IlvDkcOWxZjhuYLhuqzFgToge21pbn0g4bmvw7bDtsO2IHttYXh9XSIsIkhJR0ggdG9vbHRpcCBjb250ZW50IGZvciBBZCBSYXRpbmcgZ2F1Z2UiOiJb4biow4%2FHpuG4qDog4bmCw7bDtsO2w7bFmeG6veG6veG6veG6vSDhua%2FhuKnEg8SDxIPEg%2BC4geC4teC5iSB7bWF4fV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    