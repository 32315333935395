import { useTranslations } from '@vocab/react';
import {
  Box,
  Text,
  IconPeople,
  Heading,
  Stack,
  ButtonLink,
  Strong,
} from 'braid-design-system';

import { useConfig } from 'src/App/ConfigContext';
import { SecondarySection } from 'src/components/SecondarySection/SecondarySection';
import { useMobileView } from 'src/hooks/useMobileView';
import { trackEvent } from 'src/utils/tealiumEventTracker';

import translations from './.vocab';

export interface TalentSearchRecommendationProps {
  jobId: string;
  advertiserId: string;
}

export const TalentSearchRecommendation = ({
  jobId,
  advertiserId,
}: TalentSearchRecommendationProps) => {
  const { t } = useTranslations(translations);
  const { absoluteUrlResolver } = useConfig();
  const talentSearchUrl = absoluteUrlResolver(
    `/talentsearch/search/job/${jobId.replace(
      'demo',
      '',
    )}/profiles?lastUpdated=6&utm_source=ahc&advertiserId=${advertiserId}`,
  );

  const talentSearchButtonClicked = () => {
    trackEvent('talent_search_job_match_pressed', {
      objectInteraction: 'apr-recommendations-talent-search-section',
    });
  };

  const isMobile = useMobileView();

  return (
    <SecondarySection
      data-testid="ui-talent-search-recommendation"
      icon={
        <Box
          background="infoLight"
          borderRadius="full"
          padding="small"
          style={{ width: '56px' }}
        >
          <IconPeople tone="info" />
        </Box>
      }
      titleComponent={
        <Box>
          <Heading level="4">
            {t('Proactively connect with more candidates')}
          </Heading>
        </Box>
      }
      subTitleComponent={t(
        'Matching candidates for ad have been found in Talent Search',
        {
          Stack: (children: ReactNodeNoStrings) => (
            <Stack space="medium">{children}</Stack>
          ),
          Strong: (children) => <Strong>{children}</Strong>,
          Text: (children) => <Text>{children}</Text>,
        },
      )}
      tailComponent={
        <Box style={{ width: isMobile ? '50%' : undefined }}>
          <ButtonLink
            href={talentSearchUrl}
            variant="solid"
            data-testid="ui-talent-search-link"
            onClick={talentSearchButtonClicked}
          >
            {t('View matches')}
          </ButtonLink>
        </Box>
      }
    />
  );
};
