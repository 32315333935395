import { useTranslations } from '@vocab/react';
import { Box, Heading, Stack, Text } from 'braid-design-system';
import { addDays } from 'date-fns';

import { useConfig } from 'src/App/ConfigContext';
import { SearchHeroIcon } from 'src/assets/icons/SearchHeroIcon/SearchHeroIcon';
import { LAUNCH_DATES } from 'src/config';
import { formatDate } from 'src/utils/date';

import translations from './.vocab';

export const HistoricalConversion = () => {
  const { t } = useTranslations(translations);
  const { site } = useConfig();

  return LAUNCH_DATES[site] ? (
    <Box paddingTop="small">
      <Stack align="center" space="xlarge">
        <SearchHeroIcon />
        <Stack align="center" space="large">
          <Heading level="3" align="center">
            {t('Data not available')}
          </Heading>
          <>
            {t(
              'Data not available due to filtering before or from launch date',
              {
                Stack: (children: ReactNodeNoStrings) => (
                  <Stack align="center" space="small">
                    {children}
                  </Stack>
                ),
                Text: (children) => <Text align="center">{children}</Text>,
                availabilityDate: formatDate({
                  date: addDays(new Date(LAUNCH_DATES[site]), 1),
                }),
              },
            )}
          </>
        </Stack>
      </Stack>
    </Box>
  ) : null;
};
