import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { List, Stack, Text } from 'braid-design-system';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { AdRatingOption } from 'src/types/AdUsageFiltersResponse';
import type { FiltersAdRatingType } from 'src/types/AdUsageTypes';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import { getOptionLabel } from '../Filters.formatters';

import translations from './.vocab';
import { sortAdPerformanceTypes } from './AdPerformanceFilter.formatter';

export interface AdPerformanceFilterProps {
  adPerformanceTypes: AdRatingOption[];
}

export const AdPerformanceFilter = ({
  adPerformanceTypes,
}: AdPerformanceFilterProps) => {
  const { t } = useTranslations(translations);
  const {
    adUsageFilters: { adRatings: selectedAdPerformanceTypes },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();

  const {
    setIsFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const typedValue = value as FiltersAdRatingType;
    const newSelected = selectedAdPerformanceTypes.includes(typedValue)
      ? [
          ...selectedAdPerformanceTypes.filter(
            (singleSelected) => singleSelected !== typedValue,
          ),
        ]
      : [...selectedAdPerformanceTypes, typedValue];

    trackEvent('filter_pressed', {
      isFilterAdPerformance: newSelected.length > 0,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'ad performance',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_PERFORMANCE_TYPES,
      value: newSelected,
    });

    setIsFilterAdPerformance(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_PERFORMANCE_TYPES,
      value: [],
    });

  const formattedAdPerformanceTypes = sortAdPerformanceTypes(
    adPerformanceTypes,
  ).map((data) => ({
    ...data,
    value: t(data.key),
  }));

  const displayedValue = getOptionLabel(
    formattedAdPerformanceTypes,
    selectedAdPerformanceTypes,
    t,
    t('All ratings'),
  );

  const tooltip = (
    <Stack space="medium">
      <Text>{t('We rate the performance of your ads label tooltip text')}</Text>
      <List>
        <Text>
          {t('We rate the performance of your ads label tooltip high pointer')}
        </Text>
        <Text>
          {t(
            'We rate the performance of your ads label tooltip normal pointer',
          )}
        </Text>
        <Text>
          {t('We rate the performance of your ads label low pointer')}
        </Text>
        <Text>
          {t('We rate the performance of your ads label no rating pointer')}
        </Text>
      </List>
    </Stack>
  );

  return (
    <MultiSelectDropdown
      options={formattedAdPerformanceTypes}
      label={t('Ad performance')}
      selected={selectedAdPerformanceTypes}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      searchPlaceholder="search"
      clearSelection={clearSelection}
      isNvlEnabled={true}
      tooltipProps={{
        id: 'ad-performance-filter-tooltip',
        title: t('Ad performance'),
        contents: tooltip,
      }}
    />
  );
};
