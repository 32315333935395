import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  IconMoney,
  List,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import type {
  PostTypeCategory,
  SalaryType,
} from 'src/types/AdPerformanceTypes';
import { formatSalary } from 'src/utils/formatters/salaryFormatter';
import { trackEvent } from 'src/utils/tealiumEventTracker';

import { Medium } from '../Medium/Medium';
import { RecommendationSkeleton } from '../RecommendationSkeleton/RecommendationSkeleton';
import { SalaryBar } from '../SalaryBar/SalaryBar';

import localTranslations from './.vocab';

export interface LowSalaryRecommendationProps {
  jobTitle?: string;
  salaryRangeMin?: number;
  salaryRangeMax?: number;
  salaryType?: SalaryType;
  suggestedRangeMin?: number;
  suggestedRangeMax?: number;
  suggestedAverageRangeMin?: number;
  suggestedAverageRangeMax?: number;
  location?: string;
  currency: string;
  editAdUrl: string;
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  coverTitle?: string;
  coverSubTitle?: string;
  showPaySuggestion?: boolean;
  direction?: 'column' | 'row';
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
}

export const LowSalaryRecommendation = ({
  jobTitle,
  isExpired,
  postTypeCategory,
  salaryRangeMin,
  salaryRangeMax,
  salaryType,
  suggestedRangeMin,
  suggestedRangeMax,
  suggestedAverageRangeMin,
  suggestedAverageRangeMax,
  location,
  currency,
  editAdUrl,
  coverTitle,
  coverSubTitle,
  direction = 'row',
  showPaySuggestion = true,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
}: LowSalaryRecommendationProps) => {
  const { t } = useTranslations(localTranslations);

  const toggleModalTrackEvent = (isOpen: boolean) => {
    if (isOpen) {
      trackEvent('view_tip_pressed', {
        tipName: 'low salary',
        objectInteraction: 'apr-recommendations-low-salary-section',
      });
    }
  };

  const title = t('Consider increasing the salary');
  const subTitle = t(
    "On average, employers pay more than the range you've chosen for your job.",
  );

  const renderSelectedPayRange = () => {
    const payRangeProps = {
      fromSalary: formatSalary(salaryRangeMin!, {
        currency,
        display: 'code',
      }),
      toSalary: formatSalary(salaryRangeMax!, {
        currency,
        display: 'code',
      }),
      Medium: (children: ReactNode) => <Medium>{children}</Medium>,
    };

    if (salaryType === 'ANNUAL_COMMISSION' || salaryType === 'ANNUAL_PACKAGE') {
      return t(
        'Your selected pay range: {fromSalary} to {toSalary} annually',
        payRangeProps,
      );
    }

    if (salaryType === 'MONTHLY') {
      return t(
        'Your selected pay range: {fromSalary} to {toSalary} monthly',
        payRangeProps,
      );
    }

    if (salaryType === 'HOURLY') {
      return t(
        'Your selected pay range: {fromSalary} to {toSalary} hourly',
        payRangeProps,
      );
    }

    return t(
      'Your selected pay range: {fromSalary} to {toSalary}',
      payRangeProps,
    );
  };

  return (
    <RecommendationSkeleton
      titleContent={title}
      subtitleContent={subTitle}
      isExpired={isExpired}
      postTypeCategory={postTypeCategory}
      editAdUrl={editAdUrl}
      onEditAdButtonClicked={onEditAdButtonClicked}
      onEditAdIndirectButtonClicked={onEditAdIndirectButtonClicked}
      coverIcon={<IconMoney tone="info" />}
      toggleModalTrackEvent={toggleModalTrackEvent}
      dataId="ui-low-salary-recommendation"
      coverTitle={coverTitle}
      coverSubTitle={coverSubTitle}
      direction={direction}
    >
      <Stack space="large">
        <Columns
          space={{ mobile: 'large', tablet: 'large' }}
          collapseBelow="tablet"
        >
          {showPaySuggestion ? (
            <Column width="2/3">
              <Box data={{ id: 'salary-frequency' }}>
                <Stack space="medium">
                  <Text>{renderSelectedPayRange()}</Text>
                  <Box
                    paddingY="medium"
                    style={{ backgroundColor: '#f5f6f8' }}
                    marginBottom="medium"
                  >
                    <Box paddingTop="small" paddingX="gutter">
                      <Text>
                        {t(
                          salaryType === 'HOURLY'
                            ? 'Usual hourly pay range for role and location'
                            : 'Usual pay range for role and location',
                          {
                            roleTitle: jobTitle!,
                            location: location!,
                            minSalary: formatSalary(suggestedAverageRangeMin!, {
                              currency,
                            }),
                            maxSalary: formatSalary(suggestedAverageRangeMax!, {
                              currency,
                            }),
                            Strong: (children) => <Strong>{children}</Strong>,
                          },
                        )}
                      </Text>
                    </Box>
                    <Box
                      marginTop="medium"
                      marginBottom={['none', 'small']}
                      paddingX={['none', 'large']}
                    >
                      <SalaryBar
                        salaryRangeMin={salaryRangeMin!}
                        salaryRangeMax={salaryRangeMax!}
                        suggestedRangeMin={suggestedRangeMin!}
                        suggestedRangeMax={suggestedRangeMax!}
                        suggestedAverageRangeMin={suggestedAverageRangeMin!}
                        suggestedAverageRangeMax={suggestedAverageRangeMax!}
                        currency={currency}
                      />
                    </Box>
                  </Box>
                </Stack>
                <Text>
                  {t(
                    "Click the 'Edit ad' button to edit your selected pay range for this ad.",
                  )}
                </Text>
              </Box>
            </Column>
          ) : null}
          <Column width={showPaySuggestion ? '1/3' : 'content'}>
            {t('Not possible to increase salary tip', {
              Stack: (children: ReactNodeNoStrings) => (
                <Stack space="medium">{children}</Stack>
              ),
              List: (children: ReactNodeNoStrings) => <List>{children}</List>,
              Strong: (children) => <Text weight="strong">{children}</Text>,
              Text: (children) => <Text>{children}</Text>,
            })}
          </Column>
        </Columns>
      </Stack>
    </RecommendationSkeleton>
  );
};
