import { Stack } from 'braid-design-system';
import { useState } from 'react';

import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { RoundedCard } from 'src/components/RoundedCard/RoundedCard';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { useMobileView } from 'src/hooks/useMobileView';
import { AdRating } from 'src/widgets/AdRating/AdRating';
import { AdRatingToolbar } from 'src/widgets/AdRatingToolbar/AdRatingToolbar';

export const AdRatingSection = () => {
  const [hasToolbarError, setHasToolbarError] = useState(false);
  const [hasAdRatingError, setHasAdRatingError] = useState(false);

  const isMobile = useMobileView();

  const handleToolbarError = () => {
    setHasToolbarError(true);
  };

  const handleAdRatingError = () => {
    setHasAdRatingError(true);
  };

  return (
    <RoundedCard
      boxShadow={!isMobile ? 'borderNeutralLight' : undefined}
      padding={!isMobile ? 'gutter' : 'none'}
    >
      <Stack space="medium">
        {!hasToolbarError && (
          <ErrorBoundary fallback={null} onError={handleToolbarError}>
            <AdRatingToolbar onQueryError={handleToolbarError} />
          </ErrorBoundary>
        )}
        {(hasToolbarError || hasAdRatingError) && <WidgetError />}
        {!hasAdRatingError && (
          <ErrorBoundary fallback={null} onError={handleAdRatingError}>
            <AdRating onQueryError={handleAdRatingError} />
          </ErrorBoundary>
        )}
      </Stack>
    </RoundedCard>
  );
};
