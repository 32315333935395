
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h%2BC4muC4suC4h%2BC4hOC4meC4l%2BC4teC5iOC4quC4suC4oeC4suC4o%2BC4luC5gOC4q%2BC5h%2BC4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0PuC4q%2BC4suC4geC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4suC4o%2BC5gOC4guC5ieC4suC4luC4tuC4h%2BC4guC5ieC4reC4oeC4ueC4peC5gOC4q%2BC4peC5iOC4suC4meC4teC5iSDguIHguKPguLjguJPguLLguYHguIjguYnguIfguYHguK3guJTguKHguLTguJnguILguK3guIfguITguLjguJPguYDguJ7guLfguYjguK3guILguK3guKrguLTguJfguJjguLTguYzguYPguJnguIHguLLguKPguYDguILguYnguLLguJbguLY8L1RleHQ%2BPFRleHQ%2B4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4LitPFRleHRMaW5rPuC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jDwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h%2BC4muC4suC4h%2BC4hOC4meC4l%2BC4teC5iOC4quC4suC4oeC4suC4o%2BC4luC5gOC4q%2BC5h%2BC4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0PuC4q%2BC4suC4geC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4suC4o%2BC5gOC4guC5ieC4suC4luC4tuC4h%2BC4guC5ieC4reC4oeC4ueC4peC5gOC4q%2BC4peC5iOC4suC4meC4teC5iSDguIHguKPguLjguJPguLLguYHguIjguYnguIfguYHguK3guJTguKHguLTguJnguILguK3guIfguITguLjguJPguYDguJ7guLfguYjguK3guILguK3guKrguLTguJfguJjguLTguYzguYPguJnguIHguLLguKPguYDguILguYnguLLguJbguLY8L1RleHQ%2BPFRleHQ%2B4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4LitPFRleHRMaW5rPuC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jDwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0Pkppa2EgQW5kYSBtZXJhc2Egc2VoYXJ1c255YSBtZW1pbGlraSBha3NlcywgbWludGFsYWggYWRtaW5pc3RyYXRvciB1bnR1ayBtZW1iZXJpa2FuIGFrc2VzLjwvVGV4dD48VGV4dD5BdGF1IGh1YnVuZ2kgPFRleHRMaW5rPnRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pLjwvVGV4dExpbms%2BPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0Pkppa2EgQW5kYSBtZXJhc2Egc2VoYXJ1c255YSBtZW1pbGlraSBha3NlcywgbWludGFsYWggYWRtaW5pc3RyYXRvciB1bnR1ayBtZW1iZXJpa2FuIGFrc2VzLjwvVGV4dD48VGV4dD5BdGF1IGh1YnVuZ2kgPFRleHRMaW5rPnRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pLjwvVGV4dExpbms%2BPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h%2BC4muC4suC4h%2BC4hOC4meC4l%2BC4teC5iOC4quC4suC4oeC4suC4o%2BC4luC5gOC4q%2BC5h%2BC4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0PuC4q%2BC4suC4geC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4suC4o%2BC5gOC4guC5ieC4suC4luC4tuC4h%2BC4guC5ieC4reC4oeC4ueC4peC5gOC4q%2BC4peC5iOC4suC4meC4teC5iSDguIHguKPguLjguJPguLLguYHguIjguYnguIfguYHguK3guJTguKHguLTguJnguILguK3guIfguITguLjguJPguYDguJ7guLfguYjguK3guILguK3guKrguLTguJfguJjguLTguYzguYPguJnguIHguLLguKPguYDguILguYnguLLguJbguLY8L1RleHQ%2BPFRleHQ%2B4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4LitPFRleHRMaW5rPuC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jDwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IuC4oeC4teC5gOC4nuC4teC4ouC4h%2BC4muC4suC4h%2BC4hOC4meC4l%2BC4teC5iOC4quC4suC4oeC4suC4o%2BC4luC5gOC4q%2BC5h%2BC4meC4guC5ieC4reC4oeC4ueC4peC4meC4teC5ieC5hOC4lOC5iSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0PuC4q%2BC4suC4geC4hOC4uOC4k%2BC4leC5ieC4reC4h%2BC4geC4suC4o%2BC5gOC4guC5ieC4suC4luC4tuC4h%2BC4guC5ieC4reC4oeC4ueC4peC5gOC4q%2BC4peC5iOC4suC4meC4teC5iSDguIHguKPguLjguJPguLLguYHguIjguYnguIfguYHguK3guJTguKHguLTguJnguILguK3guIfguITguLjguJPguYDguJ7guLfguYjguK3guILguK3guKrguLTguJfguJjguLTguYzguYPguJnguIHguLLguKPguYDguILguYnguLLguJbguLY8L1RleHQ%2BPFRleHQ%2B4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4LitPFRleHRMaW5rPuC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jDwvVGV4dExpbms%2B4LiC4Lit4LiH4LmA4Lij4LiyPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0Pkppa2EgQW5kYSBtZXJhc2Egc2VoYXJ1c255YSBtZW1pbGlraSBha3NlcywgbWludGFsYWggYWRtaW5pc3RyYXRvciB1bnR1ayBtZW1iZXJpa2FuIGFrc2VzLjwvVGV4dD48VGV4dD5BdGF1IGh1YnVuZ2kgPFRleHRMaW5rPnRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pLjwvVGV4dExpbms%2BPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IkhhbnlhIGJlYmVyYXBhIG9yYW5nIHlhbmcgZGFwYXQgbWVsaWhhdCBkYXRhIGluaSIsIk5vIGFjY2VzcyBwYWdlIGRlc2NyaXB0aW9uIjoiPFN0YWNrPjxUZXh0Pkppa2EgQW5kYSBtZXJhc2Egc2VoYXJ1c255YSBtZW1pbGlraSBha3NlcywgbWludGFsYWggYWRtaW5pc3RyYXRvciB1bnR1ayBtZW1iZXJpa2FuIGFrc2VzLjwvVGV4dD48VGV4dD5BdGF1IGh1YnVuZ2kgPFRleHRMaW5rPnRpbSBMYXlhbmFuIFBlbGFuZ2dhbiBrYW1pLjwvVGV4dExpbms%2BPC9UZXh0PjwvU3RhY2s%2BIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6Ik9ubHkgc29tZSBwZW9wbGUgY2FuIHNlZSB0aGlzIGRhdGEiLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6IjxTdGFjaz48VGV4dD5JZiB5b3UgdGhpbmsgeW91IHNob3VsZCBiZSBvbmUgb2YgdGhlbSwgYXNrIHlvdXIgc3lzdGVtIGFkbWluaXN0cmF0b3IgdG8gZ2l2ZSB5b3UgYWNjZXNzLjwvVGV4dD48VGV4dD5PciByZWFjaCBvdXQgdG8gb3VyIDxUZXh0TGluaz5DdXN0b21lciBTZXJ2aWNlIHRlYW0uPC9UZXh0TGluaz48L1RleHQ%2BPC9TdGFjaz4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IlvDluC4geC4teC5icaaw73DvcO9IMWhw7bDtsO2bcyC4bq94bq94bq9IMal4bq94bq94bq9w7bDtsO2xqXGmuG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5iSDFoeG6veG6veG6veG6veG6veG6vSDhua%2FhuKnDrMOsw6zFoSDGjMSDxIPEg%2BG5r8SDxIPEg10iLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6Ils8U3RhY2s%2BPFRleHQ%2Bw4%2FGkiDDvcO9w73DtsO2w7bHmseax5og4bmv4bipw6zDrMOs4LiB4Li14LmJxLcgw73DvcO9w7bDtsO2x5rHmseaIMWh4bipw7bDtsO2x5rHmseaxprGjCDDn%2BG6veG6veG6vSDDtsO2w7bguIHguLXguYnhur3hur3hur0gw7bDtsO2xpIg4bmv4bip4bq94bq94bq9bcyCLCDEg8SDxIPFocS3IMO9w73DvcO2w7bDtseax5rHmsWZIMWhw73DvcO9xaHhua%2Fhur3hur3hur1tzIIgxIPEg8SDxoxtzILDrMOsw6zguIHguLXguYnDrMOsw6zFoeG5r8WZxIPEg8SD4bmvw7bDtsO2xZkg4bmvw7bDtsO2IMSjw6zDrMOs4bm94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmiDEg8SDxIPDp8On4bq94bq94bq9xaHFoS48L1RleHQ%2BPFRleHQ%2Bw5bFmSDFmeG6veG6veG6vcSDxIPEg8On4bipIMO2w7bDtseax5rHmuG5ryDhua%2FDtsO2w7Ygw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOHx5rHmseaxaHhua%2FDtsO2w7ZtzILhur3hur3hur3FmSDFoOG6veG6veG6vcWZ4bm9w6zDrMOsw6fhur3hur3hur0g4bmv4bq94bq94bq9xIPEg8SDbcyCLjwvVGV4dExpbms%2BPC9UZXh0PjwvU3RhY2s%2BXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBhY2Nlc3MgcGFnZSB0aXRsZSI6IlvDluC4geC4teC5icaaw73DvcO9IMWhw7bDtsO2bcyC4bq94bq94bq9IMal4bq94bq94bq9w7bDtsO2xqXGmuG6veG6veG6vSDDp8SDxIPEg%2BC4geC4teC5iSDFoeG6veG6veG6veG6veG6veG6vSDhua%2FhuKnDrMOsw6zFoSDGjMSDxIPEg%2BG5r8SDxIPEg10iLCJObyBhY2Nlc3MgcGFnZSBkZXNjcmlwdGlvbiI6Ils8U3RhY2s%2BPFRleHQ%2Bw4%2FGkiDDvcO9w73DtsO2w7bHmseax5og4bmv4bipw6zDrMOs4LiB4Li14LmJxLcgw73DvcO9w7bDtsO2x5rHmseaIMWh4bipw7bDtsO2x5rHmseaxprGjCDDn%2BG6veG6veG6vSDDtsO2w7bguIHguLXguYnhur3hur3hur0gw7bDtsO2xpIg4bmv4bip4bq94bq94bq9bcyCLCDEg8SDxIPFocS3IMO9w73DvcO2w7bDtseax5rHmsWZIMWhw73DvcO9xaHhua%2Fhur3hur3hur1tzIIgxIPEg8SDxoxtzILDrMOsw6zguIHguLXguYnDrMOsw6zFoeG5r8WZxIPEg8SD4bmvw7bDtsO2xZkg4bmvw7bDtsO2IMSjw6zDrMOs4bm94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmiDEg8SDxIPDp8On4bq94bq94bq9xaHFoS48L1RleHQ%2BPFRleHQ%2Bw5bFmSDFmeG6veG6veG6vcSDxIPEg8On4bipIMO2w7bDtseax5rHmuG5ryDhua%2FDtsO2w7Ygw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOHx5rHmseaxaHhua%2FDtsO2w7ZtzILhur3hur3hur3FmSDFoOG6veG6veG6vcWZ4bm9w6zDrMOsw6fhur3hur3hur0g4bmv4bq94bq94bq9xIPEg8SDbcyCLjwvVGV4dExpbms%2BPC9UZXh0PjwvU3RhY2s%2BXSJ9!"
        )
      )
      });
  
      export { translations as default };
    