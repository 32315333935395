import 'braid-design-system/reset';
import { HirerAuthProvider } from '@seek/hirer-auth-react';
import { FeatureToggleProvider } from '@seek/hirer-feature-toggle';
import { HirerApolloProvider } from '@seek/hirer-graphql-react';
import { VocabProvider } from '@vocab/react';
import { BraidProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { logError } from 'src/api/hirer-api/hirerApi.loggers';
import type { Config } from 'src/config';
import { featureConfigs } from 'src/utils/featureToggle/config';
import { region } from 'src/utils/locale';

import { ConfigContext, useConfig } from './ConfigContext';
import { Router } from './Router';

export interface AppProps {
  config: Config;
}

export const App = ({ config }: AppProps) => (
  <StrictMode>
    <ConfigContext.Provider value={config}>
      <AppWithConfig />
    </ConfigContext.Provider>
  </StrictMode>
);

export const AppWithConfig = () => {
  const { locale, site, version, graphUrlOverride, environment } = useConfig();
  region.initialise(locale);

  return (
    <VocabProvider language={locale ?? 'en-AU'}>
      <HirerAuthProvider
        authenticationLevel={environment === 'mock' ? 'Mocked' : 'Hirer'}
      >
        <HirerApolloProvider
          appName="hirer-ad-usage-ui"
          site={site}
          siteVersion={version}
          uri={graphUrlOverride}
          logError={logError}
        >
          <BraidProvider theme={seekJobs}>
            <FeatureToggleProvider
              appName="hirer-ad-usage-ui"
              featureConfigs={featureConfigs}
              isOverridesAllowed
              isOverridesIndicatorBannerEnabled
            >
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </FeatureToggleProvider>
          </BraidProvider>
        </HirerApolloProvider>
      </HirerAuthProvider>
    </VocabProvider>
  );
};
