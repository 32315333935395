import 'braid-design-system/reset';

import { VocabProvider } from '@vocab/react';
import { Box, BraidProvider, Loader, Page } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';

import { AdCentreFooter } from 'src/components/AdCentreFooter/AdCentreFooter';
import { Header } from 'src/components/Header/Header';
import type { Config } from 'src/config';

import { Link } from './Link';

export interface AppProps {
  config?: Config;
}

export const AppLoader = ({ config }: AppProps) => (
  <BraidProvider theme={seekJobs} linkComponent={Link}>
    <VocabProvider language={config?.locale ?? 'en'}>
      <Page footerPosition="belowFold" footer={<AdCentreFooter />}>
        <Header hirerId="" />
        <Box display="flex" justifyContent="center" padding="large">
          <Loader delayVisibility />
        </Box>
      </Page>
    </VocabProvider>
  </BraidProvider>
);
